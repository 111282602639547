import {
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
  Grid,
  CircularProgress,
  Collapse,
} from '@material-ui/core';
import { Alert, Autocomplete } from '@material-ui/lab';
import { BucketId, ClientId, PluginId, verticalDropdownItems } from '@runway/devkit-common';
import { useApi } from '@backstage/core-plugin-api';
import { Control, Controller, useForm, useWatch } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import React, { useState, useEffect, ChangeEvent } from 'react';
import { createStyles, makeStyles, Theme, useTheme } from '@material-ui/core/styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import { AzureManagementServiceApiRef, aaPingSSOAuthApiRef } from '../../../services';
import { AADFormValues, AADGroupSchema, AdminToggled, CmdbAppShortName, buildAADGroupName } from '../../../components';
import { useEmployeeProfile, useTrackedRequest } from '../../../hooks';
import { AMS_MAINTENANCE_MODE } from '../../../featureFlags';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      backgroundColor: theme.palette.background.default,
      borderRadius: 4,
      padding: theme.spacing(3),
      border: `1px solid ${theme.palette.primary.light}`,
    },
    cancelAndCreate: {
      margin: '2px',
    },
    center: {
      display: 'flex',
      justifyContent: 'center',
    },
  }),
);

const VERTICALS_OPTIONS = verticalDropdownItems;
const ENVIRONMENT_OPTIONS = [
  { name: 'Prod', value: 'P' },
  { name: 'Non Prod', value: 'N' },
  { name: 'Stage', value: 'S' },
  { name: 'Test', value: 'T' },
  { name: 'Dev', value: 'D' },
];
const SCOPE_OPTIONS = [
  { name: 'Subscription', value: 'SUB' },
  { name: 'Resource Group', value: 'RG' },
];
const TENANT_OPTIONS = [
  { name: 'CORPAA', value: 'p' },
  { name: 'QCORPAA', value: 't' },
];
const AZURE_ROLE_OPTIONS = ['OWNER', 'CONTRIBUTOR', 'READER+'];

const initialState = {
  vertical: '',
  scope: '',
  role: '',
  appName: '',
  tenant: '',
  users: [],
};

enum ResultState {
  INITIAL,
  LOADING,
  SUCCESS,
  FAILURE,
}

enum DropdownFields {
  VERTICAL = 'Vertical',
  ENVIRONMENT = 'Env',
  SCOPE = 'Scope',
  TENANT = 'Tenant',
}

enum UserType {
  OWNER = 'Owner',
  MEMBER = 'Member',
}

type DropdownProps = {
  fieldName: string;
  fieldOptions: { name: string; value: string }[];
  register: object;
  value: string | number | readonly string[] | undefined;
  optionalTitle?: string;
};

type CreateAADGroupDropdownProps = {
  submitForm: (values: AADFormValues) => Promise<void>;
  handleToggle: () => void;
  result: { state: ResultState; message: string };
};

const MakeAADGroupName = ({ control }: { control: Control<AADFormValues> }) => {
  const aadGroupName = useWatch({ control });
  const theme = useTheme();
  const previewAADGroupName = () => buildAADGroupName(aadGroupName).replace('NULL', '');
  return (
    <>
      <Typography aria-label="aad-preview" variant="body1">
        AAD Group Name: <span style={{ color: theme.palette.text.secondary }}>{previewAADGroupName()}</span>
      </Typography>
    </>
  );
};

const AzureRoleDropdown = ({ onChange }: { onChange: (value: string | null) => void }) => {
  const [inputError, setInputError] = useState(false);
  const validateInput = (input: string) => input.match(/^[A-Z0-9]{1,11}$/);
  const handleTextFieldOnChange = (event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    if (validateInput(event.target.value)) {
      onChange(event.target.value);
      setInputError(false);
      return;
    }
    setInputError(true);
    onChange('');
  };
  const handleOnChange = (_: unknown, value: string | null) => {
    setInputError(false);
    onChange(value);
  };
  return (
    <>
      <Autocomplete
        freeSolo
        onChange={handleOnChange}
        options={AZURE_ROLE_OPTIONS}
        renderInput={params => (
          <TextField
            {...params}
            error={inputError}
            helperText="Role must be uppercase and alphanumeric"
            onChange={handleTextFieldOnChange}
            label="Role"
            aria-label="azure-role-input"
            InputProps={{ ...params.InputProps }}
            required
          />
        )}
      />
    </>
  );
};

const Dropdown = ({ fieldName, fieldOptions, register, value, optionalTitle = '' }: DropdownProps) => {
  return (
    <>
      <InputLabel htmlFor={`${fieldName.toLowerCase()}-select`}>{optionalTitle || fieldName}</InputLabel>
      <Select
        id={`${fieldName.toLowerCase()}-select`}
        {...register}
        inputProps={{ 'aria-label': `${fieldName.toLowerCase()}-dropdown` }}
        value={value ?? ''}
        label={fieldName}
      >
        {fieldOptions.map((item, index) => (
          <MenuItem
            key={index}
            value={item.value}
            aria-label={`${fieldName.toLowerCase()}-option-${item.value.toLowerCase()}`}
          >
            {item.name}
          </MenuItem>
        ))}
      </Select>
    </>
  );
};

const CreateAADGroupDropdown = ({ submitForm, handleToggle, result }: CreateAADGroupDropdownProps) => {
  const { handleSubmit, register, control, watch, getValues, setValue, formState } = useForm<AADFormValues>({
    defaultValues: initialState,
    mode: 'onChange',
    shouldUnregister: true,
    resolver: yupResolver(AADGroupSchema),
  });

  const { aaId, displayName: employeeName } = useEmployeeProfile({ withPrefix: false });
  const classes = useStyles();

  useEffect(() => {
    async function setProfile() {
      setValue('users', [
        { id: aaId === '-1' ? undefined : aaId, employeeName, userType: UserType.OWNER },
        { id: aaId === '-1' ? undefined : aaId, employeeName, userType: UserType.MEMBER },
      ]);
    }
    setProfile();
  }, [getValues, setValue, aaId, employeeName]);

  return (
    <>
      <Grid container spacing={2} direction="row" justifyContent="space-around" alignItems="center">
        <Grid item md={12}>
          {result.state === ResultState.FAILURE && (
            <Alert severity="error" aria-label="error-message">
              {result.message}
            </Alert>
          )}
        </Grid>
        <Grid item md={4}>
          <FormControl variant="outlined" fullWidth required>
            <Controller
              name="vertical"
              control={control}
              render={() => (
                <Dropdown
                  fieldName={DropdownFields.VERTICAL}
                  fieldOptions={VERTICALS_OPTIONS}
                  register={register('vertical')}
                  value={getValues().vertical}
                />
              )}
            />
          </FormControl>
        </Grid>
        <Grid item md={4}>
          <FormControl variant="outlined" fullWidth required>
            <Controller
              name="scope"
              control={control}
              render={() => (
                <Dropdown
                  fieldName={DropdownFields.SCOPE}
                  fieldOptions={SCOPE_OPTIONS}
                  register={register('scope')}
                  value={getValues().scope}
                />
              )}
            />
          </FormControl>
        </Grid>
        <Grid item md={4}>
          <FormControl variant="outlined" fullWidth required>
            <Controller
              name="env"
              control={control}
              render={() => (
                <Dropdown
                  fieldName={DropdownFields.ENVIRONMENT}
                  optionalTitle="Environment"
                  fieldOptions={ENVIRONMENT_OPTIONS}
                  register={register('env')}
                  value={getValues().env}
                />
              )}
            />
          </FormControl>
        </Grid>
        <Grid item md={4}>
          <FormControl variant="outlined" fullWidth required>
            <Controller
              name="tenant"
              control={control}
              render={() => (
                <Dropdown
                  fieldName={DropdownFields.TENANT}
                  fieldOptions={TENANT_OPTIONS}
                  register={register('tenant')}
                  value={getValues().tenant}
                />
              )}
            />
          </FormControl>
        </Grid>
        <Grid item md={4}>
          <FormControl variant="outlined" required fullWidth>
            <Controller
              name="role"
              control={control}
              render={({ field: { onChange } }) => <AzureRoleDropdown onChange={onChange} />}
            />
          </FormControl>
        </Grid>
        <Grid item md={4}>
          {watch('scope') === 'RG' && (
            <Controller
              name="appName"
              control={control}
              render={({ field: { onChange } }) => (
                <CmdbAppShortName onChange={value => onChange(value.shortName)} required width={600} />
              )}
            />
          )}
        </Grid>
        <Grid xs={3} item>
          <MakeAADGroupName control={control} />
        </Grid>
        <Grid item xs={6} />
        <Grid xs={3} item className={classes.center}>
          <Button
            variant="outlined"
            onClick={handleToggle}
            className={classes.cancelAndCreate}
            aria-label="cancel"
            disabled={result.state === ResultState.LOADING}
          >
            CANCEL
          </Button>
          <Button
            variant="outlined"
            type="submit"
            aria-label="submit-form"
            className={classes.cancelAndCreate}
            disabled={result.state === ResultState.LOADING || !formState.isValid}
            onClick={handleSubmit(submitForm)}
          >
            CREATE
          </Button>
        </Grid>
      </Grid>
    </>
  );
};

export const CreateActiveDirectoryGroup = () => {
  const classes = useStyles();
  const theme = useTheme();
  const ssoApi = useApi(aaPingSSOAuthApiRef);

  const { tracker } = useTrackedRequest({
    bucket_id: BucketId.USE_METRIC,
    client_id: ClientId.RUNWAY_FRONTEND,
    pluginId: PluginId.TEMPLATE_CREATE_AAD_GROUP.id,
  });

  const [isOpen, setIsOpen] = useState(false);
  const [result, setResult] = useState({ state: ResultState.INITIAL, message: '' });

  const ams = useApi(AzureManagementServiceApiRef);

  const handleToggle = () => {
    setIsOpen(prevIsOpen => !prevIsOpen);
  };

  const submitForm = async (values: AADFormValues) => {
    setResult({ state: ResultState.LOADING, message: '' });
    const aadGroupName = buildAADGroupName(values);
    const users = values.users.map(user => {
      return { employee_id: user.id!, user_type: user.userType! };
    });

    try {
      const identity = await ssoApi.getAAProfile({
        optional: true,
        instantPopup: true,
      });
      const response = await tracker(
        async () => {
          return await ams.createAzureActiveDirectoryGroup({
            activeDirectoryGroupName: aadGroupName,
            tenant: values.tenant,
            users,
          });
        },
        {
          groupName: aadGroupName,
          groupOwner: JSON.stringify(values.users),
          userEmployeeId: identity?.aaId ? identity.aaId : '-1',
        },
      );

      if (response.includes('has been created successfully')) {
        setResult({
          state: ResultState.SUCCESS,
          message: `Azure Active Directory Group Name: ${aadGroupName} has been successfully created`,
        });
        handleToggle();
      }
    } catch (error: any) {
      setResult({
        state: ResultState.FAILURE,
        message: `Failed to create AAD group. If this problem persists, reach out to #Runway on slack with the following: ${error.message}`,
      });
    }
  };

  return (
    <div className={classes.container}>
      <AdminToggled toggleName={AMS_MAINTENANCE_MODE}>
        <Grid container spacing={2} direction="row" justifyContent="space-around" alignItems="center">
          <Grid md={10} item>
            <Typography variant="h6" aria-label="title">
              Create Azure Active Directory Group
            </Typography>
            <Typography variant="subtitle2" aria-label="description">
              Azure Active Directory (AAD) Group serves as a way to control access to clusters, projects and
              applications. To be able to have control over the application you create, you MUST be a member of the AAD
              group associated with the application. Creating an AAD Group with this form will automatically assign you
              as an owner and grant you membership. To update ownership and group members, please use the Azure Portal
              after group creation.
            </Typography>
            <AdminToggled.ShowOnEnabled>
              <Alert severity="warning">
                This feature is currently disabled due to maintenance. Please see{' '}
                <a target="_blank" href="https://americanairlines.slack.com/archives/C0112F1JKNG">
                  #runway
                </a>{' '}
                for updates.
              </Alert>
            </AdminToggled.ShowOnEnabled>
          </Grid>
          <AdminToggled.HideOnEnabled>
            <Grid md={2} item className={classes.center}>
              {!isOpen && result.state !== ResultState.SUCCESS && result.state !== ResultState.LOADING && (
                <Button variant="contained" onClick={handleToggle} aria-label="aad-form-open">
                  CREATE
                </Button>
              )}
              {!isOpen && result.state === ResultState.SUCCESS && (
                <FontAwesomeIcon
                  size="3x"
                  icon={faCheck}
                  aria-label="success-icon"
                  style={{ color: theme.palette.success.main }}
                />
              )}
              {result.state === ResultState.LOADING && <CircularProgress aria-label="progress-spinner" />}
            </Grid>
          </AdminToggled.HideOnEnabled>
        </Grid>
        <AdminToggled.HideOnEnabled>
          <Collapse in={isOpen}>
            {isOpen && <CreateAADGroupDropdown submitForm={submitForm} handleToggle={handleToggle} result={result} />}
          </Collapse>
        </AdminToggled.HideOnEnabled>
      </AdminToggled>
    </div>
  );
};
