interface ResourceGroupName {
  vertical: string;
  env: string;
  envName: string;
  region: string;
  rgDescriptor: string | undefined;
}

interface ServicePrincipalName {
  archerShortname: string;
  vertical: string;
  env: string;
  spDescriptor: string | undefined;
}

export enum EnvName {
  Dev = 'd',
  Test = 't',
  Stage = 's',
  Nonprod = 'n',
  Prod = 'p',
}

export const getEnvName = (env: string): string => {
  let displayEnv = '';
  if (env.length) displayEnv = env === 'dev' ? 'n' : 'p';
  return displayEnv;
};

export const getRGName = (values: ResourceGroupName) => {
  let regionValue = '';
  if (values.region === 'eastus') {
    regionValue = 'zeaus';
  } else if (values.region === 'westus') {
    regionValue = 'zweus';
  }
  return `${values.vertical}-${values.envName}-${regionValue}-${values.rgDescriptor}-rg`;
};

export const getSPName = (values: ServicePrincipalName) => {
  return `${values.vertical}-${getEnvName(values.env)}-${values.archerShortname}-${values.spDescriptor}-***-sp`;
};
