import { BackstageIdentityApi, createApiRef, OAuthApi } from '@backstage/core-plugin-api';
import { graphql } from '@octokit/graphql';
import gitUrlParse from 'git-url-parse';
import yaml from 'js-yaml';
import { Octokit } from '@octokit/rest';

export interface DeleteArgoVars {
  [k: string]: string | object;
  githubOwner: string;
  githubRepo: string;
}
export interface DeleteGithubRepoVars {
  [k: string]: string | object;
  githubOwner: string;
  githubRepo: string;
  action: string;
}

interface GetAaYaml {
  [k: string]: string | object;
  githubUrl: string;
}

export type AaTemplate = {
  cmdbAppShortName: string;
  squads: string[];
  apiVersion: string;
};

export type RepoCustomProperties = {
  appShortName: string;
  squadID: string[];
};

export interface CatalogProjectsServiceApi {
  deleteGithubRepo(vars: DeleteGithubRepoVars): Promise<void>;
  getAaYaml(vars: GetAaYaml): Promise<AaTemplate>;
  getRepoCustomProperties(repoLocation: string): Promise<RepoCustomProperties>;
}

export const catalogProjectsServiceApiRef = createApiRef<CatalogProjectsServiceApi>({
  id: 'plugin.catalog-customizations.backend.service',
});

export class CatalogProjectsService implements CatalogProjectsServiceApi {
  constructor(
    private ghecAuthApi: OAuthApi & BackstageIdentityApi,
    private ghecGraphUrl: string,
  ) {}

  deleteGithubRepo = async ({ ...vars }: DeleteGithubRepoVars) => {
    const queryStringVars = vars as Record<string, string>;
    const token = await this.ghecAuthApi.getAccessToken();

    const ghecApiUrl = this.ghecGraphUrl;

    const { githubOwner, githubRepo, action } = queryStringVars;

    const missingQueryParams = Object.entries({
      githubOwner,
      githubRepo,
      action,
    })
      .filter(([, value]) => !value)
      .map(([varName]) => varName);

    if (missingQueryParams.length > 0) {
      throw new Error(`Missing required query params: ${missingQueryParams}`);
    }

    if ((await this.isUserAdminOnRepo(githubOwner, githubRepo, token, ghecApiUrl)) === false) {
      throw new Error(`User is not authorized to perform these actions on ${githubOwner}/${githubRepo}`);
    }

    const octokit = new Octokit({
      auth: token,
      baseUrl: ghecApiUrl,
    });

    if (action === 'archive') {
      try {
        await octokit.repos.update({
          owner: githubOwner,
          repo: githubRepo,
          archived: true,
        });
        return;
      } catch (error: any) {
        if (error.toString().includes('Repository was archived so is read-only')) {
          throw new Error('GitHub repository was already archived. Cannot re-archive');
        } else {
          throw new Error('Error archiving GitHub repository');
        }
      }
    }

    if (action === 'delete') {
      try {
        await octokit.repos.delete({
          owner: githubOwner,
          repo: githubRepo,
        });
        return;
      } catch (error: any) {
        if (error.toString().includes('Not Found')) {
          throw new Error('Was this GitHub repo already deleted? We cannot find it');
        } else {
          throw new Error('Error deleting GitHub repository');
        }
      }
    }
    return;
  };

  loadAaYaml = (templateStr: string, repoName: string) => {
    try {
      const templateAaYaml: any = yaml.load(templateStr);

      if (templateAaYaml.squad) {
        if (!templateAaYaml.squads) {
          templateAaYaml.squads = [templateAaYaml.squad];
        }
        delete templateAaYaml.squad;
      }

      if (typeof templateAaYaml.squads === 'string') {
        templateAaYaml.squads = [templateAaYaml.squads];
      }

      if (templateAaYaml.archerShortname) {
        if (!templateAaYaml.cmdbAppShortName) {
          templateAaYaml.cmdbAppShortName = templateAaYaml.archerShortname;
        }
        delete templateAaYaml.archerShortname;
      }

      if (templateAaYaml.applicationShortname) {
        if (!templateAaYaml.cmdbAppShortName) {
          templateAaYaml.cmdbAppShortName = templateAaYaml.applicationShortname;
        }
        delete templateAaYaml.applicationShortname;
      }

      return templateAaYaml;
    } catch (err: any) {
      throw new Error(`load aa.yaml for ${repoName}`);
    }
  };

  getAaYaml = async ({ ...vars }: GetAaYaml) => {
    const gitUrlParseOut = gitUrlParse(vars.githubUrl);
    const token = await this.ghecAuthApi.getAccessToken();

    const { repository }: any = await graphql(
      `
      {
        repository(name: "${gitUrlParseOut.name}", owner: "${gitUrlParseOut.owner}") {
          aaInfo: object(expression: "HEAD:aa.yaml") {
            ... on Blob {
              text
            }
          }
        }
      }  
    `,
      {
        baseUrl: this.ghecGraphUrl,
        headers: {
          authorization: `token ${token}`,
        },
      },
    );
    const resp: any = this.loadAaYaml(repository.aaInfo?.text, gitUrlParseOut.name);
    return resp;
  };

  isUserAdminOnRepo = async (repoOwner: string, repoName: string, token: string, githubApiUrl: string) => {
    const octokit = new Octokit({
      auth: token,
      baseUrl: githubApiUrl,
    });
    const username = await octokit.users.getAuthenticated();
    try {
      const permissionLevel = await octokit.repos.getCollaboratorPermissionLevel({
        owner: repoOwner,
        repo: repoName,
        username: username.data.login as string,
      });
      if (permissionLevel?.data.permission !== 'admin') {
        return false;
      }
      return true;
    } catch (e: any) {
      return false;
    }
  };

  getRepoCustomProperties = async (repoLocation: string) => {
    const gitUrlParseOut = gitUrlParse(repoLocation);
    const token = await this.ghecAuthApi.getAccessToken();

    const octokit = new Octokit({
      auth: token,
    });

    const properties: RepoCustomProperties = {
      appShortName: '',
      squadID: [],
    };

    try {
      const resp = await octokit.request('Get /repos/{owner}/{repo}/properties/values', {
        owner: gitUrlParseOut.owner,
        repo: gitUrlParseOut.name,
        headers: {
          'X-GitHub-Api-Version': '2022-11-28',
        },
      });

      for (const element of resp.data) {
        if (element.property_name === 'app-shortname') {
          properties.appShortName = element.value;
        }
        if (element.property_name === 'squad-id') {
          properties.squadID = element.value.split(',').map((s: string) => s.trim());
        }
      }
      return properties;
    } catch (e: any) {
      throw e;
    }
  };
}
