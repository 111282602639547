import React from 'react';
import { ServicesMetadata } from '../services';
import { STATUS } from '../Status';
import { getStatusDetails2D, Layer } from './Layer';

const getClusterDetails = (name: string, cluster: ServicesMetadata[]): React.JSX.Element[] => {
  const resultArray: React.JSX.Element[] = [];
  for (let index = 0; index < Object.values(cluster).length; index++) {
    const service = cluster[index];
    if (!service.name) {
      resultArray.push(<li key={`${name}-${index.toString()}`}>{name}: Name is missing</li>);
      continue;
    }
    if (service.externalName) {
      resultArray.push(
        <li key={`${name}-${service.name}-${index.toString()}`}>
          {name}: Name: {service.name}, ExternalName: {service.externalName}
        </li>,
      );
      continue;
    }

    if (service.ip) {
      resultArray.push(
        <li key={`${name}-${service.name}-${index.toString()}`}>
          {name}: Name: {service.name}, IP: {service.ip}
        </li>,
      );
      continue;
    }
    resultArray.push(
      <li key={`${name}-${service.name}-${index.toString()}`}>
        {name}: Name: {service.name}, IP or ExternalName is missing
      </li>,
    );
  }

  return resultArray;
};

export const ServicesLayer = ({
  title,
  status,
  clusters,
}: {
  title: string;
  status: STATUS;
  clusters: Record<string, ServicesMetadata[]> | undefined;
}) => (
  <Layer
    title={title}
    status={status}
    subheader={getStatusDetails2D<ServicesMetadata>(status, clusters, getClusterDetails, 'service')}
  />
);
