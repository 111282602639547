import { useAsyncRetry } from 'react-use';
import { githubIssueApiRef } from '../api/GithubIssueApi';
import moment from 'moment';
import { useBaseUrl } from './useBaseUrl';
import { IssueState, IssuesListRespData } from '../types';
import { githubAuthApiRef, useApi } from '@backstage/core-plugin-api';

export type IssueStats = {
  avgTimeUntilClose: string;
};

export type IssueStatsCount = {
  avgTimeUntilClose: number;
  closedCount: number;
};

function calculateStatistics(issuesData: IssuesListRespData) {
  return issuesData.reduce<IssueStatsCount>(
    (acc, curr) => {
      acc.avgTimeUntilClose += curr.closedAt
        ? new Date(curr.closedAt).getTime() - new Date(curr.createdAt).getTime()
        : 0;
      acc.closedCount += curr.closedAt ? 1 : 0;
      return acc;
    },
    {
      avgTimeUntilClose: 0,
      closedCount: 0,
    },
  );
}
export function useIssuesStatistics({
  owner,
  repo,
  branch,
  pageSize,
  state,
}: {
  owner: string;
  repo: string;
  branch?: string;
  pageSize: number;
  state: IssueState;
}) {
  const api = useApi(githubIssueApiRef);
  const auth = useApi(githubAuthApiRef);
  const baseUrl = useBaseUrl();

  const {
    loading,
    value: statsData,
    error,
  } = useAsyncRetry<IssueStats>(async () => {
    const token = await auth.getAccessToken(['repo']);
    if (!repo) {
      return {
        avgTimeUntilClose: 'Never',
      };
    }
    return api
      .listIssues({
        token,
        owner,
        repo,
        first: pageSize,
        last: null,
        after: null,
        before: null,
        branch,
        state,
        baseUrl,
      })
      .then(({ issuesData }: { issuesData: IssuesListRespData }) => {
        const calcResult = calculateStatistics(issuesData);
        if (calcResult.closedCount === 0 || calcResult.closedCount === 0)
          return {
            avgTimeUntilClose: 'Never',
          };
        const avgTimeUntilCloseDiff = moment.duration(calcResult.avgTimeUntilClose / calcResult.closedCount);

        const avgTimeUntilClose = avgTimeUntilCloseDiff.humanize();
        return {
          avgTimeUntilClose: avgTimeUntilClose,
        };
      });
  }, [pageSize, repo, owner]);

  return [
    {
      loading,
      statsData,
      projectName: `${owner}/${repo}`,
      error,
    },
  ] as const;
}
