import React from 'react';
import { InfoCard, Progress } from '@backstage/core-components';
import { TextField, Typography, Button, Select, InputLabel, FormControl } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { Alert, AlertTitle, Autocomplete } from '@material-ui/lab';
import { CmdbAppShortName } from '../CmdbAppShortName/CmdbAppShortName';
import { VerticalDropdown } from '../VerticalDropdown/VerticalDropdown';
import { TenantDropdown } from '../TenantDropdown/TenantDropdown';
import { Control, Controller, useForm, useWatch } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { UserManagement } from './UserManagement';
import { Schema, FormValues } from './schema';
import { buildAADGroupName } from './utils';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    formGroup: {
      padding: theme.spacing(2),
    },
    formControl: {
      margin: theme.spacing(1),
      minWidth: 300,
    },
    button: {
      marginRight: theme.spacing(1),
      marginBottom: theme.spacing(1),
    },
    customWidth: {
      maxWidth: 300,
    },
    errorAlert: {
      whiteSpace: 'pre-line',
      margin: 10,
      maxWidth: 500,
    },
    selectEmpty: {
      marginTop: theme.spacing(2),
    },
    margin: {
      marginTop: 20,
      marginBottom: 5,
    },
  }),
);

export enum aadGroupStatus {
  awaitingInput,
  processing,
  success,
  failure,
}

const defaultValues = {
  vertical: '',
  scope: '',
  appName: '',
  role: '',
  env: '',
  tenant: '',
  users: [],
};

const MakeAADGroupName = ({ control }: { control: Control<FormValues> }) => {
  const aadGroupName = useWatch({ control });
  return (
    <div>
      <FormControl variant="outlined">
        <Alert severity="info">
          <strong>This will be your AD Group Name: {buildAADGroupName(aadGroupName)}</strong>
        </Alert>
      </FormControl>
    </div>
  );
};

type FormProps = {
  status: aadGroupStatus;
  outputMsg: string;
  onSubmit: (formValues: FormValues) => void;
};

export const Form = ({ status, outputMsg, onSubmit }: FormProps) => {
  const classes = useStyles();

  const {
    register,
    handleSubmit,
    control,
    watch,
    getValues,
    formState: { isValid, errors },
  } = useForm<FormValues>({
    defaultValues,
    mode: 'onChange',
    shouldUnregister: true,
    resolver: yupResolver(Schema),
  });

  const submitProgress = () => {
    if (status === aadGroupStatus.awaitingInput) {
      return null;
    } else if (status === aadGroupStatus.processing) {
      return <Progress />;
    } else if (status === aadGroupStatus.success) {
      return (
        <Alert severity="success">
          <AlertTitle>{outputMsg}</AlertTitle>
        </Alert>
      );
    }
    return <Alert severity="error">{outputMsg}</Alert>;
  };

  const azureRoleOptions = ['OWNER', 'CONTRIBUTOR', 'READER+'];

  return (
    <InfoCard>
      <Typography style={{ marginTop: 10, marginBottom: 10 }}>
        {' '}
        To create an Azure Active Directory Group, please fill out the following fields{' '}
      </Typography>
      <form onSubmit={handleSubmit(onSubmit)}>
        <FormControl variant="outlined" className={classes.formControl} required>
          <Controller
            name="vertical"
            control={control}
            render={({ field: { onChange } }) => <VerticalDropdown onChange={value => onChange(value.vertical)} />}
          />
        </FormControl>
        <br />
        <div>
          <FormControl variant="outlined" className={classes.formControl} required>
            <InputLabel htmlFor="scope-select">Scope</InputLabel>
            <Select native {...register('scope')} defaultValue={defaultValues.scope} id="scope-select" labelWidth={55}>
              <option aria-label="None" value="" />
              <option value="SUB">Subscription</option>
              <option value="RG">Resource Group</option>
            </Select>
          </FormControl>
        </div>
        <br />
        {watch('scope') === 'RG' && (
          <div className={classes.formControl}>
            <Controller
              name="appName"
              control={control}
              render={({ field: { onChange } }) => (
                <CmdbAppShortName onChange={value => onChange(value.shortName)} required />
              )}
            />
          </div>
        )}
        <div>
          <FormControl variant="outlined" className={classes.formControl}>
            <Controller
              name="role"
              control={control}
              render={({ field: { onChange } }) => (
                <Autocomplete
                  id="role"
                  freeSolo
                  onInputChange={(_, role) => onChange(role ?? '')}
                  onChange={(_, role) => onChange(role ?? '')}
                  options={azureRoleOptions}
                  renderInput={params => (
                    <TextField
                      {...params}
                      error={Boolean(errors.role)}
                      helperText={
                        Boolean(errors.role)
                          ? errors.role?.message
                          : 'Select role from dropdown or type out your custom role'
                      }
                      style={{ width: 300 }}
                      label="Azure Role"
                      variant="outlined"
                      required
                      InputProps={{ ...params.InputProps }}
                    />
                  )}
                />
              )}
            />
          </FormControl>
        </div>
        <div>
          <FormControl variant="outlined" className={classes.formControl}>
            <InputLabel htmlFor="env-select" required>
              Environment
            </InputLabel>
            <Select native id="env-select" {...register('env')} defaultValue={defaultValues.env} labelWidth={100}>
              <option aria-label="None" value="" />
              <option value="P">Prod</option>
              <option value="N">Non Prod</option>
              <option value="S">Stage</option>
              <option value="T">Test</option>
              <option value="D">Dev</option>
            </Select>
          </FormControl>
        </div>
        <div>
          <FormControl variant="outlined" className={classes.formControl}>
            <Controller
              name="tenant"
              control={control}
              render={({ field: { onChange } }) => <TenantDropdown onChange={value => onChange(value.tenant)} />}
            />
            {watch('users') && watch('users').length > 0 && (
              <div style={{ marginTop: 10, marginBottom: 10 }}>
                <Alert severity="warning">
                  <Typography variant="inherit">
                    Changing tenant will remove all added security group members
                  </Typography>
                </Alert>
              </div>
            )}
          </FormControl>
          {watch('tenant') && (
            <div className={classes.formControl}>
              <Controller
                name="users"
                control={control}
                render={({ field: { onChange } }) => (
                  <UserManagement handleOnClick={value => onChange(value)} tenant={getValues('tenant')} />
                )}
              />
            </div>
          )}
        </div>
        <div className={classes.formControl}>
          <MakeAADGroupName control={control} />
        </div>
        <div className={classes.formControl}>
          <Button
            type="submit"
            variant="contained"
            color="primary"
            name="Submit Request"
            className={classes.button}
            disabled={
              !isValid ||
              (watch('scope') === 'RG' && (watch('appName') === '' || !watch('appName'))) ||
              status === aadGroupStatus.processing ||
              watch('users').findIndex(user => user.userType === 'Owner') < 0
            }
          >
            Submit Request
          </Button>
          {submitProgress()}
        </div>
      </form>
    </InfoCard>
  );
};
