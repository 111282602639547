import { useApi } from '@backstage/core-plugin-api';
import React, { FC, useState } from 'react';
import { orionServiceApiRef } from '../../service/orion.service';
import { AMLCCForm } from './AMLCCForm';
import { amlCCFormInitializeValues } from './amlCCFormInitialValue';
import { amlCCFormValueTypes } from './amlCCFormValueTypes';

interface AMLCCComponentProps {
  clusterName: string;
  subscription: string;
  resourceGroup: string;
  amlWorkspace: string;
  maxNodes: string;
  vmSKU: string;
  clusterAvailability: string;
}

export const AMLCCFormContainer: FC<AMLCCComponentProps> = ({
  clusterName,
  subscription,
  resourceGroup,
  amlWorkspace,
  maxNodes,
  vmSKU,
  clusterAvailability,
}) => {
  const orionService = useApi(orionServiceApiRef);

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string>();
  const [success, setSuccess] = useState(false);

  const onSubmit = async (values: amlCCFormValueTypes) => {
    setSuccess(false);
    setLoading(true);
    setError(undefined);

    const metadata = {
      ...values,
      success: true,
    };

    try {
      await orionService.provisionAMLComputeCluster(metadata);
      setSuccess(true);
      setError(undefined);
    } catch (e: any) {
      setSuccess(false);
      setError(e.message);
    } finally {
      setLoading(false);
    }
  };

  const initialValues = amlCCFormInitializeValues({
    clusterName,
    subscription,
    resourceGroup,
    amlWorkspace,
    maxNodes,
    vmSKU,
    clusterAvailability,
  });

  return (
    <AMLCCForm initialValues={initialValues} onSubmit={onSubmit} loading={loading} success={success} error={error} />
  );
};
