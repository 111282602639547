import combineStatus from '../../combineStatus';
import { STATUS } from '../../Status';

export interface PodsMetadata {
  status: STATUS | undefined;
  podCount: number | undefined;
  healthyPodCount: number | undefined;
}
export type Pods = Record<string, PodsMetadata>;

export const getPodStatus = (item: any) => {
  const podObject = item.resources?.filter((resource: { type: string }) => {
    return resource.type === 'pods';
  })[0];

  if (!podObject) return { status: STATUS.unretrievable, podCount: undefined, healthyPodCount: undefined };

  const podStatus: STATUS[] = podObject.resources.map((pod: any) =>
    pod.status.phase === 'Running' ? STATUS.healthy : STATUS.unhealthy,
  );

  const healthyPodCount = podStatus.filter(status => status === STATUS.healthy).length ?? 0;
  return { status: combineStatus(podStatus), podCount: podStatus.length, healthyPodCount };
};
