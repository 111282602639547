import React from 'react';
import { useAsync } from 'react-use';
import { CircularProgress, FormHelperText, TextField } from '@material-ui/core';
import ErrorIcon from '@material-ui/icons/Error';
import { Autocomplete } from '@material-ui/lab';
import { Squad360ServiceApiRef, SimpleApp } from '../../services';
import { useApi } from '@backstage/core-plugin-api';

type OnChangeValue = {
  costCenter: string;
};

interface CostCenterProps {
  onChange(value: OnChangeValue): void;
  onBlur?: () => void;
}

export const CostCenter: React.FC<CostCenterProps> = ({ onChange, onBlur }) => {
  const squad360 = useApi(Squad360ServiceApiRef);

  const archerOptions = useAsync(async () => squad360.getAllApplicationsSimple(), [squad360]);

  let archerOptionsWithCostCenters: SimpleApp[] = [];
  if (!!archerOptions.value) {
    const costCenters = archerOptions.value.map(option => option.costCenter);
    archerOptionsWithCostCenters = archerOptions.value
      .filter((option, index) => {
        return option.costCenter !== null && costCenters.indexOf(option.costCenter) === index;
      })
      .sort((x, y) => (x.costCenter < y.costCenter ? -1 : 1));
  }

  return (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <Autocomplete
        disabled={archerOptions.error !== undefined}
        id="costCenter"
        data-testid="costCenter"
        disableClearable
        getOptionLabel={option => (option.costCenter !== null ? `${option.costCenter}` : '')}
        options={archerOptionsWithCostCenters}
        onChange={(_: any, application: any) => {
          onChange({
            costCenter: application.costCenter.replace('-', '/'),
          });
        }}
        onBlur={onBlur}
        style={{ width: 300, display: 'inline-flex' }}
        renderInput={params => <TextField required {...params} label="Cost Center" variant="outlined" />}
      />

      {archerOptions.loading && <CircularProgress style={{ marginLeft: 10 }} />}
      {!!archerOptions.error && (
        <>
          <ErrorIcon style={{ marginLeft: 10, marginRight: 10, color: 'red' }} />
          <FormHelperText>There was an issue getting the Cost center </FormHelperText>
        </>
      )}
    </div>
  );
};
