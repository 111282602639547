import { Grid, Typography } from '@material-ui/core';
import React from 'react';
import { CreateActiveDirectoryGroup } from '../CreateActiveDirectoryGroup/CreateActiveDirectoryGroup';
import { CreateRancherNamespace } from '../CreateRancherNamespace/CreateRancherNamespace';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { CreateGitHubTeam } from '../CreateGitHubTeam/CreateGitHubTeam';
import { CreateHashicorpVault } from '../CreateHashicorpVault/CreateHashicorpVault';
import Alert from '@material-ui/lab/Alert';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      padding: theme.spacing(1),
      paddingTop: theme.spacing(4),
      marginTop: '-9px',
    },
  }),
);

export const InfrastructureSelector = () => {
  const classes = useStyles();
  return (
    <Grid direction="column" spacing={3}>
      <Grid item xs={12} spacing={3}>
        <Typography variant="subtitle1" className={classes.container} aria-label="infrastructure-heading">
          Welcome to the Runway Scaffolder! Before we get started, please create any needed infrastructure from the list
          below.
        </Typography>
        <Typography variant="subtitle1" className={classes.container}>
          <Alert variant="standard" severity="info" style={{ fontSize: 'large' }}>
            <b> If none of these options are needed, please proceed to the next step.</b>
          </Alert>
        </Typography>
      </Grid>
      <Grid item xs={12} spacing={3} className={classes.container}>
        <CreateActiveDirectoryGroup />
      </Grid>
      <Grid item xs={12} spacing={3} className={classes.container}>
        <CreateRancherNamespace />
      </Grid>
      <Grid item xs={12} spacing={3} className={classes.container}>
        <CreateGitHubTeam />
      </Grid>
      <Grid item xs={12} spacing={3} className={classes.container}>
        <CreateHashicorpVault />
      </Grid>
    </Grid>
  );
};
