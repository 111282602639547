import { ProfileInfo, identityApiRef, useApi } from '@backstage/core-plugin-api';
import { ObserveServiceApiRef } from '../services';
import { BucketId, ClientId } from '@runway/devkit-common';

type TrackedRequestHandlers = {
  tracker: (callback: TrackerCallback, metadata: TrackerMetadata) => Promise<any>;
  createRecord: (metadata: TrackerMetadata, status?: Status, error?: string) => Promise<void>;
};
type TrackIds = {
  bucket_id: BucketId;
  client_id: ClientId;
  pluginId: string;
};
type TrackerCallback = () => Promise<any>;
type TrackerMetadata = Record<string, any>;
type Status = 'success' | 'failed';

/**
 * Hook used to automatically send record to observe store for API calls
 * @param TrackIds necessary observe record store ids
 */
function useTrackedRequest({ bucket_id, client_id, pluginId }: TrackIds): TrackedRequestHandlers {
  const observeService = useApi(ObserveServiceApiRef);
  const identityApi = useApi(identityApiRef);

  /**
   * Calls observe service with given metadata
   * @param metadata data sent to observe store
   * @param status (Optional) whether the API call failed or succeeded
   * @param error (Optional) error stack
   */
  const createRecord = async (metadata: TrackerMetadata, status?: Status, error?: string) => {
    let identity: { aaId?: string } | undefined;

    try {
      identity = (await identityApi.getProfileInfo()) as unknown as ProfileInfo & { aaId: string };
    } catch (_) {
      // ignore
    }

    try {
      await observeService.createRecord({
        bucket_id,
        client_id,
        status: status ?? 'N/A',
        actor: identity?.aaId ? identity.aaId : '-1',
        data: {
          pluginId,
          error: error ?? undefined,
          ...metadata,
        },
      });
    } catch (_) {
      // ignore
    }
  };

  /**
   * Tracks your API calls and sends data to observe store
   * @param callback api call
   * @param metadata payload sent to observe store
   */
  const tracker = async (callback: TrackerCallback, metadata: TrackerMetadata) => {
    let result: any;
    try {
      result = await callback();
      await createRecord(metadata, 'success');
    } catch (error: any) {
      await createRecord(metadata, 'failed', error.stack);
      throw error;
    }
    return result;
  };

  return { tracker, createRecord };
}

export default useTrackedRequest;
