import { createPlugin, createRoutableExtension } from '@backstage/core-plugin-api';

import { rootRouteRef } from './routes';

export const secretVaultPlugin = createPlugin({
  id: 'secret-vault',
  routes: {
    root: rootRouteRef,
  },
});

export const SecretVaultPage = secretVaultPlugin.provide(
  createRoutableExtension({
    name: 'SecretVaultPage',
    component: () => import('./components/Home').then(m => m.default),
    mountPoint: rootRouteRef,
  }),
);
