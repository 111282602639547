import { ArgoData, ArgoStatus } from '../../Layers/ArgoCD';
import { STATUS } from '../../Status';

export const getStatusArgo = (argoStatus: ArgoData[] | undefined) => {
  const argoData: ArgoStatus = {};
  if (argoStatus?.length === 0 || argoStatus?.every(element => Object.keys(element).length === 0))
    return { cluster: { status: STATUS.unretrievable } };
  argoStatus?.forEach((clusters: ArgoData) => {
    let status = STATUS.healthy;
    let message;
    if (clusters?.items?.[0]?.status?.conditions?.[0]?.message) {
      status = STATUS.unhealthy;
    } else if (clusters?.items?.[0]?.status?.health?.status !== 'Healthy') {
      status = STATUS.unhealthy;
    } else if (clusters?.items?.[0]?.status?.sync?.status !== 'Synced') {
      status = STATUS.unhealthy;
    }
    if (status === STATUS.unhealthy) {
      message =
        clusters?.items?.[0]?.status?.conditions?.[0]?.message ??
        clusters?.items?.[0]?.status?.operationState?.message!;
    } else {
      message = 'healthy';
    }
    argoData[clusters?.items?.[0]?.metadata?.instance?.name] = {
      status,
      message,
    };
  });
  return argoData;
};
