import { ScmIntegrationIcon, scmIntegrationsApiRef } from '@backstage/integration-react';
import {
  EntityRefLinks,
  FavoriteEntity,
  getEntityRelations,
  getEntitySourceLocation,
} from '@backstage/plugin-catalog-react';
import {
  Box,
  Card,
  CardActions,
  CardContent,
  CardMedia,
  Chip,
  IconButton,
  makeStyles,
  Typography,
  useTheme,
} from '@material-ui/core';
import React from 'react';
import { generatePath } from 'react-router';
import { TemplateEntityV1beta3 } from '@backstage/plugin-scaffolder-common';
import goldenJson from '../../golden.json';
import { LinkButton, ItemCardHeader } from '@backstage/core-components';
import { useApi } from '@backstage/core-plugin-api';
import { DetailsDrawerComponent } from './templateDetailsDrawer';
import VerifiedUserIcon from '@material-ui/icons/VerifiedUser';
import { Entity, RELATION_OWNED_BY } from '@backstage/catalog-model';
import { ShareTemplateButton } from './shareTemplateButton';
import { Alert } from '@material-ui/lab';

const useStyles = makeStyles(theme => ({
  cardHeader: {
    position: 'relative',
  },
  title: {
    backgroundImage: ({ backgroundImage }: any) => backgroundImage,
  },
  box: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: '-webkit-box',
    '-webkit-line-clamp': 10,
    '-webkit-box-orient': 'vertical',
    paddingBottom: '0.8em',
  },
  label: {
    color: theme.palette.text.secondary,
    textTransform: 'uppercase',
    fontSize: '0.65rem',
    fontWeight: 'bold',
    letterSpacing: 0.5,
    lineHeight: 1,
    paddingBottom: '0.2rem',
  },
  leftButton: {
    marginRight: 'auto',
  },
  starButton: {
    position: 'absolute',
    top: theme.spacing(0.5),
    right: theme.spacing(0.5),
    padding: '0.25rem',
    color: '#fff',
  },
  chip: {
    position: 'absolute',
    top: theme.spacing(1.0),
    right: theme.spacing(5.0),
    padding: '0.25rem',
    color: '#fff',
    backgroundColor: '#FFD700',
  },
}));

export type TemplateProps = {
  description: string;
  tags: string[];
  title: string;
  type: string;
  name: string;
  owner: string;
  deprecated: boolean;
};

const getTemplateCardProps = (template: TemplateEntityV1beta3): TemplateProps & { key: string | boolean } => {
  return {
    key: template.metadata.uid!,
    name: template.metadata.name,
    title: `${(template.metadata.title || template.metadata.name) ?? ''}`,
    type: template.spec.type ?? '',
    owner: template.spec.owner ?? '',
    description: template.metadata.description ?? '-',
    tags: (template.metadata.tags as string[]) ?? [],
    deprecated: (template.metadata.deprecated as boolean) ?? false,
  };
};

export const TemplateCard = ({ template }: { template: TemplateEntityV1beta3 }) => {
  const backstageTheme = useTheme();
  const templateProps = getTemplateCardProps(template);
  const ownedByRelations = getEntityRelations(template as Entity, RELATION_OWNED_BY);
  const themeId = backstageTheme.getPageTheme({ themeId: templateProps.type }) ? templateProps.type : 'other';
  const theme = backstageTheme.getPageTheme({ themeId });
  const classes = useStyles({ backgroundImage: theme.backgroundImage });
  const href = generatePath(`/create/templates/default/:templateName`, {
    templateName: templateProps.name,
  });

  const scmIntegrationsApi = useApi(scmIntegrationsApiRef);
  const sourceLocation = getEntitySourceLocation(template, scmIntegrationsApi);

  return (
    <Card>
      <CardMedia className={classes.cardHeader}>
        <FavoriteEntity className={classes.starButton} entity={template} />
        <ItemCardHeader title={templateProps.title} subtitle={templateProps.type} classes={{ root: classes.title }} />
        {goldenJson.includes(templateProps.owner) && (
          <Chip label="Certified" size="small" className={classes.chip} icon={<VerifiedUserIcon />} />
        )}
      </CardMedia>
      {templateProps.deprecated && <Alert severity="warning">Template has been deprecated</Alert>}
      <CardContent style={{ display: 'grid' }}>
        <Box className={classes.box}>
          <Typography variant="body2" className={classes.label}>
            Description
          </Typography>
          {templateProps.description}
        </Box>
        <Box className={classes.box}>
          <Typography variant="body2" className={classes.label}>
            Owner
          </Typography>
          {!template.spec.owner?.toLowerCase().includes('aainternal') ? (
            <EntityRefLinks entityRefs={ownedByRelations} defaultKind="Group" />
          ) : (
            'Not specified or invalid'
          )}
        </Box>
        <Box>
          <Typography variant="body2" className={classes.label}>
            Tags
          </Typography>
          {templateProps.tags?.map(tag => <Chip size="small" label={tag} key={tag} />)}
        </Box>
      </CardContent>
      <CardActions>
        <div className={classes.leftButton}>
          {sourceLocation && (
            <IconButton href={sourceLocation.locationTargetUrl}>
              <ScmIntegrationIcon type={sourceLocation.integrationType} />
            </IconButton>
          )}
          <ShareTemplateButton href={href} />
        </div>
        <DetailsDrawerComponent templateProps={templateProps} templateOwner={template.spec.owner} />
        <LinkButton color="primary" to={href} aria-label={`Choose ${templateProps.title}`}>
          Choose
        </LinkButton>
      </CardActions>
    </Card>
  );
};
