import { createApiRef } from '@backstage/core-plugin-api';
import { OAuth2AAPingSSOApi } from '@runway/devkit';
import axios from 'axios';

export const vmComplianceApiRef = createApiRef<VmComplianceApi>({
  id: 'plugin.infrastructure.vmcompliance.service',
});

export type VmComplianceResponse = {
  serverName: string;
  lastRebooted: string | null;
  osType: string;
  complianceScore: string;
};

export interface VmComplianceApi {
  getVmCompliance(): Promise<VmComplianceResponse[]>;
}

export default class VMComplianceService implements VmComplianceApi {
  constructor(
    private oAuthApi: OAuth2AAPingSSOApi,
    private backendUrl: string,
  ) {}
  async getVmCompliance(): Promise<VmComplianceResponse[]> {
    const complianceResponse = await axios.get<VmComplianceResponse[]>(`${this.backendUrl}/api/caas-compliance/`, {
      headers: { Authorization: `Bearer ${await this.oAuthApi.getAccessToken()}` },
    });
    return complianceResponse.data;
  }
}
