import { createPlugin, createRoutableExtension } from '@backstage/core-plugin-api';

import { rootRouteRef } from './routes';

export const swaggerPlugin = createPlugin({
  id: 'swagger',
  routes: {
    root: rootRouteRef,
  },
});

export const SwaggerPage = swaggerPlugin.provide(
  createRoutableExtension({
    name: 'SwaggerPage',
    component: () => import('./components/SwaggerComponent').then(m => m.SwaggerComponent),
    mountPoint: rootRouteRef,
  }),
);
