import { Entity } from '@backstage/catalog-model';
import { CodeSnippet } from '@backstage/core-components';
import { configApiRef, identityApiRef, useApi } from '@backstage/core-plugin-api';
import {
  Button,
  Grid,
  IconButton,
  LinearProgress,
  Link,
  List,
  ListItem,
  makeStyles,
  Popover,
  Tooltip,
  Typography,
} from '@material-ui/core';
import InfoIcon from '@material-ui/icons/Info';
import { Alert } from '@material-ui/lab';
import axios, { AxiosRequestConfig } from 'axios';
import React from 'react';
import { useAsync } from 'react-use';
import { AaTemplate, RepoCustomProperties, catalogProjectsServiceApiRef } from '../../services/catalogProjects.service';
import { AboutField } from './AboutField';

const useStyles = makeStyles(theme => ({
  code: {
    borderRadius: 6,
    margin: `${theme.spacing(2)}px 0px`,
    background: theme.palette.type === 'dark' ? '#444' : '#fff',
  },
  description: {
    wordBreak: 'break-word',
  },
}));

type Props = {
  entity: Entity;
};

const NoURLPopover = () => {
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);
  const handlePopoverClick = (event: React.MouseEvent<HTMLButtonElement>) => setAnchorEl(event.currentTarget);
  const handleClose = () => setAnchorEl(null);
  const renderTroubleshootingMessages = () => (
    <>
      <List dense>
        <ListItem dense disableGutters>
          <Typography variant="body2" paragraph>
            Make sure that the{' '}
            <b>
              <a
                href="https://developer.aa.com/docs/default/component/runway/getting-started/userguides/catalog/#backstage-kubernetes-label-selector"
                target="_blank"
              >
                <code>argocd/app-selector</code> in your <code>catalog-info.yaml</code> file and the backstageName
                annotation in your <code>webapp.yaml</code>
              </a>
            </b>{' '}
            match
          </Typography>
        </ListItem>
        <ListItem dense disableGutters>
          <Typography variant="body2" paragraph>
            Make sure an{' '}
            <b>
              <a
                href="https://developer.aa.com/docs/default/component/runway/getting-started/userguides/webapp/#annotations"
                target="_blank"
              >
                <code>enableCloudIngress</code> annotation is present in your <code>webapp.yaml</code>
              </a>
            </b>
            . Read more about Application URLs{' '}
            <b>
              <a
                href="https://developer.aa.com/docs/default/component/runway/getting-started/userguides/created-apps/#application-urls"
                target="_blank"
              >
                here
              </a>
            </b>
          </Typography>
        </ListItem>
      </List>
    </>
  );
  return (
    <>
      <IconButton onClick={handlePopoverClick} aria-label="no-url-helper">
        <InfoIcon />
      </IconButton>
      <Popover
        anchorEl={anchorEl}
        onClose={handleClose}
        open={!!anchorEl}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        PaperProps={{ style: { width: '100%' } }}
        style={{ width: '25%' }}
      >
        <Alert severity="info" aria-label="no-url-troubleshoot">
          <Typography variant="body2">
            Are you expecting a URL here? Use the suggestions below to troubleshoot:
          </Typography>
          {renderTroubleshootingMessages()}
        </Alert>
      </Popover>
    </>
  );
};

export const AaAboutContent = ({ entity }: Props) => {
  const configApi = useApi(configApiRef);
  const classes = useStyles();

  const catalogProjectsApi = useApi(catalogProjectsServiceApiRef);
  const identityApi = useApi(identityApiRef);

  const [appUrl, setAppUrl] = React.useState<string[]>([]);
  const [aaInfo, setAaInfo] = React.useState<AaTemplate>();

  const squadURLs = useAsync(async () => {
    const squadLinks = [];
    if (aaInfo?.squads) {
      for (const squadID of aaInfo?.squads) {
        const URL = squadID ? `https://squad360-ui.cloud.aa.com/squad/${squadID}/view` : '';
        squadLinks.push({ label: squadID, url: URL });
      }
    }
    return squadLinks;
  }, [entity, aaInfo]);

  const getSquadLabel = () => {
    if (squadURLs.value) {
      if (squadURLs.value?.length > 1) {
        return 'Squads';
      }
    }
    return 'Squad';
  };

  const appUrlsResp = useAsync(async () => {
    const name: string = entity.metadata.name;
    const { token } = await identityApi.getCredentials();
    const options: AxiosRequestConfig = {
      method: 'POST',
      url: `${configApi.getString('backend.baseUrl')}/api/kubernetes/services/${name}`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token ?? ''}`,
      },
      data: {
        entity: entity,
      },
    };
    const kubernetesResp = await axios.request(options);
    const appUrls: string[] = [];
    for (const cluster of kubernetesResp.data.items) {
      const ingressResp = cluster.resources.find((x: { type: any }) => x.type === 'ingresses');
      if (ingressResp !== undefined && 'resources' in ingressResp && ingressResp.resources.length > 0) {
        ingressResp.resources.forEach((resource: any) => {
          const ingress = resource.spec.rules[0].host;
          appUrls.push(`https://${ingress}`);
        });
      }

      const serviceResp = cluster.resources.find((x: { type: any }) => x.type === 'services');
      if (serviceResp !== undefined && 'resources' in serviceResp && serviceResp.resources.length > 0) {
        serviceResp.resources.forEach((resource: any) => {
          if ('externalName' in resource.spec) {
            const externalName = resource.spec.externalName;
            appUrls.push(`http://${externalName}`);
          }
        });
      }
    }
    setAppUrl([...new Set(appUrls)]);
  }, [entity]);

  const aaInfoAsyncState = useAsync(async () => {
    const repoLocation: string = entity.metadata.annotations?.['github.com/project-slug'] as string;

    let appShortName = '';
    let squads: string[] = [];
    let apiVersion = '';

    try {
      const customProperties: RepoCustomProperties = await catalogProjectsApi.getRepoCustomProperties(repoLocation);
      appShortName = customProperties.appShortName;
      squads = customProperties.squadID.length === 0 ? squads : customProperties.squadID;
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error('Error fetching custom properties from repo:', error);
    }

    if (appShortName === '' || squads.length === 0) {
      const resp = await catalogProjectsApi.getAaYaml({
        githubUrl: repoLocation,
      });
      appShortName = appShortName === '' ? resp.cmdbAppShortName : appShortName;
      squads = squads.length === 0 ? resp.squads : squads;
      apiVersion = resp.apiVersion;
    }

    setAaInfo({ cmdbAppShortName: appShortName, squads: squads, apiVersion: apiVersion });
  }, [entity]);

  const ENTITY_YAML = `apiVersion: "0.2.3"
cmdbAppShortName:
  - DevRunway
squads:
  - 12345
  - 23456`;

  const getCmdbAppShortName = () => aaInfo?.cmdbAppShortName || (entity?.metadata?.archerID as string);

  const getApiVersion = () => aaInfo?.apiVersion;

  return (
    <Grid container>
      <Grid container item xs={12} spacing={3}>
        <AboutField label="URL" gridSizes={{ xs: 12 }}>
          {appUrlsResp.loading && <LinearProgress />}
          {!appUrlsResp.loading && appUrl.length === 0 && (
            <Typography variant="body2" paragraph className={classes.description}>
              No URL <NoURLPopover />
            </Typography>
          )}
          {!appUrlsResp.loading &&
            appUrl.length > 0 &&
            appUrl.map((url: string) => {
              if (!url.includes('.mesh')) {
                return (
                  <Grid container key={url}>
                    <Grid item>
                      <Link target="_blank" href={url} key={url}>
                        {url}
                      </Link>
                    </Grid>
                  </Grid>
                );
              }
              return (
                <Grid container key={url}>
                  <Grid item>{url}</Grid>
                  <Grid item style={{ marginLeft: 2 }}>
                    <Tooltip
                      title="Use this for connectivity within the mesh. The service mesh wraps this connectivity with SSL"
                      aria-label="Use this for connectivity within the mesh. The service mesh wraps this connectivity with SSL"
                    >
                      <InfoIcon fontSize="small" />
                    </Tooltip>
                  </Grid>
                </Grid>
              );
            })}
        </AboutField>
        <AboutField label={getSquadLabel()} urls={squadURLs.value} gridSizes={{ xs: 12, sm: 6, lg: 4 }} />
        <AboutField
          label="CMDB Application Shortname"
          value={getCmdbAppShortName()}
          gridSizes={{ xs: 12, sm: 6, lg: 4 }}
        />
      </Grid>
      {!squadURLs.loading &&
      !aaInfoAsyncState.loading &&
      ([getCmdbAppShortName(), getApiVersion()].some(x => x === undefined) || squadURLs.value?.length === 0) ? (
        <Grid container alignItems="flex-start" item xs={12} spacing={3}>
          <Grid item>
            <Typography variant="body1" color="secondary">
              You can set the missing values above (except URL) in a file named aa.yaml in your repo, as shown in the
              example below:
            </Typography>

            <div className={classes.code} data-testid="aa-yaml-snippet">
              <CodeSnippet
                text={ENTITY_YAML}
                language="yaml"
                showLineNumbers
                customStyle={{ background: 'inherit', fontSize: '115%' }}
              />
            </div>

            <Button
              variant="contained"
              size="medium"
              color="primary"
              target="_blank"
              href="https://developer.aa.com/docs/#/getting-started/userguides/catalog?id=work-in-progress-more-metadata-aayaml"
            >
              Read more
            </Button>
          </Grid>
        </Grid>
      ) : null}
    </Grid>
  );
};
