import axios from 'axios';
import { createApiRef } from '@backstage/core-plugin-api';
import { OAuth2AAPingSSOApi } from '@runway/devkit';

export type VdiDesktopResponse = {
  msg: string;
  poolname: string;
  aaid: string;
  email: string;
  ad_group_sid: string;
  ad_group_name: string;
  poolid: string;
};

export interface VdiDesktopApi {
  requestVdiAccess(poolname: string, justification: string): Promise<any>;
}

export const vdiDesktopServiceApiRef = createApiRef<VdiDesktopApi>({
  id: 'plugin.create-project.vdi-desktop.service',
});

export default class VdiDesktop implements VdiDesktopApi {
  constructor(
    private oAuthApi: OAuth2AAPingSSOApi,
    private backendUrl: string,
  ) {}

  requestVdiAccess = async (poolname: string, justification: string) => {
    const token = await this.oAuthApi.getAccessToken();

    let res = {};
    await axios
      .post(
        `${this.backendUrl}/vdi-ad-group`,
        {
          poolname: poolname,
          justification: justification,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
            Accept: 'application/json',
          },
        },
      )
      .then(function handleResponse(response) {
        res = response.data;
      })
      .catch(function handleError(error) {
        if (error.message) {
          res = {
            error: true,
            msg: `${error.message || 'Error'}: ${
              error.response?.data.msg || error.response?.data.message || error.response?.data
            }`,
          };
        } else if (error.request) {
          res = {
            error: true,
            msg: error.request,
          };
        } else {
          const response = error.response;
          res = {
            error: true,
            msg: `Error ${response.status ?? 'Missing HTTP Status'}: ${response.data ?? 'Missing response data'}`,
          };
        }
      });

    return res;
  };
}
