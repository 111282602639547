import { SSOToken } from './SSOToken';
import { BackstageToken } from './BackstageToken/BackstageToken';
import React from 'react';
// eslint-disable-next-line no-restricted-imports
import Stack from '@mui/material/Stack';

export const Tokens = () => {
  return (
    <Stack spacing={1}>
      <BackstageToken />
      <SSOToken />
    </Stack>
  );
};
