import { MenuItem, Typography } from '@material-ui/core';
import React from 'react';
import { useStyles } from './orionFormStyles';
import { OrionFormDropdown } from './OrionFormDropdown';
import { OrionFormTextField } from './OrionFormTextField';

export const OrionFormSectionADX = () => {
  const classes = useStyles();

  return (
    <>
      <Typography variant="h5" className={classes.centeredText} style={{ paddingBottom: 16 }}>
        Azure Data Explorer Information
      </Typography>
      <div className={classes.flexRow} style={{ flexWrap: 'wrap', minWidth: 600, marginBottom: 8 }}>
        <OrionFormDropdown fieldPropName="adxPurpose" label="ADX Database Purpose" placeholder="analytics" required>
          <MenuItem value="analytics">Analytics</MenuItem>
          <MenuItem value="applogs">App Logs (ELM)</MenuItem>
        </OrionFormDropdown>
        <OrionFormTextField
          fieldPropName="adxHotCache"
          label="Hot Cache Period (days)"
          placeholder="default: 7"
          required
        />
        <OrionFormTextField
          fieldPropName="adxSoftDelete"
          label="Data Retention: Auto-Purge (days)"
          placeholder="default: 31"
          required
        />
      </div>
    </>
  );
};
