import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(theme => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
  formGroup: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    paddingRight: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    textAlign: 'left',
    display: 'flex',
    'flex-direction': 'row',
    justifyContent: 'center',
  },
  componentFormGroup: {
    paddingRight: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    paddingTop: theme.spacing(1),
    minWidth: theme.spacing(35),
  },
  button: {
    marginRight: theme.spacing(1),
  },
  centeredText: {
    'text-align': 'center',
    paddingBottom: theme.spacing(1),
  },
  flexColumn: {
    display: 'flex',
    'align-items': 'center',
    'flex-direction': 'column',
  },
  flexRow: {
    display: 'flex',
    'flex-direction': 'row',
    justifyContent: 'center',
    alignItems: 'baseline',
  },
  flexRowMos: {
    display: 'flex',
    'flex-direction': 'row',
    justifyContent: 'left',
    alignItems: 'center',
  },
  flexColumnMos: {
    display: 'block',
    'align-items': 'left',
    'flex-direction': 'column',
    padding: theme.spacing(3),
  },
  root: {
    display: 'flex',
  },
  formControl: {
    margin: theme.spacing(3),
  },
  fab: {
    margin: theme.spacing(2),
  },
  supportInformation: {
    'margin-left': 'auto',
  },
}));
