export type ApigeeConfig = {
  clientId: string;
  clientSecret: string;
  tokenUrl: string;
};

export enum ApigeeEnv {
  PROD,
  STAGE,
}
