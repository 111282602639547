import { MenuCard } from '@runway/devkit';
import React from 'react';
import { useNavigate } from 'react-router-dom';

export const SelfServiceAccessMenu: React.FC = () => {
  const navigate = useNavigate();

  return (
    <>
      <MenuCard
        title="Batch Ingestion Resource Template"
        subtitle="Batch ingestion resources and framework"
        onButtonClick={() => navigate('../../create/templates/default/dataops')}
        buttonText="REQUEST"
      />
      <MenuCard
        title="Streaming/Database Ingestion Accelerator"
        subtitle="Both Streaming and Ad-hoc/Sandbox database ingestion"
        onButtonClick={() => navigate('../../create/templates/default/deassa')}
        buttonText="REQUEST"
      />
    </>
  );
};
