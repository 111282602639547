import {
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  Typography,
  createStyles,
  makeStyles,
  useTheme,
} from '@material-ui/core';
import React, { useState } from 'react';

const useStyles = makeStyles(() =>
  createStyles({
    radioGroup: {
      flexDirection: 'row',
    },
  }),
);

export enum BreakBuildOptions {
  YES = 'yes',
  NO = 'no',
}

export const SecurityInfo = (props: { value: string; onChange: (value: string) => void }) => {
  const { onChange, ...other } = props;
  const classes = useStyles();
  const theme = useTheme();
  const [securityOption, setSecurityOption] = useState<string>(BreakBuildOptions.YES);

  const handleSecurityOptionChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSecurityOption(event.target.value);
    onChange(event.target.value);
  };

  return (
    <>
      <Grid container spacing={2} direction="row" justifyContent="flex-start" alignItems="center">
        <Typography aria-label="security-info" style={{ color: theme.palette.text.secondary, padding: '0px 10px' }}>
          Would you like to break your build on critical vulnerabilities?
        </Typography>
        <RadioGroup
          className={classes.radioGroup}
          {...other}
          onChange={handleSecurityOptionChange}
          aria-label="security-options"
          name="options"
          value={securityOption}
        >
          <FormControlLabel value={BreakBuildOptions.YES} aria-label="radio-btn-yes" control={<Radio />} label="Yes" />
          <FormControlLabel value={BreakBuildOptions.NO} aria-label="radio-btn-no" control={<Radio />} label="No" />
        </RadioGroup>
      </Grid>
    </>
  );
};
