import { Entity } from '@backstage/catalog-model';
import { Grid } from '@material-ui/core';
import React from 'react';
import { AboutField } from './ApiAboutField';

type Props = {
  entity: Entity;
};

export const AaAboutContent = ({ entity }: Props) => {
  return (
    <Grid container>
      <AboutField
        label="Avg Response Time"
        value={entity?.spec?.averageResponseTime as string}
        gridSizes={{ xs: 12, sm: 6, lg: 4 }}
      />
      <AboutField
        label="Max Response Time"
        value={entity?.spec?.maxResponseTime as string}
        gridSizes={{ xs: 12, sm: 6, lg: 4 }}
      />
      <AboutField
        label="How to subscribe"
        value={entity?.spec?.subscribe as string}
        gridSizes={{ xs: 12, sm: 6, lg: 4 }}
      />
    </Grid>
  );
};
