import { amlKVFormValueTypes } from './amlKVFormValueTypes';

export const amlKVFormInitializeValues = ({
  subscription,
  resourceGroup,
}: {
  subscription: string;
  resourceGroup: string;
}): amlKVFormValueTypes => ({
  subscription,
  resourceGroup,
});
