import { EntityAboutCard, EntityLayout, EntityLinksCard } from '@backstage/plugin-catalog';
import { Grid } from '@material-ui/core';
import { ContainerTags } from '@runway/plugin-catalog-customizations';
import React from 'react';
import { entityWarningContent } from './entityWarningContent';
import { EntityLayoutWrapper } from './EntityLayoutWrapper';

export const ContainerImagePage = (
  <EntityLayoutWrapper>
    <EntityLayout.Route path="/" title="Overview">
      <Grid container spacing={3} alignItems="stretch">
        {entityWarningContent}
        <Grid item md={6}>
          <EntityAboutCard variant="gridItem" />
        </Grid>
        <Grid item md={6} xs={12}>
          <EntityLinksCard cols={1} />
        </Grid>
        <Grid item md={12} xs={12}>
          <ContainerTags />
        </Grid>
      </Grid>
    </EntityLayout.Route>
  </EntityLayoutWrapper>
);
