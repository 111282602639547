import React from 'react';
import { IconButton, Tooltip } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import InfoIcon from '@material-ui/icons/Info';

export const MissingAppToolTip = () => (
  <Tooltip
    title={
      <Alert severity="info">
        If you have recently created a new Archer App, please wait up to a day for it to appear in the dropdown.
      </Alert>
    }
    style={{ paddingLeft: '10px' }}
  >
    <IconButton color="primary" size="small" aria-label="info-button">
      <InfoIcon />
    </IconButton>
  </Tooltip>
);
