import React from 'react';
import { identityApiRef, useApi } from '@backstage/core-plugin-api';
import { ProfileInfo } from '@backstage/plugin-auth-node';
import { useAsync } from 'react-use';
import { FieldExtensionComponentProps, useTemplateSecrets } from '@backstage/plugin-scaffolder-react';
import { CircularProgress, Grid, TextField } from '@material-ui/core';
import { getAAInfo } from '../../components';

export const AAEmployeeInfo = (
  props: FieldExtensionComponentProps<
    ProfileInfo & {
      aaId?: string;
    }
  >,
) => {
  const { uiSchema, onChange, formData, rawErrors } = props;
  const identityApi = useApi(identityApiRef);
  const hidden = uiSchema['ui:widget'] === 'hidden';
  const { setSecrets } = useTemplateSecrets();

  const aaInfoState = useAsync(async () => {
    const accessToken = (await identityApi.getCredentials()).token;
    if (accessToken) {
      setSecrets({ userSsoToken: accessToken });
    }
    const aaInfo = await getAAInfo(identityApi);
    onChange(aaInfo);
    return aaInfo;
  });

  if (hidden) {
    return <></>;
  }
  return (
    <Grid container>
      <Grid item xs={8}>
        <TextField
          id="aaInfo"
          label="AA Info"
          helperText="American Airlines Employee Info"
          style={{ width: '300px' }}
          required
          value={aaInfoState.value || ''}
          error={rawErrors?.length > 0 && !formData}
        />
      </Grid>
      <Grid item xs={4}>
        {aaInfoState.loading && <CircularProgress style={{ padding: 5 }} />}
      </Grid>
    </Grid>
  );
};

/**
 * @deprecated use `AAEmployeeInfo` instead.
 * This wrapper will retain the deprecated extension functionality until it is removed
 */
export const AaEmployeeInformation = (props: FieldExtensionComponentProps<string>) => {
  // @ts-expect-error We are ignoring because the we are no longer maintaining this component.
  return <AAEmployeeInfo {...props} onChange={({ aaId }) => props.onChange(aaId)} />;
};
