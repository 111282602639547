export type KeyVaultFields = {
  keyVault: boolean;
  keyVaultName?: string;
  keyVaultSku?: string;
  postgresFlexibleServer: boolean;
};

export type PostgresFlexibleServerFields = {
  postgresFlexibleServer: boolean;
  postgresSize?: number | undefined | string;
  postgresSku?: string | undefined;
  postgresVersion: number | undefined | string;
};

export type RedisCacheFields = {
  redisCache?: boolean;
  redisCacheName?: string;
  redisCapacity?: number;
  redisFamily?: string;
  redisSkuName?: string;
  redisEnableNonSSLPort?: boolean;
  redisMinTLSVersion?: string;
  redisPublicAccessEnabled?: boolean;
  redisEnableAuth?: boolean;
  redisMaxMemoryReserved?: number;
  redisMaxMemoryDelta?: number;
  redisMaxFragmentation?: number;
};

export type StorageAccountFields = {
  storageAccount?: boolean;
  storageAccountTier?: string;
  storageAccountName?: string;
  storageAccountKind?: string;
  isStorageAccountNameUnique?: boolean;
};

export const paasSpRgItemsFieldsDefaultValues: PaasSpRgItemsFields = {
  createServicePrincipal: false,
  createResourceGroup: false,
  roleAssociate: false,
};

export const paasItemsFieldDefaultValues = {
  keyVault: false,
  keyVaultName: '',
  keyVaultSku: '',
  storageAccount: false,
  storageAccountTier: '',
  storageAccountName: '',
  storageAccountKind: '',
  isStorageAccountNameUnique: false,
  redisCache: false,
  redisCacheName: '',
  redisCapacity: 0,
  redisFamily: '',
  redisSkuName: '',
  redisEnableNonSSLPort: false,
  redisMinTLSVersion: '',
  redisPublicAccessEnabled: false,
  redisEnableAuth: false,
  redisMaxMemoryReserved: 0,
  redisMaxMemoryDelta: 0,
  redisMaxFragmentation: 0,
  postgresFlexibleServer: false,
  postgresSize: 0,
  postgresSku: '',
  postgresVersion: 0,
};

export type PaasAdminItemsFields = {
  env: string;
  vertical: string;
  subscription: string;
  region: string;
};

export type PaasOptions = {
  paas: boolean;
  region?: string;
  env?: string;
  createServicePrincipal?: boolean;
  createResourceGroup?: boolean;
  roleAssociate?: boolean;
};

export type PaasSpRgItemsFields = {
  createServicePrincipal: boolean;
  createResourceGroup: boolean;
  roleAssociate: boolean;
  servicePrincipalClientId?: string;
  servicePrincipalSecret?: string;
  resourceGroupName?: string;
};

export type PaasItemsFields = KeyVaultFields & StorageAccountFields & RedisCacheFields & PostgresFlexibleServerFields;

export type PaasFormFields = PaasAdminItemsFields & PaasSpRgItemsFields & PaasItemsFields & { paas: boolean };

export type PaasFormExtensionProps = {
  onChange: (data: PaasFormFields) => void;
  formData: PaasFormFields;
};
