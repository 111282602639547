import { CircularProgress, FormHelperText, TextField } from '@material-ui/core';
import React from 'react';
import { useStyles } from '../OrionRequestForm/orionFormStyles';
import { orionServiceApiRef } from '../../service/orion.service';
import { useApi } from '@backstage/core-plugin-api';
import { useAsync } from 'react-use';
import { Alert, Autocomplete } from '@material-ui/lab';
import ErrorIcon from '@material-ui/icons/Error';

type OnChangeValue = {
  resourceGroup: string;
};

type Props = {
  onChange(value: OnChangeValue): void;
  onBlur(): void;
  disabled?: boolean | undefined;
  label?: string | undefined;
  required?: boolean;
  initialValue?: any;
  subscriptionId: string;
};

export const ResourceGroupDropdown: React.FC<Props> = ({
  onChange,
  onBlur,
  disabled = false,
  label = 'Resource Group',
  required = true,
  initialValue = undefined,
  subscriptionId,
}) => {
  const classes = useStyles();
  const orion = useApi(orionServiceApiRef);
  const resourceGroupOptions = useAsync(async () => {
    return subscriptionId ? (await orion.getResourceGroupsInSubscription(subscriptionId)).data : [];
  }, [orion, subscriptionId]);

  return (
    <>
      <div>
        <Autocomplete
          key={subscriptionId}
          disabled={resourceGroupOptions.error !== undefined || disabled || resourceGroupOptions.loading}
          id="resourceGroup"
          data-testid="resourceGroup"
          disableClearable
          autoSelect
          defaultValue={initialValue}
          options={resourceGroupOptions.value ?? []}
          getOptionLabel={option => option.name}
          getOptionSelected={(option, value) => option.name === value.name}
          onChange={(_: any, resourceGroup: any) => {
            onChange({ resourceGroup: resourceGroup.name });
          }}
          onBlur={onBlur}
          style={{ display: 'inline-flex' }}
          className={classes.componentFormGroup}
          renderInput={params => <TextField required={required} {...params} label={label} variant="outlined" />}
        />
        {!!resourceGroupOptions.loading && <CircularProgress style={{ marginLeft: 10 }} />}
        {!!resourceGroupOptions.error && (
          <>
            <ErrorIcon style={{ marginLeft: 10, marginRight: 10, color: 'red' }} />
            <FormHelperText>There was an error getting the Resource Groups. Please refresh the page.</FormHelperText>
          </>
        )}
      </div>
      <div>
        {subscriptionId.length > 0 && resourceGroupOptions.value?.length === 0 && !resourceGroupOptions.loading && (
          <>
            <Alert style={{ marginTop: 10 }} severity="error">
              <strong>There was an issue getting the Resource Groups. </strong>
            </Alert>
          </>
        )}
      </div>
    </>
  );
};
