import { Entity } from '@backstage/catalog-model';
import axios from 'axios';

export type K8sItemsResponse = {
  cluster: { name: string };
  resources: { type: string; resources: unknown[] }[];
};

export default class KubernetesService {
  private backendUrl;
  constructor(backendUrl: string) {
    this.backendUrl = backendUrl;
  }
  getService = async (entity: Entity, authToken: string): Promise<K8sItemsResponse[]> => {
    try {
      const response = await axios.post(
        `${this.backendUrl}/api/kubernetes/services/${entity.metadata.name}`,
        {
          entity,
        },
        {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        },
      );

      return response.data.items;
    } catch (e: any) {
      throw new Error(`Error Occurred While Calling Kubernetes Backend: ${e.message}`);
    }
  };
}
