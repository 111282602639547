import { createApiRef, OAuthApi } from '@backstage/core-plugin-api';
import { Octokit } from '@octokit/rest';

export type Data = Record<
  string,
  {
    name: string;
    content: string;
    path: string;
  }
>;

export type WorkflowData = {
  name: string;
  content: string;
  path: string;
};

type WorkflowDataResponse = {
  data: WorkflowData;
};
export interface DiffServiceApi {
  formatData(data: WorkflowData[]): Data;
  getCustomerTaxiwayFiles(repo: string, ref: string): Promise<WorkflowData[]>;
  getDTETaxiwayFiles(ref: string): Promise<WorkflowData[]>;
}

export const DiffServiceApiRef = createApiRef<DiffServiceApi>({
  id: 'plugin.diff-tool.service',
});

const DTE_WORKFLOWS = ['js_ci_workflow.yaml', 'python_ci_workflow.yaml', 'maven_ci_workflow.yaml', 'cd_workflow.yaml'];

const fetchWorkflows = async (octokit: Octokit, repo: string, ref: string): Promise<(WorkflowData | null)[]> =>
  Promise.all(
    DTE_WORKFLOWS.map(async workflow => {
      try {
        const file = (await octokit.repos.getContent({
          owner: 'AAInternal',
          repo: repo,
          path: `.github/workflows/${workflow}`,
          ref: ref,
        })) as unknown as WorkflowDataResponse;
        return file.data;
      } catch (error) {
        return null;
      }
    }),
  );

export class DiffService implements DiffServiceApi {
  constructor(private ghecAuthApi: OAuthApi) {}

  getCustomerTaxiwayFiles = async (repo: string, ref: string): Promise<WorkflowData[]> => {
    const token = await this.ghecAuthApi.getAccessToken();
    const octokit = new Octokit({ auth: token });

    const files = await fetchWorkflows(octokit, repo, ref);

    const validFiles: WorkflowData[] = files.filter(file => file !== null) as WorkflowData[];
    if (validFiles.length < 1) {
      throw new Error(
        "Couldn't fetch any Taxiway workflow files. Make sure the file names are correct and/or repo exists.",
      );
    }
    return validFiles;
  };

  getDTETaxiwayFiles = async (taxiwayVersion: string): Promise<WorkflowData[]> => {
    const token = await this.ghecAuthApi.getAccessToken();
    const octokit = new Octokit({ auth: token });

    const files = await fetchWorkflows(octokit, 'dte-reusable-workflows', taxiwayVersion);

    const validFiles = files.filter(file => file !== null) as WorkflowData[];
    if (validFiles.length < DTE_WORKFLOWS.length) {
      throw new Error("Couldn't fetch all Taxiway workflow files.");
    }

    return validFiles;
  };

  formatData = (data: WorkflowData[]) => {
    const output: Data = {};
    data.map(item => {
      if (item) {
        output[item.path] = {
          name: item.name,
          content: item.content,
          path: item.path,
        };
      } else {
        throw new Error('Item is undefined');
      }
    });
    return output;
  };
}
