import React from 'react';
import { Typography, Card, CardMedia, CardContent, makeStyles } from '@material-ui/core';
import { Link } from 'react-router-dom';

type Props = {
  image: string;
  title: string;
  description: string;
  target?: string;
  href: string;
};
const useStyles = makeStyles(theme => ({
  card: {
    width: '100%',
    paddingTop: 10,
    position: 'relative',
    margin: '0 10px',
  },
  cardMedia: {
    height: 140,
    backgroundSize: 'contain',
    margin: '0 20px',
  },
  cardLink: {
    display: 'block',
    color: theme.palette.text.primary,
    '&:hover': {
      textDecoration: 'none',
    },
    '&::before': {
      content: '""',
      cursor: 'inherit',
      display: 'block',
      position: 'absolute',
      top: '0px',
      left: '0px',
      width: '100%',
      height: '100%',
      zIndex: '1',
    },
  },
}));

export const HomePageCard = ({ image, title, description, target, href }: Props) => {
  const classes = useStyles();
  return (
    <Card className={classes.card}>
      <CardMedia className={classes.cardMedia} image={`/illustrations/${image}`} title="" />
      <CardContent>
        <Link className={classes.cardLink} to={href} target={target}>
          <Typography variant="h6">{title}</Typography>
        </Link>
        <Typography variant="subtitle1">{description}</Typography>
      </CardContent>
    </Card>
  );
};
