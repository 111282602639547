import { createApiRef, IdentityApi } from '@backstage/core-plugin-api';
import axios from 'axios';

export type ArgoCdDeleteRequest = {
  project_name: string;
  cluster_name: string;
  attempts: number;
  delete_response: string;
  status?: DeleteRequestStatus;
};

export enum DeleteRequestStatus {
  PENDING = 'pending',
  FAILED = 'failed',
  SUCCESS = 'success',
}

export type row = ArgoCdDeleteRequest & {
  id: string;
  created_at: string;
  updated_at: string;
};

export interface CatalogCustomizationsRecordStoreServiceApi {
  createArgoCDDeleteRequest(deleteRequest: ArgoCdDeleteRequest): Promise<row>;
  getArgoCDDeleteRequest(): Promise<row[]>;
  getArgoCDDeleteRequestByStatus(status: DeleteRequestStatus): Promise<row[]>;
  updateArgoCDDeleteRequestById(id: string, deleteRequest: ArgoCdDeleteRequest): Promise<row>;
  deleteArgoCDDeleteRequestById(id: string): Promise<row>;
}

export const CatalogCustomizationsRecordStoreServiceRef = createApiRef<CatalogCustomizationsRecordStoreServiceApi>({
  id: 'plugin.devkit.src.services.catalog-customization.service',
});

export class CatalogCustomizationsRecordStoreService implements CatalogCustomizationsRecordStoreServiceApi {
  constructor(
    private backendUrl: string,
    private identityApi: IdentityApi,
  ) {}

  createArgoCDDeleteRequest = async (deleteRequest: ArgoCdDeleteRequest): Promise<row> => {
    const { token } = await this.identityApi.getCredentials();
    return axios.post(`${this.backendUrl}/api/catalogcustomizations/argocd-delete-request`, deleteRequest, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  };

  getArgoCDDeleteRequest = async (): Promise<row[]> => {
    const { token } = await this.identityApi.getCredentials();
    return axios.get(`${this.backendUrl}/api/catalogcustomizations/argocd-delete-request`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  };

  getArgoCDDeleteRequestByStatus = async (status: DeleteRequestStatus): Promise<row[]> => {
    const { token } = await this.identityApi.getCredentials();
    return axios.get(`${this.backendUrl}/api/catalogcustomizations/argocd-delete-request?status=${status}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  };

  updateArgoCDDeleteRequestById = async (id: string, deleteRequest: ArgoCdDeleteRequest): Promise<row> => {
    const { token } = await this.identityApi.getCredentials();
    return axios.put(`${this.backendUrl}/api/catalogcustomizations/argocd-delete-request/${id}`, deleteRequest, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  };

  deleteArgoCDDeleteRequestById = async (id: string): Promise<row> => {
    const { token } = await this.identityApi.getCredentials();
    return axios.delete(`${this.backendUrl}/api/catalogcustomizations/argocd-delete-request/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  };
}
