import { createPlugin, createRoutableExtension } from '@backstage/core-plugin-api';

import { rootRouteRef } from './routes';

export const situationalAwarenessPlugin = createPlugin({
  id: 'situational-awareness',
  routes: {
    root: rootRouteRef,
  },
});

export const SituationalAwarenessPage = situationalAwarenessPlugin.provide(
  createRoutableExtension({
    name: 'SituationalAwarenessPage',
    component: () => import('./components/XMattersOnboardingForm').then(m => m.XMattersOnboardingForm),
    mountPoint: rootRouteRef,
  }),
);
