import React, { useEffect, useState } from 'react';
import { useApi, configApiRef, identityApiRef, IdentityApi } from '@backstage/core-plugin-api';
import { CircularProgress, TextField } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import axios from 'axios';
import { Config } from '@backstage/config';
import { FieldExtensionComponentProps } from '@backstage/plugin-scaffolder-react';

const getAppCodes = async (configApi: Config, identityApi: IdentityApi): Promise<string[]> => {
  const { token } = await identityApi.getCredentials();
  const headers = {
    Authorization: `Bearer ${token}`,
  };
  const backendUrl = configApi.getString('backend.baseUrl');
  const result = await axios.get(`${backendUrl}/api/orionRegistration/appcodes`, { headers });
  return result.data.data;
};

export const AppCodeSearchPicker = ({ onChange, required, formData }: FieldExtensionComponentProps<string>) => {
  const [open, setOpen] = useState(false);
  const [options, setOptions] = useState<string[]>([]);
  const loading = open && options.length === 0;
  const configApi: Config = useApi(configApiRef);
  const identityApi = useApi(identityApiRef);

  useEffect(() => {
    let active = true;

    if (!loading) {
      return undefined;
    }

    (async () => {
      const appCodes: string[] = await getAppCodes(configApi, identityApi);

      if (active) {
        setOptions(appCodes);
      }
    })();

    return () => {
      active = false;
    };
  }, [loading, configApi, identityApi]);

  useEffect(() => {
    if (!open) {
      setOptions([]);
    }
  }, [open]);

  return (
    <Autocomplete
      open={open}
      onOpen={() => {
        setOpen(true);
      }}
      onClose={() => {
        setOpen(false);
      }}
      getOptionLabel={option => option}
      getOptionSelected={(option: any, value: any) => option === value}
      options={options}
      loading={loading}
      value={formData ?? ''}
      onChange={(_: any, value: any) => onChange && onChange(value)}
      renderInput={params => (
        <TextField
          {...params}
          label="App Code"
          required={required}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {loading ? <CircularProgress color="inherit" size={20} /> : null}
                {params.InputProps.endAdornment}
              </>
            ),
          }}
        />
      )}
    />
  );
};
