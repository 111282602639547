import React, { useRef } from 'react';
import { Formik, Form, FormikProps } from 'formik';
import { Alert } from '@material-ui/lab';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import MenuItem from '@material-ui/core/MenuItem';
import Link from '@material-ui/core/Link';
import { useStyles } from '../OrionRequestForm/orionFormStyles';
import { amlCCFormValueTypes } from './amlCCFormValueTypes';
import { amlCCFormValidationSchema } from './amlCCFormValidationSchema';
import { AMLCCFormDropdown } from './AMLCCFormDropdown';
import { AMLCCFormTextField } from './AMLCCFormTextField';
import { SubscriptionDropdown } from '../SubscriptionDropdown/SubscriptionDropdown';
import { ResourceGroupDropdown } from '../ResourceGroupDropdown/ResourceGroupDropdown';
import { AMLWorkspaceDropdown } from '../AMLWorkspaceDropdown/AMLWorkspaceDropdown';

type AMLCCFormProps = {
  initialValues: amlCCFormValueTypes;
  onSubmit: (values: amlCCFormValueTypes) => void;
  loading: boolean;
  success: boolean;
  error?: string;
};

export const AMLCCForm = ({ initialValues, onSubmit, loading, success, error }: AMLCCFormProps) => {
  const classes = useStyles();
  const formRef = useRef<FormikProps<amlCCFormValueTypes>>(null);

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={values => {
        onSubmit(values);
      }}
      validationSchema={amlCCFormValidationSchema}
      innerRef={formRef}
    >
      {formik => (
        <Form className={classes.flexColumn}>
          <Typography variant="h3">AML Compute Cluster Request</Typography>
          <br />
          <SubscriptionDropdown
            onChange={async (values: any) => {
              formik.setFieldValue('subscription', values.subscription);
            }}
            onBlur={() => formik.setTouched({ subscription: true })}
            required
          />
          <ResourceGroupDropdown
            onChange={async (values: any) => {
              formik.setFieldValue('resourceGroup', values.resourceGroup);
            }}
            onBlur={() => formik.setTouched({ resourceGroup: true })}
            disabled={formik.values.subscription.length === 0}
            required
            subscriptionId={formik.values.subscription}
          />
          <AMLWorkspaceDropdown
            onChange={async (values: any) => {
              formik.setFieldValue('amlWorkspace', values.amlWorkspace);
            }}
            onBlur={() => formik.setTouched({ amlWorkspace: true })}
            disabled={formik.values.resourceGroup.length === 0}
            required
            resourceGroup={formik.values.resourceGroup}
          />
          <AMLCCFormTextField
            fieldPropName="maxNodes"
            label="Max Nodes"
            placeholder="ex: p-group020-aml-ws-001"
            required
          />
          <AMLCCFormTextField
            fieldPropName="vmSKU"
            label="VM SKU"
            placeholder="ex: Standard_NC24ads_A100_v4"
            required
          />
          <AMLCCFormDropdown
            fieldPropName="clusterAvailability"
            label="Cluster Availability"
            placeholder="low_priority"
            required
          >
            <MenuItem value="low_priority">Low Priority</MenuItem>
            <MenuItem value="dedicated">Dedicated</MenuItem>
          </AMLCCFormDropdown>
          <AMLCCFormTextField
            fieldPropName="clusterName"
            label="Cluster Name"
            placeholder="ex: low-a100-gpu"
            required
          />
          <div className={classes.flexColumn}>
            {(!!success || !!error) && (
              <div>
                {success && (
                  <Alert severity="success" style={{ marginBottom: 10 }} data-testid="submitSuccessMsg">
                    Compute Cluster provisioning has started.{' '}
                    <Link
                      href="https://github.com/AAInternal/orion-ml-provisioning/actions/workflows/aml_cc_provision.yaml"
                      rel="noopener noreferrer"
                      target="_blank"
                    >
                      You can track its progress here.
                    </Link>
                  </Alert>
                )}
                {error && (
                  <Alert aria-label="error" severity="error" style={{ marginBottom: 10 }} data-testid="submitErrorMsg">
                    {error}
                  </Alert>
                )}
              </div>
            )}
            <div className={classes.flexRow}>
              <Button
                type="submit"
                variant="text"
                color="primary"
                className={classes.button}
                disabled={loading || !formik.isValid || !formik.dirty || success}
              >
                Submit
              </Button>
            </div>
          </div>
          <Typography variant="body1" style={{ marginTop: 20 }}>
            <Link
              href="https://ml.azure.com/quota/c450f3d1-583c-495f-b5d3-0b38b99e70c0/eastus?tid=49793faf-eb3f-4d99-a0cf-aef7cce79dc1"
              rel="noopener noreferrer"
              target="_blank"
            >
              Quota
            </Link>{' '}
            and{' '}
            <Link
              href="https://learn.microsoft.com/en-us/azure/machine-learning/concept-compute-target?view=azureml-api-2#supported-vm-series-and-sizes"
              rel="noopener noreferrer"
              target="_blank"
            >
              SKU
            </Link>{' '}
            Information
          </Typography>
          <br />
        </Form>
      )}
    </Formik>
  );
};
