import React, { createContext } from 'react';
import { useAdminToggle } from '../../hooks';
import { AdminToggle } from '@runway/devkit-common';

const AdminToggleContext = createContext({} as AdminToggle);
type Children = React.JSX.Element[] | React.JSX.Element;
type AdminToggledProps = { children: Children; toggleName: string };

/**
 * Creates a context wrapper for a single AdminToggle
 * Children can use the AdminToggleContext.Consumer to access the toggle state or use one of the provided components `ShowOnEnabled` or `HideOnEnabled`
 * Chileren components that do not consume the context or use the provided components will not be affected by the toggle
 * @param children
 * @param toggleName
 * @public
 */
export function AdminToggled({ children, toggleName }: Readonly<AdminToggledProps>) {
  const adminToggle = useAdminToggle(toggleName);
  return <AdminToggleContext.Provider value={adminToggle}>{children}</AdminToggleContext.Provider>;
}

/**
 * Higher order component to show or hide children based on the toggle state
 * @param showOn will be used to compare with the toggle state
 * @private
 */
const adminToggleLogic =
  (showOn: boolean) =>
  ({ children }: { children: Children }) => (
    <AdminToggleContext.Consumer>
      {({ enabled }: AdminToggle) => <>{enabled === showOn && children}</>}
    </AdminToggleContext.Consumer>
  );

/**
 * Shows children if the toggle is enabled
 * @param children
 * @public
 */
AdminToggled.ShowOnEnabled = adminToggleLogic(true);

/**
 * Hides children if the toggle is enabled
 * @param children
 * @public
 */
AdminToggled.HideOnEnabled = adminToggleLogic(false);
