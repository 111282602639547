import React from 'react';
import { MenuCard } from '@runway/devkit';
import { useNavigate } from 'react-router-dom';

export const AMLKeyVault: React.FC = () => {
  const navigate = useNavigate();

  return (
    <MenuCard
      title="Azure Portal Key Vault"
      subtitle="Create a key vault for a user in Azure Portal for AML"
      onButtonClick={() => navigate('/dataanalytics/aml-kv')}
      buttonText="Create"
    />
  );
};
