import React from 'react';
import { FieldExtensionComponentProps } from '@backstage/plugin-scaffolder-react';
import { VerticalDropdown } from '../../components';

export const VerticalField = ({ onChange, formData }: FieldExtensionComponentProps<string>) => {
  return (
    <VerticalDropdown
      onChange={(values: { vertical: string }) => {
        onChange(values.vertical);
      }}
      initialValue={formData}
    />
  );
};
