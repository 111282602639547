import axios, { AxiosResponse } from 'axios';
import { timer } from './timer.service';
import {
  AddOwnerToApplicationRequest,
  AddOwnerToApplicationResponse,
  CreateAzureActiveDirectoryGroupReq,
  CreateRgReq,
  CreateSpReq,
  create_SP_Return_Data,
  AppData,
  GetUserReq,
  AssociateAADWithAzRGReq,
  AssociateAADWithAzRGRes,
  ApplicationRes,
  AssociateAADWithAzRGPostRes,
  CreateRGAndAADGroupsAndAssociateRes,
  LinkRgAndSP,
  LinkRgAndSpStatusResponse,
  PswdRes,
  RGRoles,
  StatusPolling,
  UserSearchResult,
} from '../api';
import { RestClient } from '../restClient/restClient';
import { RestClientApi } from '../restClient/restClientApi';

export interface AzureManagementServiceApi {
  addOwnertoApplication(req: AddOwnerToApplicationRequest): Promise<AddOwnerToApplicationResponse[]>;
  createAzureActiveDirectoryGroup(req: CreateAzureActiveDirectoryGroupReq): Promise<string>;
  createAzResourceGroup(req: CreateRgReq): Promise<string>;
  createAzServicePrincipal(req: CreateSpReq): Promise<create_SP_Return_Data>;
  getListOfDeploymentsForUser(): Promise<AppData>;
  deleteAmsDeployment(deleteLink: string): Promise<string>;
  waitForDeletionResponse(deploymentId: string): Promise<string>;
  getUser(req: GetUserReq): Promise<string>;
  associateAADWithAzResourceGroup(req: AssociateAADWithAzRGReq): Promise<AssociateAADWithAzRGRes>;
  createRGAndAADGroupsAndAssociate(req: CreateRgReq, employee_id: string): Promise<CreateRGAndAADGroupsAndAssociateRes>;
  linkRgAndSp(req: LinkRgAndSP): Promise<LinkRgAndSpStatusResponse>;
}
const pollPauseTime = 3000;
const pollAttemptCount = 20;

const AZURE_AD_GROUP_MAX_LENGTH = 120;
const FAILED_ATTEMPT_MESSAGE = 'Failed Attempt';
const ECONNRESET_ERROR_MESSAGE = 'read: ECONNRESET';
const UNKNOWN_ERROR_MESSAGE = 'Unknown Error Occurred. Please reach out to the Runway team for additional information.';
export class AzureManagementService implements AzureManagementServiceApi {
  constructor(
    private amServiceUrl: string,
    private token: string | undefined,
  ) {}
  private restClient: RestClientApi = new RestClient(console);

  getToken = async () => this.token;

  poll = async (
    attempt: () => Promise<any>,
    pause: number,
    attempts: number,
    timeoutMsg: string,
    tolerate500?: number,
    maxEconnResetAttempts = 2,
  ): Promise<any> => {
    let attemptsLeft = attempts;
    let tol500 = tolerate500;
    let econnAttempts = maxEconnResetAttempts;
    while (attemptsLeft > 0) {
      try {
        return await attempt();
      } catch (error: any) {
        if (!error.message) throw new Error(UNKNOWN_ERROR_MESSAGE);
        if (!error.message.includes(FAILED_ATTEMPT_MESSAGE)) {
          if (error.message.includes(ECONNRESET_ERROR_MESSAGE) && econnAttempts > 0) {
            econnAttempts -= 1;
          } else if (tol500 && error.message.includes('Network Error') && tol500 > 0) {
            tol500 -= 1;
          } else {
            throw new Error(error.message);
          }
        }
        attemptsLeft--;
        await timer(pause);
      }
    }
    throw new Error(timeoutMsg);
  };

  createADGroup = async (group: string, env: string, employee_id: string): Promise<null> => {
    return new Promise(async (resolve, reject) => {
      try {
        await this.createAzureActiveDirectoryGroup({
          activeDirectoryGroupName: group,
          tenant: env,
          users: [{ employee_id, user_type: 'Owner' }],
        });

        resolve(null);
      } catch (error: Error | unknown) {
        if (error instanceof Error && error.message.includes('already exist')) {
          resolve(null);
        }
        reject(error);
      }
    });
  };

  async createAzureActiveDirectoryGroup(req: CreateAzureActiveDirectoryGroupReq) {
    const token = await this.getToken();
    const ams_security_group_response = await this.restClient.post(
      `${this.amServiceUrl}/security-groups`,
      { name: req.activeDirectoryGroupName, tenant: req.tenant, users: req.users },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    );
    const activeDirectoryError = 'There was an error creating Azure Active Directory Group';
    if (ams_security_group_response.status !== 202) {
      throw Error(activeDirectoryError);
    }
    return this.poll(
      async () => {
        const deployment_link = ams_security_group_response.data._links.deployment.href;
        const deployment_details = await this.restClient.get(deployment_link, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        const activeDirectoryComplexError = `${activeDirectoryError}. Deployment ID: ${deployment_details.data.deployment_id} Error: ${deployment_details.data.status.current_status} :  ${deployment_details.data.status.error_msg}`;
        if (deployment_details.data.status.current_status === 'completed') {
          return deployment_details.data.status.status_detail;
        } else if (deployment_details.data.status.current_status.includes('failed')) {
          throw new Error(`${activeDirectoryComplexError}`);
        }
        throw Error(FAILED_ATTEMPT_MESSAGE);
      },
      1000,
      10,
      'Timed out',
    );
  }

  async createRGAndAADGroupsAndAssociate(
    req: CreateRgReq,
    employee_id: string,
  ): Promise<CreateRGAndAADGroupsAndAssociateRes> {
    const aad_env = req.envName === 'p' ? 'p' : 'n';
    const owner_aad_group = `AAD_${req.appVertical.toUpperCase()}_RG_${req.archerShortname.toUpperCase()}_OWNER_${aad_env.toUpperCase()}`;
    const contributor_aad_group = `AAD_${req.appVertical.toUpperCase()}_RG_${req.archerShortname.toUpperCase()}_CONTRIBUTOR_${aad_env.toUpperCase()}`;
    const reader_aad_group = `AAD_${req.appVertical.toUpperCase()}_RG_${req.archerShortname.toUpperCase()}_READER+_${aad_env.toUpperCase()}`;
    const result: CreateRGAndAADGroupsAndAssociateRes = {
      resource_group_name: '',
      aad_group_owner: '',
      aad_group_contributor: '',
      aad_group_reader_plus: '',
    };

    if (
      owner_aad_group.length > AZURE_AD_GROUP_MAX_LENGTH ||
      contributor_aad_group.length > AZURE_AD_GROUP_MAX_LENGTH ||
      reader_aad_group.length > AZURE_AD_GROUP_MAX_LENGTH
    ) {
      throw new Error('AAD Group name exceeds 120 characters');
    }

    let rgRes = '';
    try {
      rgRes = await this.createAzResourceGroup(req);
      result.resource_group_name = rgRes;
    } catch (error: unknown) {
      if (error instanceof Error) {
        throw new Error(`Error Creating Resource Group: ${error.message}`);
      }
      throw new Error('Error Creating Resource Group: Unknown Error Occurred');
    }

    await Promise.all(
      [owner_aad_group, contributor_aad_group, reader_aad_group].map(async group => {
        await this.createADGroup(group, req.tenant, employee_id);
      }),
    )
      .then(async () => {
        result.aad_group_owner = owner_aad_group;
        result.aad_group_contributor = contributor_aad_group;
        result.aad_group_reader_plus = reader_aad_group;

        try {
          await this.associateAADWithAzResourceGroup({
            resource_group_name: rgRes,
            tenant: req.tenant,
            subscription_id: req.subscriptionId,
            aad_groups: [
              { aad_name: owner_aad_group, role_id: RGRoles.OWNER },
              { aad_name: contributor_aad_group, role_id: RGRoles.CONTRIBUTOR },
              { aad_name: reader_aad_group, role_id: RGRoles.READER_PLUS },
            ],
          });
        } catch (error: unknown) {
          if (error instanceof Error) throw new Error(`Error Associating AAD Groups with RG: ${error.message}`);
          throw new Error('Error Associating AAD Groups with RG: Unknown Error Occurred');
        }
      })
      .catch((error: unknown) => {
        if (error instanceof Error) throw new Error(`Error Creating AAD Groups: ${error.message}`);
        throw new Error(`Error Creating AAD Groups: Unknown Error Occurred`);
      });

    return result;
  }

  async createAzResourceGroup(req: CreateRgReq) {
    const token = await this.getToken();
    const details = await this.restClient.post(
      `${this.amServiceUrl}/resource-groups`,
      {
        app_name: req.appName,
        app_vertical: req.appVertical,
        cost_center: req.costCenter,
        subscription_id: req.subscriptionId,
        archer_id: req.archerId,
        archer_shortname: req.archerShortname,
        tenant: req.tenant,
        env_name: req.envName,
        region: req.region,
        data_classification: req.dataClassification,
        custom_tags: req.tags,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    );
    const resourceGroupError = 'There was an error creating Resource Group';
    if (details.status !== 202) {
      throw Error(resourceGroupError);
    }

    return this.poll(
      async () => {
        const deployment_details = await this.restClient.get(details.data._links.deployment.href, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        const resourceGroupComplexError = `${resourceGroupError}, Status: ${deployment_details.data.status.current_status}, Error: ${deployment_details.data.status.error_msg};`;
        if (deployment_details.data.status.current_status === 'completed') {
          return deployment_details.data.resource_group;
        } else if (deployment_details.data.status.current_status.includes('failed')) {
          if (deployment_details.data.status.current_status === 'failed (6 of 6)') {
            throw new Error(
              `${resourceGroupComplexError} the process failed to assign user as owner to the resource group but the resource group was created. ref: CRG1`,
            );
          }
          throw new Error(`${resourceGroupComplexError}`);
        }
        throw Error(FAILED_ATTEMPT_MESSAGE);
      },
      pollPauseTime,
      pollAttemptCount,
      `Timed out: ${resourceGroupError}`,
    );
  }

  async getUser(req: GetUserReq) {
    const token = await this.getToken();
    const resp = await this.restClient.get(
      `${this.amServiceUrl}/users?tenant=${req.tenant}&badge_number=${req.employee_id}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    );

    if (resp.status === 200) {
      return resp.data.display_name;
    }
    throw new Error('There was an error creating Azure Active Directory Group');
  }

  async deleteAmsDeployment(deleteLink: string) {
    const token = await this.getToken();
    const resp = await this.restClient.delete(`${deleteLink}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    if (resp.data.status === 'FAILED') {
      throw new Error('There was an error deleting your deployment');
    }
    return 'Deployment will be Removed';
  }

  async getListOfDeploymentsForUser() {
    const token = await this.getToken();
    try {
      const resp = await this.restClient.get(`${this.amServiceUrl}/applications`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      return resp.data;
    } catch (error: any) {
      throw new Error('There was an error deleting your deployment');
    }
  }

  async waitForDeletionResponse(deploymentId: string) {
    const token = await this.getToken();
    const VALIDERROR = 'The specified resource does not exist';
    const resolvedValue = 'deleted';
    const pollResult = await this.poll(
      async () => {
        const res = await this.restClient.get(`${this.amServiceUrl}/deployments/${deploymentId}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        if (res.data.status.error_msg.includes(VALIDERROR)) {
          return resolvedValue;
        }
        throw Error(FAILED_ATTEMPT_MESSAGE);
      },
      pollPauseTime,
      pollAttemptCount,
      'Error getting response from deletion',
    );
    return pollResult;
  }

  async createAzServicePrincipal(req: CreateSpReq) {
    const servicePrincipalError = `There was an error creating Service Principal`;
    const token = await this.getToken();

    const servicePrincipalResponse = await this.restClient.post(
      `${this.amServiceUrl}/service-principals`,
      { display_name: req.displayName, tenant: req.env, owners: req.owners },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    );

    if (servicePrincipalResponse.status !== 202) {
      throw Error(servicePrincipalError);
    }

    const deployment_data: ApplicationRes = await this.poll(
      async () => {
        const deployment_link = servicePrincipalResponse.data._links.deployment.href;
        const deploymentInfo = await this.restClient.get(deployment_link, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        if (deploymentInfo.data.status.current_status === 'completed') {
          return {
            azure_app_client_id: deploymentInfo.data.application.client_id,
            azure_app_object_id: deploymentInfo.data.application.id,
            deployment_id: servicePrincipalResponse.data._links.deployment.href,
            outcome: deploymentInfo.data.status.current_status,
            pswd: deploymentInfo.data.application._links.password.href,
          };
        } else if (deploymentInfo.data.status.current_status.includes('failed')) {
          throw Error(
            `${servicePrincipalError} Status: ${deploymentInfo.data.status.current_status} Error: ${deploymentInfo.data.status.error_msg}`,
          );
        }
        throw Error(FAILED_ATTEMPT_MESSAGE);
      },
      pollPauseTime,
      pollAttemptCount,
      `Timed out: ${servicePrincipalError}`,
    );

    const secret = await this.restClient.post(
      deployment_data.pswd,
      {},
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    );

    const pswd_data: PswdRes = {
      azure_secret: secret.data.password.scrt_txt,
      azure_sp_name: secret.data.password.display_name,
    };

    const createSPReturnData: create_SP_Return_Data = {
      deployment_data,
      pswd_data,
    };

    return createSPReturnData;
  }

  async associateAADWithAzResourceGroup(req: AssociateAADWithAzRGReq): Promise<AssociateAADWithAzRGRes> {
    const associateAzResourceGroupError = 'There was an error associating AAD groups with Resource Group';
    const token = await this.getToken();
    let associateAzRGResponse: AxiosResponse<AssociateAADWithAzRGPostRes>;
    try {
      associateAzRGResponse = await this.restClient.post(
        `${this.amServiceUrl}/resource-groups-role-assignment/aad-groups`,
        req,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      );
    } catch (error: unknown) {
      if (axios.isAxiosError(error) && error.response) {
        throw new Error(`${associateAzResourceGroupError}. ${error.response.status} response received.`);
      }
      if (axios.isAxiosError(error) && error.request) {
        throw new Error(`${associateAzResourceGroupError}. Request made but no response received.`);
      }
      throw new Error(associateAzResourceGroupError);
    }

    if (associateAzRGResponse.status !== 202) {
      throw Error(associateAzResourceGroupError);
    }

    return await this.poll(
      async () => {
        const deployment_link = associateAzRGResponse.data._links.deployment.href;
        const deploymentInfo = await this.restClient.get(deployment_link, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        if (deploymentInfo.data.status.current_status === 'completed') {
          return deploymentInfo.data.status.current_status;
        } else if (
          deploymentInfo.data.status.current_status.includes('failed') ||
          deploymentInfo.data.status.current_status.includes('partial failure')
        ) {
          const statusMessage = deploymentInfo.data.status.failed_role_associations
            ? `${associateAzResourceGroupError} Status: ${deploymentInfo.data.status.current_status} Error: ${deploymentInfo.data.status.error_msg} Failed_Role_Associations: ${deploymentInfo.data.status.failed_role_associations}`
            : `${associateAzResourceGroupError} Status: ${deploymentInfo.data.status.current_status} Error: ${deploymentInfo.data.status.error_msg}`;
          throw Error(statusMessage);
        }
        throw Error(FAILED_ATTEMPT_MESSAGE);
      },
      pollPauseTime,
      pollAttemptCount,
      `Timed out: ${associateAzResourceGroupError}`,
      5,
    );
  }

  async linkRgAndSp(req: LinkRgAndSP) {
    const token = await this.getToken();
    const linkRgAndSpResponse = await this.restClient.post(
      `${this.amServiceUrl}/resource-groups-role-assignment/service-principal`,
      {
        resource_group_name: req.rgName,
        service_principal_display_name: req.spName,
        subscription_id: req.azSubscriptionId,
      },
      { headers: { Authorization: `Bearer ${token}` } },
    );
    if (linkRgAndSpResponse.status !== 202) throw new Error('expectedError');
    const linkRgAndSpError = `There was an error linking resource group to service principal`;
    const deployment_data: StatusPolling = await this.poll(
      async () => {
        const deployment_link = linkRgAndSpResponse.data._links.deployment.href;
        const deploymentInfo = await this.restClient.get(deployment_link, {
          headers: { Authorization: `Bearer ${token}` },
        });

        if (deploymentInfo.data.status.current_status === 'completed') return deploymentInfo.data;
        if (deploymentInfo.data.status.current_status.includes('failed')) {
          throw Error(
            `${linkRgAndSpError} Status: ${deploymentInfo.data.status.current_status} Error: ${deploymentInfo.data.status.error_msg}`,
          );
        } else throw Error(FAILED_ATTEMPT_MESSAGE);
      },
      pollPauseTime,
      pollAttemptCount,
      `Timed out: ${linkRgAndSpError}`,
    );

    return deployment_data.status;
  }

  async searchADUsers(searchQuery: string, tenant = 'p'): Promise<UserSearchResult> {
    try {
      const token = await this.getToken();
      const result = await this.restClient.get(
        `${this.amServiceUrl}/users/search?tenant=${tenant}&search_query=${searchQuery}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      );
      return result.data;
    } catch (error: unknown) {
      if (axios.isAxiosError(error)) {
        if (error.response === undefined) throw new Error('Unknown Axios Error Occurred');
        if (typeof error.response?.data.detail === 'string') throw new Error(error.response?.data.detail);
        throw new Error(error.response?.data.detail[0].msg);
      }
      throw new Error('Unknown Error Occurred');
    }
  }

  async addOwnertoApplication(req: AddOwnerToApplicationRequest): Promise<AddOwnerToApplicationResponse[]> {
    const token = await this.getToken();
    const result = await this.restClient.post(
      `${this.amServiceUrl}/applications/${req.appId}/owners`,
      {
        tenant: req.tenant,
        add_owners: req.owners,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    );

    return result.data.result;
  }
}
