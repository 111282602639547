import React from 'react';
import { Autocomplete } from '@material-ui/lab';
import {
  createStyles,
  Link,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Theme,
  withStyles,
} from '@material-ui/core';

const StyledTableCell = withStyles((theme: Theme) =>
  createStyles({
    head: {
      backgroundColor: theme.palette.primary.dark,
      color: theme.palette.common.white,
      fontWeight: 'bold',
    },
    body: {
      fontSize: 14,
    },
  }),
)(TableCell);

const StyledTableRow = withStyles((theme: Theme) =>
  createStyles({
    root: {
      '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
      },
    },
  }),
)(TableRow);

// TO DO: Hide table until value in drop down is selected
export default function AdoTable({ members }: { members: any }) {
  return (
    <TableContainer component={Paper}>
      <Table aria-label="simple table">
        <TableHead>
          <TableRow>
            <StyledTableCell>Project Administrators</StyledTableCell>
            <StyledTableCell>Email</StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {members.map((member: any) => (
            <StyledTableRow key={member.user.displayName}>
              <StyledTableCell component="th" scope="row">
                {member.user.displayName}
              </StyledTableCell>
              <StyledTableCell component="th" scope="row">
                {/* TODO: for link href just grab a normal string and URL encode*/}
                <Link href={`mailto:${member.user.mailAddress}?subject=ADOC%20Project%20Access`}>
                  {member.user.mailAddress}
                </Link>
              </StyledTableCell>
            </StyledTableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

interface AdoAccessFetchComponentProps {
  projectList: any;
  waitingForProjectList: boolean;
  members: any;
  handleFindProjectMembers: (project: any) => Promise<void>;
}

export const AdoAccessFetchComponent = (props: AdoAccessFetchComponentProps) => {
  // TO DO: Add a clear, so the table empties when they clear the project name
  return (
    <div>
      <Autocomplete
        data-testid="ado-access-dropdown"
        getOptionLabel={(option: any) => option.name}
        style={{ width: 500 }}
        renderInput={params => <TextField {...params} label="Select your ADOC Project" variant="outlined" />}
        options={props.waitingForProjectList ? [] : props.projectList}
        onChange={(_: any, project: any) => {
          props.handleFindProjectMembers(project.id);
        }}
      />
      <br />
      <AdoTable members={props.members} />
    </div>
  );
};
