import React from 'react';
import { configApiRef, githubAuthApiRef, useApi } from '@backstage/core-plugin-api';
import { useAsync } from 'react-use';
import { Octokit } from '@octokit/rest';
import { CircularProgress, Grid, TextField } from '@material-ui/core';
import { FieldExtensionComponentProps } from '@backstage/plugin-scaffolder-react';

export const GitHubUserId = ({ onChange, formData, rawErrors, uiSchema }: FieldExtensionComponentProps<string>) => {
  const hidden = uiSchema['ui:widget'] === 'hidden';

  const ghecAuthApi = useApi(githubAuthApiRef);
  const configApi = useApi(configApiRef);
  const githubUrl = configApi.getString('github.ghec.apiUrl');

  const githubOwnerState = useAsync(async () => {
    const ghecAccessToken = await ghecAuthApi.getAccessToken();
    const octokit = new Octokit({
      auth: ghecAccessToken,
      baseUrl: githubUrl,
    });
    const user = await octokit.users.getAuthenticated();
    onChange(user.data.login);
    return user.data.login;
  });

  if (hidden) {
    return <></>;
  }

  return (
    <Grid container>
      <Grid item xs={8}>
        <TextField
          id="githubOwner"
          helperText="Github Owner username"
          style={{ width: '300px' }}
          required
          value={githubOwnerState.value}
          error={rawErrors?.length > 0 && !formData}
        />
      </Grid>
      <Grid item xs={4}>
        {githubOwnerState.loading && <CircularProgress style={{ padding: 5 }} />}
      </Grid>
    </Grid>
  );
};
