import { TextField } from '@material-ui/core';
import React from 'react';
import { useStyles } from '../OrionRequestForm/orionFormStyles';
import { Autocomplete } from '@material-ui/lab';

type OnChangeValue = {
  subscription: string;
};

type Props = {
  onChange(value: OnChangeValue): void;
  onBlur(): void;
  disabled?: boolean | undefined;
  label?: string | undefined;
  required?: boolean;
  initialValue?: any;
};

export const SubscriptionDropdown: React.FC<Props> = ({
  onChange,
  onBlur,
  label = 'Subscription',
  required = true,
  initialValue = undefined,
}) => {
  const classes = useStyles();
  const subscriptionOptions = [
    { id: 'c450f3d1-583c-495f-b5d3-0b38b99e70c0', name: 'aa-ba-prod-spoke' },
    { id: 'ae0b13d9-e09c-4e54-b1e6-8e777d8fbe14', name: 'aa-ba-nonprod-spoke' },
  ];

  return (
    <>
      <div>
        <Autocomplete
          id="subscription"
          data-testid="subscription"
          disableClearable
          autoSelect
          defaultValue={initialValue}
          options={subscriptionOptions ?? []}
          getOptionLabel={option => option.name}
          getOptionSelected={(option, value) => option.name === value.name}
          onChange={(_: any, subscription: any) => {
            onChange({ subscription: subscription.id });
          }}
          onBlur={onBlur}
          style={{ display: 'inline-flex' }}
          className={classes.componentFormGroup}
          renderInput={params => <TextField required={required} {...params} label={label} variant="outlined" />}
        />
      </div>
    </>
  );
};
