import React from 'react';
import { DeploymentsMetadata } from '../services';
import { STATUS } from '../Status';
import { getClusterDetails, getStatusDetails2D, Layer } from './Layer';

export const DeploymentsLayer = ({
  status,
  clusters,
}: {
  status: STATUS;
  clusters: Record<string, DeploymentsMetadata[]> | undefined;
}) => (
  <Layer
    title="Deployments"
    status={status}
    subheader={getStatusDetails2D<DeploymentsMetadata>(status, clusters, getClusterDetails, 'deployment')}
  />
);
