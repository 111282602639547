import { Config } from '@backstage/config';
import {
  ApiRef,
  AuthRequestOptions,
  BackstageIdentityApi,
  BackstageIdentityResponse,
  createApiRef,
  githubAuthApiRef,
  OAuthApi,
  OAuthScope,
  OpenIdConnectApi,
  ProfileInfo,
  ProfileInfoApi,
  SessionApi,
  SessionState,
} from '@backstage/core-plugin-api';
import { Observable } from '@backstage/types';
import { Octokit } from '@octokit/rest';
import { jwtDecode } from 'jwt-decode';

// Included for Runway however this is no longer exported
type GithubSession = {
  providerInfo: {
    accessToken: string;
    scopes: Set<string>;
    expiresAt?: Date;
  };
  profile: ProfileInfo;
  backstageIdentity: BackstageIdentityResponse;
};

// Runway customized the OAuth2AuthProvider so that the profile contains aaId.
// The OAuth2 apiRef and it's implementation have to be wrapped for typescript to reflect the new property.
// The wrapping is purely for type annotations and readability.

// this is the interface for getting the aa profile
interface AAProfileInfoApi {
  getAAProfile(options?: AuthRequestOptions | undefined): Promise<(ProfileInfo & { aaId?: string }) | undefined>;
}

type OAuth2Api = OAuthApi & OpenIdConnectApi & ProfileInfoApi & BackstageIdentityApi & SessionApi;

// use this type for services that use the sso apiRef
export type OAuth2AAPingSSOApi = OAuth2Api & AAProfileInfoApi;

export const aaPingSSOAuthApiRef: ApiRef<OAuth2AAPingSSOApi> = createApiRef({
  id: 'runway.auth.ping.sso',
});

export class OAuth2AAPingSSO implements OAuth2AAPingSSOApi {
  constructor(
    private baseClass: OAuth2Api,
    private config: Config,
  ) {}

  getAAProfile(options?: AuthRequestOptions): Promise<(ProfileInfo & { aaId?: string | undefined }) | undefined> {
    return this.baseClass.getProfile(options);
  }

  getIdToken(options?: AuthRequestOptions): Promise<string> {
    return this.baseClass.getIdToken(options);
  }

  getAccessToken(scope?: OAuthScope, options?: AuthRequestOptions): Promise<string> {
    if (process.env.NODE_ENV === 'development') {
      const localToken = this.config.getString('backendOverrides.authorizationSso');
      const backstageTokenDecoded = jwtDecode<{ aaSso: string }>(localToken || '');
      return Promise.resolve(backstageTokenDecoded.aaSso);
    }
    return this.baseClass.getAccessToken(scope, options);
  }
  signIn(): Promise<void> {
    return this.baseClass.signIn();
  }
  signOut(): Promise<void> {
    return this.baseClass.signOut();
  }
  sessionState$(): Observable<SessionState> {
    return this.baseClass.sessionState$();
  }
  getBackstageIdentity(options?: AuthRequestOptions): Promise<BackstageIdentityResponse | undefined> {
    return this.baseClass.getBackstageIdentity(options);
  }
  getProfile(options?: AuthRequestOptions): Promise<ProfileInfo | undefined> {
    return this.baseClass.getProfile(options);
  }
}

type GithubAuthApi = typeof githubAuthApiRef.T;

export class MockGithubAuthApi implements GithubAuthApi {
  constructor(private storageKey: string) {}

  signIn(): Promise<void> {
    return Promise.resolve();
  }
  signOut(): Promise<void> {
    localStorage.removeItem(this.storageKey);
    return Promise.resolve();
  }
  sessionState$(): Observable<SessionState> {
    throw new Error('Method not implemented.');
  }
  getAccessToken(_scope?: string, _options?: AuthRequestOptions): Promise<string> {
    const ghSession = this.loadSession();
    if (ghSession) {
      return Promise.resolve(ghSession.providerInfo.accessToken);
    }
    // jump to /dev-github-login
    window.location.pathname = '/dev-github-login';
    return Promise.resolve('');
  }
  getBackstageIdentity(_options?: AuthRequestOptions): Promise<BackstageIdentityResponse | undefined> {
    const ghSession = this.loadSession();
    if (ghSession) {
      return Promise.resolve(ghSession.backstageIdentity);
    }
    // jump to /dev-github-login
    window.location.pathname = '/dev-github-login';
    return Promise.resolve(undefined);
  }
  getProfile(_options?: AuthRequestOptions): Promise<ProfileInfo | undefined> {
    return Promise.resolve({
      email: 'mock.user@aa.com',
      displayName: 'Mock G. H. User',
    });
  }

  private loadSession(): GithubSession | undefined {
    try {
      const sessionJson = localStorage.getItem(this.storageKey);
      if (sessionJson) {
        const session = JSON.parse(sessionJson, (_key, value) => {
          if (value?.__type === 'Set') {
            return new Set(value.__value);
          }
          return value;
        });
        return session;
      }

      return undefined;
    } catch (error: any) {
      localStorage.removeItem(this.storageKey);
      return undefined;
    }
  }

  saveSession(session: GithubSession | undefined) {
    if (session === undefined) {
      localStorage.removeItem(this.storageKey);
    } else {
      localStorage.setItem(
        this.storageKey,
        JSON.stringify(session, (_key, value) => {
          if (value instanceof Set) {
            return {
              __type: 'Set',
              __value: Array.from(value),
            };
          }
          return value;
        }),
      );
    }
  }

  setAccessTokenInSession(token: string, githubUrl: string) {
    const octokit = new Octokit({
      auth: token,
      baseUrl: githubUrl,
    });
    octokit.users.getAuthenticated().then(user => {
      this.saveSession({
        providerInfo: {
          accessToken: token,
          scopes: new Set(),
          expiresAt: new Date(),
        },
        profile: {},
        backstageIdentity: {
          identity: {
            ownershipEntityRefs: [`user:default/${user.data.login}`],
            type: 'user',
            userEntityRef: `user:default/${user.data.login}`,
          },
          token: 'test',
        },
      });
    });
  }
}
