import { IconButton, Tooltip } from '@material-ui/core';
import React from 'react';
import EditIcon from '@material-ui/icons/Edit';
import { DeleteStatus } from './utils';

export const UpdateToolTip = (props: any | undefined) => {
  const { handleClick, title, appNameDeleteStatus, clusterNameDeleteStatus, appNameIsVisible, clusterNameIsVisible } =
    props;
  if (!appNameIsVisible && !clusterNameIsVisible) {
    return null;
  }
  return (
    <Tooltip title={title}>
      <span>
        <IconButton
          role="button"
          aria-label={`${title}-update`}
          onClick={() => handleClick()}
          disabled={appNameDeleteStatus === DeleteStatus.PENDING && clusterNameDeleteStatus === DeleteStatus.PENDING}
        >
          <EditIcon />
        </IconButton>
      </span>
    </Tooltip>
  );
};
