import React from 'react';
import { MenuCard } from '@runway/devkit';

export const DataLakeAccessMenu: React.FC = () => {
  return (
    <MenuCard
      title="Orion Data Lake Access"
      subtitle="Request access to Orion's Data Lake via AAs Data Marketplace"
      linkText="Guide for first-time users"
      linkHref="https://spteam.aa.com/sites/DataGovernance/SitePages/American%27s-Data-Marketplace---First-Time-Login.aspx"
      onButtonClick={() => window.open('https://ez.cloud.aa.com/go/data', '_blank')}
      buttonText="Request"
    />
  );
};
