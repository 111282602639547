import axios, { AxiosResponse } from 'axios';
import { createApiRef, IdentityApi } from '@backstage/core-plugin-api';

type ReqCreateExtnLinuxFSReq = {
  serverName: string;
  mountPoint: string;
  size: number;
};

export interface ReqCreateExtnLinuxFSApi {
  reqCreateExtnLinuxFS(req: ReqCreateExtnLinuxFSReq): Promise<AxiosResponse>;
}

export const ReqCreateExtnLinuxFSApiRef = createApiRef<ReqCreateExtnLinuxFSApi>({
  id: 'plugin.infrastructure.reqcreateextnlinuxfs.service',
});

export class ReqCreateExtnLinuxFS implements ReqCreateExtnLinuxFSApi {
  constructor(
    private backendUrl: string,
    private identityApi: IdentityApi,
  ) {}

  async reqCreateExtnLinuxFS(req: ReqCreateExtnLinuxFSReq) {
    // trigger create
    const { token } = await this.identityApi.getCredentials();
    let deets: AxiosResponse;
    try {
      deets = await axios.post(
        `${this.backendUrl}/manage/vm/extend-fs`,
        { ...req },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      );
    } catch (e: string | any) {
      if (e.response) {
        const message = `Status code: ${e.response.status}
Failure Message: ${e.response.data.detail}`;
        throw new Error(message);
      } else {
        throw e;
      }
    }
    return deets;
  }
}
