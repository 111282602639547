import { K8sItemsResponse } from './services/kubernetes.service';

type IngressData = {
  metadata: {
    name: string;
  };
};

export const ingressResolver = (k8sResponse: K8sItemsResponse[]) =>
  Array.from(
    new Set(
      k8sResponse.flatMap(item =>
        item.resources
          .filter(resource => resource.type === 'ingresses')
          .flatMap(ingress => ingress.resources.map(ingressData => (ingressData as IngressData).metadata.name)),
      ),
    ),
  );
