import { useApi } from '@backstage/core-plugin-api';
import { FieldExtensionComponentProps } from '@backstage/plugin-scaffolder-react';
import { CircularProgress, Grid, TextField } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import { FieldValidation } from '@rjsf/utils';
import React from 'react';
import { useAsyncFn } from 'react-use';
import { GithubEnterpriseClientApiGraphRef } from '../../services';

const forbiddenNpmNames = ['http', 'stream', 'node_modules', 'favicon.ico'];

export const GithubRepoField = ({
  onChange,
  rawErrors,
  required,
  formData,
  schema,
}: FieldExtensionComponentProps<string>) => {
  const githubEnterpriseClientGraph = useApi(GithubEnterpriseClientApiGraphRef);
  const validationType = (schema as any)?.validation;
  const customRegexPattern = (schema as any)?.pattern;

  const [validateRepoNameState, validateRepoName] = useAsyncFn(async (repo: string) => {
    if (repo === '') {
      throw new Error('Cannot have empty repo');
    }
    if (customRegexPattern === 'npmRegex') {
      if (repo.length > 214) {
        throw new Error('Package name length cannot exceed 214 characters');
      }
      if (repo !== repo.toLowerCase()) {
        throw new Error('all the characters in the package name must be lowercase');
      }
      if (forbiddenNpmNames.includes(repo)) {
        throw new Error(
          `package name cannot be the same as a node.js/io.js core module nor a reserved/blacklisted name`,
        );
      }
      if (repo.startsWith('.') || repo.startsWith('_')) {
        throw new Error('Package name cannot start with a period or underscore');
      }
      if (/\s/.test(repo)) {
        throw new Error('Package name cannot contain spaces');
      }
      if (/[~()\!'*]/.test(repo)) {
        throw new Error("Package name cannot contain any of the following characters: ~()'!*");
      }
    }

    const isUniqueRepoNameResp = await githubEnterpriseClientGraph.isUniqueRepo('AAInternal', repo);
    if (isUniqueRepoNameResp) {
      if (validationType === 'pullRequest') {
        throw new Error('Repo does not exist for Pull Request!');
      }
      return 'Repo Name is unique!';
    } else if (validationType === 'pullRequest') {
      return 'Repo exists for Pull Request!';
    }
    throw new Error('Repo Name is not unique! Cannot create repo');
  });

  const [showNpmRules, setShowNpmRules] = React.useState(false);

  return (
    <Grid container>
      <Grid
        item
        xs={validateRepoNameState.loading || validateRepoNameState.value || validateRepoNameState.error ? 8 : 12}
      >
        <TextField
          id="name"
          label="Repo Name"
          helperText="Repo Name"
          style={{ width: '100%' }}
          required={required}
          value={formData ?? ''}
          onInput={() => {
            if (customRegexPattern === 'npmRegex') {
              setShowNpmRules(true);
            }
          }}
          onChange={({ target: { value } }) => {
            onChange(value);
          }}
          onBlur={({ target: { value } }) => {
            if (value) validateRepoName(value);
          }}
          error={!!((rawErrors?.length > 0 && !formData) || validateRepoNameState.error)}
        />
      </Grid>{' '}
      {showNpmRules && (
        <Grid item xs={12}>
          <Alert severity="info">
            GitHub repository name turns into a dependency to be used to name npm package, and should follow these npm
            naming rules:
            <ul>
              <li>all the characters in the package name must be lowercase.</li>
              <li>package name should not start with . or _ </li>
              <li>package name should not contain any spaces or special characters like ~)('!* </li>
              <li>
                package name cannot be the same as a node.js/io.js core module nor a reserved/blacklisted name like
                http, stream
              </li>
            </ul>
          </Alert>
        </Grid>
      )}
      {validateRepoNameState.loading && (
        <Grid item xs={4}>
          <CircularProgress style={{ padding: 5 }} />
        </Grid>
      )}
      {validateRepoNameState.error && (
        <Grid item xs={4}>
          <Alert severity="error">{validateRepoNameState.error?.message}</Alert>
        </Grid>
      )}
      {validateRepoNameState.value && !validateRepoNameState.error && (
        <Grid item xs={4}>
          <Alert severity="success">{validateRepoNameState.value}</Alert>
        </Grid>
      )}
    </Grid>
  );
};

export const githubRepoFieldValidation = (value: string, validation: FieldValidation) => {
  const regexp = new RegExp('^[a-zA-Z][a-z0-9-.A-Z_]*$');
  if (!regexp.test(value)) {
    validation.addError(
      'Please use letters, dashes, underscores, periods, and numbers. You must start and end with a letter.',
    );
  } else if (value.length > 56) {
    validation.addError('Names cannot be more than 56 characters.');
  }
};
