import React, { useEffect } from 'react';
import { Button, Typography, Grid, Popper, Fade, Paper, IconButton, makeStyles } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';

const useStyles = makeStyles(theme => ({
  popPaper: {
    width: 380,
    height: 120,
    textAlign: 'left',
    backgroundColor: theme.palette.primary.light,
    padding: 15,
  },
}));

export const Survey = () => {
  const [openSurveyPopup, setOpenSurveyPopup] = React.useState(true);
  const classes = useStyles();

  useEffect(() => {
    const showpopup = window.localStorage.getItem('swagger-survey-popup');
    if (showpopup) {
      const now = new Date();
      const thirtyDaysAgo = new Date(showpopup);
      thirtyDaysAgo.setDate(thirtyDaysAgo.getDate() + 30);
      if (now > thirtyDaysAgo) {
        setOpenSurveyPopup(true);
      } else {
        setOpenSurveyPopup(false);
      }
    }
  }, []);

  const handleSurveyPopup = () => {
    setOpenSurveyPopup(false);
    const now = new Date();
    window.localStorage.setItem('swagger-survey-popup', now.toString());
  };

  return (
    <div>
      <Popper
        open={openSurveyPopup}
        transition
        style={{ position: 'fixed', bottom: 400, right: 0, top: 'unset', left: 'unset' }}
      >
        {({ TransitionProps }) => (
          <Fade {...TransitionProps} timeout={350}>
            <Paper square={false} elevation={6} className={classes.popPaper}>
              <Grid container direction="row" justifyContent="space-between" alignItems="flex-start">
                <Grid item>
                  <Typography variant="h6">We would love your feedback!</Typography>
                </Grid>
                <Grid item>
                  <IconButton data-testid="close-button" onClick={handleSurveyPopup}>
                    <CloseIcon />
                  </IconButton>
                </Grid>
              </Grid>
              <Grid container justifyContent="center">
                <Grid item>
                  <Button
                    data-testid="survey-button"
                    href="https://surveys.aa.com/assets/run.html?survey_id=654d1b966845101bd1fc744b&archer_id=7183256"
                    target="_blank"
                  >
                    Give feedback
                  </Button>
                </Grid>
              </Grid>
            </Paper>
          </Fade>
        )}
      </Popper>
    </div>
  );
};

export default Survey;
