import { createApiRef } from '@backstage/core-plugin-api';
import {
  AzureManagementService as AzureManagementServiceCommon,
  AzureManagementServiceApi,
} from '@runway/devkit-common';
import { OAuth2AAPingSSOApi } from './runwayAuthApiRefs';

export const AzureManagementServiceApiRef = createApiRef<AzureManagementServiceApi>({
  id: 'packages.devkit.src.service.am.service',
});

class AzureManagementService extends AzureManagementServiceCommon {
  constructor(
    backendUrl: string,
    private oAuthApi: OAuth2AAPingSSOApi,
  ) {
    super(backendUrl, '');
  }
  getToken = async () => await this.oAuthApi.getAccessToken();
}

export default AzureManagementService;
