export const workspaceMenuItems = [
  { value: 'ba-p-zeaus-orion-adbde-dbws-001', label: 'ba-p-zeaus-orion-adbde-dbws-001' },
  { value: 'ba-p-zeaus-orion-adbde-dbws-002', label: 'ba-p-zeaus-orion-adbde-dbws-002' },
  { value: 'ba-p-zeaus-orion-adbde-dbws-003', label: 'ba-p-zeaus-orion-adbde-dbws-003' },
  { value: 'ba-p-zeaus-orion-adbde-dbws-007', label: 'ba-p-zeaus-orion-adbde-dbws-007' },
  { value: 'ba-p-zweus-orionadbde-dbws-001', label: 'ba-p-zweus-orionadbde-dbws-001' },
  { value: 'ba-p-zweus-orionadbde-dbws-002', label: 'ba-p-zweus-orionadbde-dbws-002' },
  { value: 'ba-p-zweus-orionadbde-dbws-003', label: 'ba-p-zweus-orionadbde-dbws-003' },
  { value: 'ba-p-zweus-orionadbde-dbws-007', label: 'ba-p-zweus-orionadbde-dbws-007' },
  { value: 'ba-p-zeaus-oriontops-dbws-001', label: 'ba-p-zeaus-oriontops-dbws-001' },
  { value: 'ba-p-zeaus-orionadbrm-dbws-001', label: 'ba-p-zeaus-orionadbrm-dbws-001' },
  { value: 'ba-p-zeaus-orionadbrb-dbws-001', label: 'ba-p-zeaus-orionadbrb-dbws-001' },
];
