import { useLayerInfo } from './useLayerInfo';
import { STATUS } from './Status';
import React from 'react';
import ErrorIcon from '@material-ui/icons/Error';

export const TroubleshooterTabErrorBadge = () => {
  const { allStatus } = useLayerInfo();

  if (
    Object.values(allStatus).includes(STATUS.unhealthy) ||
    Object.values(allStatus).includes(STATUS.unretrievable) ||
    Object.values(allStatus).includes(STATUS.partiallyHealthy)
  )
    return (
      <ErrorIcon
        aria-label="error"
        color="error"
        style={{
          fontSize: '1.7rem',
          marginBottom: '0px',
          paddingBottom: '2px',
          marginLeft: '3px',
        }}
      />
    );

  return null;
};
