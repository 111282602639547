import axios from 'axios';
import { AkamaiGTMMetadata } from '../Layers/AkamaiGTMLayer';

export default class AkamaiService {
  private backendUrl: string;

  constructor(backendUrl: string) {
    this.backendUrl = backendUrl;
  }

  getGTMMetadata = async (ingressList: string[], authToken: string): Promise<AkamaiGTMMetadata[]> => {
    const gtmArray: AkamaiGTMMetadata[] = [];
    await Promise.all(
      ingressList.map(async ingress => {
        try {
          const result = await axios.get(`${this.backendUrl}/api/catalogCustomizations/akamaiFetch/${ingress}`, {
            headers: { authorization: `Bearer ${authToken}` },
            timeout: 7500,
          });
          gtmArray.push(...result.data);
        } catch (err) {
          /* Do nothing */
        }
      }),
    );
    return gtmArray;
  };
}
