import React, { useState, useRef, useEffect, SetStateAction } from 'react';
import {
  Button,
  Grid,
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
  DialogProps,
  DialogContentText,
  makeStyles,
  LinearProgress,
  TextField,
} from '@material-ui/core';
import { Alert, Autocomplete } from '@material-ui/lab';
import 'swagger-editor/dist/swagger-editor.css';
import { ProfileInfo, identityApiRef, useApi } from '@backstage/core-plugin-api';
import { SwaggerServiceApiRef } from '../../services/swagger.service';
import { useTrackedRequest } from '@runway/devkit';
import { BucketId, ClientId, PluginId } from '@runway/devkit-common';
import './table.css';
import { useFilePicker } from 'use-file-picker';

type TBodyData = {
  line?: number;
  message?: string;
  severity?: string;
} & { paths?: string[] } & Record<string, string | number | undefined>;

// Set the styles
const useStyles = makeStyles({
  paper: { maxWidth: '100%', width: '1000px' },
  Alert: {
    whiteSpace: 'pre-line',
    margin: 10,
    maxWidth: 500,
  },
  import: {
    width: 150,
    '& .MuiOutlinedInput-input': {
      color: 'blue',
    },
    '& .MuiInputLabel-root': {
      color: 'blue',
    },
    '& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
      borderColor: 'blue',
    },
  },
});

export default function Table({ theadData, tbodyData }: { theadData: string[]; tbodyData: TBodyData[] }) {
  return (
    <table className="SwaggerComponent">
      <thead>
        <tr>
          {theadData.map((heading: string) => {
            return <th key={heading}>{heading}</th>;
          })}
        </tr>
      </thead>
      <tbody>
        {tbodyData.map((row: TBodyData, index: number) => {
          return (
            <tr key={index}>
              {theadData.map((key: string) => {
                if (key === 'path') {
                  return <td key={row[key]}>{JSON.stringify(row[key])}</td>;
                }
                if (key === 'severity' && row[key] === 'error') {
                  return (
                    <td key={row[key]}>
                      <p style={{ color: 'red', fontWeight: 'bold' }}>{row[key]}</p>
                    </td>
                  );
                }
                if (key === 'severity' && row[key] === 'warning') {
                  return (
                    <td key={row[key]}>
                      <p style={{ color: 'orange', fontWeight: 'bold' }}>{row[key]}</p>
                    </td>
                  );
                }
                return <td key={row[key]}>{row[key]}</td>;
              })}
            </tr>
          );
        })}
      </tbody>
    </table>
  );
}

export const downloadFile = () => {
  const content = window.localStorage.getItem('swagger-editor-content');

  if (content) {
    const downloadLink = document.createElement('a');
    downloadLink.setAttribute('href', window.URL.createObjectURL(new Blob([content], { type: 'text/yaml' })));
    downloadLink.setAttribute('download', 'swagger.yaml');
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
  }
};

export const SwaggerValidateComponent = () => {
  const classes = useStyles();
  const swaggerSvc = useApi(SwaggerServiceApiRef);
  const identityApi = useApi(identityApiRef);

  const { tracker } = useTrackedRequest({
    pluginId: PluginId.SWAGGER_VALIDATE.id,
    bucket_id: BucketId.USE_METRIC,
    client_id: ClientId.RUNWAY_FRONTEND,
  });

  const importFromMenu = ['File', 'URL'];
  const [resultLoading, setResultLoading] = useState<boolean>(false);
  const [specResults, setSpecResults] = useState<TBodyData[]>([]);
  const [error, setError] = useState<boolean>(false);
  const [errorText, setErrorText] = useState<string>('');
  const [open, setOpen] = useState(false);
  const [openImportUrl, setOpenImportUrl] = useState(false);
  const [importURL, setImportURL] = useState('');
  const [importURLError, setImportURLError] = useState(false);
  const [importURLErrorText, setImportURLErrorText] = useState<string>('');
  const [selectedIndex, setSelectedIndex] = React.useState('');
  const [scroll, setScroll] = useState<DialogProps['scroll']>('paper');

  const { openFilePicker } = useFilePicker({
    readAs: 'Text',
    accept: '.yaml',
    multiple: false,
    onFilesSelected: ({ filesContent }) => {
      if (filesContent[0].content.length > 0) {
        window.localStorage.setItem('swagger-editor-content', filesContent[0].content);
        window.location.reload();
      }
    },
  });

  const handleClickOpen = (scrollType: DialogProps['scroll']) => async () => {
    const content = window.localStorage.getItem('swagger-editor-content');
    const identity = (await identityApi.getProfileInfo()) as unknown as ProfileInfo & { aaId: string };
    setResultLoading(true);
    try {
      const res = await tracker(
        async () => {
          return await swaggerSvc.validateSpec(content);
        },
        {
          action: 'Swagger API Validate call invoked',
          userEmployeeId: identity?.aaId ?? '-1',
        },
      );

      setOpen(true);
      setScroll(scrollType);
      setSpecResults(res);
      setError(false);
    } catch (e) {
      setResultLoading(false);
      setError(true);
      setErrorText(`${(e as Error).message}`);
    }
    setResultLoading(false);
  };

  const importFileURL = async () => {
    try {
      const results = await swaggerSvc.downloadSpec(importURL);

      if (results.length > 0) {
        window.localStorage.setItem('swagger-editor-content', results.toString());
        window.location.reload();
      }
    } catch (e) {
      setImportURLError(true);
      setImportURLErrorText(`Failed to import using the provided URL.`);
    }
  };

  const handleClose = () => {
    setOpen(false);
    setOpenImportUrl(false);
    setSelectedIndex('');
  };

  const handleImportClick = (index: any) => {
    setSelectedIndex(index);
    try {
      if (index === 'File') {
        openFilePicker();
      } else if (index === 'URL') {
        setOpenImportUrl(true);
      }
      setError(false);
    } catch (e) {
      setResultLoading(false);
      setError(true);
      setErrorText(`${(e as Error).message}`);
    }
  };

  const handleDownloadClick = () => {
    try {
      downloadFile();
      setError(false);
    } catch (e) {
      setResultLoading(false);
      setError(true);
      setErrorText(`${(e as Error).message}`);
    }
  };

  const headings = ['line', 'path', 'message', 'severity'];

  const descriptionElementRef = useRef<HTMLElement>(null);
  useEffect(() => {
    if (open) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [open]);

  const ValidateButton = () => {
    return (
      <Button
        data-testid="namespace-button"
        color="primary"
        variant={resultLoading ? 'text' : 'contained'}
        disabled={resultLoading}
        onClick={handleClickOpen('paper')}
      >
        {resultLoading ? 'Loading' : 'VALIDATE'}
      </Button>
    );
  };
  if (resultLoading) {
    return (
      <>
        <ValidateButton />
        <LinearProgress />
      </>
    );
  }
  if (error) {
    return (
      <>
        <ValidateButton />
        <div>
          <Alert severity="error" className={classes.Alert}>
            {errorText}
          </Alert>
        </div>
      </>
    );
  }
  const ImportButton = () => {
    return (
      <>
        <div>
          <Autocomplete
            id="importFrom"
            data-testid="importFrom"
            disableClearable
            options={importFromMenu}
            onChange={(_: any, value: string) => {
              handleImportClick(value);
            }}
            freeSolo
            forcePopupIcon
            value={selectedIndex}
            style={{ width: 150, display: 'inline-flex' }}
            size="small"
            renderInput={params => <TextField {...params} label="Import From" variant="outlined" />}
          />
        </div>
      </>
    );
  };
  const DownloadButton = () => {
    return (
      <Button
        data-testid="namespace-button2"
        color="secondary"
        variant={resultLoading ? 'text' : 'contained'}
        disabled={resultLoading}
        onClick={handleDownloadClick}
      >
        {resultLoading ? 'Loading' : 'EXPORT'}
      </Button>
    );
  };
  return (
    <div>
      <Grid container spacing={1}>
        <Grid item xs={8}>
          <ValidateButton />
        </Grid>
        <Grid item>
          <ImportButton />
        </Grid>
        <Grid item>
          <DownloadButton />
        </Grid>
      </Grid>
      <Dialog
        open={open}
        onClose={handleClose}
        scroll={scroll}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
        classes={{ paper: classes.paper }}
      >
        <DialogTitle id="scroll-dialog-title">API Standards Validation Results</DialogTitle>
        <DialogContent
          data-testid="view-data-test"
          id="scroll-dialog-description"
          ref={descriptionElementRef}
          tabIndex={-1}
        >
          {specResults?.length > 0 && (
            <div className="container">
              <Table theadData={headings} tbodyData={specResults} />
            </div>
          )}
          {specResults?.length === 0 && (
            <DialogContentText style={{ color: 'green', fontSize: '1rem' }}>
              Yay ... Swagger specification is according to AA standards
            </DialogContentText>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Close</Button>
        </DialogActions>
      </Dialog>
      <Dialog open={openImportUrl} onClose={handleClose} classes={{ paper: classes.paper }}>
        <DialogTitle id="importurl-dialog-title">Import Swagger Spec via URL</DialogTitle>
        <TextField
          id="import-url-text-field"
          data-testid="import-url-text-field"
          label="URL"
          style={{ margin: 8 }}
          placeholder="https://raw.githubusercontent.com/asyncapi/spec/v2.6.0/examples/streetlights-kafka.yml"
          helperText="Input http URL to your Swagger Specification yaml file"
          margin="normal"
          onChange={(e: { target: { value: SetStateAction<string> } }) => {
            setImportURL(e.target.value);
          }}
          InputLabelProps={{
            shrink: true,
          }}
          variant="filled"
        />
        {importURLError === true && (
          <DialogContent data-testid="view-data-url-error">
            <span style={{ color: 'red' }}>Import: {importURLErrorText} </span> <br />
          </DialogContent>
        )}
        <DialogActions>
          <Button onClick={handleClose}>Close</Button>
          <Button onClick={importFileURL}>Submit</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};
