import axios from 'axios';
import { createApiRef, IdentityApi } from '@backstage/core-plugin-api';
import { MembersData, SecretVaultServiceApi, UserMapData, Response, Namespace } from './secret-vault.service.type';

export const SecretVaultServiceApiRef = createApiRef<SecretVaultServiceApi>({
  id: 'plugin.secret-vault.service',
});

export class SecretVaultService implements SecretVaultServiceApi {
  constructor(
    private backendUrl: string,
    private identityApi: IdentityApi,
  ) {}

  getProxy = async () => {
    const { token } = await this.identityApi.getCredentials();
    const res = await axios.get(`${this.backendUrl}/api/secret-vault/health`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    if (res.status !== 200) {
      throw new Error('Failed to get proxy settings');
    }

    return res.data;
  };

  async createNamespace(
    namespaceName: string,
    adminAdGroup: string,
    readerAdGroup: string,
    appShortname: string,
    squadName: string,
    squadId: number,
    k8s_namespace: string,
    hostClusters: string[],
    githubRepoName: string[],
    adSecretEngine?: boolean,
  ): Promise<Response> {
    try {
      const { token } = await this.identityApi.getCredentials();
      const response = await axios.post(
        `${this.backendUrl}/api/secret-vault/create-namespace`,
        {
          namespaceName,
          adminAdGroup,
          readerAdGroup,
          appShortname,
          squadName,
          squadId,
          k8s_namespace,
          hostClusters,
          githubRepoName,
          adSecretEngine,
        },
        {
          headers: {
            authorization: `Bearer ${token}`,
          },
        },
      );
      return response;
    } catch (error: any) {
      return error;
    }
  }

  async getNameSpacesFromShortName(shortName: string): Promise<Namespace[]> {
    try {
      const { token } = await this.identityApi.getCredentials();
      const response = await axios.get(`${this.backendUrl}/api/secret-vault/get-namespaces/${shortName}`, {
        headers: {
          authorization: `Bearer ${token}`,
        },
      });
      return response.data;
    } catch (error: any) {
      return error;
    }
  }

  async editNamespaceForGithubRepo({
    namespace,
    github_repo,
  }: {
    namespace: string;
    github_repo: string[];
  }): Promise<Response> {
    try {
      const { token } = await this.identityApi.getCredentials();
      const response = await axios.post(
        `${this.backendUrl}/api/secret-vault/edit-namespace-github`,
        {
          namespace,
          github_repo,
        },
        {
          headers: {
            authorization: `Bearer ${token}`,
          },
        },
      );
      return response;
    } catch (error: any) {
      return error;
    }
  }

  async editNamespaceForKubernetes({
    namespace,
    cluster_names,
    k8s_namespace_name,
  }: {
    namespace: string;
    cluster_names?: string[];
    k8s_namespace_name?: string;
  }): Promise<Response> {
    try {
      const { token } = await this.identityApi.getCredentials();
      const response = await axios.post(
        `${this.backendUrl}/api/secret-vault/edit-namespace-kubernetes`,
        {
          namespace,
          cluster_names,
          k8s_namespace_name,
        },
        {
          headers: {
            authorization: `Bearer ${token}`,
          },
        },
      );
      return response;
    } catch (error: any) {
      return error;
    }
  }

  async getUsermapData({ aaId }: { aaId: string }): Promise<UserMapData[]> {
    try {
      const { token } = await this.identityApi.getCredentials();
      const response = await axios.get(`${this.backendUrl}/api/secret-vault/get-user-data/${aaId}`, {
        headers: {
          authorization: `Bearer ${token}`,
        },
      });
      return response.data;
    } catch (error: any) {
      return error;
    }
  }

  async getManageGroupsData({ groupName }: { groupName: string }): Promise<Record<string, string>[]> {
    try {
      const { token } = await this.identityApi.getCredentials();
      const response = await axios.get(`${this.backendUrl}/api/secret-vault/get-manage-groups/${groupName}`, {
        headers: {
          authorization: `Bearer ${token}`,
        },
      });
      return response.data;
    } catch (error: any) {
      return error;
    }
  }

  async addManageGroupsData({
    members,
    groupName,
  }: {
    members: MembersData[];
    groupName: string;
  }): Promise<{ data: string; status: number }> {
    try {
      const { token } = await this.identityApi.getCredentials();
      const response = await axios.post(
        `${this.backendUrl}/api/secret-vault/add-manage-groups`,
        {
          members,
          groupName,
        },
        {
          headers: {
            authorization: `Bearer ${token}`,
          },
        },
      );
      return response;
    } catch (error: any) {
      return error;
    }
  }

  async deleteManageGroupsData({
    members,
    groupName,
  }: {
    members: MembersData[];
    groupName: string;
  }): Promise<{ data: string; status: number }> {
    try {
      const { token } = await this.identityApi.getCredentials();
      const response = await axios.post(
        `${this.backendUrl}/api/secret-vault/delete-manage-groups`,
        {
          members,
          groupName,
        },
        {
          headers: {
            authorization: `Bearer ${token}`,
          },
        },
      );
      return response;
    } catch (error: any) {
      return error;
    }
  }
}
