import React from 'react';
import { useAsyncFn } from 'react-use';
import { Form, Formik } from 'formik';
import { Grid, TextField, Button, DialogActions, FormHelperText, InputAdornment } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { ClusterSelector, clusterSelectorStyles, NamespaceSelector } from '@runway/devkit';
import { Progress } from '@backstage/core-components';
import * as Yup from 'yup';

export type Props = {
  appName: string;
  hideResult: boolean;
  handleClose: () => void;
  submitState: ReturnType<typeof useAsyncFn>[0];
  doSubmit: (values: any) => Promise<string>;
};

export type RegisterAppFormValues = {
  hostCluster: string;
  namespace: string;
  appName: string;
  sourcePath: string;
  sourceRepo: string;
};

export const RegisterAppForm = ({ appName, hideResult, handleClose, submitState, doSubmit }: Props) => {
  const classes = clusterSelectorStyles();

  const argoFormSchema = Yup.object().shape({
    hostCluster: Yup.string().required(),
    namespace: Yup.string().required(),
    appName: Yup.string()
      .required()
      .matches(/^[a-z0-9-]*$/g, 'Must not contain uppercase letters or underscores'),
    sourcePath: Yup.string().required(),
    sourceRepo: Yup.string().required().matches(/^\S*$/g, 'Source Repo cannot contain spaces'),
  });

  return (
    <Formik
      validationSchema={argoFormSchema}
      validateOnMount
      initialValues={{
        hostCluster: '',
        namespace: '',
        appName: appName,
        sourcePath: 'k8s/nonprod',
        sourceRepo: appName,
      }}
      onSubmit={values => {
        doSubmit(values);
      }}
    >
      {formik => (
        <Form>
          <Grid container spacing={4} classes={{ root: classes.container }}>
            <Grid item>
              <ClusterSelector
                onClick={(cluster: string) => {
                  formik.setFieldValue('hostCluster', cluster);
                  formik.setFieldValue('appName', cluster.includes('-p-') ? `${appName}-prod` : `${appName}-nonprod`);
                  formik.setFieldValue('sourcePath', cluster.includes('-p-') ? `k8s/prod` : `k8s/nonprod`);
                  formik.setTouched({ appName: true });
                  formik.setTouched({ sourcePath: true });
                }}
                includeProd
              />
            </Grid>

            <Grid item xs={7}>
              <NamespaceSelector
                selectedCluster={formik.values.hostCluster}
                isCreateButtonVisible
                onChange={(values: any) => {
                  formik.setFieldValue('namespace', values);
                }}
              />
            </Grid>

            <Grid item xs={7}>
              <TextField
                required
                label="App Name"
                variant="outlined"
                id="appName"
                value={formik.values.appName}
                onChange={event => {
                  formik.setFieldValue('appName', event.target.value);
                }}
                onBlur={formik.handleBlur}
                error={Boolean(formik.errors.appName)}
                helperText={formik.errors.appName}
              />
              <FormHelperText>App Name</FormHelperText>
            </Grid>

            <Grid item xs={7}>
              <TextField
                required
                label="Source Repo"
                variant="outlined"
                id="githubpath"
                defaultValue={appName}
                onChange={event => {
                  formik.setFieldValue('sourceRepo', event.target.value);
                }}
                onBlur={formik.handleBlur}
                error={Boolean(formik.errors.sourceRepo)}
                helperText={formik.errors.sourceRepo || 'Name of your AAinternal Repo with the k8s yaml file.'}
                InputProps={{
                  startAdornment: <InputAdornment position="start">AAInternal /</InputAdornment>,
                }}
              />
            </Grid>

            <Grid item xs={7}>
              <TextField
                required
                label="Source Path"
                variant="outlined"
                id="k8path"
                value={formik.values.sourcePath}
                onChange={event => {
                  formik.setFieldValue('sourcePath', event.target.value);
                }}
                onBlur={formik.handleBlur}
                helperText={formik.errors.sourcePath}
              />
              <FormHelperText>directory of k8s config yaml file</FormHelperText>
            </Grid>
          </Grid>

          {submitState.loading && <Progress />}

          {!hideResult && submitState.error && (
            <Alert severity="error" aria-label="error">
              {submitState.error.message}
            </Alert>
          )}

          {!hideResult && submitState.value && (
            <Alert severity="success" aria-label="success">
              {submitState.value}
            </Alert>
          )}

          <DialogActions>
            <Button
              type="button"
              className="outline"
              onClick={() => {
                handleClose();
              }}
              disabled={submitState.loading}
            >
              Close
            </Button>
            {!(!hideResult && submitState.value) && (
              <Button type="submit" variant="contained" color="primary" disabled={!formik.isValid}>
                Submit
              </Button>
            )}
          </DialogActions>
        </Form>
      )}
    </Formik>
  );
};
