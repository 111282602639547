import { configApiRef, identityApiRef, useApi } from '@backstage/core-plugin-api';
import {
  Checkbox,
  CircularProgress,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  TextField,
  makeStyles,
} from '@material-ui/core';
import CheckCircle from '@material-ui/icons/CheckCircle';
import Error from '@material-ui/icons/Error';
import { ClassNameMap } from '@material-ui/styles';
import { ErrorSchema } from '@rjsf/utils';
import axios from 'axios';
import React, { ChangeEvent, useState } from 'react';
import { useAsyncFn } from 'react-use';
import { PaasFormExtensionProps, RedisCacheFields } from '../types';

const useStyles = makeStyles(runwayTheme => ({
  root: {
    backgroundColor: runwayTheme.palette.background.default,
    maxWidth: 500,
    marginBottom: 10,
  },
  container: {
    marginBottom: 2,
  },
  iconFormat: {
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',
  },
  iconError: {
    color: runwayTheme.palette.error.main,
    fontSize: 'inherit',
  },
  iconSuccess: {
    color: runwayTheme.palette.success.main,
    fontSize: 'inherit',
  },
}));

const formDefaults: RedisCacheFields = {
  redisCacheName: '',
  redisSkuName: 'Standard',
  redisCapacity: 1,
  redisFamily: 'C',
  redisEnableNonSSLPort: false,
  redisMinTLSVersion: '1.2',
  redisPublicAccessEnabled: true,
  redisEnableAuth: true,
  redisMaxMemoryReserved: 50,
  redisMaxMemoryDelta: 50,
  redisMaxFragmentation: 50,
};

type redisCapacityTypes = {
  skuName: string;
  capacityOptions: capacityDataType[];
};

type capacityDataType = {
  capacity: number;
  cacheSize: string;
};

const redisCapacityOptions: redisCapacityTypes[] = [
  {
    skuName: 'Standard',
    capacityOptions: [
      { capacity: 0, cacheSize: '250 MB' },
      { capacity: 1, cacheSize: '1 GB' },
      { capacity: 2, cacheSize: '2.5 GB' },
      { capacity: 3, cacheSize: '6 GB' },
      { capacity: 4, cacheSize: '13 GB' },
      { capacity: 5, cacheSize: '26 GB' },
      { capacity: 6, cacheSize: '53 GB' },
    ],
  },
  {
    skuName: 'Premium',
    capacityOptions: [
      { capacity: 0, cacheSize: '6 GB' },
      { capacity: 1, cacheSize: '13 GB' },
      { capacity: 2, cacheSize: '26 GB' },
      { capacity: 3, cacheSize: '53 GB' },
      { capacity: 4, cacheSize: '120 GB' },
    ],
  },
];

enum RedisCacheNameUnique {
  INITIAL_STATE,
  NOT_UNIQUE,
  UNIQUE,
  ERROR,
}

enum RedisCacheNameValid {
  INITIAL_STATE,
  NOT_VALID,
  VALID,
}

const renderRedisNameHelperText = (isError: boolean, message: string, classes: ClassNameMap, helperText?: boolean) => {
  if (helperText) {
    return <span style={{ marginLeft: '3px' }}>{message}</span>;
  }
  if (isError) {
    return (
      <span className={classes.iconFormat}>
        <Error className={classes.iconError} />
        <span style={{ marginLeft: '3px' }}>{message}</span>
      </span>
    );
  }
  return (
    <span style={{ flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
      <CheckCircle className={classes.iconSuccess} />
      <span style={{ marginLeft: '3px' }}>{message}</span>
    </span>
  );
};

const renderValidateRedisCacheNameMessage = (
  redisCacheNameValid: RedisCacheNameValid,
  redisCacheNameUnique: RedisCacheNameUnique,
  classes: ClassNameMap,
) => {
  if (redisCacheNameValid === RedisCacheNameValid.INITIAL_STATE) {
    return renderRedisNameHelperText(false, 'Valid characters are letters | numbers | hyphens', classes, true);
  }
  if (redisCacheNameValid === RedisCacheNameValid.NOT_VALID) {
    return renderRedisNameHelperText(
      true,
      `Invalid Redis Cache Name.
        Names can only contain letters, numbers and hyphens.
        The first and last characters must each be a letter or a number.
        Consecutive hyphens are not allowed. Max length of 63`,
      classes,
    );
  }
  switch (redisCacheNameUnique) {
    case RedisCacheNameUnique.NOT_UNIQUE:
      return renderRedisNameHelperText(true, 'Redis Cache Name is Not Unique', classes);
    case RedisCacheNameUnique.UNIQUE:
      return renderRedisNameHelperText(false, 'Redis Cache name is unique', classes);
    case RedisCacheNameUnique.ERROR:
      return renderRedisNameHelperText(true, 'Error determining name uniqueness', classes);
    default:
      return <span />;
  }
};

const removeDefaultValues = (
  onChange: (e: any, es?: ErrorSchema | undefined) => any,
  formDataFields: RedisCacheFields,
) => {
  delete formDataFields.redisEnableNonSSLPort;
  delete formDataFields.redisMinTLSVersion;
  delete formDataFields.redisPublicAccessEnabled;
  delete formDataFields.redisEnableAuth;
  delete formDataFields.redisMaxMemoryReserved;
  delete formDataFields.redisMaxMemoryDelta;
  delete formDataFields.redisMaxFragmentation;
  delete formDataFields.redisCacheName;
  delete formDataFields.redisCapacity;
  delete formDataFields.redisSkuName;
  delete formDataFields.redisFamily;
  onChange({ ...formDataFields, redisCache: false });
};

export const RedisCache = ({ onChange, formData }: PaasFormExtensionProps) => {
  const classes = useStyles();
  const [isChecked, setIsChecked] = useState(false);
  const [isRedisCacheNameValid, setIsRedisCacheNameValid] = useState(RedisCacheNameValid.INITIAL_STATE);
  const [isRedisCacheNameUnique, setIsRedisCacheNameUnique] = useState(RedisCacheNameUnique.INITIAL_STATE);
  const [capacityOptions, setCapacityOptions] = useState(
    redisCapacityOptions.find(option => option.skuName === formDefaults.redisSkuName)?.capacityOptions,
  );
  const identityApi = useApi(identityApiRef);
  const configApi = useApi(configApiRef);

  const handleCheckbox = (event: ChangeEvent<HTMLInputElement>) => {
    setIsChecked(event.target.checked);
    if (event.target.checked) {
      onChange({ ...formData, ...formDefaults, redisCache: true });
    } else {
      removeDefaultValues(onChange, formData);
      setIsRedisCacheNameUnique(RedisCacheNameUnique.INITIAL_STATE);
      setIsRedisCacheNameValid(RedisCacheNameValid.INITIAL_STATE);
      setCapacityOptions(
        redisCapacityOptions.find(option => option.skuName === formDefaults.redisSkuName)?.capacityOptions,
      );
    }
  };

  const [validateRedisNameState, validateRedisName] = useAsyncFn(async (redisName: string) => {
    try {
      const { token } = await identityApi.getCredentials();
      const validateHttpResponse = (status: number) => {
        return status === 200 || status === 409;
      };
      await axios
        .post(
          `${configApi.getString(
            'backend.baseUrl',
          )}/api/infrastructure/azure/checkRedisNameAvailability?name=${redisName}`,
          undefined,
          {
            headers: {
              authorization: `Bearer ${token}`,
            },
            validateStatus: validateHttpResponse,
          },
        )
        .then(response => {
          switch (response.status) {
            case 200:
              setIsRedisCacheNameUnique(RedisCacheNameUnique.UNIQUE);
              break;
            case 409:
              setIsRedisCacheNameUnique(RedisCacheNameUnique.NOT_UNIQUE);
              break;
            default:
          }
        });
    } catch (err) {
      setIsRedisCacheNameUnique(RedisCacheNameUnique.ERROR);
    }
  });

  return (
    <Grid container spacing={3} className={classes.root}>
      <Grid item xs={12} className={classes.container}>
        <FormControlLabel
          control={
            <Checkbox
              checked={isChecked}
              name="redisCache"
              key="redisCache"
              color="primary"
              onChange={handleCheckbox}
            />
          }
          label="Redis Cache"
        />
      </Grid>
      {isChecked && (
        <>
          <Grid item xs={validateRedisNameState.loading ? 8 : 12} className={classes.container}>
            <TextField
              required
              id="redisCacheName"
              style={{ width: 300, display: 'inline-flex' }}
              label="Redis Cache Name"
              variant="outlined"
              helperText={renderValidateRedisCacheNameMessage(isRedisCacheNameValid, isRedisCacheNameUnique, classes)}
              onBlur={({ target: { value } }) => {
                const redisCacheName = value;
                if (redisCacheName.match(/^[a-zA-Z](?!.*--)[a-zA-Z\d-]{1,63}$/g) && !redisCacheName.endsWith('-')) {
                  setIsRedisCacheNameValid(RedisCacheNameValid.VALID);
                  setIsRedisCacheNameUnique(RedisCacheNameUnique.INITIAL_STATE);
                  onChange({ ...formData, redisCacheName });
                  validateRedisName(redisCacheName);
                } else {
                  setIsRedisCacheNameValid(RedisCacheNameValid.NOT_VALID);
                  delete formData.redisCacheName;
                }
              }}
            />
          </Grid>
          {validateRedisNameState.loading && (
            <Grid item xs={4}>
              <CircularProgress style={{ padding: 5 }} />
            </Grid>
          )}
          <Grid item xs={12} className={classes.container}>
            <FormControl variant="outlined">
              <FormLabel id="accountTier">Account Tier</FormLabel>
              <RadioGroup
                row
                defaultValue={formDefaults.redisSkuName}
                style={{ width: 300, display: 'inline-flex' }}
                aria-label="Account Tier"
                onChange={event => {
                  onChange({
                    ...formData,
                    redisSkuName: event.target.value,
                    redisFamily: event.target.value === 'Standard' ? 'C' : 'P',
                    redisCapacity: 1,
                  });
                  setCapacityOptions(
                    redisCapacityOptions.find(option => option.skuName === event.target.value)?.capacityOptions,
                  );
                }}
              >
                <FormControlLabel value="Standard" control={<Radio />} label="Standard" />
                <FormControlLabel value="Premium" control={<Radio />} label="Premium" />
              </RadioGroup>
            </FormControl>
          </Grid>
          <Grid item xs={12} className={classes.container}>
            <FormControl variant="outlined">
              <InputLabel id="capacityTier">Capacity Tier</InputLabel>
              <Select
                labelId="capacityTier"
                id="capacityTierSelect"
                aria-label="Capacity Tier"
                labelWidth={120}
                style={{ width: 300, display: 'inline-flex' }}
                onChange={(event: React.ChangeEvent<{ value: any }>) => {
                  onChange({ ...formData, redisCapacity: event.target.value });
                }}
                value={formData.redisCapacity ?? 1}
              >
                {capacityOptions?.map((cap, index) => (
                  <MenuItem value={cap.capacity.toString()} key={index}>
                    {cap.capacity} - ({cap.cacheSize})
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
        </>
      )}
    </Grid>
  );
};
