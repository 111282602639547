import { useApi } from '@backstage/core-plugin-api';
import { useEmployeeProfile } from '@runway/devkit';
import React, { FC, useState } from 'react';
import { orionServiceApiRef } from '../../service/orion.service';

import { AMLKVForm } from './AMLKeyVaultForm';
import { amlKVFormInitializeValues } from './amlKVFormInitialValue';
import { amlKVFormValueTypes } from './amlKVFormValueTypes';
import { Backdrop, CircularProgress } from '@material-ui/core';

interface AMLKVComponentProps {
  subscription: string;
  resourceGroup: string;
}
export const AMLKVFormContainer: FC<AMLKVComponentProps> = ({ subscription, resourceGroup }) => {
  const orionService = useApi(orionServiceApiRef);

  const { aaId } = useEmployeeProfile({ withPrefix: false });

  const [loading, setLoading] = React.useState(false);
  const [error, setError] = useState<string>();
  const [success, setSuccess] = useState(false);

  const onSubmit = async (values: amlKVFormValueTypes) => {
    setSuccess(false);
    setLoading(true);
    setError(undefined);

    const metadata = {
      aaId: aaId,
      resourceGroup: values.resourceGroup,
    };

    try {
      await orionService.provisionAZKeyVault(metadata);
      setSuccess(true);
      setError(undefined);
    } catch (e: any) {
      setSuccess(false);
      setError(e.message);
    } finally {
      setLoading(false);
    }
  };

  const initialValues = amlKVFormInitializeValues({
    subscription,
    resourceGroup,
  });

  return (
    <>
      <AMLKVForm initialValues={initialValues} onSubmit={onSubmit} loading={loading} success={success} error={error} />
      <Backdrop open={loading}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </>
  );
};
