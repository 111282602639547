import React, { useState } from 'react';
import { configApiRef, useApi } from '@backstage/core-plugin-api';
import {
  Button,
  makeStyles,
  Grid,
  ButtonGroup,
  ListItem,
  List,
  ListItemText,
  Typography,
  Box,
  LinearProgress,
} from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import grey from '@material-ui/core/colors/grey';
import { adServiceApiRef } from '../../services/ad.service';
import { useAsync } from 'react-use';

export const clusterSelectorStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    backgroundColor: theme.palette.background.paper,
    position: 'relative',
    overflow: 'auto',
    maxHeight: 210,

    '&$selected': {
      backgroundColor: theme.palette.primary.main,
      color: 'white',
      '&:hover': {
        backgroundColor: theme.palette.primary.light,
      },
    },
  },
  listSection: {
    backgroundColor: 'inherit',
  },
  ul: {
    backgroundColor: 'inherit',
    padding: 0,
  },
  selected: {},
  gridContainer: {
    maxWidth: 900,
  },
  formGroup: {
    padding: theme.spacing(2),
  },
  button: {
    marginRight: theme.spacing(1),
  },
  buttonGroup: {
    margin: '1rem 0',
  },
  section: {
    margin: '1rem 0',
  },
  nested: {
    paddingLeft: theme.spacing(4),
    paddingTop: 0,
    paddingBottom: 0,
    display: 'list-item',
    listStyleType: 'disc',
    listStylePosition: 'inside',
  },
  flexContainer: {
    display: 'block',
    flexDirection: 'row',
    padding: 5,
    margin: 0,
  },
  listItem: {
    padding: 0,
    margin: 0,
    display: 'list-item',
    listStyleType: 'disc',
    listStylePosition: 'inside',
  },
  smallText: {
    fontSize: '0.9em',
  },
  container: {
    padding: '1rem',
  },
}));

type ClusterSelectorProps = {
  onClick: (cluster: string) => void;
  includeProd?: boolean;
  cluster?: string | null;
};

enum SelectorView {
  guided = 'guided',
  advanced = 'advanced',
}

enum Environment {
  prod = 'prod',
  nonprod = 'nonprod',
}

enum DataClassification {
  pci = 'pci',
  nonpci = 'nonpci',
}

export const ClusterSelector = ({ onClick, includeProd = true, cluster = null }: ClusterSelectorProps) => {
  const classes = clusterSelectorStyles();
  const [clusterSelectionMethod, setClusterSelectionMethod] = useState<string>('guided');
  const [clusterEnv, setClusterEnv] = useState<string>('nonprod');
  const [clusterDataClassification, setClusterDataClassification] = useState<string>('nonpci');
  const [selectedCluster, setSelectedCluster] = useState<string | null>(cluster);
  const [usersAdGroups, setUsersAdGroups] = useState<object[]>([]);
  const ad = useApi(adServiceApiRef);

  const getUsersGroups = useAsync(async () => {
    const resp: any = await ad.getUsersGroupMembershipAzureAD();
    setUsersAdGroups(resp?.outputGroups);
  });

  const config = useApi(configApiRef);
  const KubernetesConfigArray: any = config.getConfigArray('kubernetes.clusterLocatorMethods')[0];
  // Filter Clusters
  const filterClusters = (clusterListInput: [], env: string, dataClassification: string, selectionMethod: string) => {
    const filteredClusterList = clusterListInput.filter((item: any) => {
      if (!includeProd && item.production) {
        return false;
      }

      // PERMISSION SECTION
      if (item.allowedAadGroups !== undefined) {
        const userPermitted: boolean = item.allowedAadGroups.some((group: any) => {
          return usersAdGroups.some((userGroup: any) => userGroup.groupName === group.name);
        });
        if (userPermitted === false) {
          return false;
        }
      }

      // DATA CLASSIFICATION SELECTION
      if (selectionMethod === SelectorView.guided && dataClassification === DataClassification.pci && !item.pci) {
        return false;
      }

      if (selectionMethod === SelectorView.guided && dataClassification === DataClassification.nonpci && item.pci) {
        return false;
      }

      // ENV SELECTION
      if (selectionMethod === SelectorView.guided && env === Environment.nonprod && item.production) {
        return false;
      }

      if (selectionMethod === SelectorView.guided && env === Environment.prod && !item.production) {
        return false;
      }

      return true;
    });
    return filteredClusterList;
  };

  const isPciClusterExist = (clusterListInput: []) => {
    return clusterListInput.some((item: any) => item.pci);
  };

  const handleSelectionMethod = (selectionMethod: string) => {
    setClusterSelectionMethod(selectionMethod);
    setSelectedCluster(null);
    onClick('');
  };
  const handleEnvSelection = (env: string) => {
    setClusterEnv(env);
    onClick('');
  };
  const handleDataClassificationSelection = (dataClassification: string) => {
    setClusterDataClassification(dataClassification);
    onClick('');
  };

  const handleListItemClick = (_event: React.MouseEvent<HTMLDivElement, MouseEvent>, newCluster: string) => {
    setSelectedCluster(newCluster);
    onClick(newCluster);
  };

  return (
    <Grid container spacing={3}>
      <ButtonGroup
        fullWidth
        variant="contained"
        aria-label="full width outlined button group"
        classes={{ root: classes.buttonGroup }}
      >
        <Button
          color={clusterSelectionMethod === 'guided' ? 'primary' : 'inherit'}
          onClick={() => handleSelectionMethod('guided')}
        >
          Guided
        </Button>
        <Button
          color={clusterSelectionMethod === 'advanced' ? 'primary' : 'inherit'}
          onClick={() => handleSelectionMethod('advanced')}
        >
          Advanced
        </Button>
      </ButtonGroup>
      {!getUsersGroups.loading && clusterSelectionMethod === 'guided' && (
        <>
          {includeProd && (
            <ButtonGroup
              fullWidth
              variant="contained"
              aria-label="full width outlined button group"
              classes={{ root: classes.buttonGroup }}
            >
              <Button
                color={clusterEnv === 'nonprod' ? 'primary' : 'inherit'}
                onClick={() => handleEnvSelection('nonprod')}
              >
                Non-Production
              </Button>
              <Button
                disabled={!includeProd}
                color={clusterEnv === 'prod' ? 'primary' : 'inherit'}
                onClick={() => handleEnvSelection('prod')}
              >
                Production
              </Button>
            </ButtonGroup>
          )}
          <ButtonGroup
            fullWidth
            variant="contained"
            aria-label="full width outlined button group"
            classes={{ root: classes.buttonGroup }}
          >
            <Button
              color={clusterDataClassification === 'nonpci' ? 'primary' : 'inherit'}
              onClick={() => handleDataClassificationSelection('nonpci')}
            >
              Non-PCI
            </Button>
            <Button
              disabled={!isPciClusterExist(KubernetesConfigArray.data.clusters as [])}
              color={clusterDataClassification === 'pci' ? 'primary' : 'inherit'}
              onClick={() => handleDataClassificationSelection('pci')}
            >
              PCI
            </Button>
          </ButtonGroup>
        </>
      )}
      {getUsersGroups.loading && (
        <LinearProgress
          style={{
            marginTop: 30,
            marginLeft: 10,
            marginRight: 10,
            width: '100%',
          }}
        />
      )}
      {!getUsersGroups.loading &&
        filterClusters(
          KubernetesConfigArray.data.clusters as [],
          clusterEnv,
          clusterDataClassification,
          clusterSelectionMethod,
        ).length > 0 && (
          <Grid item xs={12}>
            <Typography variant="h5" gutterBottom align="left">
              Select a Cluster
            </Typography>
            <Box borderRadius="borderRadius" border={1} borderColor={grey[400]} p="2" m="2">
              <List subheader={<li />}>
                {filterClusters(
                  KubernetesConfigArray.data.clusters as [],
                  clusterEnv,
                  clusterDataClassification,
                  clusterSelectionMethod,
                ).map((item: any) => {
                  const displayName = item.displayName ? item.displayName : item.name;
                  return (
                    <ListItem
                      disabled={!item.deployable}
                      button
                      key={`${item.name}-list-item`}
                      onClick={event => {
                        handleListItemClick(event, `${item.name}`);
                      }}
                      selected={cluster ? cluster === `${item.name}` : selectedCluster === `${item.name}`}
                      classes={{
                        root: classes.root,
                        selected: classes.selected,
                      }}
                    >
                      <ListItemText
                        primary={`${item.deployable ? displayName : `${displayName} - NOT DEPLOYABLE`}`}
                        key={`${item.name}-list-text`}
                      />
                    </ListItem>
                  );
                })}
              </List>
            </Box>
          </Grid>
        )}
      {filterClusters(
        KubernetesConfigArray.data.clusters as [],
        clusterEnv,
        clusterDataClassification,
        clusterSelectionMethod,
      ).length === 0 && (
        <Grid item xs={12}>
          <Alert severity="error">No Clusters Found</Alert>
        </Grid>
      )}
    </Grid>
  );
};
