import React, { useState } from 'react';
import { aaPingSSOAuthApiRef } from '@runway/devkit';
import { useApi } from '@backstage/core-plugin-api';
import { InfoCard } from '@backstage/core-components';
import { Button, makeStyles, Typography } from '@material-ui/core';
import { useAsync, useCopyToClipboard } from 'react-use';

const useStyles = makeStyles(theme => ({
  formGroup: {
    margin: theme.spacing(2),
    minWidth: 150,
  },
  button: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  inputFields: {
    width: 500,
    height: 100,
  },
}));

export const SSOToken = () => {
  const classes = useStyles();
  const [showCopied, setShowCopy] = useState(false);
  const [, setCopyToClipboard] = useCopyToClipboard();
  const oAuthApi = useApi(aaPingSSOAuthApiRef);
  const token = useAsync(async () => {
    return await oAuthApi.getAccessToken();
  }, []);

  const handleCopy = () => {
    setCopyToClipboard(token.value ?? '');
    setShowCopy(true);
    setTimeout(() => {
      setShowCopy(false);
    }, 5000);
  };

  return (
    <InfoCard>
      <div>
        <Typography>SSO Token</Typography>
        <Typography variant="caption" display="block" gutterBottom>
          Use this if you need to make an API call that requires the Ping Fed SSO Token.
        </Typography>
        {token.value && (
          <>
            <div>
              <Button className={classes.button} variant="contained" color="primary" onClick={handleCopy}>
                {showCopied ? 'Copied!' : 'Copy'}
              </Button>
            </div>
          </>
        )}
        {token.error && <div>Something went wrong...</div>}
      </div>
    </InfoCard>
  );
};
