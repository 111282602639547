import React from 'react';
import { STATUS } from '../Status';
import { Layer } from './Layer';

export interface AkamaiGTMMetadata {
  datacenterId?: number;
  datacenterAlias?: string;
  enabled?: boolean;
  servers?: string[];
  weight?: number;
}

const getAkamaiDetails = (status: STATUS, akamaiInfo: AkamaiGTMMetadata[] | undefined): React.JSX.Element | string => {
  if (status === STATUS.loading) return 'Loading...';
  if (status === STATUS.unretrievable) return 'Unable to retrieve Akamai GTM information';

  return (
    <ul>
      {akamaiInfo?.map(dc => (
        <li key={akamaiInfo.indexOf(dc)}>
          datacenterId: {dc.datacenterId}, datacenterAlias: {dc.datacenterAlias}, enabled:{' '}
          {dc.enabled ? 'true' : 'false'}, weight: {dc.weight?.toFixed(1)}, servers: {dc.servers?.join(',')}
        </li>
      ))}
    </ul>
  );
};

export const AkamaiGTMLayer = ({
  title,
  status,
  akamaiInfo,
}: {
  title: string;
  status: STATUS;
  akamaiInfo: AkamaiGTMMetadata[] | undefined;
}) => <Layer title={title} status={status} subheader={getAkamaiDetails(status, akamaiInfo)} />;
