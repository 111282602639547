import React, { useEffect, useState } from 'react';
import { FieldExtensionComponentProps } from '@backstage/plugin-scaffolder-react';
import { Checkbox, FormControlLabel, FormGroup, Grid, IconButton, Tooltip } from '@material-ui/core';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';

export const MicrogatewayCheckbox = ({ onChange, formData }: FieldExtensionComponentProps<boolean>) => {
  const [checked, setChecked] = useState<boolean>(formData ?? false);

  useEffect(() => {
    onChange(checked);
  }, [onChange, checked]);

  return (
    <Grid container spacing={1}>
      <Grid item>
        <FormGroup>
          <FormControlLabel
            control={
              <Checkbox
                checked={checked}
                onChange={() => {
                  setChecked(!checked);
                }}
                name="microgatewayCheckbox"
                key="microgatewayCheckbox"
                color="primary"
              />
            }
            label="Microgateway API proxy"
          />
        </FormGroup>
      </Grid>
      <Grid item>
        <Tooltip title="For more information, visit the apigee with runway information page">
          <IconButton
            target="_blank"
            aria-label="MicrogatewayInfoButton"
            href="https://developer.aa.com/docs/default/component/apigee-docs/Runway/apigee-plugin/"
          >
            <HelpOutlineIcon color="primary" />
          </IconButton>
        </Tooltip>
      </Grid>
    </Grid>
  );
};
