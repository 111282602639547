import React from 'react';
import { Content, Header, HeaderLabel, InfoCard, Page } from '@backstage/core-components';
import { OrionFormContainer } from './OrionFormContainer';

export const OrionPage = () => {
  return (
    <Page themeId="home">
      <Header title="Orion Platform Application Setup" subtitle="Orion Application Setup Request">
        <HeaderLabel label="Owner" value="Orion Infra Team" />
        <HeaderLabel label="Lifecycle" value="Alpha" />
      </Header>
      <Content>
        <div style={{ maxWidth: 1200, margin: '0 auto' }}>
          <InfoCard>
            <OrionFormContainer archerShortname="" costCenter="" squadName="" appName="" />
          </InfoCard>
        </div>
      </Content>
    </Page>
  );
};
