import { useEntity } from '@backstage/plugin-catalog-react';
import { Card, CardContent, CardHeader, Divider, Grid, makeStyles, Tooltip, Typography } from '@material-ui/core';
import InfoIcon from '@material-ui/icons/Info';
import React from 'react';
import { ObservabilityEntity } from '@runway/plugin-observability-common';

const useStyles = makeStyles(
  /* theme => */ {
    gridItemCard: {
      display: 'flex',
      flexDirection: 'column',
      height: 'calc(100% - 10px)', // for pages without content header
      marginBottom: '10px',
    },
    gridItemCardContent: {
      flex: 1,
    },
    cardHeader: {
      marginBottom: '20px',
    },
    description: {
      wordBreak: 'break-word',
    },
  },
);

type ObservabilityInfoCardProps = {
  variant?: string;
};

export function ObservabilityInfoCard({ variant }: Readonly<ObservabilityInfoCardProps>) {
  const { entity } = useEntity<ObservabilityEntity>();
  const classes = useStyles();

  const linkTooltip = 'This transaction has not been implemented yet, so there is no URL to the synthetic monitor yet.';
  const monitorURL = 'No URL';

  return (
    <Card className={classes.gridItemCard}>
      <CardHeader title="Observability Overview" className={classes.cardHeader} />
      <CardContent className={variant === 'gridItem' ? classes.gridItemCardContent : ''}>
        <Grid container>
          <Grid container item xs={12} spacing={3}>
            <Grid item>
              <Typography
                variant="body2"
                paragraph
                className={classes.description}
                style={{ display: 'flex', width: '100%', flexFlow: 'row' }}
              >
                {entity.metadata.name}
              </Typography>
            </Grid>
            <Grid item>
              <Typography
                variant="body2"
                paragraph
                className={classes.description}
                style={{ display: 'flex', width: '100%', flexFlow: 'row' }}
              >
                {monitorURL}{' '}
                <Tooltip title={linkTooltip} aria-label={linkTooltip}>
                  <InfoIcon fontSize="small" />
                </Tooltip>
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Divider />
        <Grid container>
          <Grid container item xs={12} spacing={3}>
            <Grid item>
              <Typography
                variant="body2"
                paragraph
                className={classes.description}
                style={{ display: 'flex', width: '100%', flexFlow: 'row' }}
              >
                {entity.spec?.businessValue}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
}
