import { configApiRef, identityApiRef, useApi } from '@backstage/core-plugin-api';
import {
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  InputLabel,
  makeStyles,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  TextField,
} from '@material-ui/core';
import CheckCircle from '@material-ui/icons/CheckCircle';
import Error from '@material-ui/icons/Error';
import { ClassNameMap } from '@material-ui/styles';
import { ErrorSchema } from '@rjsf/utils';
import axios from 'axios';
import React, { ChangeEvent, useState } from 'react';
import { PaasFormExtensionProps, StorageAccountFields } from '../types';

const useStyles = makeStyles(runwayTheme => ({
  root: {
    backgroundColor: runwayTheme.palette.background.default,
    maxWidth: 500,
    marginBottom: 10,
  },
  container: {
    marginBottom: 2,
  },
  iconFormat: {
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',
  },
  iconError: {
    color: runwayTheme.palette.error.main,
    fontSize: 'inherit',
  },
  iconSuccess: {
    color: runwayTheme.palette.success.main,
    fontSize: 'inherit',
  },
}));

type StorageAccountNameQueryResponse = {
  nameAvailable: boolean;
};

enum StorageAccountNameUnique {
  INITIAL_STATE,
  NOT_UNIQUE,
  UNIQUE,
  ERROR,
}

enum StorageAccountNameValid {
  INITIAL_STATE,
  NOT_VALID,
  VALID,
}

const defaultStorageAccountValues = {
  storageAccountKind: 'BlobStorage',
  storageAccountTier: 'Standard',
};

const validStorageAccountKindOptions = [
  {
    value: 'BlobStorage',
    validStorageAccountTier: 'Standard',
  },
  {
    value: 'BlockBlobStorage',
    validStorageAccountTier: 'Premium',
  },
];

const removeDefaultValues = (
  onChange: (e: any, es?: ErrorSchema | undefined) => any,
  formDataFields: StorageAccountFields,
) => {
  delete formDataFields.storageAccountTier;
  delete formDataFields.storageAccountName;
  delete formDataFields.storageAccountKind;
  delete formDataFields.isStorageAccountNameUnique;
  onChange({ ...formDataFields, storageAccount: false });
};

const renderStorageNameHelperText = (
  isError: boolean,
  message: string,
  classes: ClassNameMap,
  helperText?: boolean,
) => {
  if (helperText) {
    return <span style={{ marginLeft: '3px' }}>{message}</span>;
  }
  if (isError) {
    return (
      <span className={classes.iconFormat}>
        <Error className={classes.iconError} />
        <span style={{ marginLeft: '3px' }}>{message}</span>
      </span>
    );
  }
  return (
    <span style={{ flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
      <CheckCircle className={classes.iconSuccess} />
      <span style={{ marginLeft: '3px' }}>{message}</span>
    </span>
  );
};

const renderValidateStorageAccountNameMessage = (
  storageAccountNameValid: StorageAccountNameValid,
  storageAccountNameUnique: StorageAccountNameUnique,
  classes: ClassNameMap,
) => {
  if (storageAccountNameValid === StorageAccountNameValid.INITIAL_STATE) {
    return renderStorageNameHelperText(false, 'Valid characters are [a-z0-9] | Length of 3-24', classes, true);
  }
  if (storageAccountNameValid === StorageAccountNameValid.NOT_VALID) {
    return renderStorageNameHelperText(true, 'Invalid Storage Account Name', classes);
  }
  switch (storageAccountNameUnique) {
    case StorageAccountNameUnique.NOT_UNIQUE:
      return renderStorageNameHelperText(true, 'Storage Account Name is Not Unique', classes);
    case StorageAccountNameUnique.UNIQUE:
      return renderStorageNameHelperText(false, 'Storage account name is unique', classes);
    case StorageAccountNameUnique.ERROR:
      return renderStorageNameHelperText(true, 'Error determining name uniqueness', classes);
    default:
      return <span />;
  }
};

export const StorageAccount = ({ onChange, formData }: PaasFormExtensionProps) => {
  const [isChecked, setIsChecked] = useState(false);
  const [isStorageAccountNameValid, setIsStorageAccountNameValid] = useState(StorageAccountNameValid.INITIAL_STATE);
  const [isStorageAccountNameUnique, setIsStorageAccountNameUnique] = useState(StorageAccountNameUnique.INITIAL_STATE);
  const [storageAccountTier, setStorageAccountTier] = useState(defaultStorageAccountValues.storageAccountTier);
  const [storageAccountKind, setStorageAccountKind] = useState(defaultStorageAccountValues.storageAccountKind);
  const configApi = useApi(configApiRef);
  const identityApi = useApi(identityApiRef);
  const classes = useStyles();

  const handleCheckbox = (event: ChangeEvent<HTMLInputElement>) => {
    setIsChecked(event.target.checked);
    if (event.target.checked) {
      onChange({ ...formData, ...defaultStorageAccountValues, storageAccount: true });
    } else {
      removeDefaultValues(onChange, formData);
      setIsStorageAccountNameUnique(StorageAccountNameUnique.INITIAL_STATE);
      setIsStorageAccountNameValid(StorageAccountNameValid.INITIAL_STATE);
    }
  };

  const handleValidateName = async () => {
    try {
      if (isStorageAccountNameValid === StorageAccountNameValid.VALID) {
        const { token } = await identityApi.getCredentials();
        const result = (
          await axios.post(
            `${configApi.getString('backend.baseUrl')}/api/infrastructure/azure/checkStorageNameAvailability?name=${
              formData.storageAccountName
            }`,
            undefined,
            {
              headers: {
                authorization: `Bearer ${token}`,
              },
            },
          )
        ).data as StorageAccountNameQueryResponse;
        formData.isStorageAccountNameUnique = result.nameAvailable;
        if (result.nameAvailable) {
          setIsStorageAccountNameUnique(StorageAccountNameUnique.UNIQUE);
        } else {
          setIsStorageAccountNameUnique(StorageAccountNameUnique.NOT_UNIQUE);
        }
      }
    } catch (err) {
      setIsStorageAccountNameUnique(StorageAccountNameUnique.ERROR);
      formData.isStorageAccountNameUnique = false;
    }
  };
  return (
    <Grid container spacing={3} className={classes.root}>
      <Grid item xs={12} className={classes.container}>
        <FormControlLabel
          control={
            <Checkbox
              checked={isChecked}
              name="storageAccount"
              key="storageAccount"
              color="primary"
              onChange={handleCheckbox}
            />
          }
          label="Storage Account"
        />
      </Grid>
      {isChecked && (
        <>
          <Grid item xs={12} className={classes.container}>
            <TextField
              required
              id="storageAccountName"
              label="Storage Account Name"
              style={{ width: 300, display: 'inline-flex' }}
              variant="outlined"
              helperText={renderValidateStorageAccountNameMessage(
                isStorageAccountNameValid,
                isStorageAccountNameUnique,
                classes,
              )}
              onChange={event => {
                const storageAccountName = event.target.value;
                if (storageAccountName.match(/^[a-z0-9]{3,24}$/gm)) {
                  setIsStorageAccountNameValid(StorageAccountNameValid.VALID);
                  setIsStorageAccountNameUnique(StorageAccountNameUnique.INITIAL_STATE);
                  onChange({ ...formData, storageAccountName });
                } else {
                  setIsStorageAccountNameValid(StorageAccountNameValid.NOT_VALID);
                  delete formData.storageAccountName;
                }
              }}
            />
          </Grid>
          <Grid item xs={12} className={classes.container}>
            <Button aria-label="Validate Storage Account Name" onClick={handleValidateName} variant="contained">
              Validate Name
            </Button>
          </Grid>
          <Grid item xs={12} className={classes.container}>
            <FormControl variant="outlined">
              <FormLabel id="accountTier">Account Tier</FormLabel>
              <RadioGroup
                row
                defaultValue={defaultStorageAccountValues.storageAccountTier}
                style={{ width: 300, display: 'inline-flex' }}
                aria-label="Account Tier"
                onChange={event => {
                  setStorageAccountTier(event.target.value);
                  const determinedStorageAccountKind = validStorageAccountKindOptions.find(
                    option => option.validStorageAccountTier === event.target.value,
                  );
                  setStorageAccountKind(
                    determinedStorageAccountKind
                      ? determinedStorageAccountKind.value
                      : defaultStorageAccountValues.storageAccountKind,
                  );
                  onChange({
                    ...formData,
                    storageAccountTier: event.target.value,
                    storageAccountKind: determinedStorageAccountKind
                      ? determinedStorageAccountKind.value
                      : defaultStorageAccountValues.storageAccountKind,
                  });
                }}
              >
                <FormControlLabel value="Standard" control={<Radio />} label="Standard" />
                <FormControlLabel value="Premium" control={<Radio />} label="Premium" />
              </RadioGroup>
            </FormControl>
          </Grid>
          <Grid item xs={12} className={classes.container}>
            <FormControl variant="outlined">
              <InputLabel id="accountKind">Account Kind</InputLabel>
              <Select
                labelId="accountKind"
                id="accountKindSelect"
                aria-label="Account Kind"
                labelWidth={120}
                style={{ width: 300, display: 'inline-flex' }}
                onChange={event => {
                  onChange({ ...formData, storageAccountKind: event.target.value as string });
                }}
                defaultValue={defaultStorageAccountValues.storageAccountKind}
                value={storageAccountKind}
              >
                {validStorageAccountKindOptions.map(option => {
                  if (option.validStorageAccountTier === storageAccountTier) {
                    return (
                      <MenuItem value={option.value} key={option.value} aria-label={option.value}>
                        {option.value}
                      </MenuItem>
                    );
                  }
                  return null;
                })}
              </Select>
            </FormControl>
          </Grid>
        </>
      )}
    </Grid>
  );
};
