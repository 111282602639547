import { CircularProgress, FormHelperText, TextField } from '@material-ui/core';
import React from 'react';
import { useStyles } from '../OrionRequestForm/orionFormStyles';
import { orionServiceApiRef } from '../../service/orion.service';
import { useApi } from '@backstage/core-plugin-api';
import { useAsync } from 'react-use';
import { Alert, Autocomplete } from '@material-ui/lab';
import ErrorIcon from '@material-ui/icons/Error';

type OnChangeValue = {
  amlWorkspace: string;
};

type Props = {
  onChange(value: OnChangeValue): void;
  onBlur(): void;
  disabled?: boolean | undefined;
  label?: string | undefined;
  required?: boolean;
  initialValue?: any;
  resourceGroup: string;
};

export const AMLWorkspaceDropdown: React.FC<Props> = ({
  onChange,
  onBlur,
  disabled = false,
  label = 'AML Workspace',
  required = true,
  initialValue = undefined,
  resourceGroup,
}) => {
  const classes = useStyles();
  const orion = useApi(orionServiceApiRef);
  const amlWorkspaceOptions = useAsync(async () => {
    return resourceGroup ? (await orion.getAMLWorkspacesInResourceGroup(resourceGroup)).data : [];
  }, [orion, resourceGroup]);

  return (
    <>
      <div>
        <Autocomplete
          key={resourceGroup}
          disabled={amlWorkspaceOptions.error !== undefined || disabled || amlWorkspaceOptions.loading}
          id="amlWorkspace"
          data-testid="amlWorkspace"
          disableClearable
          autoSelect
          defaultValue={initialValue}
          options={amlWorkspaceOptions.value ?? []}
          getOptionLabel={option => option}
          getOptionSelected={(option, value) => option === value}
          onChange={(_: any, amlWorkspace: any) => {
            onChange({ amlWorkspace: amlWorkspace });
          }}
          onBlur={onBlur}
          style={{ display: 'inline-flex' }}
          className={classes.componentFormGroup}
          renderInput={params => <TextField required={required} {...params} label={label} variant="outlined" />}
        />
        {!!amlWorkspaceOptions.loading && <CircularProgress style={{ marginLeft: 10 }} />}
        {!!amlWorkspaceOptions.error && (
          <>
            <ErrorIcon style={{ marginLeft: 10, marginRight: 10, color: 'red' }} />
            <FormHelperText>There was an error getting the Workspaces. Please refresh the page.</FormHelperText>
          </>
        )}
      </div>
      <div>
        {resourceGroup.length > 0 && amlWorkspaceOptions.value?.length === 0 && !amlWorkspaceOptions.loading && (
          <>
            <Alert style={{ marginTop: 10 }} severity="error">
              <strong>There are no Workspaces in this Resource Group. </strong>
            </Alert>
          </>
        )}
      </div>
    </>
  );
};
