import { AdminToggle } from '../types/index';
import { Config } from '@backstage/config';

export interface AdminToggleServiceApi {
  getToggleByName(name: string): AdminToggle;
}

class AdminToggleService implements AdminToggleServiceApi {
  private readonly flags: Record<string, AdminToggle>;
  constructor(private config: Config) {
    const configs = this.config.getOptionalConfigArray('adminToggles') || [];
    this.flags = configs.reduce(
      (acc, flagConfig) => {
        const flag: AdminToggle = {
          name: flagConfig.getString('name'),
          description: flagConfig.getString('description'),
          enabled: flagConfig.getBoolean('enabled'),
        };
        acc[flag.name] = flag;
        return acc;
      },
      {} as Record<string, AdminToggle>,
    );
  }

  getToggleByName(name: string): AdminToggle {
    if (this.flags[name]) {
      return this.flags[name];
    }
    return {
      name: name,
      description: 'Default description',
      enabled: false,
    };
  }
}

export default AdminToggleService;
