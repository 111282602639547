import axios from 'axios';
import { createApiRef, IdentityApi } from '@backstage/core-plugin-api';
import type { FormInputs } from '@runway/plugin-orion-auto-backend';
import { timer } from '@runway/devkit';

type OrionServiceResponse = {
  response: string;
};

export interface OrionServiceApi {
  updateDataFromForm(formParams: FormInputs): Promise<OrionServiceResponse>;

  addEntity(entityParams: {
    partitionKey: Date;
    rowKey: string;
    name: string;
    ID: string;
  }): Promise<OrionServiceResponse>;

  getAppCodes(): Promise<any>;

  getDatabricksClusters(workspaceName: any): Promise<any>;
  getDatabricksClusterPermissions(clusterName: any, workspaceName: any): Promise<any>;
  listMembersInAdGroup(data: any): Promise<any>;
  adbGrantManagePermission(data: any): Promise<any>;
  createDeaEdpIssue(params: { requestor: string; summary: string; details: string }): Promise<any>;
  provisionAMLComputeInstance(inputs: any): Promise<any>;
  getResourceGroupsInSubscription(subscriptionId: any): Promise<any>;
  getAMLWorkspacesInResourceGroup(resourceGroup: any): Promise<any>;
  provisionAZKeyVault(params: { resourceGroup: string; aaId: string }): Promise<void>;
  provisionAMLComputeCluster(inputs: {
    clusterName: string;
    subscription: string;
    resourceGroup: string;
    amlWorkspace: string;
    maxNodes: string;
    vmSKU: string;
    clusterAvailability: string;
  }): Promise<void>;
}

export const orionServiceApiRef = createApiRef<OrionServiceApi>({
  id: 'plugin.orion-auto.orion.service',
});

export default class OrionService implements OrionServiceApi {
  constructor(
    private backendUrl: string,
    private identityApi: IdentityApi,
  ) {}

  getHeaders = async (): Promise<Record<string, string>> => {
    const { token } = await this.identityApi.getCredentials();
    return { Authorization: `Bearer ${token}` };
  };

  updateDataFromForm = async (formParams: FormInputs) => {
    const headers = await this.getHeaders();
    const res = await axios.put(
      `${this.backendUrl}/api/orion-auto/updateData`,
      {
        ...formParams,
      },
      { headers },
    );
    if (res.status !== 200) {
      throw new Error('Failed to update data');
    }
    let counter = 1;
    let jobUrl = 'no match';
    const commitSHA = res.data.response;
    while (jobUrl === 'no match' && counter < 15) {
      await timer(5000);
      const adoLink = await axios.get(`${this.backendUrl}/api/orion-auto/getPipeline?pipelineSha=${commitSHA}`, {
        headers,
      });
      jobUrl = adoLink.data.response;
      counter++;
    }
    if (jobUrl === 'no match') {
      throw new Error('Job URL not found');
    }
    return { response: jobUrl };
  };

  addEntity = async (entityParams: { partitionKey: Date; rowKey: string; name: string; ID: string }) => {
    const headers = await this.getHeaders();
    const res = await axios.post(
      `${this.backendUrl}/api/orion-auto/addEntity`,
      {
        ...entityParams,
      },
      { headers },
    );

    if (res.status !== 200) {
      throw new Error('Failed to add entity');
    }

    return { response: res.data };
  };

  getAppCodes = async (): Promise<any> => {
    const headers = await this.getHeaders();
    const result = await axios.get(`${this.backendUrl}/api/orionRegistration/appcodes`, { headers });
    if (result.status !== 200) {
      throw new Error('Failed to retrieve app codes');
    }
    return result.data;
  };

  getDatabricksClusters = async (workspaceName: any): Promise<any> => {
    const headers = await this.getHeaders();
    const result = await axios.get(`${this.backendUrl}/api/orionRegistration/list_all_clusters/${workspaceName}`, {
      headers,
    });
    if (result.status !== 200) {
      throw new Error('Failed to retrieve databricks clusters');
    }
    return result.data;
  };

  getDatabricksClusterPermissions = async (clusterName: any, workspaceName: any): Promise<any> => {
    const headers = await this.getHeaders();
    const result = await axios.get(
      `${this.backendUrl}/api/orionRegistration/get_cluster_permissions/${clusterName}/${workspaceName}`,
      {
        headers,
      },
    );
    if (result.status !== 200) {
      throw new Error('Failed to retrieve cluster permissions');
    }
    return result.data;
  };

  listMembersInAdGroup = async (data: any) => {
    try {
      const headers = await this.getHeaders();
      const url = `${this.backendUrl}/api/orionRegistration/list_members_in_AD_group`;
      const result = await axios.post(url, data, { headers });
      return result.data;
    } catch (error) {
      throw new Error('There was an error getting members.');
    }
  };

  adbGrantManagePermission = async (data: any) => {
    try {
      const headers = await this.getHeaders();
      const url = `${this.backendUrl}/api/orionRegistration/grant_adb_permission`;
      const result = await axios.post(url, data, { headers });
      return result.data;
    } catch (error) {
      throw new Error('There was an error granting access.');
    }
  };

  createDeaEdpIssue = async (params: { requestor: string; summary: string; details: string }) => {
    const headers = await this.getHeaders();
    const res = await axios.post(`${this.backendUrl}/api/orion-auto/createDeaEdpIssue`, params, { headers });

    if (res.status !== 200) {
      throw new Error(`Failed to create issue: ${res.statusText}`);
    }

    return { response: res.data };
  };

  provisionAMLComputeInstance = async (params: any): Promise<any> => {
    const headers = await this.getHeaders();
    const res = await axios.post(`${this.backendUrl}/api/orion-auto/provisionAMLComputeInstance`, params, { headers });
    return res;
  };

  provisionAMLComputeCluster = async (params: {
    amlWorkspace: string;
    clusterAvailability: string;
    clusterName: string;
    maxNodes: string;
    resourceGroup: string;
    subscription: string;
    vmSKU: string;
  }): Promise<void> => {
    const headers = await this.getHeaders();
    await axios.post(`${this.backendUrl}/api/orion-auto/provisionAMLComputeCluster`, params, { headers });
  };

  getResourceGroupsInSubscription = async (subscriptionId: string): Promise<any> => {
    const headers = await this.getHeaders();
    const result = await axios.get(`${this.backendUrl}/api/orionRegistration/get_resource_groups/${subscriptionId}`, {
      headers,
    });
    return result.data;
  };

  getAMLWorkspacesInResourceGroup = async (resourceGroup: string): Promise<any> => {
    const headers = await this.getHeaders();
    const result = await axios.get(`${this.backendUrl}/api/orionRegistration/get_aml_workspaces/${resourceGroup}`, {
      headers,
    });
    return result.data;
  };

  provisionAZKeyVault = async (params: { resourceGroup: string; aaId: string }): Promise<void> => {
    const headers = await this.getHeaders();
    await axios.post(`${this.backendUrl}/api/orionRegistration/key-vault`, params, { headers });
  };
}
