import { useApi } from '@backstage/core-plugin-api';
import {
  Box,
  Button,
  CircularProgress,
  FormControl,
  FormControlLabel,
  FormLabel,
  IconButton,
  InputAdornment,
  Link,
  makeStyles,
  Radio,
  RadioGroup,
  TextField,
  Tooltip,
} from '@material-ui/core';
import InfoIcon from '@material-ui/icons/Info';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import { Alert } from '@material-ui/lab';
import { BucketId, ClientId, PluginId } from '@runway/devkit-common';
import React, { useState } from 'react';
import { aaPingSSOAuthApiRef, ObserveServiceApiRef } from '../../services';
import { AzureManagementServiceApiRef } from '../../services/am.service';
import { EnvName, getRGName } from './GetRGorSPName';

const useStyles = makeStyles(() => ({
  formControl: {
    minWidth: 300,
  },
  customWidth: {
    maxWidth: 300,
  },
  errorAlert: {
    whiteSpace: 'pre-line',
    margin: 10,
    maxWidth: 500,
  },
}));

enum ErrorCategory {
  NONE,
  NORMAL,
  INVALID_SHORTNAME,
}

export const GenerateSPandRGComponent = (props: {
  env: 'dev' | 'prod';
  envName?: EnvName;
  region: 'eastus' | 'westus';
  disabled: boolean | undefined;
  formik: any;
  componentId: string;
  vertical: string;
  shortname: string;
  type: 'SP' | 'RG' | 'SP and RG';
  tags?: Record<string, string>[];
  onSPGenerate?: (load: boolean) => void;
  owners?: string[];
}) => {
  const { env, formik, componentId, type, disabled, owners } = props;
  const classes = useStyles();

  const ams = useApi(AzureManagementServiceApiRef);
  const observeSvc = useApi(ObserveServiceApiRef);
  const aaAuthSvc = useApi(aaPingSSOAuthApiRef);

  const [generateOrCreateNewAzSpRg, setGenerateOrCreateNewAzSpRg] = useState('createNew');
  const [showSecret, setShowSecret] = useState(false);

  const handleClickShowPassword = () => {
    setShowSecret(!showSecret);
  };

  const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };

  const handleToggleUseExistingOrGenerateNew = (event: any) => {
    setGenerateOrCreateNewAzSpRg(event.target.value);
  };

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<{ category: ErrorCategory; message: string }>({
    category: ErrorCategory.NONE,
    message: '',
  });
  const [completed, setCompleted] = useState(false);

  const [spSecret, setSpSecret] = useState('');
  const [spName, setSpName] = useState('');
  const [spClientId, setSpClientId] = useState('');
  const [spObjectId, setSpObjectId] = useState('');
  const [aadOwnerGroup, setAadOwnerGroup] = useState('');
  const [aadContributorGroup, setAadContributorGroup] = useState('');
  const [aadReaderPlusGroup, setAadReaderPlusGroup] = useState('');
  const [keyVaultMessage, setkeyVaultMessage] = useState('');

  const [rgCreateLoading, setRgCreateLoading] = useState(false);
  const [spCreateLoading, setSpCreateLoading] = useState(false);

  const callObserveApiForRG = async (rgPayload: any) => {
    try {
      const userEmpId = await aaAuthSvc.getAAProfile();
      await observeSvc.createRecord({
        bucket_id: BucketId.USE_METRIC,
        client_id: ClientId.RUNWAY_FRONTEND,
        actor: userEmpId?.aaId ?? '-1',
        status: 'success',
        data: {
          userEmployeeId: userEmpId?.aaId ?? '-1',
          pluginId: PluginId.INFRASTRUCTURE_RESOURCE_GROUP.id,
          resourceGroupName: getRGName({
            vertical: rgPayload.appVertical,
            env: rgPayload.env,
            envName: rgPayload.envName,
            region: rgPayload.region,
            rgDescriptor: rgPayload.appName,
          }),
          payload: rgPayload,
        },
      });
    } catch {
      return;
    }
  };

  const callObserveApiForSP = async (spPayload: any) => {
    try {
      const userEmpId = await aaAuthSvc.getAAProfile();
      await observeSvc.createRecord({
        bucket_id: BucketId.USE_METRIC,
        client_id: ClientId.RUNWAY_FRONTEND,
        actor: userEmpId?.aaId ?? '-1',
        status: 'success',
        data: {
          userEmployeeId: userEmpId?.aaId ?? '-1',
          pluginId: PluginId.INFRASTRUCTURE_SERVICE_PRINCIPAL.id,
          servicePrincipalName: spPayload.displayName,
          owners: spPayload.owners,
        },
      });
    } catch {
      return;
    }
  };

  const handleClickGenerate = async () => {
    const userEmpId = await aaAuthSvc.getAAProfile();
    setLoading(true);
    setError({ category: ErrorCategory.NONE, message: '' });
    if (props.onSPGenerate !== undefined) {
      props.onSPGenerate(true);
    }
    let displayName = formik.values?.spDescriptor;
    if (!displayName) {
      displayName = `${props.vertical}-${formik.values?.env}-${formik.values?.archerShortname}-sp`;
    } else {
      displayName = `${props.vertical}-${formik.values?.env}-${formik.values?.archerShortname}-${formik.values?.spDescriptor}-sp`;
    }
    try {
      if (type === 'SP and RG') {
        setRgCreateLoading(true);
        setSpCreateLoading(true);
        const createAzResourceGroupRequest = {
          appName: componentId,
          appVertical: props.vertical,
          subscriptionId: formik.values?.[env]?.subscription_id,
          archerShortname: props.shortname,
          tenant: env === 'dev' ? EnvName.Nonprod : EnvName.Prod,
          envName: props.envName,
          region: props.region,
          dataClassification: formik.values?.data_classification,
        };

        const createAzServicePrincipalRequest = {
          displayName,
          env: formik.values?.env,
        };
        const rgName = await ams.createAzResourceGroup(createAzResourceGroupRequest);
        const spDetails = await ams.createAzServicePrincipal(createAzServicePrincipalRequest);
        await ams.linkRgAndSp({
          rgName,
          spName: createAzServicePrincipalRequest.displayName,
          azSubscriptionId: formik.values?.[env]?.subscription_id,
        });
        formik.setFieldValue(`${env}.clientId`, spDetails.deployment_data.azure_app_client_id);
        formik.setFieldValue(`${env}.objectId`, spDetails.deployment_data.azure_app_object_id);
        formik.setFieldValue(`${env}.clientSecret`, spDetails.pswd_data.azure_secret);
        formik.setFieldValue(`${env}.resource_group`, rgName);
        setLoading(false);
        setRgCreateLoading(false);
        setSpCreateLoading(false);
        setCompleted(true);
      } else if (type === 'SP') {
        setSpCreateLoading(true);
        const req = {
          displayName: displayName,
          env: formik.values?.env,
          owners: owners ? owners.join(',') : undefined,
        };
        callObserveApiForSP(req);
        const response = await ams.createAzServicePrincipal(req);
        setSpClientId(`Service Principal Client ID: ${response.deployment_data.azure_app_client_id}`);
        setSpObjectId(`Service Principal Object ID: ${response.deployment_data.azure_app_object_id}`);
        setSpSecret(`Service Principal Secret: ${response.pswd_data.azure_secret}`);
        setSpName(`Service Principal Name: ${response.pswd_data.azure_sp_name}`);
        setkeyVaultMessage(`If you use the runway "create app" feature,
                            be sure to use Service Client Id and not Service Principal Name.
                            It is recommended to store the secret in an Azure keyvault`);

        setLoading(false);
        setSpCreateLoading(false);
        setCompleted(true);
      } else if (type === 'RG') {
        setRgCreateLoading(true);
        const request = {
          appName: componentId,
          appVertical: props.vertical,
          subscriptionId: formik.values?.[env]?.subscription_id,
          archerShortname: props.shortname,
          tenant: env === 'dev' ? EnvName.Nonprod : EnvName.Prod,
          envName: props.envName,
          region: props.region,
          dataClassification: formik.values?.data_classification,
          tags: props.tags,
        };
        callObserveApiForRG(request);
        let employeeId = userEmpId?.aaId;
        if (!employeeId) throw Error('Employee Id missing');
        if (employeeId.length === 8) {
          if (employeeId.substring(0, 2) === '00') {
            employeeId = employeeId.substring(2);
          }
        }
        const response = await ams.createRGAndAADGroupsAndAssociate(request, employeeId);
        formik.setFieldValue(`${env}.resource_group`, response.resource_group_name);
        setAadOwnerGroup(`AAD Owner Group: ${response.aad_group_owner}`);
        setAadContributorGroup(`AAD Contributor Group: ${response.aad_group_contributor}`);
        setAadReaderPlusGroup(`AAD Reader+ Group: ${response.aad_group_reader_plus}`);

        setLoading(false);
        setRgCreateLoading(false);
        setCompleted(true);
      }
    } catch (e: any) {
      const errorMessage = e.message || e.toString();
      if (errorMessage.includes('Provisioning')) {
        setError({
          category: ErrorCategory.INVALID_SHORTNAME,
          message: `${errorMessage}\n\nThis application '${componentId}' has not been approved to onboard to Azure. Please `,
        });
      } else {
        setError({
          category: ErrorCategory.NORMAL,
          message: `Error: ${errorMessage}`,
        });
      }
      setLoading(false);
    }
    if (props.onSPGenerate !== undefined) {
      props.onSPGenerate(false);
    }
  };

  let createNew: any;

  if (loading === true) {
    createNew = () => (
      <>
        {(type === 'SP' || type === 'SP and RG') && (
          <>
            {spCreateLoading ? 'Creating Service Principal...' : 'Service Principal Completed'}
            <br />
          </>
        )}
        {(type === 'RG' || type === 'SP and RG') && (
          <>
            {rgCreateLoading
              ? 'Creating Resource Group, creating and associating AAD Groups...'
              : 'Resource Group and AAD Groups Completed'}
            <br />
          </>
        )}
        {type === 'SP and RG' && (
          <>
            {spCreateLoading || rgCreateLoading
              ? 'Waiting to associate Service Principal to Resource Group...'
              : 'Associating Service Principal to Resource Group...'}
            <br />
          </>
        )}
        <CircularProgress />
      </>
    );
  } else if (error.category !== ErrorCategory.NONE) {
    createNew = () => (
      <>
        <Alert severity="error" className={classes.errorAlert}>
          {error.message}
          {error.category === ErrorCategory.INVALID_SHORTNAME && (
            <>
              <Link
                href="https://developer.aa.com/create/templates/default/gaas-app-onboard"
                target="_blank"
                rel="noopener"
              >
                <b>Submit Approval Request</b>
              </Link>
              .<div>You can create a Resource Group once it's approved.</div>
            </>
          )}
        </Alert>
        <Box>
          {error.category === ErrorCategory.NORMAL && (
            <Button
              variant="contained"
              color="primary"
              name={`Generate ${type}`}
              onClick={() => handleClickGenerate()}
              disabled={disabled}
            >
              Generate {type} {type === 'RG' || type === 'SP' ? '' : `(${env})`}
            </Button>
          )}
        </Box>
      </>
    );
  } else if (completed === false) {
    createNew = () => (
      <Box>
        <Button
          variant="contained"
          color="primary"
          name={`Generate ${type}`}
          onClick={() => handleClickGenerate()}
          disabled={disabled}
        >
          Generate {type} {type === 'RG' || type === 'SP' ? '' : `(${env})`}
        </Button>
      </Box>
    );
  } else if (completed) {
    createNew = () => (
      <>
        {(type === 'SP' || type === 'RG') && (
          <Box>
            <Button
              variant="contained"
              color="primary"
              name={`Generate ${type}`}
              onClick={() => handleClickGenerate()}
              disabled={disabled}
            >
              Generate {type}
            </Button>
          </Box>
        )}

        {(type === 'SP' || type === 'SP and RG') && (
          <div>
            <Alert>Service Principal Created</Alert>
            <h2>Save the secret now - It will not be shown again</h2>
          </div>
        )}
        {(type === 'RG' || type === 'SP and RG') && (
          <>
            <h2>
              Resource Group was created! Name of the Resource Group is{' '}
              {env === 'prod' ? formik.values?.prod.resource_group : formik.values?.dev.resource_group}{' '}
            </h2>
            <h2>
              Along with the resource group, these below AD Groups have been created with permissions over the resource
              group as described by their name:
            </h2>
          </>
        )}
      </>
    );
  } else {
    createNew = () => <div />;
  }

  return (
    <>
      {type === 'SP and RG' && (
        <div>
          <FormControl component="fieldset" className={classes.formControl}>
            <FormLabel component="legend">Service Principal and Resource Group</FormLabel>
            <RadioGroup
              row
              aria-label="generateSPandRG"
              name="generateSPandRG"
              value={generateOrCreateNewAzSpRg}
              onChange={handleToggleUseExistingOrGenerateNew}
            >
              <FormControlLabel value="createNew" control={<Radio color="primary" />} label={`Create New (${env})`} />
              <FormControlLabel value="existing" control={<Radio color="primary" />} label={`Use Existing (${env})`} />
            </RadioGroup>
            {generateOrCreateNewAzSpRg === 'createNew' && createNew(formik)}
          </FormControl>
        </div>
      )}

      {type === 'SP and RG' &&
        ((generateOrCreateNewAzSpRg === 'createNew' && completed) || generateOrCreateNewAzSpRg === 'existing') && (
          <>
            <div>
              <FormControl className={classes.formControl}>
                <TextField
                  required
                  disabled={generateOrCreateNewAzSpRg === 'createNew'}
                  label="Service Principal Client ID"
                  {...formik.getFieldProps(`${env}.clientId`)}
                  inputProps={{ 'aria-label': `Service Principal Client ID` }}
                  variant="outlined"
                  error={Boolean(formik.errors?.[env]?.clientId)}
                  helperText={formik.errors?.[env]?.clientId || 'Not the SP Name.'}
                />
              </FormControl>
            </div>
            {generateOrCreateNewAzSpRg === 'createNew' && (
              <div>
                <FormControl className={classes.formControl}>
                  <TextField
                    required
                    disabled={generateOrCreateNewAzSpRg === 'createNew'}
                    label="Service Principal Object ID"
                    {...formik.getFieldProps(`${env}.objectId`)}
                    inputProps={{ 'aria-label': `Service Principal Object ID` }}
                    variant="outlined"
                    error={Boolean(formik.errors?.[env]?.objectId)}
                    helperText={formik.errors?.[env]?.objectId || 'Not the SP Name.'}
                  />
                </FormControl>
              </div>
            )}
            <div>
              <FormControl className={classes.formControl}>
                <TextField
                  required
                  disabled={generateOrCreateNewAzSpRg === 'createNew'}
                  label="Service Principal Secret"
                  {...formik.getFieldProps(`${env}.clientSecret`)}
                  inputProps={{ 'aria-label': `Service Principal Secret` }}
                  variant="outlined"
                  error={Boolean(formik.errors?.[env]?.clientSecret)}
                  helperText={formik.errors?.[env]?.clientSecret}
                  type={showSecret ? 'text' : 'password'}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                          onMouseDown={handleMouseDownPassword}
                          edge="end"
                        >
                          {showSecret ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                        <IconButton edge="end">
                          <Tooltip
                            title="Runway will store your Service Pricipal secret in the Runway keyvault for use during deployments."
                            classes={{ tooltip: classes.customWidth }}
                          >
                            <InfoIcon />
                          </Tooltip>
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </FormControl>
            </div>
            <br />
            <div>
              <FormControl className={classes.formControl}>
                <TextField
                  required
                  disabled={generateOrCreateNewAzSpRg === 'createNew'}
                  label="Resource Group"
                  {...formik.getFieldProps(`${env}.resource_group`)}
                  inputProps={{ 'aria-label': `Resource Group` }}
                  variant="outlined"
                  error={Boolean(formik.errors?.[env]?.resource_group)}
                  helperText={formik.errors?.[env]?.resource_group}
                />
              </FormControl>
            </div>
          </>
        )}

      {type === 'SP' && (
        <>
          {createNew(formik)}
          <FormControl disabled>
            <p className="dd-privacy-mask">{spName}</p>
            <br />
            <p className="dd-privacy-mask">{spClientId}</p>
            <br />
            <p className="dd-privacy-mask">{spObjectId}</p>
            <br />
            <p className="dd-privacy-mask">{spSecret}</p>
            <br />
            <p>{keyVaultMessage}</p>
          </FormControl>
        </>
      )}

      {type === 'RG' && (
        <>
          {createNew(formik)}
          <FormControl>
            <p className="dd-privacy-mask">{aadOwnerGroup}</p>
            <p className="dd-privacy-mask">{aadContributorGroup}</p>
            <p className="dd-privacy-mask">{aadReaderPlusGroup}</p>
          </FormControl>
        </>
      )}
    </>
  );
};
