import { useApi } from '@backstage/core-plugin-api';
import { CircularProgress, Grid, TextField } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import { FieldValidation } from '@rjsf/utils';
import React from 'react';
import { useAsyncFn } from 'react-use';
import { GithubEnterpriseClientApiGraphRef } from '../../../services';

export const GithubRepoField = (props: { error: boolean; onChange: (value: string) => void }) => {
  const { error, onChange } = props;

  const githubEnterpriseClientGraph = useApi(GithubEnterpriseClientApiGraphRef);
  const [validateRepoNameState, validateRepoName] = useAsyncFn(async (repo: string) => {
    const regexp = new RegExp('^[a-zA-Z][a-z0-9-.A-Z_]*$');
    if (!regexp.test(repo)) {
      throw new Error(
        'Please use letters, dashes, underscores, periods, and numbers. You must start and end with a letter.',
      );
    } else if (repo.length > 56) {
      throw new Error('Names cannot be more than 56 characters.');
    }

    const isUniqueRepoNameResp = await githubEnterpriseClientGraph.isUniqueRepo('AAInternal', repo);
    if (isUniqueRepoNameResp) {
      return 'Repo Name is unique!';
    }
    throw new Error('Repo Name is taken!');
  });

  return (
    <Grid container>
      <Grid item xs={6}>
        <TextField
          id="repo-name"
          label="Repo Name"
          style={{ width: '100%' }}
          required
          variant="outlined"
          onChange={({ target: { value } }) => {
            onChange(value);
          }}
          onBlur={({ target: { value } }) => {
            if (value) validateRepoName(value);
          }}
          error={!!(error || validateRepoNameState.error)}
        />
      </Grid>
      {validateRepoNameState.loading && (
        <Grid item xs={1}>
          <CircularProgress style={{ padding: 5 }} />
        </Grid>
      )}
      {validateRepoNameState.error && !validateRepoNameState.loading && (
        <Grid item xs={3}>
          <Alert severity="error">{validateRepoNameState.error?.message}</Alert>
        </Grid>
      )}
      {validateRepoNameState.value && !validateRepoNameState.error && !validateRepoNameState.loading && (
        <Grid item xs={3}>
          <Alert severity="success">{validateRepoNameState.value}</Alert>
        </Grid>
      )}
    </Grid>
  );
};

export const repoNameValidation = (value: string, validation: FieldValidation) => {
  if (!value) {
    validation.addError('Please enter a repo name');
  }
};

export const GithubRepoDescription = (props: { error: boolean; onChange: (value: string) => void }) => {
  const { error, onChange } = props;

  return (
    <TextField
      id="description"
      label="Repo Description"
      style={{ width: '100%' }}
      required
      variant="outlined"
      aria-label="repo-description"
      fullWidth
      onChange={({ target: { value } }) => {
        onChange(value);
      }}
      error={error}
    />
  );
};

export const repoDescriptionValidation = (value: string, validation: FieldValidation) => {
  if (!value) {
    validation.addError('Please enter a repo description');
  }
};
