import React from 'react';
import { Button, Dialog, DialogContent, DialogTitle, FormControl, TextField } from '@material-ui/core';
import { Alert, AlertTitle } from '@material-ui/lab';

interface ConfirmDeleteDialogProps {
  confirmationText: string;
  isOpen: boolean;
  onDelete(): void;
  onCancel(): void;
  children: React.ReactNode;
}

export const ConfirmDeleteDialog = ({
  confirmationText,
  isOpen,
  onDelete,
  onCancel,
  children,
}: ConfirmDeleteDialogProps) => {
  const [deleteName, setDeleteName] = React.useState('');

  return (
    <Dialog open={isOpen} fullWidth maxWidth="sm" keepMounted>
      <DialogTitle>Are you absolutely sure?</DialogTitle>
      <DialogContent>
        <Alert severity="error">
          <AlertTitle>Unexpected bad things will happen if you don't read this!</AlertTitle>
        </Alert>
        {children}
        <FormControl fullWidth>
          <TextField
            required
            variant="outlined"
            size="small"
            value={deleteName}
            onChange={event => setDeleteName(event.target.value)}
          />
          <br />
          <Button
            variant="contained"
            data-testid="deleteBtn"
            color="secondary"
            onClick={() => onDelete()}
            disabled={deleteName !== confirmationText}
          >
            I understand the consequences
          </Button>
          <Button color="primary" onClick={() => onCancel()}>
            Cancel
          </Button>
        </FormControl>
      </DialogContent>
    </Dialog>
  );
};
