import { configApiRef, identityApiRef, useApi } from '@backstage/core-plugin-api';
import { useEntity } from '@backstage/plugin-catalog-react';
import axios from 'axios';
import { useAsync } from 'react-use';
import combineStatus from './combineStatus';
import KubernetesService from './services/kubernetes.service';
import AkamaiService from './services/akamai.service';
import { ingressResolver } from './ingressResolver';
import { AkamaiGTMMetadata } from './Layers/AkamaiGTMLayer';
import { ArgoData, Cluster } from './Layers/ArgoCD';
import { getStatus, getStatusArgo } from './services';
import { AllStatus, STATUS } from './Status';

export type ClusterStatus = Record<string, { status: STATUS | undefined }>;

export type ClusterStatus2D = Record<string, { status: STATUS | undefined }[]>;

const getStatusArray2D = (clusters: ClusterStatus2D): (STATUS | undefined)[] =>
  Object.values(clusters)
    .map(cluster => cluster.map(item => item.status))
    .reduce((acc, curr) => [...acc, ...curr], []);

const getStatusArray = (clusters: ClusterStatus): (STATUS | undefined)[] =>
  Object.values(clusters).map(cluster => cluster.status);

const getLayerStatus = (error: Error | undefined, status: ClusterStatus | undefined) => {
  if (error) return STATUS.unretrievable;
  if (status) return combineStatus(getStatusArray(status));
  return STATUS.loading;
};

const getLayerStatus2D = (error: Error | undefined, status: ClusterStatus2D | undefined) => {
  if (error) return STATUS.unretrievable;
  if (status) return combineStatus(getStatusArray2D(status));
  return STATUS.loading;
};

const getAkamaiGTMStatus = (akamaiInfo: AkamaiGTMMetadata[] | undefined) => {
  if (!akamaiInfo) return STATUS.loading;
  if (akamaiInfo.length === 0) return STATUS.unretrievable;
  if (
    akamaiInfo.filter(item => {
      return item.weight === 0;
    }).length === akamaiInfo.length
  )
    return STATUS.unhealthy;
  return STATUS.healthy;
};

const getArgoStatus = (argoError: Error | undefined, argoStatus: ArgoData[] | undefined) => {
  if (argoError) return STATUS.unretrievable;
  if (argoStatus) return combineStatus(getStatusArray(getStatusArgo(argoStatus)));
  return STATUS.loading;
};

export const useLayerInfo = () => {
  const identityApi = useApi(identityApiRef);
  const configApi = useApi(configApiRef);
  const { entity } = useEntity();
  const kubernetesService = new KubernetesService(configApi.getString('backend.baseUrl'));
  const akamaiService = new AkamaiService(configApi.getString('backend.baseUrl'));

  const { value: layerInfo, error } = useAsync(async () => {
    const { token } = await identityApi.getCredentials();
    const items = await kubernetesService.getService(entity, token || '');
    return {
      status: getStatus(items),
      akamaiInfo: await akamaiService.getGTMMetadata(ingressResolver(items), token || ''),
    };
  });

  const { value: argoData, error: argoError } = useAsync(async (): Promise<ArgoData[]> => {
    const { token } = await identityApi.getCredentials();
    const backendUrl = configApi.getString('backend.baseUrl');
    const argoCdSelector = entity?.metadata?.annotations?.['argocd/app-selector'];
    const clusters = await axios.get(`${backendUrl}/api/argocd/find/selector/${argoCdSelector}`, {
      headers: { Authorization: `Bearer ${token}` },
    });
    const response: Promise<ArgoData>[] = clusters.data.map(async (cluster: Cluster) => {
      const res = await axios.get(
        `${backendUrl}/api/argocd/argoInstance/${cluster.name}/applications/selector/${argoCdSelector}`,
        {
          headers: { Authorization: `Bearer ${token}` },
        },
      );
      return res.data;
    });

    return Promise.all(response);
  });

  const allStatus: AllStatus = {
    akamaiStatus: getAkamaiGTMStatus(layerInfo?.akamaiInfo),
    argoStatus: getArgoStatus(argoError, argoData),
    externalServicesStatus: getLayerStatus2D(error, layerInfo?.status?.externalServices),
    servicesStatus: getLayerStatus2D(error, layerInfo?.status?.services),
    deploymentsStatus: getLayerStatus2D(error, layerInfo?.status?.deployments),
    hpasStatus: getLayerStatus2D(error, layerInfo?.status?.hpas),
    replicaSetsStatus: getLayerStatus2D(error, layerInfo?.status?.replicaSets),
    podsStatus: getLayerStatus(error, layerInfo?.status?.pods),
    kumaSideCarsStatus: getLayerStatus(error, layerInfo?.status?.kumaSideCars),
    containersStatus: getLayerStatus(error, layerInfo?.status?.containers),
  };

  return { allStatus, error, layerInfo, argoError, argoData };
};
