import React from 'react';
import { Table, TableColumn, Link } from '@backstage/core-components';
import { Card, CardContent, Grid, Typography, Box } from '@material-ui/core';

type TowerMetadata = {
  etag: string;
  timestamp: Date;
};

export type TowerData = {
  Squad360ID: number;
  RowKey: string;
  _metadata?: TowerMetadata;
  timestamp?: Date;
  feedback: string;
};

export type TowerDataProps = {
  towerData: TowerData[];
};

// Function to parse and render text with hyperlinks
const renderTextWithLinks = (text: string) => {
  const parsedText = text.split('\n').map((line, index) => {
    const hyperlinkText = line.split(/(\[.*\]\(.*\))/g).map((part, partIndex) => {
      const match = part.match(/\[(.*)\]\((.*)\)/);
      if (match) {
        return (
          <Link key={partIndex} to={match[2]} target="_blank">
            {match[1]}
          </Link>
        );
      }
      return part;
    });
    return <Typography key={index}>{hyperlinkText}</Typography>;
  });
  return <Box>{parsedText}</Box>;
};

export const DenseTable = ({ towerData }: TowerDataProps) => {
  const columns: TableColumn[] = [
    { title: 'Squad ID', field: 'squadId' },
    { title: 'CMDB Application Shortname', field: 'archerShortName' },
    {
      title: 'Timestamp',
      field: 'timestamp',
      type: 'datetime',
      defaultSort: 'desc',
    },
    { title: 'Feedback', field: 'feedback', render: rowData => renderTextWithLinks((rowData as TowerData).feedback) },
  ];

  const data = towerData.map(field => {
    try {
      field.feedback = Object.entries(JSON.parse(field.feedback.replace(/\n/g, '\\n')))
        .map(([key, val]) => `${key}: ${val}`)
        .join('\n');
    } catch (error) {
      // Do nothing
    }

    return {
      squadId: field.Squad360ID,
      archerShortName: field.RowKey,
      timestamp: field._metadata?.timestamp ?? field.timestamp,
      feedback: field.feedback,
    };
  });

  return (
    <Table
      title="Tower Submissions"
      options={{ search: true, paging: true, sorting: true }}
      columns={columns}
      data={data}
    />
  );
};

export const HighLevelSummary = ({ towerData }: TowerDataProps) => {
  const uniqueSquads = new Set<string>();
  const uniqueArcherShortNames = new Set<string>();

  towerData?.map(data => {
    uniqueSquads.add(String(data.Squad360ID));
    uniqueArcherShortNames.add(data.RowKey);
  });

  return (
    <Grid container>
      <Grid container justifyContent="center" style={{ textAlign: 'center' }}>
        <Grid key="uniqueshortnames" item xs={6}>
          <Card>
            <CardContent>
              <Typography variant="h3">Unique CMDB Application Shortnames</Typography>
              <Typography variant="h1">{uniqueArcherShortNames.size}</Typography>
            </CardContent>
          </Card>
        </Grid>
        <Grid key="uniquesquads" item xs={6}>
          <Card>
            <CardContent>
              <Typography variant="h3">Unique Squads</Typography>
              <Typography variant="h1">{uniqueSquads.size}</Typography>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Grid>
  );
};
