import React from 'react';
import { Grid } from '@material-ui/core';
import { Content, Header, HeaderLabel, Page, Progress } from '@backstage/core-components';
import { DenseTable } from '../FetchComponent';
import { HighLevelSummary, TowerData } from '../FetchComponent/FetchComponent';
import { useAsync } from 'react-use';
import { useApi } from '@backstage/core-plugin-api';
import { towerDataServiceApiRef } from '../../services/tower-frontend.service';
import Alert from '@material-ui/lab/Alert';
import { RunwaySupportInformation } from '@runway/devkit';

const DataGrid = () => {
  const { getTowerData } = useApi(towerDataServiceApiRef);

  const { value, loading, error } = useAsync(async (): Promise<TowerData[]> => {
    return await getTowerData();
  }, []);

  if (loading) {
    return <Progress />;
  } else if (error) {
    return <Alert severity="error">{error.message}</Alert>;
  }

  return (
    <Grid container spacing={3} direction="column">
      <Grid item>
        <HighLevelSummary towerData={value || []} />
      </Grid>
      <Grid item>
        <DenseTable towerData={value || []} />
      </Grid>
    </Grid>
  );
};

export const TowerPage = () => {
  return (
    <Page themeId="tool">
      <Header title="Tower Statistics" subtitle="View the last 7 days of information captured by Tower">
        <HeaderLabel label="Owner" value="Runway" />
        <HeaderLabel label="Lifecycle" value="Alpha" />
      </Header>
      <Content>
        <RunwaySupportInformation />
        <DataGrid />
      </Content>
    </Page>
  );
};
