import React, { useEffect, useState } from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import {
  GithubRepoDescription,
  GithubRepoField,
  repoDescriptionValidation,
  repoNameValidation,
} from './GithubRepoField';
import { GithubTeamSelect, githubTeamValidation } from './../../../components/GithubTeamSelect';
import { Cluster, DataClassificationGroup, clusterValidation, DataClassification } from './Cluster';
import { Namespace, namespaceValidation } from './Namespace';
import {
  ContainerNameField,
  ContainerPortField,
  containerNameValidation,
  containerPortValidation,
} from './ContainerNameAndPort';
import { CreateIngress, IngressOptions } from './CreateIngress';
import { MicrogatewayProxy } from './MicrogatewayProxy';
import { SecurityInfo, BreakBuildOptions } from './SecurityInfo';
import { FieldValidation } from '@rjsf/utils';
import { FieldExtensionComponentProps } from '@backstage/plugin-scaffolder-react';
import { ReuseableWorkflow, rwSelectionOptions } from './ReuseableWorkflow';
import { CmdbAppShortName, SquadInfo } from '../../../components';
import { HashicorpVault } from './HashicorpVault';

export type ReturnedData = {
  squad: {
    name: string;
    id: string;
  };
  shortName: string;
  archerId: string;
  cmdbAppFullName: string;
  costCenter: string;
  repoName: string;
  repoDescription: string;
  githubTeam: string;
  pci: string;
  cluster: string;
  namespace: string;
  containerName: string;
  containerPort: number;
  createIngress: string;
  trafficPermissionPolicy: boolean;
  breakBuild: string;
  rwSelection: string;
  vaultNamespace: string;
  vaultSecretsPath: string;
};

const defaultValues = {
  squad: {
    name: '',
    id: '',
  },
  shortName: '',
  archerId: '',
  cmdbAppFullName: '',
  costCenter: '',
  repoName: '',
  repoDescription: '',
  githubTeam: '',
  pci: DataClassification.NON_PCI,
  cluster: '',
  namespace: '',
  containerName: '',
  containerPort: 8100,
  createIngress: IngressOptions.AKS_PUBLIC,
  trafficPermissionPolicy: false,
  breakBuild: BreakBuildOptions.YES,
  rwSelection: rwSelectionOptions.DEFAULT,
  vaultNamespace: '',
  vaultSecretsPath: '',
};

export const ComponentInformation = ({
  onChange,
  formData,
  uiSchema,
  error,
}: FieldExtensionComponentProps<ReturnedData>) => {
  if (!formData) {
    onChange({ ...defaultValues, containerPort: (uiSchema?.['ui:options']?.defaultPort as number) ?? 8100 });
  }
  const [returnedData, setReturnedData] = useState<ReturnedData>({
    ...defaultValues,
    containerPort: (uiSchema?.['ui:options']?.defaultPort as number) ?? 8100,
  });

  useEffect(() => {
    onChange({ ...returnedData });
  }, [onChange, returnedData]);

  const handleChangeBreakBuild = (event: string) => {
    setReturnedData({
      ...returnedData,
      breakBuild: event,
    });
  };

  const handleChangeAks = (event: string) => {
    setReturnedData({
      ...returnedData,
      createIngress: event,
    });
  };

  const handleChangePci = (event: string) => {
    setReturnedData({
      ...returnedData,
      pci: event,
    });
  };

  const handleRWOption = (event: string) => {
    setReturnedData({
      ...returnedData,
      rwSelection: event,
    });
  };

  const handleHashicorpVault = (vaultInfo: { vaultNamespace: string; vaultPath: string }) => {
    setReturnedData({
      ...returnedData,
      vaultNamespace: vaultInfo.vaultNamespace,
      vaultSecretsPath: vaultInfo.vaultPath,
    });
  };

  return (
    <Grid
      container
      spacing={2}
      direction="row"
      style={{ padding: '0px 10px' }}
      justifyContent="flex-start"
      alignItems="flex-start"
    >
      <Grid item md={12}>
        <Typography variant="h4" aria-label="title">
          American Airlines Information
        </Typography>
      </Grid>
      <Grid item md={3}>
        <SquadInfo
          aria-label="squad-name"
          onChange={values => {
            if (values) {
              setReturnedData({
                ...returnedData,
                squad: {
                  name: values.name,
                  id: String(values.id),
                },
              });
            }
          }}
          width="100%"
          required
        />
      </Grid>
      <Grid item md={3}>
        <CmdbAppShortName
          aria-label="short-name"
          onChange={values => {
            setReturnedData({
              ...returnedData,
              shortName: values.shortName,
              archerId: values.archerId,
              cmdbAppFullName: values.cmdbAppFullName,
              costCenter: values.costCenter,
            });
          }}
          width="100%"
          required
        />
      </Grid>
      <Grid item md={12}>
        <Typography variant="h4">Repo Information</Typography>
      </Grid>
      <Grid item md={12}>
        <GithubRepoField
          onChange={value => {
            setReturnedData({
              ...returnedData,
              repoName: value,
            });
          }}
          error={!!error}
        />
      </Grid>
      <br />
      <Grid item md={6}>
        <GithubRepoDescription
          onChange={value => {
            setReturnedData({
              ...returnedData,
              repoDescription: value,
            });
          }}
          error={!!error}
        />
      </Grid>
      <Grid item md={6}>
        <GithubTeamSelect
          formData={formData?.githubTeam ?? ''}
          onChange={(values: any) => {
            setReturnedData({
              ...returnedData,
              githubTeam: values,
            });
          }}
        />
      </Grid>
      <Grid item md={12}>
        <Typography variant="h4">Deployment Information</Typography>
      </Grid>
      <Grid item md={12}>
        <DataClassificationGroup
          value={formData?.pci}
          onChange={handleChangePci}
          // eslint-disable-next-line @typescript-eslint/no-empty-function
          onBlur={() => {}}
          name={formData?.pci ?? ''}
        />
      </Grid>
      <Grid item md={3}>
        <Cluster
          pci={formData?.pci ?? ''}
          value={formData?.cluster ?? ''}
          onChange={values => {
            setReturnedData({
              ...returnedData,
              cluster: values.target.value,
            });
          }}
          // eslint-disable-next-line @typescript-eslint/no-empty-function
          onBlur={() => {}}
          name={formData?.cluster ?? ''}
        />
      </Grid>
      <Grid item md={3}>
        <Namespace
          formData={formData?.namespace ?? ''}
          cluster={formData?.cluster ?? ''}
          onChange={value => {
            setReturnedData({
              ...returnedData,
              namespace: value,
            });
          }}
        />
      </Grid>
      <Grid item md={6}>
        <ContainerNameField
          value={formData?.containerName ?? ''}
          onChange={value => {
            setReturnedData({
              ...returnedData,
              containerName: value,
            });
          }}
        />
      </Grid>
      <Grid item md={3}>
        <ContainerPortField
          value={returnedData.containerPort}
          onChange={value => {
            setReturnedData({
              ...returnedData,
              containerPort: value,
            });
          }}
        />
      </Grid>
      <Grid item container md={4} alignItems="center">
        <CreateIngress value={formData?.createIngress ?? ''} onChange={handleChangeAks} />
      </Grid>
      <Grid item md={4}>
        <MicrogatewayProxy
          value={formData?.trafficPermissionPolicy ?? false}
          onChange={value => {
            setReturnedData({
              ...returnedData,
              trafficPermissionPolicy: value.target.checked,
            });
          }}
        />
      </Grid>
      <Grid item md={12}>
        <Typography variant="h4">Security Information</Typography>
      </Grid>
      <Grid item md={12}>
        <SecurityInfo value={formData?.breakBuild ?? ''} onChange={handleChangeBreakBuild} />
      </Grid>
      <Grid item md={12}>
        <Typography variant="h4">CICD Workflow</Typography>
      </Grid>
      <Grid item md={12}>
        <ReuseableWorkflow value={formData?.rwSelection ?? ''} onChange={handleRWOption} />
      </Grid>
      <Grid item md={12}>
        <Typography variant="h4">Hashicorp Vault</Typography>
      </Grid>
      <Grid item md={12}>
        <HashicorpVault
          vaultNamespace={formData?.vaultNamespace ?? ''}
          vaultPath={formData?.vaultSecretsPath ?? ''}
          onChange={handleHashicorpVault}
          shortName={formData?.shortName}
        />
      </Grid>
    </Grid>
  );
};

export const componentInformationValidation = (value: ReturnedData, validation: FieldValidation) => {
  if (!value.squad.name || !value.squad.id) {
    validation.addError('Please select a squad');
  }
  if (!value.shortName) {
    validation.addError('Please select a short name');
  }
  repoNameValidation(value.repoName, validation);
  repoDescriptionValidation(value.repoDescription, validation);
  githubTeamValidation(value.githubTeam, validation);
  clusterValidation(value.cluster, validation);
  namespaceValidation(value.namespace, validation);
  containerNameValidation(value.containerName, validation);
  containerPortValidation(value.containerPort, validation);
};
