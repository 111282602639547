import { createPlugin, createRoutableExtension } from '@backstage/core-plugin-api';

import { rootRouteRef } from './routes';

export const ezurlshortenerPlugin = createPlugin({
  id: 'ezurlshortener',
  routes: {
    root: rootRouteRef,
  },
});

export const EzurlshortenerPage = ezurlshortenerPlugin.provide(
  createRoutableExtension({
    name: 'EzurlshortenerPage',
    component: () => import('./components/EzUrlsComponent').then(m => m.EZUrlList),
    mountPoint: rootRouteRef,
  }),
);
