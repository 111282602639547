import axios from 'axios';
import { Card, CardContent, CardHeader, Divider, IconButton, makeStyles, Grid } from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import React from 'react';
import { AaAboutContent } from './AboutContent';
import { DeleteComponent } from '../DeleteRepoAndCatalogButton';

import { configApiRef, githubAuthApiRef, useApi } from '@backstage/core-plugin-api';
import { Octokit } from '@octokit/rest';
import gitUrlParse, { GitUrl } from 'git-url-parse';
import { useAsync } from 'react-use';
import { useEntity } from '@backstage/plugin-catalog-react';
import { parseEntityRef } from '@backstage/catalog-model';
import { ManageArgoButton } from '../ManageArgoAppButton/ManageArgoButton';
import { AdminToggled } from '@runway/devkit';

const useStyles = makeStyles({
  gridItemCard: {
    display: 'flex',
    flexDirection: 'column',
    height: 'calc(100% - 10px)', // for pages without content header
    marginBottom: '10px',
  },
  gridItemCardContent: {
    flex: 1,
  },
  cardHeader: {
    marginBottom: '20px',
  },
});

type AboutCardProps = {
  variant?: string;
};

export function AaAboutCard({ variant }: AboutCardProps) {
  const ghecAuthApi = useApi(githubAuthApiRef);
  const configApi = useApi(configApiRef);
  const { entity } = useEntity();

  const classes = useStyles();
  const [isGithubRepoAdmin, setIsGithubRepoAdmin] = React.useState(false);
  const [gitUrlPath, setGitUrlPath] = React.useState<GitUrl>();
  useAsync(async () => {
    const [ghecAccessToken, ghecBackstageIdentity] = await Promise.all([
      ghecAuthApi.getAccessToken(),
      ghecAuthApi.getBackstageIdentity(),
    ]);
    const token = ghecAccessToken;
    if (!ghecBackstageIdentity?.identity.userEntityRef) {
      throw new Error('Unable to read your username. Please re-login to refresh GitHub auth.');
    }
    const { name: username } = parseEntityRef(ghecBackstageIdentity?.identity.userEntityRef);

    const annotation = entity.metadata.annotations?.['github.com/project-slug'] as string;
    const gitUrlParseOut = gitUrlParse(annotation);
    setGitUrlPath(gitUrlParseOut);

    const githubApiUrl = configApi.getString('github.ghec.apiUrl');

    const octokit = new Octokit({
      auth: token,
      baseUrl: githubApiUrl,
    });
    const permissionLevel = await octokit.repos.getCollaboratorPermissionLevel({
      owner: gitUrlParseOut.owner,
      repo: gitUrlParseOut.name,
      username: username as string,
    });
    if (permissionLevel?.data.permission !== 'admin') {
      setIsGithubRepoAdmin(false);
    } else {
      setIsGithubRepoAdmin(true);
    }
  }, [entity]);

  // EXAMPLE: repoPath = "/AAInternal/runway/blob/main/catalog.yaml"
  async function aaYamlUrlCreatorAndOpener(repoPath: string) {
    // Parse out catalog-info.yaml for use to navigate to aa.yaml by chopping off "/catalog.yaml" and add github domain
    let concatUrl = `https://github.com/${repoPath?.replace(`/${gitUrlPath?.filepath}/`, '')}`;

    // Make a request to check for whether or not aa.yaml exists
    let defaultBranch = '';
    const axiosToken = await ghecAuthApi.getAccessToken();
    try {
      const response = await axios.get(`https://api.github.com/repos/${gitUrlPath?.owner}/${gitUrlPath?.name}`, {
        headers: { authorization: `bearer ${axiosToken}` },
      });
      if (response.data) {
        if (response.data.default_branch) {
          defaultBranch = response.data.default_branch;
        } else {
          return;
        }
      }
    } catch (error: any) {
      const response = {
        message: error.message || 'Ooops. Something is broken.',
        details: error.response.data || 'No further details available',
      };
      // eslint-disable-next-line no-console
      console.error(response);
      return;
    }

    if (defaultBranch !== '') {
      try {
        await axios.get(`https://api.github.com/repos/${gitUrlPath?.owner}/${gitUrlPath?.name}/contents/aa.yaml`, {
          headers: { authorization: `bearer ${axiosToken}` },
        });
        concatUrl = concatUrl.replace('/blob', '/edit');
        concatUrl += `/edit/${defaultBranch}/aa.yaml`;
      } catch (error: any) {
        if (error.response.status === 404) {
          concatUrl = concatUrl.replace('/blob', '/new');
          concatUrl += `/new/${defaultBranch}/?filename=aa.yaml`;
        } else {
          const response = {
            message: error.message || 'Ooops. Something is broken.',
            details: error.response.data || 'No further details available',
          };
          // eslint-disable-next-line no-console
          console.error(response);
          return;
        }
      } finally {
        window.open(concatUrl ?? '#', '_blank');
      }
    }
  }

  return (
    <Card className={variant === 'gridItem' ? classes.gridItemCard : ''}>
      <CardHeader
        title="Runway Information"
        className={classes.cardHeader}
        action={
          <IconButton
            aria-label="Edit"
            disabled={!gitUrlPath}
            title="Edit AA Yaml"
            onClick={() => {
              aaYamlUrlCreatorAndOpener(gitUrlPath?.pathname!);
            }}
          >
            <EditIcon />
          </IconButton>
        }
      />
      <Divider />
      <CardContent className={variant === 'gridItem' ? classes.gridItemCardContent : ''}>
        <AaAboutContent entity={entity} />
        <AdminToggled toggleName="maintenance-mode">
          <AdminToggled.HideOnEnabled>
            <Grid container>
              <Grid item>
                <ManageArgoButton entity={entity} isGithubRepoAdmin={isGithubRepoAdmin} />
              </Grid>

              <Grid item>
                <DeleteComponent entity={entity} isGithubRepoAdmin={isGithubRepoAdmin} />
              </Grid>
            </Grid>
          </AdminToggled.HideOnEnabled>
        </AdminToggled>
      </CardContent>
    </Card>
  );
}
