import { createApiRef, IdentityApi } from '@backstage/core-plugin-api';
import axios from 'axios';

export interface ContainerImagePackagesApi {
  getPackageListing(image: any): Promise<PackageListingResult>;
}

export const containerImagePackagesApiRef = createApiRef<ContainerImagePackagesApi>({
  id: 'plugin.plugin-catalog-customizations.container-image-packages.service',
});

interface PackageListingResult {
  data: any;
  page: number;
  totalCount: number;
}

export class ContainerImagePackagesService implements ContainerImagePackagesApi {
  constructor(
    private backendUrl: string,
    private identityApi: IdentityApi,
  ) {}

  getPackageListing = async (image: any): Promise<PackageListingResult> => {
    try {
      const { token } = await this.identityApi.getCredentials();

      return new Promise((resolve, reject) => {
        axios
          .get(
            `${this.backendUrl}/api/catalogCustomizations/imagePackages/${image.image}/${image.digest.replace(':', '-')}`,
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            },
          )
          .then(response => response.data)
          .then(result => {
            resolve({
              data: result,
              page: 1,
              totalCount: result.total,
            });
          })
          .catch(e => {
            if (e.message === 'Request failed with status code 401') {
              reject('Unauthorized');
            }
            reject(e);
          });
      });
    } catch (e) {
      throw new Error(`Error Getting Chainguard Package Data: ${e}`);
    }
  };
}
