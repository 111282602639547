import { Grid, FormControl, FormControlLabel, Typography, Checkbox } from '@material-ui/core';
import React, { useContext } from 'react';
import { Controller } from 'react-hook-form';
import FormContext from '../FormContext';
import { CheckboxProps, ObjectLike } from '../types';
import { toCapital } from '../utils';

/**
 * Use this component to create one checkbox field.
 * @param name your schema field name
 * @param title your schema field title
 * @param size optional size of the component
 * @param label optional label for the checkbox
 * @param options optional checkbox prop options
 */
export function FormCheckbox<T extends ObjectLike>({ name, title, size = 4, label, options = {} }: CheckboxProps<T>) {
  const { ctx } = useContext(FormContext);

  return (
    <Grid item xs={12} sm={12} md={size} style={{ paddingLeft: '30px' }}>
      <FormControl variant="outlined" fullWidth required>
        <Typography aria-label="title" style={{ fontWeight: 500 }}>
          {title || toCapital(name)}
        </Typography>
        <Controller
          name={name}
          control={ctx.control}
          {...{ ...options }}
          render={({ field }) => (
            <FormControlLabel label={label} control={<Checkbox {...field} style={{ alignSelf: 'self-start' }} />} />
          )}
        />
      </FormControl>
    </Grid>
  );
}
