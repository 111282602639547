import { TextField, Tooltip } from '@material-ui/core';
import { useFormikContext } from 'formik';
import React from 'react';
import { useStyles } from './orionFormStyles';
import { OrionAppRequestValues } from './orionFormValuesTypes';

type Props = {
  fieldPropName: keyof OrionAppRequestValues;
  label: string;
  placeholder: string;
  required?: boolean;
  children: React.ReactNode;
  tooltip?: string;
};

type Ref = ((instance: HTMLDivElement | null) => void) | React.RefObject<HTMLDivElement> | null | undefined;

export const OrionFormDropdown = React.forwardRef(
  ({ fieldPropName, label, placeholder, required, children, tooltip }: Props, ref?: Ref) => {
    const classes = useStyles();
    const formik = useFormikContext<OrionAppRequestValues>();

    return (
      <>
        {tooltip ? (
          <Tooltip enterTouchDelay={0} leaveTouchDelay={0} arrow title={<h3>{tooltip}</h3>}>
            <TextField
              ref={ref}
              id={fieldPropName}
              key={fieldPropName}
              data-testid={fieldPropName}
              required={required}
              label={label}
              {...formik.getFieldProps(fieldPropName)}
              placeholder={placeholder}
              variant="outlined"
              select
              error={Boolean(formik.errors[fieldPropName] && formik.touched[fieldPropName])}
              className={classes.componentFormGroup}
            >
              {children}
            </TextField>
          </Tooltip>
        ) : (
          <TextField
            ref={ref}
            id={fieldPropName}
            key={fieldPropName}
            data-testid={fieldPropName}
            required={required}
            label={label}
            {...formik.getFieldProps(fieldPropName)}
            placeholder={placeholder}
            variant="outlined"
            select
            error={Boolean(formik.errors[fieldPropName] && formik.touched[fieldPropName])}
            className={classes.componentFormGroup}
          >
            {children}
          </TextField>
        )}
      </>
    );
  },
);
