import * as Yup from 'yup';

export const orionAppRequestSchema = Yup.object().shape({
  ADX: Yup.boolean().required(),
  AEH: Yup.boolean().required(),
  MOS: Yup.boolean().required(),
  ASTRA: Yup.boolean().required(),
  location: Yup.string().required('Required'),
  environment: Yup.string().required('Required'),
  prefix: Yup.string().required('Required'),
  squadPrimary: Yup.string()
    .matches(/^[a-z0-9]{6}$/g, 'Must contain exactly 6 numbers')
    .required('Required'),
  squadSecondary: Yup.string()
    .matches(/^[a-z0-9]{6}$/g, 'Must contain exactly 6 numbers')
    .required('Required'),
  dataOwner: Yup.string()
    .matches(/^[a-z0-9]{6}$/g, 'Must contain exactly 6 numbers')
    .required('Required'),
  cherwellTeam: Yup.string()
    .matches(/^(?!\s*$).+/, 'Must be valid name.')
    .required('Required'),
  delivTeamEmail: Yup.string()
    .matches(/.*\@aa\.com/, 'Must be a valid email address ending in @aa.com')
    .required('Required'),
  estimatedBudget: Yup.string()
    .matches(/^[1-9]\d*(((,\d{3}){1})?(\.\d{0,2})?)$/, 'Must be a valid dollar amount')
    .required('Required'),
  appName: Yup.string()
    .matches(/^[a-z0-9]{2,30}$/g, 'App name can not be left blank.')
    .required('Required'),
  archerShortname: Yup.string().matches(/^(?!\s*$).+/, 'Team name can not be left blank.'),
  adxPurpose: Yup.string().when('ADX', {
    is: true,
    then: () => Yup.string().required(),
  }),
  adxHotCache: Yup.string()
    .matches(/^[0-9]+$/, 'Must be valid name.')
    .when('ADX', {
      is: true,
      then: () => Yup.string().required(),
    }),
  adxSoftDelete: Yup.string()
    .matches(/^[0-9]+$/, 'Must be valid name.')
    .when('ADX', {
      is: true,
      then: () => Yup.string().required(),
    }),
  productGroup: Yup.string()
    .matches(/^[a-zA-Z0-9. ]+$/, 'Must be valid name.')
    .required('Required'),
  componentEmpty: Yup.string().when(['ADX', 'AEH', 'MOS', 'ASTRA'], {
    is: false,
    then: () => Yup.string().required(),
  }),
  dataZonePrep: Yup.boolean().required(),
  dataZonePkg: Yup.boolean().required(),
  dataZoneEmpty: Yup.string().when('MOS', {
    is: true,
    then: () =>
      Yup.string().when(['dataZonePrep', 'dataZonePkg'], {
        is: false,
        then: () => Yup.string().required(),
      }),
  }),
  mosDeployType: Yup.string().when('MOS', {
    is: true,
    then: () => Yup.string().required(),
  }),
  saFunctionalId: Yup.string().when(['MOS', 'mosDeployType'], {
    is: (MOS: boolean, mosDeployType: string) => MOS === true && mosDeployType === 'ETLMOD',
    then: () => Yup.string().required(),
  }),
  astraKeyspacePurpose: Yup.string().when('ASTRA', {
    is: true,
    then: () => Yup.string().required(),
  }),
  astraCriticalApp: Yup.string().when('ASTRA', {
    is: true,
    then: () => Yup.string().required(),
  }),
});
