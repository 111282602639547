import { IdentityApi, createApiRef } from '@backstage/core-plugin-api';
import { RestClientApi, RestClientRequestConfig } from '@runway/devkit-common';

export type CloudAccess = {
  success: boolean;
};

export interface CloudElevatedAccessServiceApi {
  elevateAccess(
    employeeId: string,
    subscriptionId: string,
    resourceGroup: string,
    role: string,
    businessJustification: string,
  ): Promise<CloudAccess>;
  elevateAccessV2(
    subscriptionId: string,
    resourceGroup: string,
    role: string,
    itsmRecordType: string,
    incidentNumber: string | null,
    businessJustification: string,
  ): Promise<CloudAccess>;
  getResourceGroups(subscriptionId: string): Promise<string[]>;
  validateHasReaderPlus(subId: string, resourceG: string): Promise<validateHasReaderPlusResp>;
}
export interface validateHasReaderPlusResp {
  aaReaderPlus: boolean;
}

export const cloudElevatedAccessServiceApiRef = createApiRef<CloudElevatedAccessServiceApi>({
  id: 'plugin.access-control.cloud-elevated-access.service',
});

export default class CloudElevatedAccessService implements CloudElevatedAccessServiceApi {
  constructor(
    private backendUrl: string,
    private identityApi: IdentityApi,
    private restClient: RestClientApi,
  ) {}

  elevateAccess = async (
    employeeId: string,
    subscriptionId: string,
    resourceGroup: string,
    role: string,
    businessJustification: string,
  ) => {
    const { token } = await this.identityApi.getCredentials();
    const payload = {
      employeeId: employeeId,
      subscriptionId: subscriptionId,
      resourceGroup: resourceGroup,
      role: role,
      businessJustification: businessJustification,
    };

    const headers = {
      Authorization: `Bearer ${token}`,
    };

    const options: RestClientRequestConfig = {
      method: 'POST',
      url: `${this.backendUrl}/api/cloudElevatedAccess/azureaccess`,
      headers: headers,
      data: payload,
    };

    const res = await this.restClient.request(options);

    if (res.status !== 200) {
      throw new Error();
    }

    return res.data;
  };

  elevateAccessV2 = async (
    subscriptionId: string,
    resourceGroup: string,
    role: string,
    itsmRecordType: string,
    incidentNumber: string | null,
    businessJustification: string,
  ) => {
    const { token } = await this.identityApi.getCredentials();
    const payload = {
      subscriptionId,
      resourceGroup,
      role,
      itsmRecordType,
      incidentNumber,
      businessJustification,
    };

    const headers = {
      Authorization: `Bearer ${token}`,
    };

    const options: RestClientRequestConfig = {
      method: 'POST',
      url: `${this.backendUrl}/api/cloudElevatedAccess/azureaccess/V2`,
      headers: headers,
      data: payload,
    };

    const res = await this.restClient.request(options);

    if (res.status !== 200) {
      throw new Error();
    }
    return res.data;
  };

  getResourceGroups = async (subscriptionId: string) => {
    const { token } = await this.identityApi.getCredentials();
    const headers = {
      Authorization: `Bearer ${token}`,
    };

    const options: RestClientRequestConfig = {
      method: 'GET',
      url: `${this.backendUrl}/api/cloudElevatedAccess/validrg/${subscriptionId}`,
      headers: headers,
    };

    const res = await this.restClient.request(options);

    if (res.status !== 200) {
      throw new Error();
    }

    return res.data;
  };

  validateHasReaderPlus = async (subscriptionId: string, resourceGroup: string): Promise<validateHasReaderPlusResp> => {
    const { token } = await this.identityApi.getCredentials();
    const payload = {
      subscriptionId: subscriptionId,
      resourceGroup: resourceGroup,
    };

    const options: RestClientRequestConfig = {
      method: 'POST',
      url: `${this.backendUrl}/api/cloudElevatedAccess/azureaccess/v1/aareaderplus`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data: payload,
    };

    const res = await this.restClient.request(options);

    if (res.status !== 200) {
      throw new Error('unable to validate user has the aa reader + role');
    }

    return res.data;
  };
}
