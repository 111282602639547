import { Checkbox, FormControlLabel, Tooltip } from '@material-ui/core';
import { useFormikContext } from 'formik';
import React from 'react';
import { OrionAppRequestValues } from './orionFormValuesTypes';

type Props = {
  fieldPropName: keyof OrionAppRequestValues;
  label: string;
  tooltip?: string;
  disabled?: boolean;
};

export const OrionFormCheckbox = ({ fieldPropName, label, tooltip, disabled }: Props) => {
  const formik = useFormikContext<OrionAppRequestValues>();
  return (
    <>
      {tooltip ? (
        <FormControlLabel
          control={
            <Tooltip disableFocusListener arrow title={<h3>{tooltip}</h3>}>
              <Checkbox
                {...formik.getFieldProps(fieldPropName)}
                name={fieldPropName}
                color="primary"
                disabled={disabled}
              />
            </Tooltip>
          }
          label={label}
        />
      ) : (
        <FormControlLabel
          control={<Checkbox {...formik.getFieldProps(fieldPropName)} name={fieldPropName} color="primary" />}
          label={label}
        />
      )}
    </>
  );
};
