import axios from 'axios';
import { createApiRef } from '@backstage/core-plugin-api';
import { OAuth2AAPingSSOApi } from '@runway/devkit';

export type VmServiceResponse = {
  'Number of vCPU': string;
  'Memory in GB': string;
  'Server Name': string;
  'Total space in GB': string;
  DataCenter: string;
  'Power State': string;
  'Guest OS': string;
  'Server UpTime': string;
  'Application Name': string;
  'Application Cost Center': string;
  Criticality: string;
  'SnapShot Information': string[];
  'IpAddress (Green And Grey)': string[];
  'vCenter URL': string;
};

export interface VmServiceApi {
  getVmInfo(vmName: string): Promise<VmServiceResponse>;
}

export const vmServiceApiRef = createApiRef<VmServiceApi>({
  id: 'plugin.infrastructure.vminfo.service',
});

export default class VmService implements VmServiceApi {
  constructor(
    private oAuthApi: OAuth2AAPingSSOApi,
    private backendUrl: string,
  ) {}

  getVmInfo = async (vmName: string) => {
    const token = await this.oAuthApi.getAccessToken();

    const res = await axios.get(`${this.backendUrl}/findmyserver`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      params: { servername: vmName },
    });

    return res.data;
  };
}
