import { STATUS } from '../../Status';

interface HpaResource {
  metadata: { name: string };
  status: { currentReplicas: number; desiredReplicas: number };
}

export interface HpasMetadata {
  name?: string | undefined;
  status: STATUS;
  currentReplicas?: number | undefined;
  desiredReplicas?: number | undefined;
}
export type Hpas = Record<string, HpasMetadata[]>;

const combineHpaStatus = (hpa: any) => {
  if (!hpa.status.currentReplicas) return STATUS.unhealthy;
  if (hpa.status.currentReplicas === 0) return STATUS.unhealthy;
  if (hpa.status.currentReplicas === hpa.status.desiredReplicas) return STATUS.healthy;
  return STATUS.partiallyHealthy;
};

export const getHpaStatus = (item: any): HpasMetadata[] => {
  const hpaObject = item.resources?.filter((resource: { type: string }) => {
    return resource.type === 'horizontalpodautoscalers';
  })[0];

  if (!hpaObject) return [{ status: STATUS.unretrievable, currentReplicas: undefined, desiredReplicas: undefined }];
  const hpas = hpaObject.resources as HpaResource[];

  if (!hpas || hpas.length === 0)
    return [{ status: STATUS.unretrievable, currentReplicas: undefined, desiredReplicas: undefined }];

  return hpas.map((hpa: HpaResource) => {
    return {
      name: hpa.metadata.name,
      status: combineHpaStatus(hpa),
      currentReplicas: hpa.status.currentReplicas,
      desiredReplicas: hpa.status.desiredReplicas,
    };
  });
};
