import axios from 'axios';
import { IdentityApi, createApiRef } from '@backstage/core-plugin-api';
import { OAuth2AAPingSSOApi } from '@runway/devkit';
import { validateChangeNumResp } from '@runway/devkit-backend';

export type VmDecomServiceResponse = {
  dcname: string;
  hostname: string;
  networks: string;
  osinfo: string;
  powerstate: string;
  ipAddresses: string[];
  fromVra: boolean;
  success: boolean;
  ansibleJobId: string;
  dynatraceDisabled_NONPROD: string;
  dynatraceDisabled_PROD: string;
};

export interface VmDecomServiceApi {
  getVmDecom(
    vmname: string,
    appname: string,
    envname: string,
    description: string,
    dataOnServer: boolean,
    isBeingMigrated: boolean,
    dataMigratedTo: string,
    crNumber?: string,
  ): Promise<VmDecomServiceResponse>;

  cancelVmDecom(requestId: string, cancelReason: string): Promise<void>;

  upgradeVmWareTools(vmname: string): Promise<void>;

  validateChangeNum(changeId: number): Promise<validateChangeNumResp>;
}

export const vmDecomServiceApiRef = createApiRef<VmDecomServiceApi>({
  id: 'plugin.infrastructure.vmdecom.service',
});

export default class VmDecomService implements VmDecomServiceApi {
  constructor(
    private oAuthApi: OAuth2AAPingSSOApi,
    private identityApi: IdentityApi,
    private backendUrl: string,
    private baseBackendUrl: string,
  ) {}

  getVmDecom = async (
    vmname: string,
    appname: string,
    envname: string,
    description: string,
    dataOnServer: boolean,
    isBeingMigrated: boolean,
    dataMigratedTo: string,
    crNumber?: string,
  ) => {
    const token = await this.oAuthApi.getAccessToken();
    const res = await axios.post(
      `${this.backendUrl}/decom`,
      {
        serverName: vmname,
        archerName: appname,
        environment: envname,
        description: description,
        dataOnServer: dataOnServer,
        isBeingMigrated: isBeingMigrated,
        dataMigratedTo: dataMigratedTo,
        crNumber: crNumber,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      },
    );
    return res.data;
  };

  cancelVmDecom = async (requestId: string, cancelReason: string) => {
    const token = await this.oAuthApi.getAccessToken();
    await axios.patch(
      `${this.backendUrl}/decom/${requestId}/cancel`,
      {
        reason: cancelReason,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      },
    );
  };

  upgradeVmWareTools = async (vmname: string) => {
    const token = await this.oAuthApi.getAccessToken();
    await axios.post(
      `${this.backendUrl}/manage/vm/upgradevmwaretools`,
      {
        hostname: vmname,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      },
    );
  };

  validateChangeNum = async (changeId: number): Promise<validateChangeNumResp> => {
    const { token } = await this.identityApi.getCredentials();
    const response = await axios.get(`${this.baseBackendUrl}/api/cloudElevatedAccess/validateChangeNum/${changeId}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  };
}
