import { Grid, makeStyles, Typography } from '@material-ui/core';
import React from 'react';
import { paasAdminItemsFieldsDefaultValues } from './paasAdmin';
import { KeyVaultItemsField } from './paasComponents/keyVault';
import { RedisCache } from './paasComponents/redisCache';
import { StorageAccount } from './paasComponents/storageAccount';
import { PaasFormExtensionProps, paasItemsFieldDefaultValues, paasSpRgItemsFieldsDefaultValues } from './types';
import { AdminToggled } from '../../../components';
import { AMS_MAINTENANCE_MODE } from '../../../featureFlags';
import Alert from '@material-ui/lab/Alert';

const useStyles = makeStyles(() => ({
  root: {
    maxWidth: 500,
    marginBottom: 10,
    padding: 10,
  },
  container: {
    marginBottom: 2,
  },
}));

export const PaasItemsField = (props: PaasFormExtensionProps) => {
  const classes = useStyles();
  if (!props.formData) {
    props.formData = {
      paas: true,
      ...paasAdminItemsFieldsDefaultValues,
      ...paasSpRgItemsFieldsDefaultValues,
      ...paasItemsFieldDefaultValues,
    };
  }
  const { onChange, formData } = props;
  return (
    <AdminToggled toggleName={AMS_MAINTENANCE_MODE}>
      <Grid container spacing={3} className={classes.root}>
        <Grid item xs={12} className={classes.container}>
          <Typography variant="body1">Certified PaaS Options</Typography>
        </Grid>
        <AdminToggled.ShowOnEnabled>
          <Alert severity="warning">
            This feature is currently disabled due to maintenance. Please see{' '}
            <a target="_blank" href="https://americanairlines.slack.com/archives/C0112F1JKNG">
              #runway
            </a>{' '}
            for updates.
          </Alert>
        </AdminToggled.ShowOnEnabled>
        <AdminToggled.HideOnEnabled>
          {/* <Grid item xs={12} className={classes.container}>
            <PostgresFlexibleServerItemsField onChange={onChange} formData={formData!} />
          </Grid> */}
          <Grid item xs={12} className={classes.container}>
            <KeyVaultItemsField onChange={onChange} formData={formData} />
          </Grid>
          <Grid item xs={12} className={classes.container}>
            <StorageAccount onChange={onChange} formData={formData} />
          </Grid>
          <Grid item xs={12} className={classes.container}>
            <RedisCache onChange={onChange} formData={formData} />
          </Grid>
        </AdminToggled.HideOnEnabled>
      </Grid>
    </AdminToggled>
  );
};
