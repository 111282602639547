import * as Yup from 'yup';

export const amlCCFormValidationSchema = Yup.object().shape({
  clusterName: Yup.string().required(),
  subscription: Yup.string().required(),
  resourceGroup: Yup.string().required(),
  amlWorkspace: Yup.string().required(),
  maxNodes: Yup.string().required(),
  vmSKU: Yup.string().required(),
  clusterAvailability: Yup.string().required(),
});
