import {
  Button,
  CircularProgress,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  TextField,
  Tooltip,
  Typography,
  Grid,
  Collapse,
  IconButton,
} from '@material-ui/core';
import { useTheme, makeStyles, Theme, createStyles, withStyles } from '@material-ui/core/styles';
import axios from 'axios';
import React, { Ref, useState } from 'react';
import * as yup from 'yup';
import { Controller, ControllerRenderProps, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { ProfileInfo, githubAuthApiRef, identityApiRef, useApi } from '@backstage/core-plugin-api';
import { Alert } from '@material-ui/lab';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import { BucketId, ClientId, PluginId } from '@runway/devkit-common';
import InfoIcon from '@material-ui/icons/Info';
import { useTrackedRequest } from '../../../hooks';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      backgroundColor: theme.palette.background.default,
      borderRadius: 4,
      padding: theme.spacing(3),
      border: `1px solid ${theme.palette.primary.light}`,
      minHeight: '150px',
    },
    cancelAndCreate: {
      margin: '2px',
    },
    center: {
      display: 'flex',
      justifyContent: 'center',
    },
    radioGroup: {
      flexDirection: 'row',
    },
  }),
);

const NOTIFICATION_OPTIONS = [
  { name: 'Disabled', id: 'notifications_disabled' },
  { name: 'Enabled', id: 'notifications_enabled' },
];

const VISIBILITY_OPTIONS = [
  { name: 'Closed', id: 'closed' },
  { name: 'Secret', id: 'secret' },
];

const Schema = yup.object({
  teamName: yup
    .string()
    .required()
    .matches(
      /^(?![-.])[a-zA-Z0-9-. ]{1,255}(?<![.])$/g,
      "Invalid team name. Please adhere to GitHub's naming conventions.",
    ),
  description: yup.string(),
  visibility: yup
    .string()
    .oneOf(VISIBILITY_OPTIONS.map(o => o.id))
    .required(),
  notificationSetting: yup
    .string()
    .oneOf(NOTIFICATION_OPTIONS.map(o => o.id))
    .required(),
});

type FormType = yup.InferType<typeof Schema>;

enum FormFields {
  TEAM_NAME = 'teamName',
  DESCRIPTION = 'description',
  VISIBILITY = 'visibility',
  NOTIFICATION_SETTING = 'notificationSetting',
}

enum State {
  INIT,
  LOADING,
  SUCCESS,
  ERROR,
}

type CurrentStateType = {
  state: State;
  message?: string;
  teamData?: { name: string; url: string };
};

type OptionsType = {
  name: string;
  id: string;
};

type RadioGroupProps = {
  label: string;
  title: string;
  options: OptionsType[];
  tooltip: string;
};

type PayloadType = {
  org: string;
  name: string;
  notification_setting: string;
  privacy: string;
  description?: string;
};

type CreateGitHubTeamFormProps = {
  currentState: CurrentStateType;
  handleToggle: () => void;
  submit: (data: FormType) => void;
};

const defaultValues = {
  teamName: '',
  description: '',
  visibility: '',
  notificationSetting: '',
};

const NoPaddingTooltip = withStyles({
  tooltip: {
    padding: 0,
    margin: 5,
  },
})(Tooltip);

const GenericRadioGroup = React.forwardRef((props: ControllerRenderProps & RadioGroupProps, _: Ref<unknown>) => {
  const { label, title, options, tooltip, ...other } = props;

  const classes = useStyles();
  const theme = useTheme();

  return (
    <Grid container spacing={2} direction="row" justifyContent="flex-start" alignItems="center">
      <Typography
        aria-label={`${label}-title`}
        style={{ color: theme.palette.text.secondary, padding: '0px 10px', fontWeight: 500 }}
      >
        {title}
      </Typography>
      <NoPaddingTooltip title={<Alert severity="info">{tooltip}</Alert>}>
        <IconButton color="primary" size="small" aria-label="info-button">
          <InfoIcon aria-label={`info-icon-${label}`} />
        </IconButton>
      </NoPaddingTooltip>
      <Grid item md={1} />
      <RadioGroup className={classes.radioGroup} aria-label={`${label}-options`} {...other}>
        {options.map((option: OptionsType) => (
          <FormControlLabel
            key={option.id}
            value={option.id}
            aria-label={`radio-btn-${option.id}`}
            control={<Radio />}
            label={option.name}
          />
        ))}
      </RadioGroup>
    </Grid>
  );
});

const DescriptionTextField = React.forwardRef((props: ControllerRenderProps, _: Ref<unknown>) => (
  <TextField aria-label="description-textfield" label="Description" id="description" fullWidth required {...props} />
));

const TeamNameTextField = React.forwardRef(
  (props: ControllerRenderProps & { error: boolean; helperText: string }, _: Ref<unknown>) => (
    <TextField
      {...props}
      aria-label="team-textfield"
      label="Team Name"
      id="team-name"
      name="team name"
      fullWidth
      required
    />
  ),
);

const CreateGitHubTeamForm = ({ currentState, handleToggle, submit }: CreateGitHubTeamFormProps) => {
  const { handleSubmit, control, formState } = useForm<FormType>({
    defaultValues,
    mode: 'onChange',
    shouldUnregister: true,
    resolver: yupResolver(Schema),
  });

  const classes = useStyles();

  return (
    <Grid
      container
      spacing={2}
      direction="row"
      justifyContent="space-around"
      alignItems="center"
      style={{ padding: '6px' }}
    >
      <Grid item md={12}>
        {currentState.state === State.ERROR && (
          <Alert severity="error" aria-label="error-message">
            {currentState.message}
          </Alert>
        )}
      </Grid>
      <Grid item md={6}>
        <FormControl variant="outlined" fullWidth required>
          <Controller
            name={FormFields.TEAM_NAME}
            control={control}
            render={({ field, fieldState: { error } }) => (
              <TeamNameTextField error={!!error} helperText={error?.message ? error.message : ''} {...field} />
            )}
          />
        </FormControl>
      </Grid>
      <Grid item md={6}>
        <FormControl variant="outlined" fullWidth>
          <Controller
            name={FormFields.DESCRIPTION}
            control={control}
            render={({ field }) => <DescriptionTextField {...field} />}
          />
        </FormControl>
      </Grid>
      <Grid item xs={9} />
      <Grid item md={6}>
        <FormControl variant="outlined" fullWidth required>
          <Controller
            name={FormFields.VISIBILITY}
            control={control}
            render={({ field }) => (
              <GenericRadioGroup
                title="Team Visibility"
                label="visibility"
                options={VISIBILITY_OPTIONS}
                tooltip="A 'Closed' team can be seen and @mentioned by every member of this org. A 'Secret' team can only be seen by its members and may not be nested"
                {...field}
              />
            )}
          />
        </FormControl>
      </Grid>
      <Grid item md={6}>
        <FormControl variant="outlined" fullWidth required>
          <Controller
            name={FormFields.NOTIFICATION_SETTING}
            control={control}
            render={({ field }) => (
              <GenericRadioGroup
                title="Team Notifications"
                label="teamnotifications"
                options={NOTIFICATION_OPTIONS}
                tooltip="Enabling notifications allows for all users on the team to be notified when the team is @mentioned"
                {...field}
              />
            )}
          />
        </FormControl>
      </Grid>
      <Grid item xs={9} />
      <Grid item xs={3} className={classes.center}>
        <Button
          onClick={handleToggle}
          aria-label="btn-close-form"
          className={classes.cancelAndCreate}
          variant="outlined"
        >
          CANCEL
        </Button>
        <Button
          variant="outlined"
          onClick={handleSubmit(submit)}
          aria-label="btn-submit-form"
          disabled={currentState.state === State.LOADING || !formState.isValid}
          className={classes.cancelAndCreate}
        >
          CREATE
        </Button>
      </Grid>
    </Grid>
  );
};

export const CreateGitHubTeam = () => {
  const ghecAuthApi = useApi(githubAuthApiRef);
  const identityApi = useApi(identityApiRef);

  const { tracker } = useTrackedRequest({
    bucket_id: BucketId.USE_METRIC,
    client_id: ClientId.RUNWAY_FRONTEND,
    pluginId: PluginId.TEMPLATE_CREATE_GITHUB_TEAM.id,
  });

  const theme = useTheme();
  const classes = useStyles();

  const [currentState, setCurrentState] = useState<CurrentStateType>({ state: State.INIT });
  const [isOpen, setIsOpen] = useState(false);

  const handleToggle = () => setIsOpen(prevIsOpen => !prevIsOpen);

  const submit = async (data: FormType) => {
    setCurrentState({ state: State.LOADING });

    const payload: PayloadType = {
      org: 'AAInternal',
      name: data.teamName,
      notification_setting: data.notificationSetting,
      privacy: data.visibility,
      description: data.description || '',
    };

    try {
      const identity = (await identityApi.getProfileInfo()) as unknown as ProfileInfo & { aaId: string };
      const response = await tracker(
        async () => {
          const token = await ghecAuthApi.getAccessToken();
          return await axios.post('https://api.github.com/orgs/AAInternal/teams', payload, {
            headers: {
              Authorization: `token ${token}`,
              Accept: 'application/vnd.github.v3+json',
              'Content-Type': 'application/json',
            },
          });
        },
        {
          userEmployeeId: identity?.aaId ? identity.aaId : '-1',
          notificationSetting: data.notificationSetting,
          description: data.description || '',
          privacy: data.visibility,
          teamName: data.teamName,
        },
      );
      setCurrentState({
        state: State.SUCCESS,
        message: 'GitHub Team successfully created!',
        teamData: { url: response.data.html_url, name: data.teamName },
      });
      handleToggle();
    } catch (error: any) {
      setCurrentState({ state: State.ERROR, message: error.message });
    }
  };

  return (
    <div className={classes.container}>
      <Grid container spacing={2} direction="row" justifyContent="space-around" alignItems="flex-end">
        <Grid md={10} item>
          <Typography variant="h6" aria-label="title">
            Create GitHub Team
          </Typography>
          <Typography variant="subtitle2" aria-label="description">
            A GitHub Team is a group of GitHub users who collaborate on projects and repositories within an organization
            on GitHub. It allows you to organize users into teams based on their roles, responsibilities, or project
            requirements.
          </Typography>
        </Grid>
        <Grid md={2} item className={classes.center}>
          {!isOpen && currentState.state !== State.SUCCESS && currentState.state !== State.LOADING && (
            <Button
              variant="contained"
              onClick={handleToggle}
              aria-label="btn-open-form"
              style={{ marginBottom: '15px' }}
            >
              CREATE
            </Button>
          )}
          {!isOpen && currentState.state === State.SUCCESS && (
            <FontAwesomeIcon
              size="3x"
              icon={faCheck}
              aria-label="success-icon"
              style={{ color: theme.palette.success.main, marginBottom: '15px' }}
            />
          )}
          {currentState.state === State.LOADING && <CircularProgress aria-label="submit-spinner" />}
        </Grid>
        {!isOpen && currentState.state === State.SUCCESS && currentState.teamData && (
          <Grid md={12} item>
            <Alert severity="success" aria-label="success-message">
              <b>{currentState.message}</b>{' '}
              <a href={currentState.teamData.url} target="_blank">
                <b style={{ color: theme.palette.info.dark }}>@{currentState.teamData.name}</b>
              </a>
            </Alert>
          </Grid>
        )}
        <Collapse in={isOpen} timeout="auto" unmountOnExit>
          {isOpen && <CreateGitHubTeamForm currentState={currentState} handleToggle={handleToggle} submit={submit} />}
        </Collapse>
      </Grid>
    </div>
  );
};
