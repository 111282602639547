import { OrionAppRequestValues } from './orionFormValuesTypes';

export const orionAppInitializeValues = ({
  archerShortname,
  costCenter,
  squadName,
  appName,
}: {
  archerShortname: string;
  costCenter: string;
  squadName: string;
  appName: string;
}): OrionAppRequestValues => ({
  ADX: false,
  AEH: false,
  MOS: false,
  ASTRA: false,
  archerShortname,
  costCenter,
  squadName,
  appName,
  squadPrimary: '',
  squadSecondary: '',
  dataOwner: '',
  cherwellTeam: '',
  prefix: '',
  environment: '',
  adxPurpose: '',
  adxHotCache: '',
  adxSoftDelete: '',
  location: '',
  delivTeamEmail: '',
  product: '',
  productGroup: '',
  componentEmpty: '',
  estimatedBudget: '',
  aaEmail: '',
  aaId: '',
  dataZonePkg: false,
  dataZonePrep: false,
  mosDeployType: '',
  saFunctionalId: '',
  dataZoneEmpty: '',
  astraKeyspacePurpose: '',
  astraCriticalApp: 'N',
  astraAppendName: '',
  astraCustomNameReq: false,
});
