import React, { useRef } from 'react';
import { Formik, Form, FormikProps } from 'formik';
import { Alert } from '@material-ui/lab';
import Backdrop from '@material-ui/core/Backdrop';
import Link from '@material-ui/core/Link';
import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import { useStyles } from '../OrionRequestForm/orionFormStyles';
import { amlKVFormValueTypes } from './amlKVFormValueTypes';
import { amlKVFormValidationSchema } from './amlKVFormValidationSchema';
import { ResourceGroupDropdown } from '../ResourceGroupDropdown/ResourceGroupDropdown';
import { SubscriptionDropdown } from '../SubscriptionDropdown/SubscriptionDropdown';

type AMLKVFormProps = {
  initialValues: amlKVFormValueTypes;
  onSubmit: (values: amlKVFormValueTypes) => Promise<void>;
  loading: boolean;
  success: boolean;
  error?: string;
};

export const AMLKVForm = ({ initialValues, onSubmit, loading, success, error }: AMLKVFormProps) => {
  const classes = useStyles();
  const formRef = useRef<FormikProps<amlKVFormValueTypes>>(null);
  // const [isLoading, setIsLoading] = useState(false);
  const refUrl = 'https://github.com/AAInternal/orion-platform-api/actions/workflows/orchestrator_model.yaml';
  return (
    <Formik
      initialValues={initialValues}
      onSubmit={async values => {
        await onSubmit(values);
      }}
      validationSchema={amlKVFormValidationSchema}
      innerRef={formRef}
    >
      {formik => (
        <>
          <Form className={classes.flexColumn}>
            <Typography variant="h3">AZ Key Vault Request</Typography>
            <br />
            <SubscriptionDropdown
              onChange={async (values: any) => {
                formik.setFieldValue('subscription', values.subscription);
              }}
              onBlur={() => formik.setTouched({ subscription: true })}
              required
            />
            <ResourceGroupDropdown
              onChange={async (values: any) => {
                formik.setFieldValue('resourceGroup', values.resourceGroup);
              }}
              onBlur={() => formik.setTouched({ resourceGroup: true })}
              disabled={formik.values.subscription.length === 0}
              required
              subscriptionId={formik.values.subscription}
            />
            <div className={classes.flexColumn}>
              <div className={classes.flexRow}>
                <Button
                  type="submit"
                  variant={loading ? 'text' : 'contained'}
                  color="primary"
                  className={classes.button}
                  disabled={loading || !formik.isValid || !formik.dirty || success}
                >
                  Submit
                </Button>
              </div>
              {(!!success || !!error) && (
                <div>
                  {success && (
                    <Alert
                      aria-label="success"
                      severity="success"
                      style={{ marginBottom: 10, marginTop: 10 }}
                      data-testid="submitSuccessMsg"
                    >
                      Key Vault provisioning has started.{' '}
                      <Link href={refUrl} rel="noopener noreferrer" target="_blank">
                        You can track its progress here.
                      </Link>
                    </Alert>
                  )}
                  {error && (
                    <Alert
                      aria-label="error"
                      severity="error"
                      style={{ marginBottom: 10 }}
                      data-testid="submitErrorMsg"
                    >
                      {error}
                    </Alert>
                  )}
                </div>
              )}
              {!formik.isValid && !formik.errors && (
                <Alert severity="error" style={{ marginTop: 10, marginBottom: 8 }} data-testid="formInvalidMsg">
                  You have missed some inputs, please double check previous values
                </Alert>
              )}
            </div>
          </Form>
          <Backdrop aria-label="progress" className={classes.backdrop} open={loading}>
            <CircularProgress color="inherit" />
          </Backdrop>
        </>
      )}
    </Formik>
  );
};
