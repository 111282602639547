import { amlCCFormValueTypes } from './amlCCFormValueTypes';

export const amlCCFormInitializeValues = ({
  clusterName,
  subscription,
  resourceGroup,
  amlWorkspace,
  vmSKU,
}: {
  clusterName: string;
  subscription: string;
  resourceGroup: string;
  amlWorkspace: string;
  maxNodes: string;
  vmSKU: string;
  clusterAvailability: string;
}): amlCCFormValueTypes => ({
  clusterName,
  subscription,
  resourceGroup,
  amlWorkspace,
  maxNodes: '1',
  vmSKU,
  clusterAvailability: 'low_priority',
});
