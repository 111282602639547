import axios from 'axios';
import { createApiRef } from '@backstage/core-plugin-api';
import { OAuth2AAPingSSOApi } from '@runway/devkit';

export type RebootServerServiceResponse = {
  'Number of CPU': string;
  'Memory in GB': string;
  'Server Name': string;
  'Total space in GB': string;
  DataCenter: string;
  'Power State': string;
  'Guest OS': string;
  'Server UpTime': string;
  'Application Name': string;
  'Application Cost Center': string;
  Criticality: string;
  'SnapShot Information': string[];
  'IpAddress (Green And Grey)': string[];
  'vCenter URL': string;
};

export interface RebootServerServiceApi {
  getRebootServer(vmNames: string[], rebootTime?: Date, crNumber?: string): Promise<RebootServerServiceResponse>;
}

export const rebootServerServiceApiRef = createApiRef<RebootServerServiceApi>({
  id: 'plugin.infrastructure.rebootserver.service',
});

export default class RebootServerService implements RebootServerServiceApi {
  constructor(
    private oAuthApi: OAuth2AAPingSSOApi,
    private backendUrl: string,
  ) {}

  getRebootServer = async (vmNames: string[], rebootTime?: Date, crNumber?: string) => {
    const token = await this.oAuthApi.getAccessToken();
    const Application_JSON = 'application/json';
    const res = await axios.patch(
      `${this.backendUrl}/manage/vm/reboot`,
      {
        hostNames: vmNames,
        ...(rebootTime ? { rebootTime: rebootTime.toISOString() } : {}),
        cherwellChangeId: crNumber,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
          Accept: Application_JSON,
          'Content-Type': Application_JSON,
        },
      },
    );
    return res.data;
  };
}
