import { createPlugin, createRoutableExtension } from '@backstage/core-plugin-api';

import { rootRouteRef } from './routes';

export const DiffPlugin = createPlugin({
  id: 'diff-tool',
  routes: {
    root: rootRouteRef,
  },
});

export const DiffPage = DiffPlugin.provide(
  createRoutableExtension({
    name: 'DiffPage',
    component: () => import('./components/PluginHome').then(m => m.PluginHome),
    mountPoint: rootRouteRef,
  }),
);
