export const sampleEntity = {
  metadata: {
    annotations: {
      'backstage.io/managed-by-location': 'https://github.com/AAInternal/runway-software-templates/tree/add-fastapi',
      'backstage.io/managed-by-origin-location': 'file:/Users/testuesr/dev/work/runway-fastapi-k8s/template.yaml',
      'backstage.io/source-location': 'https://github.com/AAInternal/runway-software-templates/tree/add-fastapi',
      'backstage.io/view-url':
        'https://github.com/AAInternal/runway-software-templates/tree/main/fastapi/template.yaml',
    },
    description: 'runway-fastapi-k8s',
    etag: '11d46fac81abb86a573b585b532c5182dfe389a4',
    name: 'runway-fastapi-k8s',
    namespace: 'default',
    tags: ['javascript', 'react', 'kubernetes'],
    title: 'runway-fastapi-k8s',
    uid: 'cfa3abd6-2642-4866-b724-f13b790629ca',
  },
  apiVersion: 'scaffolder.backstage.io/v1beta3',
  kind: 'Template',
  spec: {
    owner: 'guest',
    type: 'service',
    lifecycle: 'production',
  },
};
