import { createPlugin, createRouteRef, createRoutableExtension } from '@backstage/core-plugin-api';

export const rootRouteRef = createRouteRef({
  id: 'orion-automation:root',
});

export const powerRouteRef = createRouteRef({
  id: 'access-control:power-bi',
});

export const orionAutoPlugin = createPlugin({
  id: 'orion-auto',
  featureFlags: [{ name: 'edsp-data-sharing' }],
});

export const OrionAutoPage = orionAutoPlugin.provide(
  createRoutableExtension({
    name: 'Orion Automation',
    component: () => import('./App').then(m => m.App),
    mountPoint: rootRouteRef,
  }),
);

export const PowerBIPage = orionAutoPlugin.provide(
  createRoutableExtension({
    name: 'PowerBI Access',
    component: () => import('./components/PowerBI').then(m => m.PowerBIPage),
    mountPoint: powerRouteRef,
  }),
);
