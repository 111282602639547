import React from 'react';
import { useNavigate } from 'react-router-dom';
import { MenuCard } from '@runway/devkit';

export const ADBAccessCard: React.FC = () => {
  const navigate = useNavigate();

  return (
    <MenuCard
      title="Request Access to Manage Interactive Clusters"
      subtitle="ADB Elevated Access Request"
      onButtonClick={() => navigate('/dataanalytics/adb-access')}
      buttonText="Request"
    />
  );
};
