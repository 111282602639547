import { FieldExtensionComponentProps } from '@backstage/plugin-scaffolder-react';
import { Checkbox, FormControlLabel, Grid, makeStyles } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { PaasAdminItemsField, paasAdminItemsFieldsDefaultValues } from './paasAdmin';
import { PaasItemsField } from './PaasItems';
import { PaasSpRgItemsField } from './spRgElement';
import { PaasFormFields, paasItemsFieldDefaultValues, paasSpRgItemsFieldsDefaultValues } from './types';

const useStyles = makeStyles(() => ({
  root: {
    borderColor: '#eeeeee',
    borderWidth: 1,
    borderStyle: 'solid',
    maxWidth: 500,
    marginBottom: 10,
    padding: 10,
  },
  container: {
    marginBottom: 2,
  },
}));

export const paasDefaults: PaasFormFields = {
  paas: false,
  ...paasAdminItemsFieldsDefaultValues,
  ...paasSpRgItemsFieldsDefaultValues,
  ...paasItemsFieldDefaultValues,
};

export const PaasFields = (props: FieldExtensionComponentProps<PaasFormFields>) => {
  if (!props.formData) {
    props.onChange({ ...paasDefaults });
  }
  const classes = useStyles();
  const [paasChecked, setPaasChecked] = useState(false);

  useEffect(() => {
    if (!paasChecked) {
      props.onChange({ ...paasDefaults });
    } else {
      props.onChange({ ...props.formData!, paas: true });
    }
  }, [paasChecked, props]);

  return (
    <Grid container spacing={3} className={classes.root}>
      <Grid item xs={12} className={classes.container}>
        <FormControlLabel
          control={
            <Checkbox
              checked={paasChecked}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => setPaasChecked(event.target.checked)}
              data-testid="createPaas"
              name="createPaas"
              key="createPaas"
              color="primary"
            />
          }
          label="Create PaaS"
        />
      </Grid>{' '}
      {props.formData?.paas && (
        <>
          <Grid item xs={12} className={classes.container}>
            <PaasAdminItemsField {...props} />
          </Grid>
          <Grid item xs={12} className={classes.container}>
            <PaasSpRgItemsField {...props} />
          </Grid>
          <Grid item xs={12} className={classes.container}>
            <PaasItemsField formData={props.formData} onChange={props.onChange} />
          </Grid>
        </>
      )}
    </Grid>
  );
};
