import React from 'react';
import { ReplicaSetsMetadata } from '../services';
import { STATUS } from '../Status';
import { getClusterDetails, getStatusDetails2D, Layer } from './Layer';

export const ReplicaSetsLayer = ({
  status,
  clusters,
}: {
  status: STATUS;
  clusters: Record<string, ReplicaSetsMetadata[]> | undefined;
}) => (
  <Layer
    title="Replicasets"
    status={status}
    subheader={getStatusDetails2D<ReplicaSetsMetadata>(status, clusters, getClusterDetails, 'replicaset')}
  />
);
