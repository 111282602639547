import { createPlugin, createRouteRef, createRoutableExtension } from '@backstage/core-plugin-api';

export const rootRouteRef = createRouteRef({
  id: 'infrastructure:root',
});

export const cloudRouteRouteRef = createRouteRef({
  id: 'infrastructure:cloud',
});

export const spRouteRef = createRouteRef({
  id: 'infrastructure:Request-Service-Principal',
});

export const rgRouteRef = createRouteRef({
  id: 'infrastructure:Request-Resource-Group',
});

export const aadRouteRef = createRouteRef({
  id: 'infrastructure:Request-Azure-Active-Directory-Group',
});

export const rancherDashboardRouteRef = createRouteRef({
  id: 'infrastructure:Rancher-Dashboard',
});

export const kubernetesNamespacesRouteRef = createRouteRef({
  id: 'infrastructure:Kubernetes-Namespaces',
});

export const linuxPasswordResetRouteRef = createRouteRef({
  id: 'infrastructure:Linux Password Reset',
});

export const CreateRancherResourceRouteRef = createRouteRef({
  id: 'infrastructure:Create Rancher Resource',
});

export const ResizeRancherResourceRouteRef = createRouteRef({
  id: 'infrastructure:Resize Rancher Resource',
});

export const FMSRouteRef = createRouteRef({
  id: 'infrastructure:Find-My-Server',
});

export const vmsRouteRef = createRouteRef({
  id: 'infrastructure:VMs',
});

export const cloudsmithRouteRef = createRouteRef({
  id: 'infrastructure:cloudsmith-service-accounts',
});

export const infrastructurePlugin = createPlugin({
  id: 'infrastructure',
});

export const cloudInfrastructurePlugin = createPlugin({
  id: 'cloud-infrastructure',
});

export const InfrastructureApp = infrastructurePlugin.provide(
  createRoutableExtension({
    name: 'Infrastructure',
    component: () => import('./App').then(m => m.App),
    mountPoint: rootRouteRef,
  }),
);

export const CloudInfrastructurePage = cloudInfrastructurePlugin.provide(
  createRoutableExtension({
    name: 'Cloud',
    component: () => import('./Cloud').then(m => m.CloudInfrastructurePage),
    mountPoint: cloudRouteRouteRef,
  }),
);

export const InfrastructureServicePrincipal = infrastructurePlugin.provide(
  createRoutableExtension({
    name: 'Request Service Principal',
    component: () => import('./components/ServicePrincipal/ServicePrincipal').then(m => m.default),
    mountPoint: spRouteRef,
  }),
);

export const InfrastructureResourceGroup = infrastructurePlugin.provide(
  createRoutableExtension({
    name: 'Request Resource Group',
    component: () => import('./components/ResourceGroup/ResourceGroup').then(m => m.default),
    mountPoint: rgRouteRef,
  }),
);

export const InfrastructureVmManagement = infrastructurePlugin.provide(
  createRoutableExtension({
    name: 'Find My Server',
    component: () => import('./components/VMManagement/VMManagementPage').then(m => m.VMManagementPage),
    mountPoint: FMSRouteRef,
  }),
);

export const InfrastructureCreateActiveDirectoryGroup = infrastructurePlugin.provide(
  createRoutableExtension({
    name: 'Request Azure Active Directory Group',
    component: () => import('./components/ActiveDirectoryGroups/ActiveDirectoryGroups').then(m => m.default),
    mountPoint: aadRouteRef,
  }),
);

export const ViewRancherProjects = infrastructurePlugin.provide(
  createRoutableExtension({
    name: 'Rancher Dashboard',
    component: () => import('./components/RancherManagement/ViewRancherProjects').then(m => m.default),
    mountPoint: rancherDashboardRouteRef,
  }),
);

export const ViewKubernetesNamespace = infrastructurePlugin.provide(
  createRoutableExtension({
    name: 'Kubernetes Namespace Page',
    component: () => import('./components/RancherManagement/ViewKubernetesNamespace').then(m => m.default),
    mountPoint: kubernetesNamespacesRouteRef,
  }),
);

export const CreateRancherResource = infrastructurePlugin.provide(
  createRoutableExtension({
    name: 'Create Rancher Resource',
    component: () => import('./components/RancherManagement/CreateRancherResource').then(m => m.default),
    mountPoint: CreateRancherResourceRouteRef,
  }),
);

export const ResizeRancherResource = infrastructurePlugin.provide(
  createRoutableExtension({
    name: 'Resize Rancher Resource',
    component: () => import('./components/RancherManagement/ResizeRancherResource').then(m => m.default),
    mountPoint: ResizeRancherResourceRouteRef,
  }),
);

export const LinuxPasswordReset = infrastructurePlugin.provide(
  createRoutableExtension({
    name: 'Linux Password Reset',
    component: () =>
      import('./components/LinuxPasswordReset/LinuxPasswordResetRequestForm').then(m => m.PasswordResetPage),
    mountPoint: linuxPasswordResetRouteRef,
  }),
);

export const InfrastructureVMs = infrastructurePlugin.provide(
  createRoutableExtension({
    name: 'VMs',
    component: () => import('./components/VMLists/VMs').then(m => m.default),
    mountPoint: vmsRouteRef,
  }),
);

export const CloudsmithPage = infrastructurePlugin.provide(
  createRoutableExtension({
    name: 'Cloudsmith',
    component: () => import('./components/Cloudsmith').then(m => m.CloudsmithRouting),
    mountPoint: cloudsmithRouteRef,
  }),
);
