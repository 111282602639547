import { FieldExtensionComponentProps, useTemplateSecrets } from '@backstage/plugin-scaffolder-react';
import { Checkbox, FormControl, FormControlLabel, Grid, makeStyles, TextField, Typography } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import React, { useState } from 'react';
import { PaasFormFields, paasItemsFieldDefaultValues } from './types';

const useStyles = makeStyles(() => ({
  root: {
    maxWidth: 500,
    marginBottom: 10,
    padding: 10,
  },
  container: {
    marginBottom: 2,
  },
}));

export const PaasSpRgItemsField = ({ formData, onChange }: FieldExtensionComponentProps<PaasFormFields>) => {
  if (!formData) {
    onChange({
      createServicePrincipal: true,
      createResourceGroup: true,
      roleAssociate: true,
      ...paasItemsFieldDefaultValues,
    } as unknown as PaasFormFields);
  }
  const { setSecrets } = useTemplateSecrets();
  const classes = useStyles();
  const [createAzureResources, setCreateAzureResources] = useState({
    createServicePrincipal: true,
    createResourceGroup: true,
    roleAssociate: true,
  });

  onChange({ ...formData!, ...createAzureResources });

  const handleInputChange = (field: string, value: boolean) => {
    let roleAssociation = false;
    if (value === false) {
      onChange({ ...formData!, [field]: value, roleAssociate: roleAssociation });
    } else {
      onChange({ ...formData!, [field]: value });
    }

    // Reset fields for existing service principal
    if (field === 'createServicePrincipal' && value === true) {
      if (formData!.createResourceGroup) {
        formData!.roleAssociate = true;
        roleAssociation = true;
      }
      delete formData!.servicePrincipalClientId;
      delete formData!.servicePrincipalSecret;
      onChange({ ...formData!, [field]: value });
    }

    // Reset fields for existing resourcegroup
    if (field === 'createResourceGroup' && value === true) {
      if (formData!.createServicePrincipal) {
        formData!.roleAssociate = true;
        roleAssociation = true;
      }
      delete formData!.resourceGroupName;
      onChange({ ...formData!, [field]: value });
    }

    setCreateAzureResources({ ...createAzureResources, [field]: value, roleAssociate: roleAssociation });
  };

  return (
    <Grid container spacing={3} className={classes.root}>
      <Grid item xs={12} className={classes.container}>
        <Typography variant="body1">Service Principal and Resource Group</Typography>
      </Grid>
      <Grid item xs={12} className={classes.container}>
        <FormControlLabel
          control={
            <Checkbox
              checked={createAzureResources.createServicePrincipal}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                const value: boolean = event.target.checked;
                handleInputChange('createServicePrincipal', value);
              }}
              data-testid="createServicePrincipal"
              name="createServicePrincipal"
              key="createServicePrincipal"
              color="primary"
            />
          }
          label="Create Service Principal"
        />
      </Grid>
      {!createAzureResources.createServicePrincipal && (
        <>
          <Grid item xs={12} className={classes.container}>
            <FormControl>
              <TextField
                required
                label="Service Principal Client ID"
                inputProps={{
                  'aria-label': 'Service Principal Client ID',
                }}
                variant="outlined"
                value={formData?.servicePrincipalClientId ?? undefined}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  const value: string = event.target.value;
                  onChange({ ...formData!, servicePrincipalClientId: value });
                }}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} className={classes.container}>
            <FormControl>
              <TextField
                required
                label="Service Principal Secret"
                inputProps={{
                  'aria-label': 'Service Principal Secret',
                }}
                variant="outlined"
                type="password"
                data-testid="servicePrincipalSecret"
                value={formData?.servicePrincipalSecret ?? undefined}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  const value: string = event.target.value;
                  setSecrets({ servicePrincipalSecret: value });
                }}
              />
            </FormControl>
          </Grid>
        </>
      )}
      <Grid item xs={12} className={classes.container}>
        <FormControlLabel
          control={
            <Checkbox
              checked={createAzureResources.createResourceGroup}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                const value: boolean = event.target.checked;
                handleInputChange('createResourceGroup', value);
              }}
              name="createResourceGroup"
              key="createResourceGroup"
              data-testid="createResourceGroup"
              color="primary"
            />
          }
          label="Create Resource Group"
        />
      </Grid>

      {!createAzureResources.createResourceGroup && (
        <Grid item xs={12} className={classes.container}>
          <FormControl>
            <TextField
              required
              label="Resource Group Name"
              inputProps={{
                'aria-label': 'Resource Group Name',
              }}
              variant="outlined"
              value={formData?.resourceGroupName ?? undefined}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                const value: string = event.target.value;
                onChange({ ...formData!, resourceGroupName: value });
              }}
            />
          </FormControl>
        </Grid>
      )}
      {createAzureResources.createResourceGroup && createAzureResources.createServicePrincipal && (
        <Grid item xs={12} className={classes.container}>
          <FormControlLabel
            control={
              <Checkbox
                checked={createAzureResources.roleAssociate}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  const value: boolean = event.target.checked;
                  onChange({ ...formData!, roleAssociate: value });
                  setCreateAzureResources({ ...createAzureResources, roleAssociate: value });
                }}
                name="roleAssociate"
                key="roleAssociate"
                data-testid="roleAssociate"
                color="primary"
              />
            }
            label="Associate Service Principal with Resource Group"
          />
        </Grid>
      )}
      {!createAzureResources.roleAssociate && (
        <Grid item xs={12} className={classes.container}>
          <Alert severity="info" aria-label="associate-info">
            <Typography variant="body2">
              <strong>
                For Terraform GitHub Actions to complete successfully, please remember to associate the Service
                Principal with the Resource Group.
              </strong>
            </Typography>
          </Alert>
        </Grid>
      )}
    </Grid>
  );
};
