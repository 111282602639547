import { Entity } from '@backstage/catalog-model';
import { Progress } from '@backstage/core-components';
import { useApi } from '@backstage/core-plugin-api';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, LinearProgress, Snackbar } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { RegisterAppDialog } from '../RegisterComponent/RegisterAppDialog';
import React, { useState } from 'react';
import { useAsync } from 'react-use';
import { ArgoCDServiceApiRef } from '../../services/argocd.catalog.service';
import { ManageArgoAppTable } from './ManageArgoAppTable';
import { ArgoData } from './utils';

enum SyncStatus {
  NOT_SYNCED,
  SYNCED,
  SYNCING,
  FAILED,
}

interface ManageArgoAppDialogProps {
  isOpen: boolean;
  close(): void;
  entity: Entity;
}

export const ManageArgoAppDialog = ({ isOpen, close, entity }: ManageArgoAppDialogProps) => {
  const appSelector = entity.metadata.annotations?.['argocd/app-selector']!;
  const argoCdService = useApi(ArgoCDServiceApiRef);
  const [registerAppComponentOpen, setRegisterAppComponentOpen] = useState(false);
  const [loading, setLoading] = React.useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [message, setMessage] = React.useState<string | undefined>();
  const [sync, setSync] = useState(SyncStatus.NOT_SYNCED);
  const handleAddNew = () => {
    setRegisterAppComponentOpen(true);
  };

  const syncResult = useAsync(async () => {
    if (sync === SyncStatus.SYNCING) {
      let result;
      try {
        result = await argoCdService.syncArgoApp(appSelector);
      } catch (err) {
        setSync(SyncStatus.FAILED);
        throw err;
      }
      setSync(SyncStatus.SYNCED);
      return result;
    }
    if (sync === SyncStatus.NOT_SYNCED) {
      return undefined;
    }
    if (sync === SyncStatus.FAILED) {
      throw new Error();
    }
    return [];
  }, [sync]);

  const argoResources = useAsync(async () => {
    try {
      if (isOpen) {
        const data = await argoCdService.getArgoNameAndCluster(appSelector);
        const argoDataRows: ArgoData[] = data
          .map((argoData: { name: string; appName: string[] }, index: number) => {
            const clusterName = argoData.name;
            return argoData.appName.map(appName => {
              return {
                id: index,
                appName,
                clusterName,
              };
            });
          })
          .flat()
          .map((appName: ArgoData, index: number) => {
            return {
              ...appName,
              id: index,
            };
          });

        return argoDataRows;
      }
      return undefined;
    } catch (err) {
      const errorMessage = appSelector
        ? 'Error with App Selector'
        : "Missing 'metadata.annotations.argocd/app-selector' in 'catalog-info.yaml'. Please add this field and use the format 'backstage-name=<app-name>' for the value.";
      throw new Error(errorMessage);
    }
  }, [isOpen, appSelector, argoCdService]);

  const handleClose = () => {
    close();
  };

  const handleSync = () => {
    setSync(SyncStatus.SYNCING);
  };

  return (
    <>
      <Dialog
        open={isOpen}
        fullWidth
        maxWidth="md"
        keepMounted
        onClose={handleClose}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle>Manage Deployments</DialogTitle>
        <DialogContent>
          <div>
            <ManageArgoAppTable
              isOpen={isOpen}
              data={argoResources}
              setClose={close}
              setLoading={setLoading}
              setMessage={setMessage}
              setSnackbarOpen={setSnackbarOpen}
              appSelector={appSelector}
            />
          </div>
          {syncResult.loading && (
            <Alert severity="info">
              Syncing <Progress />
            </Alert>
          )}
          {syncResult.error && (
            <Alert aria-label="syncFail" severity="error">
              Failed to Sync Argo Apps. Please try again. If the error persists, please reach out to the Runway team via
              Slack on #runway
            </Alert>
          )}
          {syncResult.value && (
            <Alert aria-label="syncSuccess" severity="success">
              Successfully Synced Argo Deployments
            </Alert>
          )}
        </DialogContent>
        <DialogActions>
          <Button color="primary" onClick={handleAddNew}>
            Add New
          </Button>
          <Button color="primary" onClick={handleSync}>
            Sync All
          </Button>
          <Button color="primary" onClick={handleClose}>
            Cancel
          </Button>
        </DialogActions>
        <LinearProgress hidden={!loading} />
      </Dialog>
      {registerAppComponentOpen && (
        <>
          <RegisterAppDialog
            isOpen={registerAppComponentOpen}
            setRegisterAppComponentOpen={setRegisterAppComponentOpen}
            setSnackbarOpen={setSnackbarOpen}
            close={() => setRegisterAppComponentOpen(false)}
            entity={entity}
          />
        </>
      )}
      <Snackbar
        open={snackbarOpen}
        aria-label="success-alert"
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        autoHideDuration={10000}
        onClose={() => {
          setSnackbarOpen(false);
          setMessage(undefined);
        }}
      >
        <Alert
          onClose={() => setSnackbarOpen(false)}
          severity="success"
          style={{
            boxShadow: '0 0 5px 1px #000',
            WebkitBoxShadow: '0 0 5px 1px #000',
            borderColor: '#004F0C',
          }}
        >
          {message}
        </Alert>
      </Snackbar>
    </>
  );
};
