import { useApi } from '@backstage/core-plugin-api';
import { useEmployeeProfile } from '@runway/devkit';
import React, { FC, useState } from 'react';
import { orionServiceApiRef } from '../../service/orion.service';
import { AMLCIForm } from './AMLCIForm';
import { amlCIFormInitializeValues } from './amlCIFormInitialValue';
import { amlCIFormValueTypes } from './amlCIFormValueTypes';

interface AMLCIComponentProps {
  subscription: string;
  resourceGroup: string;
  amlWorkspace: string;
  computeSize: string;
  computeType: string;
}

export const AMLCIFormContainer: FC<AMLCIComponentProps> = ({
  subscription,
  resourceGroup,
  amlWorkspace,
  computeSize,
  computeType,
}) => {
  const orionService = useApi(orionServiceApiRef);

  const { aaId, email: employeeEmail } = useEmployeeProfile({ withPrefix: false });

  const [loading, setLoading] = React.useState(false);
  const [error, setError] = useState<string>();
  const [success, setSuccess] = useState(false);

  const onSubmit = async (values: amlCIFormValueTypes) => {
    setSuccess(false);
    setLoading(true);
    setError(undefined);

    const metadata = {
      ...values,
      userEmployeeId: aaId,
      aaEmail: employeeEmail,
      success: true,
    };

    try {
      await orionService.provisionAMLComputeInstance(metadata);
      setSuccess(true);
      setError(undefined);
    } catch (e: any) {
      setSuccess(false);
    } finally {
      setLoading(false);
    }
  };

  const initialValues = amlCIFormInitializeValues({
    subscription,
    resourceGroup,
    amlWorkspace,
    computeSize,
    computeType,
  });

  return (
    <AMLCIForm initialValues={initialValues} onSubmit={onSubmit} loading={loading} success={success} error={error} />
  );
};
