import { createApiRef, OAuthApi } from '@backstage/core-plugin-api';
import { graphql } from '@octokit/graphql';
import jsYaml from 'js-yaml';

// type for runway yaml file
export type DeploymentInfo = {
  deployment_info: {
    service_principal: {
      client_id: string;
    };
    resource_group: string;
    vra_ids: {
      sp_deployment_id: string;
      rg_deployment_id: string;
      sp_and_rg_deployment_id: string;
    };
  };
  links: {
    address: string;
    display_name: string;
  }[];
};

export type RemoteState = {
  stateFile: string;
  container: string;
  resourceGroup: string;
  storageAccount: string;
};

export type RepoDetails = {
  repo: any;
  readme?: string;
  dev?: {
    deploymentInfo?: DeploymentInfo;
    remoteState?: RemoteState;
  };
  prod?: {
    deploymentInfo?: DeploymentInfo;
    remoteState?: RemoteState;
  };
  managedPipelineProperties?: any;
};

export enum JobState {
  SUCCESS = 'SUCCESS',
  FAILURE = 'FAILURE',
  IN_PROGRESS = 'BUILDING',
  ABORTED = 'ABORTED',
  WAITING = 'Not found',
}

export const GET_REPO_NAME = `
query repositoryDetails($owner: String!, $name: String!){ 
  repository(owner: $owner, name: $name){
    id
  }
}
`;

export const GET_DEFAULT_BRANCH_NAME = `
query repositoryDetails($owner: String!, $name: String!){ 
  repository(owner: $owner, name: $name){
    defaultBranchRef {
      name
    }
  }
}
`;

export const GET_REPO_DETAILS_QUERY_TEMPLATE = `
query repositoryDetails($owner: String!, $name: String!){ 
  repository(owner: $owner, name: $name){
    id
    description
    url
    readme:object(expression:"HEAD:README.md") {
      ... on Blob {
          text
      }
    }
    runwayMetadata:object(expression:"HEAD:runway.yaml") {
      ... on Blob {
          text
      }
    }
    devDeploymentInfo:object(expression:"HEAD:.runway/dev/runway.yaml") {
      ... on Blob {
        text
      }
    }
    prodDeploymentInfo:object(expression:"HEAD:.runway/prod/runway.yaml") {
      ... on Blob {
        text
      }
    }
    infrastructure:object(expression:"HEAD:infrastructure.yaml") {
        ... on Blob {
            text
        }
    }
    managedPipelineProperties:object(expression:"HEAD:managedPipelineProperties.yaml") {
      ... on Blob {
        text
      }
    }
  }
}
`;

const DEVELOPER_EXPERIENCE_WORK_TEMPLATE = `
{
  search(query: "topic:developer-experience org:AAInternal", type: REPOSITORY, first: 100) {
    edges {
      node {
        ... on Repository {
          name
          url
          id
          pullRequests(first: 100, states: OPEN) {
            edges {
              node {
                title
                state
                url
                lastEditedAt
                createdAt
                id
                isDraft
              }
            }
          }
        }
      }
    }
  }
}
`;

export const GET_USER_GITHUB_TEAMS = `
query githubTeams($githubUsername: String!) {
    organization(login: "AAInternal") {
      teams(first: 100, userLogins: [$githubUsername]) {
        totalCount
        edges {
          node {
            name
            id
          }
        }
      }
    }
}
`;

export interface GithubEnterpriseClientApiGraph {
  isUniqueRepo: (owner: string, repoName: string) => Promise<boolean>;
  getDefaultBranchName: (owner: string, repoName: string) => Promise<string>;
  getDxWork: () => Promise<object>;
  getUsersGithubTeams: (githubUsername: string) => Promise<{ name: string; id: string }[]>;
}

export const GithubEnterpriseClientApiGraphRef = createApiRef<GithubEnterpriseClientApiGraph>({
  id: 'plugin.create-project.github.service',
});

export class GithubEnterpriseClientGraph implements GithubEnterpriseClientApiGraph {
  constructor(
    private ghecGraphUrl: string,
    private ghecAuthApi: OAuthApi,
  ) {}

  getGraphUrl() {
    return this.ghecGraphUrl;
  }

  getAuthApi() {
    return this.ghecAuthApi;
  }

  isUniqueRepo = async (owner: string, repoName: string): Promise<boolean> => {
    const filter = { owner, name: repoName };
    const baseUrl: string = this.getGraphUrl();
    const token = await this.getAuthApi().getAccessToken();
    const result: any = await graphql(GET_REPO_NAME, {
      ...filter,
      baseUrl,
      headers: {
        authorization: `token ${token}`,
      },
    })
      .then(() => {
        return false;
      })
      .catch(() => {
        return true;
      });
    return result;
  };

  getDefaultBranchName = async (owner: string, repoName: string): Promise<string> => {
    const filter = { owner, name: repoName };
    const baseUrl: string = this.getGraphUrl();
    const token = await this.getAuthApi().getAccessToken();

    const result: any = await graphql(GET_DEFAULT_BRANCH_NAME, {
      ...filter,
      baseUrl,
      headers: {
        authorization: `token ${token}`,
      },
    })
      .then((data: any) => {
        return data.repository.defaultBranchRef.name;
      })
      .catch(() => {
        throw new Error(`Cannot retrieve default branch name from repo ${repoName}`);
      });
    return result;
  };

  getDxWork = async (): Promise<object> => {
    const baseUrl: string = this.getGraphUrl();
    const token = await this.getAuthApi().getAccessToken();

    const result: any = await graphql(DEVELOPER_EXPERIENCE_WORK_TEMPLATE, {
      baseUrl,
      headers: {
        authorization: `token ${token}`,
      },
    })
      .then((data: any) => {
        return data;
      })
      .catch(() => {
        throw new Error(`Cannot retrieve dx prs`);
      });
    return result;
  };

  getUsersGithubTeams = async (githubUsername: string): Promise<{ name: string; id: string }[]> => {
    const baseUrl: string = this.getGraphUrl();
    const token = await this.getAuthApi().getAccessToken();
    const result: any = await graphql(GET_USER_GITHUB_TEAMS, {
      githubUsername,
      baseUrl,
      headers: {
        authorization: `token ${token}`,
      },
    });
    return result?.organization?.teams?.edges?.map((team: { node: { name: string; id: string } }) => ({
      ...team.node,
    }));
  };
}

export interface ProjectsDetailsQueryApi {
  projectsDetailsQuery: (owner: string, repoName: string) => Promise<RepoDetails>;

  getLink(env: 'dev' | 'prod', managedPipelineProperties: any): string | null;
}

export const projectsDetailsQueryApiRef = createApiRef<ProjectsDetailsQueryApi>({
  id: 'plugin.projects-details.github.service',
});

export class ProjectsDetails implements ProjectsDetailsQueryApi {
  constructor(
    private ghecGraphUrl: string,
    private ghecAuthApi: OAuthApi,
  ) {}

  getGraphUrl() {
    return this.ghecGraphUrl;
  }

  getAuthApi() {
    return this.ghecAuthApi;
  }

  projectsDetailsQuery = async (owner: string, repoName: string): Promise<RepoDetails> => {
    const filter = { owner, name: repoName };
    const baseUrl: string = this.getGraphUrl();
    const token = await this.getAuthApi().getAccessToken();
    const result: any = await graphql(GET_REPO_DETAILS_QUERY_TEMPLATE, {
      ...filter,
      baseUrl,
      headers: {
        authorization: `token ${token}`,
      },
    });
    const repo = {
      id: result.repository.id,
      name: filter.name,
      description: result.repository.description,
      full_name: `${filter.owner}/${filter.name}`,
      owner: filter.owner,
      html_url: result.repository.url,
    };

    const devDeploymentInfo: DeploymentInfo = ProjectsDetails.tryParseYaml(
      result.repository.runwayMetadata?.text || result.repository.devDeploymentInfo?.text,
    );

    const prodDeploymentInfo: DeploymentInfo = ProjectsDetails.tryParseYaml(result.repository.prodDeploymentInfo?.text);

    const infrastructure: any = ProjectsDetails.tryParseYaml(result.repository.infrastructure?.text);

    const managedPipelineProperties: any = ProjectsDetails.tryParseYaml(
      result.repository.managedPipelineProperties?.text,
    );

    let devRemoteState: RemoteState | undefined = undefined;
    if (!!infrastructure) {
      const remoteState = infrastructure?.['remote-state'];
      devRemoteState = {
        stateFile: remoteState?.key,
        container: remoteState?.container_name,
        resourceGroup: remoteState?.resource_group_name,
        storageAccount: remoteState?.storage_account_name,
      };
    } else if (!!managedPipelineProperties && managedPipelineProperties?.deployInfra === 'terraform') {
      // we only expect mp to have remote state for deployInfra=terraform jobs
      const mpRemoteState = managedPipelineProperties?.CD?.deployments?.dev?.azurerm;
      devRemoteState = {
        stateFile: mpRemoteState?.statefile,
        container: mpRemoteState?.container_name,
        resourceGroup: mpRemoteState?.resource_group_name,
        storageAccount: mpRemoteState?.storage_account_name,
      };
    }

    let prodRemoteState: RemoteState | undefined = undefined;
    if (!!managedPipelineProperties && managedPipelineProperties?.deployInfra === 'terraform') {
      // we only expect mp to have remote state for deployInfra=terraform jobs
      const mpRemoteState = managedPipelineProperties?.CD?.deployments?.prod?.azurerm;
      prodRemoteState = {
        stateFile: mpRemoteState?.statefile,
        container: mpRemoteState?.container_name,
        resourceGroup: mpRemoteState?.resource_group_name,
        storageAccount: mpRemoteState?.storage_account_name,
      };
    }

    return {
      repo,
      readme: result.repository.readme?.text || undefined,
      dev: {
        deploymentInfo: devDeploymentInfo || undefined,
        remoteState: devRemoteState,
      },
      prod: {
        deploymentInfo: prodDeploymentInfo || undefined,
        remoteState: prodRemoteState,
      },
      managedPipelineProperties,
    };
  };

  private static tryParseYaml(text: string): any {
    try {
      if (text) {
        return jsYaml.load(text);
      }
      return undefined;
    } catch (error: any) {
      return undefined;
    }
  }

  getLink = (env: 'dev' | 'prod', managedPipelineProperties: any) => {
    const appName = managedPipelineProperties?.CD?.deployments?.[env]?.app?.[0]?.name;

    if (!appName) {
      return null;
    }
    if (managedPipelineProperties?.deployApp === 'appservice') {
      return `https://${appName}.azurewebsites.net`;
    }
    if (managedPipelineProperties?.deployApp === 'storage') {
      // z13 is for eastus region... atm only deploying there.
      return `https://${appName}.z13.web.core.windows.net`;
    }
    return null;
  };
}
