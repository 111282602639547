import React, { useState } from 'react';
import { InputLabel, Select, MenuItem } from '@material-ui/core';

type OnChangeValue = {
  tenant: unknown;
};

interface TenantDropdownProps {
  onChange(value: OnChangeValue): void;
  error?: boolean | undefined;
  initialValue?: string | undefined;
  label?: string;
}

export const TenantDropdown: React.FC<TenantDropdownProps> = ({
  onChange,
  error,
  initialValue = undefined,
  label = 'Tenant',
}) => {
  const [tenantSelected, setTenant] = useState(initialValue ? initialValue : '');

  return (
    <>
      <InputLabel id="tenant-label" required>
        {label}
      </InputLabel>
      <Select
        labelId="tenant-label"
        value={tenantSelected || ''}
        inputProps={{ 'data-testid': 'tenant' }}
        error={error}
        labelWidth={60}
        style={{ width: 300, display: 'inline-flex' }}
        onChange={e => {
          onChange({
            tenant: e.target.value,
          });
          setTenant(e.target.value as string);
        }}
      >
        <MenuItem value="t" key={1}>
          QCORPAA
        </MenuItem>
        <MenuItem value="p" key={2}>
          CORPAA
        </MenuItem>
      </Select>
    </>
  );
};
