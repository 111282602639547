import { STATUS } from './Status';

const combineStatus = (status: (STATUS | undefined)[] | undefined) => {
  if (!status) return STATUS.unretrievable;
  if (status.length === 0) return STATUS.unretrievable;
  if (status.every(s => s === STATUS.healthy)) return STATUS.healthy;
  if (status.every(s => s === STATUS.unhealthy)) return STATUS.unhealthy;
  if (status.every(s => s === STATUS.unretrievable)) return STATUS.unretrievable;
  return STATUS.partiallyHealthy;
};

export default combineStatus;
