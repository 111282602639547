import React from 'react';
import { Alert, Autocomplete } from '@material-ui/lab';
import { TextField } from '@material-ui/core';
import axios from 'axios';
import { configApiRef, identityApiRef, useApi } from '@backstage/core-plugin-api';
import { useAsync } from 'react-use';

type ResourceGroupDropdownProps = {
  subscriptionId: string;
  handleSelect: (rg: string) => any;
  handleBlur?: () => void;
  regex?: RegExp | string;
};

export const ResourceGroupDropdown = ({
  subscriptionId,
  handleSelect,
  handleBlur,
  regex = '.*',
}: ResourceGroupDropdownProps) => {
  const config = useApi(configApiRef);
  const identityApi = useApi(identityApiRef);
  const resourceGroup = useAsync(async () => {
    const { token } = await identityApi.getCredentials();
    const headers = {
      authorization: `Bearer ${token}`,
    };
    if (subscriptionId) {
      const res = await axios.get(
        `${config.getString('backend.baseUrl')}/api/cloudElevatedAccess/validrg/${subscriptionId}`,
        { headers: headers },
      );
      return (res.data as string[]).filter(value => new RegExp(regex).exec(value));
    }
    return [];
  }, [subscriptionId]);

  return (
    <>
      <Autocomplete
        id="ResourceGroups"
        disableClearable
        options={resourceGroup.value ?? []}
        onChange={(_: any, rg: string) => {
          handleSelect(rg);
        }}
        onBlur={handleBlur}
        style={{ width: 300, display: 'inline-flex' }}
        renderInput={params => <TextField required {...params} label="Resource Group" variant="outlined" />}
      />
      {resourceGroup.error && (
        <>
          <Alert severity="error">There was an error retrieving resource groups: {resourceGroup.error.message}</Alert>
        </>
      )}
    </>
  );
};
