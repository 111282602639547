import { useApi } from '@backstage/core-plugin-api';
import { adminToggleServiceApiRef } from '../refs';
import { AdminToggle } from '@runway/devkit-common';

function useAdminToggle(toggleName: string): AdminToggle {
  const adminToggleService = useApi(adminToggleServiceApiRef);
  return adminToggleService.getToggleByName(toggleName);
}

export default useAdminToggle;
