import axios from 'axios';
import { createApiRef, IdentityApi } from '@backstage/core-plugin-api';

type SurveysServiceResponse = {
  error?: any;
  response: string;
};

export interface SurveysServiceApi {
  appUrls: Record<string, string>;
  provisionUser(badgeNo: string, archerId: string, environment: string): Promise<SurveysServiceResponse>;
}

export const surveysServiceApiRef = createApiRef<SurveysServiceApi>({
  id: 'plugin.surveys-auto.surveys.service',
});

export default class SurveysService implements SurveysServiceApi {
  constructor(
    private backendUrl: string,
    public appUrls: Record<string, string>,
    private identityApi: IdentityApi,
  ) {}

  provisionUser = async (badgeNo: string, archerId: string, environment: string) => {
    const { token } = await this.identityApi.getCredentials();
    try {
      const res = await axios.put(
        `${this.backendUrl}/api/surveys/provisionUser`,
        {
          badgeNo,
          archerId,
          environment,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      );
      return res.data;
    } catch (err: any) {
      if (axios.isAxiosError(err)) {
        throw new Error(err?.response?.data?.error?.message);
      }
      throw new Error('Unknown error occurred');
    }
  };
}
