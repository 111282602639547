import React from 'react';

export const SlackLogo = (): JSX.Element => {
  const svgCode = `
    <svg viewBox="0 0 500 500" xmlns="http://www.w3.org/2000/svg">
      <defs>
        <clipPath id="clip-1">
          <rect width="30" height="30" fill="white"/>
        </clipPath>
      </defs>
      <g clip-path="url(#clip-1)" transform="matrix(20.010662, 0, 0, 20.009342, 84.100685, 80.553787)" style="">
        <path d="M9.97749 11.554C9.10749 11.554 8.40199 10.8485 8.40199 9.9785C8.40199 9.1085 9.10749 8.403 9.97749 8.403H13.922C14.792 8.403 15.4975 9.1085 15.4975 9.9785C15.4975 10.8485 14.792 11.554 13.922 11.554H9.97749ZM9.97749 12.3465C10.847 12.3465 11.552 13.0515 11.552 13.921C11.552 14.7905 10.847 15.4955 9.97749 15.4955C9.10849 15.4955 8.40349 14.7915 8.40299 13.9225V12.3465H9.97749ZM11.554 6.022C11.554 6.892 10.8485 7.5975 9.97849 7.5975C9.10849 7.5975 8.40299 6.892 8.40299 6.022V2.078C8.40299 1.208 9.10849 0.502502 9.97849 0.502502C10.8485 0.502502 11.554 1.208 11.554 2.078V6.022ZM12.3465 6.022C12.347 5.153 13.0515 4.4485 13.9205 4.4485C14.7895 4.4485 15.4945 5.1535 15.4945 6.023C15.4945 6.892 14.7905 7.5965 13.922 7.5975H12.346L12.3465 6.022ZM6.02199 4.4465C6.88999 4.449 7.59299 5.153 7.59299 6.0215C7.59299 6.89 6.88999 7.5945 6.02199 7.5965H2.07799C1.20999 7.594 0.506993 6.89 0.506993 6.0215C0.506993 5.153 1.20999 4.4485 2.07799 4.4465H6.02199ZM6.02199 3.6525C5.15399 3.6515 4.45049 2.9475 4.45049 2.079C4.45049 1.21 5.15499 0.505502 6.02399 0.505502C6.89299 0.505502 7.59649 1.2095 7.59749 2.0775V3.652L6.02199 3.6525ZM4.44649 9.9775C4.44899 9.1095 5.15299 8.4065 6.02149 8.4065C6.88999 8.4065 7.59449 9.1095 7.59649 9.9775V13.922C7.59399 14.79 6.88999 15.493 6.02149 15.493C5.15299 15.493 4.44849 14.79 4.44649 13.922V9.9775ZM3.65249 9.9775C3.65199 10.846 2.94749 11.55 2.07899 11.55C1.21049 11.55 0.505493 10.8455 0.505493 9.9765C0.505493 9.1075 1.20949 8.4035 2.07799 8.403H3.65249V9.9775Z" style="fill: rgb(61, 101, 157);"/>
      </g>
      <ellipse style="fill-opacity: 0; paint-order: stroke; fill: rgb(245, 213, 213); fill-rule: nonzero; stroke: rgb(61, 101, 157);" cx="250.319" cy="249.791" rx="240" ry="240"/>
    </svg>`;
  return (
    <img alt="slackLogo" src={`data:image/svg+xml;utf8,${encodeURIComponent(svgCode)}`} height="47px" width="47px" />
  );
};
