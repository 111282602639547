import React, { useEffect } from 'react';
import swaggerEditor from 'swagger-editor';
import 'swagger-editor/dist/swagger-editor.css';
import './index.css';

declare global {
  interface Window {
    editor: any;
  }
}

export const SwaggerEditorComponent = () => {
  useEffect(() => {
    window.editor = swaggerEditor({
      dom_id: '#swagger-editor',
      layout: 'EditorLayout',
    });
  });

  return <div id="swagger-editor" data-testid="swagger" />;
};
