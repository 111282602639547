import { TextField } from '@material-ui/core';
import { useFormikContext } from 'formik';
import React from 'react';
import { useStyles } from '../OrionRequestForm/orionFormStyles';
import { amlCIFormValueTypes } from './amlCIFormValueTypes';

type Props = {
  fieldPropName: keyof amlCIFormValueTypes;
  label: string;
  placeholder: string;
  required?: boolean;
  children: React.ReactNode;
};

type Ref = ((instance: HTMLDivElement | null) => void) | React.RefObject<HTMLDivElement> | null | undefined;

export const AMLCIFormDropdown = React.forwardRef(
  ({ fieldPropName, label, placeholder, required, children }: Props, ref?: Ref) => {
    const classes = useStyles();
    const formik = useFormikContext<amlCIFormValueTypes>();

    return (
      <TextField
        ref={ref}
        id={fieldPropName}
        key={fieldPropName}
        required={required}
        label={label}
        {...formik.getFieldProps(fieldPropName)}
        placeholder={placeholder}
        variant="outlined"
        select
        error={Boolean(formik.errors[fieldPropName] && formik.touched[fieldPropName])}
        className={classes.componentFormGroup}
      >
        {children}
      </TextField>
    );
  },
);
