import { FormLabel, Typography, Link, MenuItem, FormControl, FormGroup } from '@material-ui/core';
import React from 'react';
import { useStyles } from './orionFormStyles';
import { OrionFormTextField } from './OrionFormTextField';
import { useFormikContext } from 'formik';
import { OrionAppRequestValues } from './orionFormValuesTypes';
import { OrionFormCheckbox } from './OrionFormCheckbox';
import HelpIcon from '@material-ui/icons/Help';
import { OrionFormDropdown } from './OrionFormDropdown';

export const OrionFormSectionMOS = () => {
  const classes = useStyles();
  const formik = useFormikContext<OrionAppRequestValues>();

  return (
    <>
      <Typography variant="h5" className={classes.centeredText} style={{ paddingBottom: 16 }}>
        MOSAIC Information
        <Link
          variant="h5"
          target="_blank"
          rel="noopener noreferrer"
          href="https://wiki.aa.com/bin/view/Orion/Mosaic/Mosaic%20Database%20Onbaording%20-%20Runway/"
        >
          <HelpIcon />
        </Link>
      </Typography>

      {formik.values.MOS && (
        <div className={classes.flexColumnMos}>
          <div className={classes.flexRowMos}>
            <OrionFormDropdown
              fieldPropName="mosDeployType"
              label="Mosaic Deployment Type"
              placeholder="newMosaicApp"
              required
              data-testid="mosDeployType"
            >
              <MenuItem data-testid="newMosaicApp" value="NEWDB">
                New MOSAIC Application
              </MenuItem>
              <MenuItem data-testid="nos" value="NOSDB">
                NOS - Native Object Store
              </MenuItem>
              <MenuItem data-testid="etlMod" value="ETLMOD">
                ETL Modernization
              </MenuItem>
            </OrionFormDropdown>
            <br />
            <div className={classes.flexColumnMos}>
              <FormControl
                required
                error={Boolean(formik.errors.dataZoneEmpty && formik.touched.dataZoneEmpty)}
                component="fieldset"
              >
                <FormLabel component="legend" color="primary">
                  Data Zone
                </FormLabel>
                <FormGroup row>
                  <OrionFormCheckbox fieldPropName="dataZonePrep" label="Data Zone - PREP" test-id="test123" />
                  <OrionFormCheckbox fieldPropName="dataZonePkg" label="Data Zone - PKG" />
                </FormGroup>
              </FormControl>
            </div>
          </div>
          {formik.values.mosDeployType === 'ETLMOD' && (
            <div className={classes.formGroup} data-testid="saFunctionalId">
              <OrionFormTextField
                fieldPropName="saFunctionalId"
                label="Existing Subject Area Functional ID"
                placeholder="MOSMDT01"
                required
                tooltip="Provide existing functional ID"
              />
            </div>
          )}
        </div>
      )}
    </>
  );
};
