import React, { useContext, useEffect } from 'react';
import { Controller, FieldPath, FieldPathValues, FieldValues } from 'react-hook-form';
import FormContext from '../FormContext';
import FormControl from '@material-ui/core/FormControl';
import { HiddenInputProps } from '../types';

/**
 * Use this component to create a hidden input field that watches for changes in the dependent fields and thus
 * dynamically setting the values of the hidden input field.
 *
 * This field is usually required when you need a field to be calculated based on the values of other fields,
 * and require this field when submitting the form.
 *
 * You can use this in conjunction with the DynamicTextField component to display the value of the hidden input field.
 *
 * @param name - name of field
 * @param renderCallback - callback function that returns the value of the hidden input given the depend fields
 * @param dependentFieldsArray - array of dependent fields that you want to watch and will have access to in the renderCallback
 */
export function FormDynamicHiddenInput<
  TFieldValues extends FieldValues = FieldValues,
  TFieldNames extends readonly FieldPath<TFieldValues>[] = readonly FieldPath<TFieldValues>[],
>({ name, renderCallback, dependentFieldsArray }: HiddenInputProps<TFieldValues, TFieldNames>) {
  const { ctx } = useContext(FormContext);
  const { watch, setValue } = ctx;
  const results = watch(dependentFieldsArray) as unknown as FieldPathValues<TFieldValues, TFieldNames>;

  useEffect(() => {
    setValue(name, renderCallback(results));
  }, [results, name, setValue, renderCallback]);

  return (
    <FormControl variant="outlined" fullWidth>
      <Controller
        name={name}
        control={ctx.control}
        render={({ field }) => (
          <span>
            <input
              key={name}
              aria-label={`${name.toString().toLowerCase()}-dynamic-textfield`}
              id={name}
              type="hidden"
              {...field}
            />
          </span>
        )}
      />
    </FormControl>
  );
}
