import React, { useState } from 'react';
import { Grid, Link, Typography } from '@material-ui/core';
import { AdoAccessFetchComponent } from '../AdoAccessFetchComponent';
import { findProjectMembers, getProjectList } from '../../../services/adoaccess.service';
import { useTrackedRequest } from '@runway/devkit';
import { Content, InfoCard } from '@backstage/core-components';
import { useApi, configApiRef, identityApiRef, ProfileInfo } from '@backstage/core-plugin-api';
import { BucketId, ClientId, PluginId } from '@runway/devkit-common';
import { Alert } from '@material-ui/lab';
import { useAsync } from 'react-use';

export const ADOAccessContent = () => {
  const configApi = useApi(configApiRef);
  const identityApi = useApi(identityApiRef);

  const { tracker } = useTrackedRequest({
    bucket_id: BucketId.USE_METRIC,
    client_id: ClientId.RUNWAY_FRONTEND,
    pluginId: PluginId.ADO_PROJECT_ACCESS.id,
  });

  const [members, setMembers] = useState([]);
  const [error, setError] = useState(null);
  const backendUrl = configApi.getString('backend.baseUrl');

  const { value: projects, loading } = useAsync(async () => {
    const { token } = await identityApi.getCredentials();
    return await getProjectList(backendUrl, token || '');
  });

  const handleFindProjectMembers = async (projectId: string) => {
    try {
      const aaProfile = (await identityApi.getProfileInfo()) as ProfileInfo & { aaId: string };
      const { token } = await identityApi.getCredentials();
      const project = projects.find((x: { id: string }) => x.id === projectId);
      setMembers(
        await tracker(
          async () => {
            return await findProjectMembers(backendUrl, projectId, token || '');
          },
          { projectId: project?.id, projectName: project?.name, userEmployeeId: aaProfile?.aaId },
        ),
      );
    } catch (e: any) {
      setError(e.message);
    }
  };

  return (
    <Content noPadding>
      <div style={{ maxWidth: 1080 }}>
        <InfoCard>
          {error && (
            <>
              <Alert severity="error">{error}</Alert>
            </>
          )}
          <Typography variant="h6">Get ADOC Project Administrators</Typography>
          <Typography variant="body1" style={{ margin: 12 }}>
            Teams are responsible for granting team members access to their respective ADO projects. Any Project
            Administrator or Team Administrator can grant team members access to the project. Find who your Project
            Administrators below so you can reach out to them and ask for project access. You can review more details
            regarding ADOC project access and Licenses{' '}
            <Link href="https://wiki.aa.com/bin/view/DTE/ADO/ADO%20Access%20and%20Licenses/" target="_blank">
              here
            </Link>
            .
          </Typography>
          <Typography variant="h6">Instructions</Typography>
          <Typography variant="body1" style={{ margin: 12 }}>
            <li>
              Select the ADO project from the drop down. You can start typing the project name in the box to filter the
              list.
            </li>
            <li>The administrators for that project will be returned in the table below.</li>
            <li>
              Click on the email address for anyone listed to send them an email and ask them for access to the project.
            </li>
          </Typography>
          <div
            style={{
              fontWeight: 'bold',
              fontStyle: 'italic',
              fontSize: 14,
              marginBottom: 4,
              marginTop: 18,
            }}
          >
            **Only ADO cloud (ADOC) projects are listed**
          </div>
          <Grid container spacing={3} direction="column">
            <Grid item>
              <AdoAccessFetchComponent
                waitingForProjectList={loading}
                projectList={projects}
                members={members}
                handleFindProjectMembers={handleFindProjectMembers}
              />
            </Grid>
          </Grid>
        </InfoCard>
      </div>
    </Content>
  );
};

export default ADOAccessContent;
