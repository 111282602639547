import React from 'react';
import { AzureManagementServiceApiRef, aaPingSSOAuthApiRef } from '../../services/';
import { useApi } from '@backstage/core-plugin-api';
import { BucketId, ClientId, PluginId } from '@runway/devkit-common';
import { aadGroupStatus, Form } from './Form';
import { FormValues } from './schema';
import { buildAADGroupName } from './utils';
import { useTrackedRequest } from '../../hooks';

type RequestFormProps = {
  onSuccess?: (req: {
    activeDirectoryGroupName: string;
    tenant: string;
    users: { employee_id: string; user_type: string }[];
  }) => void;
};

export const RequestForm: React.FC<RequestFormProps> = ({ onSuccess }: RequestFormProps) => {
  const ams = useApi(AzureManagementServiceApiRef);
  const ssoApi = useApi(aaPingSSOAuthApiRef);

  const { tracker } = useTrackedRequest({
    bucket_id: BucketId.USE_METRIC,
    client_id: ClientId.RUNWAY_FRONTEND,
    pluginId: PluginId.ACTIVE_DIRECTORY_GROUP.id,
  });

  const [aadProcessing, setAzureActiveDirectoryProcessing] = React.useState(aadGroupStatus.awaitingInput);
  const [GroupName, setAzureActiveDirectoryGroupName] = React.useState('');
  const [outputMsg, setOutputMsg] = React.useState('');

  const handleSubmitRequestClick = async (values: FormValues) => {
    setAzureActiveDirectoryProcessing(aadGroupStatus.processing);

    const reqUsers = values.users.map(user => {
      return { employee_id: user.id!, user_type: user.userType! };
    });

    const req = {
      activeDirectoryGroupName: buildAADGroupName(values),
      tenant: values.tenant,
      users: reqUsers,
    };

    try {
      const identity = await ssoApi.getAAProfile({
        optional: true,
        instantPopup: true,
      });
      const response = await tracker(
        async () => {
          return await ams.createAzureActiveDirectoryGroup(req);
        },
        {
          groupName: buildAADGroupName(values),
          groupOwner: JSON.stringify(values.users),
          userEmployeeId: identity?.aaId ? identity.aaId : '-1',
        },
      );

      if (response.includes('has been created successfully')) {
        setAzureActiveDirectoryGroupName(`Azure Active Directory Group Name: ${GroupName}`);
        setAzureActiveDirectoryProcessing(aadGroupStatus.success);
        if (onSuccess) onSuccess(req);
      }
      setOutputMsg(response);
    } catch (error: any) {
      setOutputMsg(error.message || 'Error communicating with backend services');
      setAzureActiveDirectoryGroupName('Error communicating with backend services');
      setAzureActiveDirectoryProcessing(aadGroupStatus.failure);
    }
  };

  return <Form status={aadProcessing} outputMsg={outputMsg} onSubmit={handleSubmitRequestClick} />;
};
