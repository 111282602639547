import React, { useState } from 'react';
import { HashicorpVaultNamespaces } from '../../../components';
import { makeStyles, createStyles, useTheme as useMuiTheme } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import FormControlLabel from '@material-ui/core/FormControlLabel';

const useStyles = makeStyles(theme =>
  createStyles({
    radioGroup: {
      flexDirection: 'row',
    },
    typography: {
      color: theme.palette.text.secondary,
      padding: '8px 16px',
      borderRadius: '4px',
    },
  }),
);

export type HashiCorpVaultProps = {
  vaultNamespace: string;
  vaultPath: string;
  shortName?: string;
  onChange: (vaultInfo: { vaultNamespace: string; vaultPath: string }) => void;
};

export const HashicorpVault = (props: HashiCorpVaultProps) => {
  const theme = useMuiTheme();
  const classes = useStyles(theme);
  const { vaultNamespace, vaultPath, shortName, onChange } = props;
  const [useVault, setUseVault] = useState<'true' | 'false'>(vaultNamespace ? 'true' : 'false');
  const [namespace, setNamespace] = useState(vaultNamespace);
  const [path, setPath] = useState(vaultPath);

  const handleVaultOptionChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setUseVault(event.target.value as 'true' | 'false');

    if (event.target.value === 'false') {
      setNamespace('');
      setPath('');
      onChange({ vaultNamespace: '', vaultPath: '' });
    }
  };

  const handleNamespaceChange = (values: { namespace: string }) => {
    setNamespace(values.namespace);
    onChange({ vaultNamespace: values.namespace, vaultPath: path });
  };

  const handlePathChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPath(event.target.value);
    onChange({ vaultNamespace: namespace, vaultPath: event.target.value });
  };

  return (
    <>
      <Grid container spacing={2} direction="row" justifyContent="flex-start" alignItems="center">
        <Typography aria-label="rw-info" className={classes.typography}>
          Would you like to use Hashicorp Vault to store secrets?
        </Typography>
        <RadioGroup
          className={classes.radioGroup}
          onChange={handleVaultOptionChange}
          aria-label="use-hashicorp-vault"
          name="option"
          value={useVault}
        >
          <FormControlLabel value="true" control={<Radio />} label="Yes" />
          <FormControlLabel value="false" control={<Radio />} label="No" />
        </RadioGroup>
      </Grid>
      {useVault === 'true' && (
        <Grid container spacing={2} direction="row" justifyContent="flex-start" alignItems="center">
          <Grid item md={4}>
            <HashicorpVaultNamespaces
              onChange={handleNamespaceChange}
              shortName={shortName}
              defaultNamespaceValue={namespace}
              restrictByAdmin={false}
            />
          </Grid>

          <Grid item md={4}>
            <TextField
              id="hashicorp-vault-path"
              label="Hashicorp Vault Path"
              type="text"
              style={{ width: '100%' }}
              variant="outlined"
              value={path}
              onChange={handlePathChange}
              placeholder="Enter the path to stored secrets"
            />
          </Grid>
        </Grid>
      )}
    </>
  );
};
