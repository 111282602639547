import { Container, Grid } from '@material-ui/core';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import React, { useState, ReactNode } from 'react';
import { ContentComponent } from '@runway/plugin-diff-tool';

enum CurrentPage {
  CICD = 'CI/CD',
  TAXI_DIFF = 'Taxiway Diff',
}

interface CicdMenuProps {
  children: ReactNode;
}

export const CicdMenu: React.FC<CicdMenuProps> = ({ children }) => {
  const [currentPage, setCurrentPage] = useState(CurrentPage.CICD);
  return (
    <Grid>
      <Container style={{ justifyContent: 'center', display: 'flex', marginBottom: '10px' }}>
        <ToggleButtonGroup
          value={currentPage}
          exclusive
          onChange={(_e, value) => {
            setCurrentPage(value);
          }}
        >
          <ToggleButton value={CurrentPage.CICD}>CI/CD</ToggleButton>
          <ToggleButton value={CurrentPage.TAXI_DIFF}>Taxiway Diff Tool</ToggleButton>
        </ToggleButtonGroup>
      </Container>
      {currentPage === CurrentPage.CICD && <>{children}</>}
      {currentPage === CurrentPage.TAXI_DIFF && <ContentComponent />}
    </Grid>
  );
};
