import { createApiRef, IdentityApi } from '@backstage/core-plugin-api';
import axios from 'axios';

export type WorkMgmt = {
  createdBy?: string;
  createdDate?: string;
  id?: number;
  lastModifiedBy?: string;
  lastModifiedDate?: string;
  name: string;
  product?: Product;
  source?: string;
  srcCreatedDate?: string;
  srcItemId?: string;
  srcLastModifiedDate?: string;
  state?: string;
};

export type IssueMgmt = {
  createdBy?: string;
  createdDate?: string;
  id?: number;
  lastModifiedBy?: string;
  lastModifiedDate?: string;
  name: string;
  source?: string;
  srcCreatedDate?: string;
  srcItemId?: string;
  srcLastModifiedDate?: string;
};

export type ContactInfoType = {
  code?: string;
  createdBy?: string;
  createdDate?: string;
  description?: string;
  id?: number;
  lastModifiedBy?: string;
  lastModifiedDate?: string;
  name: string;
};

export type ContactInfo = {
  createdBy?: string;
  createdDate?: string;
  id?: number;
  lastModifiedBy?: string;
  lastModifiedDate?: string;
  squad?: Squad;
  type?: ContactInfoType[];
  value: string;
};

export type ChangeMgmt = {
  createdBy?: string;
  createdDate?: string;
  id?: number;
  lastModifiedBy?: string;
  lastModifiedDate?: string;
  name: string;
  source?: string;
  srcCreatedDate?: string;
  srcItemId?: string;
  srcLastModifiedDate?: string;
};

export type IdealProductTeamRole = {
  createdBy?: string;
  createdDate?: string;
  defaultRole: boolean;
  description?: string;
  id?: number;
  lastModifiedBy?: string;
  lastModifiedDate?: string;
  name: string;
  receiveNotifications: boolean;
};

export type Squad = {
  alias1?: string;
  alias2?: string;
  archived?: boolean;
  changeMgmts?: ChangeMgmt[];
  contactId?: string;
  contactInfoList?: ContactInfo[];
  createdBy?: string;
  createdDate?: string;
  dirMngDir?: Employee;
  id?: number;
  issueMgmts?: IssueMgmt[];
  lastModifiedBy?: string;
  lastModifiedDate?: string;
  mdId?: string;
  members?: SquadMember[];
  name: string;
  product?: Product;
  productOwners?: Employee[];
  squadType?: string;
  workMgmts?: WorkMgmt[];
};

export type SquadMember = {
  createdBy?: string;
  createdDate?: string;
  id?: number;
  iptRoles?: IdealProductTeamRole[];
  lastModifiedBy?: string;
  lastModifiedDate?: string;
  member?: Employee;
  role?: string;
  squad?: Squad;
  status?: string;
};

export type Employee = {
  archived?: boolean;
  createdBy?: string;
  createdDate?: string;
  department?: string;
  email?: string;
  empId?: string;
  firstName?: string;
  id?: number;
  jobTitle?: string;
  lastModifiedBy?: string;
  lastModifiedDate?: string;
  lastName?: string;
  memberships?: SquadMember;
  source?: string;
};

export type Product = {
  archived?: boolean;
  costCenter?: string;
  createdBy?: string;
  createdDate?: string;
  fundingUnitCostCenter?: string;
  id?: number;
  lastModifiedBy?: string;
  lastModifiedDate?: string;
  name: string;
  productGroup?: ProductGroup;
  productOwners?: Employee;
  source?: string;
  srcCreatedDate?: string;
  srcLastModifiedDate?: string;
};

export type ProductGroup = {
  archived?: boolean;
  createdBy?: string;
  createdDate?: string;
  fundingUnit?: string;
  id?: number;
  lastModifiedBy?: string;
  lastModifiedDate?: string;
  name: string;
  productPortfolio?: ProductPortfolio;
  source?: string;
  srcCreatedDate?: string;
  srcLastModifiedDate?: string;
  vertical?: Vertical;
};

export type ProductPortfolio = {
  archived?: boolean;
  createdBy?: string;
  createdDate?: string;
  id?: number;
  lastModifiedBy?: string;
  lastModifiedDate?: string;
  name: string;
  portfolioGroup?: PortfolioGroup;
  source?: string;
  srcCreatedDate?: string;
  srcLastModifiedDate?: string;
};

export type Vertical = {
  archived?: boolean;
  createdBy?: string;
  createdDate?: string;
  id?: number;
  lastModifiedBy?: string;
  lastModifiedDate?: string;
  name: string;
  shortName?: string;
};

export type PortfolioGroup = {
  archived?: boolean;
  createdBy?: string;
  createdDate?: string;
  id?: number;
  lastModifiedBy?: string;
  lastModifiedDate?: string;
  name: string;
  source?: string;
  srcCreatedDate?: string;
  srcLastModifiedDate?: string;
};

export type SimpleApp = {
  name: string;
  shortName: string;
  srcRecId: string;
  costCenter: string;
  product: {
    id: number;
    name: string;
    costCenter: string;
  };
};

export type SimpleSquad = {
  alias1: string;
  alias2: string;
  contactId: string;
  id: number;
  mdId: string;
  members: {
    aaId: string;
    email: string;
    firstName: string;
    lastName: string;
    role: 'MEMBER' | string;
  }[];
  name: string;
  product: {
    costCenter: string;
    id: number;
    name: string;
  };
  productOwners: {
    empId: string;
  }[];
  workMgmts: {
    id: number;
    name: string;
    source: string;
  }[];
};

export type SimpleSquadByAAId = {
  aaId: string;
  firstName: string;
  lastName: string;
  email: string;
  squads: SimpleSquad[];
};

export type AzureSubscriptionRespItem = {
  name: string;
  subscriptionId: string;
};

export type SquadIdName = {
  id: number;
  name: string;
};

export type SquadIdNameGroup = {
  items: SquadIdName[];
  total: number;
  page: number;
  size: number;
  pages: number;
};

export interface Squad360ServiceApi {
  getAllApplicationsSimple(archerId?: string, productName?: string, shortName?: string): Promise<SimpleApp[]>;
  getSquadsByName(name: string): Promise<SimpleSquad[]>;
  getSquadsByAAId(aaId?: string): Promise<SimpleSquadByAAId>;
  getAllSquads(): Promise<SquadIdNameGroup>;
  getAllSquadsSimple(): Promise<SimpleSquad[]>;
  getAzureSubscriptions(shortName?: string, employeeId?: string): Promise<AzureSubscriptionRespItem[]>;
  getOwnSupportedApps(): Promise<any[]>;
  getAppInformation(appId?: string): Promise<any[]>;
  getAllProductGroups(): Promise<ProductGroup[]>;
  getAllProducts(): Promise<Product[]>;
  getProduct(productId: number): Promise<Product>;
}

export const Squad360ServiceApiRef = createApiRef<Squad360ServiceApi>({
  id: 'plugin.devkit.squad360.service',
});

export default class Squad360Service implements Squad360ServiceApi {
  constructor(
    private backendUrl: string,
    private identityApi: IdentityApi,
  ) {}

  getAllApplicationsSimple = async (archerId?: string, productName?: string, shortName?: string) => {
    try {
      const params = new URLSearchParams();
      if (archerId) params.append('archerId', archerId);
      if (productName) params.append('productName', productName);
      if (shortName) params.append('shortName', shortName);
      const backstageToken = (await this.identityApi.getCredentials()).token;
      const result = await axios.get(`${this.backendUrl}/api/squad360/getAllApplicationsSimple`, {
        params,
        headers: {
          authorization: `Bearer ${backstageToken}`,
        },
      });
      return result.data;
    } catch (e: any) {
      throw new Error(e.response?.data?.message || e.message);
    }
  };

  getSquadsByName = async (name: string) => {
    try {
      const params = new URLSearchParams();
      params.append('name', name);
      const backstageToken = (await this.identityApi.getCredentials()).token;
      const squads = await axios.get(`${this.backendUrl}/api/squad360/squads`, {
        params,
        headers: { Authorization: `Bearer ${backstageToken}` },
      });
      const squadGroup: SquadIdNameGroup = squads.data;
      return this.squadIdNameArrayToSimpleSquadArray(squadGroup.items);
    } catch (error: any) {
      throw new Error(error.response?.data?.message || error.message);
    }
  };

  getSquadsByAAId = async (aaId?: string) => {
    try {
      const backstageToken = (await this.identityApi.getCredentials()).token;
      const squadsByAAId = await axios.get(`${this.backendUrl}/api/squad360/getSquadsByAAId/${aaId}`, {
        headers: { Authorization: `Bearer ${backstageToken}` },
      });
      return squadsByAAId.data;
    } catch (error: any) {
      throw new Error(error.response?.data?.message || error.message);
    }
  };

  getAllSquadsSimple = async () => {
    const squad: SquadIdNameGroup = await this.getAllSquads();
    return this.squadIdNameArrayToSimpleSquadArray(squad.items);
  };

  getAllSquads = async () => {
    try {
      const backstageToken = (await this.identityApi.getCredentials()).token;
      const squads = await axios.get(`${this.backendUrl}/api/squad360/squads`, {
        headers: { Authorization: `Bearer ${backstageToken}` },
      });
      return squads.data;
    } catch (error: any) {
      throw new Error(error.response?.data?.message || error.message);
    }
  };

  getAzureSubscriptions = async (shortName?: string, aaId?: string) => {
    try {
      const params = new URLSearchParams();
      if (shortName) params.append('appShortname', shortName);
      if (aaId) params.append('aaId', aaId);
      const backstageToken = (await this.identityApi.getCredentials()).token;
      const result = await axios.get(`${this.backendUrl}/api/squad360/azureSubscriptions`, {
        params,
        headers: { Authorization: `Bearer ${backstageToken}` },
      });
      return result.data;
    } catch (e: any) {
      throw new Error(e.response?.data?.message || e.message);
    }
  };

  getOwnSupportedApps = async () => {
    try {
      const backstageToken = (await this.identityApi.getCredentials()).token;
      const result = await axios.get(`${this.backendUrl}/api/squad360/ownsupportedapps`, {
        headers: { Authorization: `Bearer ${backstageToken}` },
      });
      return result.data;
    } catch (e: any) {
      throw new Error(e.response?.data?.message || e.message);
    }
  };

  getAppInformation = async (appId?: string) => {
    try {
      const backstageToken = (await this.identityApi.getCredentials()).token;
      let url = `${this.backendUrl}/api/squad360/getAppInfo`;
      if (appId) {
        url = `${url}?appId=${appId}`;
      }
      const result = await axios.get(url, {
        headers: { Authorization: `Bearer ${backstageToken}` },
      });
      return result.data;
    } catch (e: any) {
      throw new Error(e.response?.data?.message || e.message);
    }
  };

  getAllProductGroups = async () => {
    try {
      const backstageToken = (await this.identityApi.getCredentials()).token;
      const url = `${this.backendUrl}/api/squad360/getAllProductGroups`;
      const result = await axios.get(url, {
        headers: { Authorization: `Bearer ${backstageToken}` },
      });
      return result.data;
    } catch (e: any) {
      throw new Error(e.response?.data?.message || e.message);
    }
  };

  getAllProducts = async () => {
    try {
      const backstageToken = (await this.identityApi.getCredentials()).token;
      const url = `${this.backendUrl}/api/squad360/getAllProducts`;
      const result = await axios.get(url, {
        headers: { Authorization: `Bearer ${backstageToken}` },
      });
      return result.data;
    } catch (e: any) {
      throw new Error(e.response?.data?.message || e.message);
    }
  };

  getProduct = async (productId: number) => {
    try {
      if (!Number.isInteger(productId) || productId <= 0) {
        throw new Error(`Invalid productId: ${productId}. Must be a positive integer.`);
      }
      const backstageToken = (await this.identityApi.getCredentials()).token;
      const url = `${this.backendUrl}/api/squad360/getProduct/${productId}`;
      const result = await axios.get(url, {
        headers: { Authorization: `Bearer ${backstageToken}` },
      });
      return result.data;
    } catch (e: any) {
      throw new Error(e.response?.data?.message || e.message);
    }
  };

  squadIdNameArrayToSimpleSquadArray = (squad: SquadIdName[]) => {
    const simpleSquad: SimpleSquad = {
      alias1: '',
      alias2: '',
      contactId: '',
      id: squad[0].id,
      mdId: '',
      members: [],
      name: squad[0].name,
      product: { costCenter: '', id: -1, name: '' },
      productOwners: [],
      workMgmts: [],
    };
    return [simpleSquad];
  };
}
