import { createPlugin, createRouteRef, createRoutableExtension } from '@backstage/core-plugin-api';

export const rootRouteRef = createRouteRef({
  id: 'cost-management:root',
});

export const avmsRouteRef = createRouteRef({
  id: 'cost-management:Auto-VM-Shutdown',
});

export const costManagementPlugin = createPlugin({
  id: 'cost-management',
});

export const CostManagementApp = costManagementPlugin.provide(
  createRoutableExtension({
    name: 'Cost Management',
    component: () => import('./App').then(m => m.App),
    mountPoint: rootRouteRef,
  }),
);

export const CostManagementAutoVMShutdown = costManagementPlugin.provide(
  createRoutableExtension({
    name: 'Auto VM Shutdown',
    component: () => import('./components/AutoVMShutdown/AutoVMShutdown').then(m => m.default),
    mountPoint: avmsRouteRef,
  }),
);
