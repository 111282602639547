import { createPlugin, createComponentExtension } from '@backstage/core-plugin-api';

export const catalogCustomizationsPlugin = createPlugin({
  id: 'catalog-customizations',
});

export const CatalogCustomizationsPage = catalogCustomizationsPlugin.provide(
  createComponentExtension({
    name: 'Custom About Card',
    component: {
      lazy: () => import('./components/AboutCard').then(m => m.AaAboutCard),
    },
  }),
);
