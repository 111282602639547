import { Snackbar } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import React, { useState } from 'react';

const MIN_CHROME_VERSION = 86;

const getMajorVersionNumber = (): number | null => {
  const rawVersion = navigator.userAgent.match(/Chrom(?:e|ium)\/([0-9]+)/);
  if (rawVersion === null) return null;
  return parseInt(rawVersion[1], 10);
};

export const OutdatedBrowserToast = ({ children }: { children: React.JSX.Element }) => {
  const [isOpen, setIsOpen] = useState(true);
  const majorVersionNumber = getMajorVersionNumber();

  if (majorVersionNumber === null || majorVersionNumber >= MIN_CHROME_VERSION) return children;

  return (
    <Snackbar open={isOpen} anchorOrigin={{ horizontal: 'center', vertical: 'top' }}>
      <Alert onClose={() => setIsOpen(false)} severity="error" aria-label="Old Browser Warning">
        Your version of Chrome (v{majorVersionNumber}) is unsupported and you may experience errors while using Runway.
        Please upgrade to Chrome version {MIN_CHROME_VERSION} or newer.
      </Alert>
    </Snackbar>
  );
};
