import { IdentityApi, createApiRef } from '@backstage/core-plugin-api';

export interface NotificationServiceApi {
  sendEmail: (emailTo: string[], emailContents: string, subject: string) => Promise<boolean>;

  sendSlackNotification: (channelId: string, message: string) => Promise<boolean>;
}

export const notificationServiceApiRef = createApiRef<NotificationServiceApi>({
  id: 'plugin.notification.service',
});

export class NotificationService implements NotificationServiceApi {
  constructor(
    private backendUrl: string,
    private identityApi: IdentityApi,
  ) {}

  async sendEmail(sendTo: string[], body: string, subject: string): Promise<boolean> {
    const { token } = await this.identityApi.getCredentials();
    const apiResponse = await fetch(`${this.backendUrl}/api/notification-service/email`, {
      method: 'POST',
      body: JSON.stringify({ sendTo, body, subject }),
      headers: {
        authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    });

    if (apiResponse.status === 200) {
      return true;
    }
    return false;
  }

  async sendSlackNotification(channelId: string, message: string): Promise<boolean> {
    const { token } = await this.identityApi.getCredentials();
    const apiResponse = await fetch(`${this.backendUrl}/api/notification-service/slack`, {
      method: 'POST',
      body: JSON.stringify({ channelId, message }),
      headers: {
        authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    });

    if (apiResponse.status === 200) {
      return true;
    }
    return false;
  }
}
