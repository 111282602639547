import { createPlugin, createRoutableExtension } from '@backstage/core-plugin-api';

import { rootRouteRef } from './routes';

export const towerPlugin = createPlugin({
  id: 'tower',
  routes: {
    root: rootRouteRef,
  },
});

export const TowerPage = towerPlugin.provide(
  createRoutableExtension({
    name: 'TowerPage',
    component: () => import('./components/TowerComponent').then(m => m.TowerComponent),
    mountPoint: rootRouteRef,
  }),
);
