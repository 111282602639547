import * as Yup from 'yup';

export const Schema = Yup.object({
  vertical: Yup.string().required(),
  scope: Yup.string().required(),
  appName: Yup.string(),
  role: Yup.string()
    .required('Azure role is required')
    .min(1)
    .max(11)
    .nullable()
    .matches(/^(READER\+|[A-Z0-9]+)$/g, 'Role must be uppercase and alphanumeric'),
  env: Yup.string().required(),
  users: Yup.array(
    Yup.object({
      id: Yup.string(),
      employeeName: Yup.string(),
      userType: Yup.string(),
    }),
  ).required(),
  tenant: Yup.string().required(),
});

export type FormValues = Yup.InferType<typeof Schema>;

export const SimpleSchema = Yup.object({
  activeDirectoryGroupName: Yup.string().matches(
    /^AAD_[A-Z][A-Z0-9_+]*$/g,
    'Must be upper case. Start with AAD_  and followed by a letter immediately, then letters or numbers or plus sign are allowed',
  ),
  tenant: Yup.string().required(),
  users: Yup.array(
    Yup.object({
      id: Yup.string(),
      employeeName: Yup.string(),
      userType: Yup.string(),
    }),
  ).required(),
});

export type SimpleFormValues = Yup.InferType<typeof SimpleSchema>;
