import { AlertState } from './ADBAccessForm';

export const checkPermission = async (
  orionRef: any,
  cluster: string,
  workspace: string,
  setManageGroupRef: any,
  setRwxGroupRef: any,
  aaIdRef: any,
  setLoadingAlert: React.Dispatch<React.SetStateAction<boolean>>,
  setAlert: React.Dispatch<React.SetStateAction<AlertState | false>>,
) => {
  if (cluster && workspace) {
    setLoadingAlert(true);
    setAlert(AlertState.Checking);

    const clusterPermissions = await orionRef.getDatabricksClusterPermissions(cluster, workspace);

    const findManageGroup = clusterPermissions.data.find((item: { group_name: string }) =>
      item.group_name.endsWith('_M_P'),
    )?.group_name;

    const findRwxGroup = clusterPermissions.data.find((item: { group_name: string }) =>
      item.group_name.endsWith('_RWX_P'),
    )?.group_name;

    setManageGroupRef.current(findManageGroup);
    setRwxGroupRef.current(findRwxGroup);

    if (!findManageGroup && !findRwxGroup) {
      setAlert(AlertState.NoGroup);
    } else if (!findManageGroup || !findRwxGroup) {
      setAlert(AlertState.MissingGroup);
    } else {
      const manageGroupMembers = await orionRef.listMembersInAdGroup({
        env: 'prod',
        group_name: `${findManageGroup}`,
      });

      const rwxGroupMembers = await orionRef.listMembersInAdGroup({
        env: 'prod',
        group_name: `${findRwxGroup}`,
      });

      const combinedMembers = [...manageGroupMembers.data.members, ...rwxGroupMembers.data.members];
      const uniqueMembers = Array.from(new Set(combinedMembers));
      if (uniqueMembers.includes(aaIdRef.current?.toString())) {
        setAlert(AlertState.ValidGroup);
      } else {
        setAlert(AlertState.InvalidGroup);
      }
    }

    setLoadingAlert(false);
  }
};
