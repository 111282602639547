import { scaffolderPlugin } from '@backstage/plugin-scaffolder';
import { createScaffolderFieldExtension } from '@backstage/plugin-scaffolder-react';
import { CoverityIssueTypePicker } from '../CoverityPicker/coverityIssueType';
import { AAEmployeeInfo, AaEmployeeInformation } from './AAEmployeeInfo';
import { AppCodeSearchPicker } from './appCodeSearch';
import { AppShortnamePicker } from './appShortname';
import { AppShortNamePickerSchema } from './appShortname.schema';
import { ClusterPicker } from './cluster';
import { ComponentInformation, componentInformationValidation } from './ComponentInformation/ComponentInformation';
import { DescriptionField, descriptionFieldValidation } from './description';
import { GithubRepoField, githubRepoFieldValidation } from './githubRepo';
import { GithubSecretsFieldPicker } from './GithubSecrets';
import { GithubTeamField } from './githubTeam';
import { GitHubUserId } from './githubUserInfo';
import { InfrastructureSelector } from './InfrastructureSelector/InfrastructureSelector';
import { LDAPGroupField } from './LDAPGroup';
import { MicrogatewayCheckbox } from './microgatewayCheckbox';
import { PaasAdminItemsField } from './paas/paasAdmin';
import { PaasFields } from './paas/paasForm';
import { PaasSpRgItemsField } from './paas/spRgElement';
import { SecretVaultForm, secretVaultFormFieldValidation } from './secretVault';
import { SecretVaultNamespaceForm } from './SecretVaultNamespace';
import { ServerNamingField } from './serverNaming';
import { SquadPicker, squadPickerValidation } from './squadPicker';
import { VerticalField } from './vertical';

export const SquadPickerFieldExtension = scaffolderPlugin.provide(
  createScaffolderFieldExtension({
    name: 'SquadPickerFieldExtension',
    component: SquadPicker,
    validation: squadPickerValidation,
  }),
);

export const AppShortnamePickerFieldExtension = scaffolderPlugin.provide(
  createScaffolderFieldExtension({
    name: 'AppShortnamePickerFieldExtension',
    component: AppShortnamePicker,
    schema: AppShortNamePickerSchema.schema,
  }),
);

export const ClusterPickerFieldExtension = scaffolderPlugin.provide(
  createScaffolderFieldExtension({
    name: 'KubernetesClusterPickerFieldExtension',
    component: ClusterPicker,
    // cannot validate objects until https://github.com/backstage/backstage/issues/8542
  }),
);

export const GithubSecretsPickerFieldExtension = scaffolderPlugin.provide(
  createScaffolderFieldExtension({
    name: 'GithubSecretsPickerFieldExtension',
    component: GithubSecretsFieldPicker,
    // validation: GithubSecretsFieldPickerValidation,
  }),
);

export const GithubRepoFieldExtension = scaffolderPlugin.provide(
  createScaffolderFieldExtension({
    name: 'GithubRepoFieldExtension',
    component: GithubRepoField,
    validation: githubRepoFieldValidation,
  }),
);

export const GithubTeamFieldExtension = scaffolderPlugin.provide(
  createScaffolderFieldExtension({
    name: 'GithubTeamFieldExtension',
    component: GithubTeamField,
  }),
);

export const DescriptionFieldExtension = scaffolderPlugin.provide(
  createScaffolderFieldExtension({
    name: 'DescriptionFieldExtension',
    component: DescriptionField,
    validation: descriptionFieldValidation,
  }),
);

/**
 * @deprecated use `AAEmployeeInfoFieldExtension` instead.
 */
export const AaEmployeeInformationFieldExtension = scaffolderPlugin.provide(
  createScaffolderFieldExtension({
    name: 'AaEmployeeInformationFieldExtension',
    component: AaEmployeeInformation,
  }),
);

export const AAEmployeeInfoFieldExtension = scaffolderPlugin.provide(
  createScaffolderFieldExtension({
    name: 'AAEmployeeInfoFieldExtension',
    component: AAEmployeeInfo,
  }),
);

export const GitHubUserIdFieldExtension = scaffolderPlugin.provide(
  createScaffolderFieldExtension({
    name: 'GitHubUserIdFieldExtension',
    component: GitHubUserId,
  }),
);

export const VerticalFieldExtension = scaffolderPlugin.provide(
  createScaffolderFieldExtension({
    name: 'VerticalFieldExtension',
    component: VerticalField,
  }),
);

export const PaasAdminItemsFieldExtension = scaffolderPlugin.provide(
  createScaffolderFieldExtension({
    name: 'PaasAdminItemsFieldExtension',
    component: PaasAdminItemsField,
  }),
);

export const PaasSpRgItemsFieldExtension = scaffolderPlugin.provide(
  createScaffolderFieldExtension({
    name: 'PaasSpRgItemsFieldExtension',
    component: PaasSpRgItemsField,
  }),
);

export const PaasFieldExtension = scaffolderPlugin.provide(
  createScaffolderFieldExtension({
    name: 'PaasFieldExtension',
    component: PaasFields,
  }),
);

export const ServerNamingFieldExtension = scaffolderPlugin.provide(
  createScaffolderFieldExtension({
    name: 'ServerNamingFieldExtension',
    component: ServerNamingField,
  }),
);

export const AppCodeSearchPickerFieldExtension = scaffolderPlugin.provide(
  createScaffolderFieldExtension({
    name: 'AppCodeSearchPickerFieldExtension',
    component: AppCodeSearchPicker,
  }),
);

export const SecretVaultFormFieldExtension = scaffolderPlugin.provide(
  createScaffolderFieldExtension({
    name: 'SecretVaultFormFieldExtension',
    component: SecretVaultForm,
    validation: secretVaultFormFieldValidation,
  }),
);

export const SecretVaultNamespaceFormFieldExtension = scaffolderPlugin.provide(
  createScaffolderFieldExtension({
    name: 'SecretVaultNamespaceFormFieldExtension',
    component: SecretVaultNamespaceForm,
  }),
);

export const MicrogatewayPickerExtension = scaffolderPlugin.provide(
  createScaffolderFieldExtension({
    name: 'MicrogatewayPickerExtension',
    component: MicrogatewayCheckbox,
  }),
);

export const LDAPGroupFieldExtension = scaffolderPlugin.provide(
  createScaffolderFieldExtension({
    name: 'LDAPGroupFieldExtension',
    component: LDAPGroupField,
  }),
);

export const InfrastructureSelectorExtension = scaffolderPlugin.provide(
  createScaffolderFieldExtension({
    name: 'InfrastructureSelectorExtension',
    component: InfrastructureSelector,
  }),
);

export const ComponentInformationExtension = scaffolderPlugin.provide(
  createScaffolderFieldExtension({
    name: 'ComponentInformationExtension',
    component: ComponentInformation,
    validation: componentInformationValidation,
  }),
);

export const CoverityIssueTypeExtension = scaffolderPlugin.provide(
  createScaffolderFieldExtension({
    name: 'CoverityIssueTypeExtension',
    component: CoverityIssueTypePicker,
  }),
);
