import { Page, Header, HeaderLabel, Content } from '@backstage/core-components';
import { Link, Typography } from '@material-ui/core';
import { RunwaySupportInformation } from '@runway/devkit';
import React from 'react';
import { Link as RouteLink } from 'react-router-dom';

const OldBlueprintsPage = () => {
  return (
    <Page themeId="home">
      <Header title="Create">
        <HeaderLabel label="Owner" value="Runway Team" />
        <HeaderLabel label="Lifecycle" value="Alpha" />
      </Header>
      <Content>
        <RunwaySupportInformation />
        <Typography variant="h3" gutterBottom>
          Runway Blueprints have been upgraded!
        </Typography>
        <Typography variant="h5" gutterBottom>
          Why am I seeing this?
        </Typography>
        <Typography variant="body1" gutterBottom>
          Runway has implemented a new backend to create repositories to better align with our upstream framework.
        </Typography>
        <Typography variant="body1" gutterBottom>
          We have been missing out on a lot of functionality from the latest scaffolder that we want to incorporate and
          the Runway community has been asking for. We'll be moving to the Backstage scaffolder
          scaffolder.backstage.io/v1beta3 specification.
        </Typography>
        <Typography variant="h5" gutterBottom>
          What do I do?
        </Typography>
        <Typography variant="h6" gutterBottom>
          Blueprint Owners
        </Typography>
        <Typography variant="body1" gutterBottom>
          We have developed a guide to help you shift from the old blueprints to the new software templates.{' '}
          <Link component={RouteLink} to="/docs/default/component/runway/getting-started/blueprint-upgrade-guide/">
            Upgrade old blueprints guide.
          </Link>
        </Typography>
        <Typography variant="h6" gutterBottom>
          Blueprint Users
        </Typography>
        <Typography variant="body1" gutterBottom>
          The blueprint you're trying to use either hasn't yet been upgraded or you have an old link. Please use the
          'Create' link in the sidebar to navigate to the new Software Templates.
        </Typography>
      </Content>
    </Page>
  );
};

export const oldBlueprintsPage = <OldBlueprintsPage />;
