import React from 'react';
import { Containers, ContainersMetadata } from '../services';
import { STATUS } from '../Status';
import { getStatusDetails, Layer } from './Layer';

const getClusterDetails = (cluster: ContainersMetadata) => {
  if (cluster.healthyContainerCount === undefined || cluster.containerCount === undefined)
    return 'container information is missing';
  return `${cluster.healthyContainerCount}/${cluster.containerCount} container(s) healthy`;
};

export const ContainersLayer = ({
  title,
  status,
  clusters,
}: {
  title: string;
  status: STATUS;
  clusters: Containers | undefined;
}) => (
  <Layer
    title={title}
    status={status}
    subheader={getStatusDetails<ContainersMetadata>(
      status,
      clusters,
      getClusterDetails,
      title === 'App Containers' ? 'container' : 'kuma sidecar',
    )}
  />
);
