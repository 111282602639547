import { CSSProperties } from 'react';

/**
 * If you need to have certain CSS only for specific pages you can use these
 * rules where it'll match the beginning of the url.
 */

const pageCssProperties: Record<string, Record<string, CSSProperties>> = {
  '/catalog/default/component': {
    'header button[aria-label="more"]': {
      display: 'none',
    },
  },
};

/**
 * Turn the page overrides into css selectors
 *
 * You shouldn't have to tweak this unless there is a bug or you want to
 * expand on the capabilities of this utility
 */
export const pageCssOverrides: Record<string, CSSProperties> = Object.entries(pageCssProperties).reduce(
  (allCssProperties, [pathname, selectorProperties]) => {
    const pathScopedProperties = Object.entries(selectorProperties).reduce(
      (modifiedProperties, [selector, properties]) => ({
        ...modifiedProperties,
        [`#location-wrapper[data-pathname^="${pathname}"] ${selector}`]: properties,
      }),
      {},
    );

    return { ...allCssProperties, ...pathScopedProperties };
  },
  {},
);
