import { createPlugin, createRoutableExtension } from '@backstage/core-plugin-api';

import { rootRouteRef } from './routes';

export const towerApiPlugin = createPlugin({
  id: 'tower-api',
  routes: {
    root: rootRouteRef,
  },
});

export const TowerApiPage = towerApiPlugin.provide(
  createRoutableExtension({
    name: 'Tower Summary',
    component: () => import('./components/Component').then(m => m.TowerPage),
    mountPoint: rootRouteRef,
  }),
);
