import React from 'react';
import { makeStyles, Paper, Typography, useTheme } from '@material-ui/core';
import { heroBackgroundImage } from './heroBackground';

const useStyles = makeStyles({
  header: {
    gridArea: 'pageHeader',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    borderRadius: 0,
    padding: '60px 20px 100px',

    '& > *': {
      maxWidth: '30em',
      textAlign: 'center',
    },
  },
  lightModeHeroBackground: {
    backgroundImage: heroBackgroundImage('hsl(210, 50%, 90%)'),
    borderBottom: `1px solid hsl(210, 50%, 90%)`,
  },
  darkModeHeroBackground: {
    backgroundImage: heroBackgroundImage('hsl(210, 25%, 5%)'),
    borderBottom: `1px solid hsl(210, 25%, 5%)`,
  },
  title: {
    marginBottom: 10,
  },
  subtitle: {
    fontSize: 26,
    lineHeight: 1.3,
  },
});

interface HeroHeaderProps {
  title: string;
  subtitle: string;
}

export const HeroHeader: React.FC<HeroHeaderProps> = ({ title, subtitle }) => {
  const classes = useStyles();
  const theme = useTheme();
  const isDarkMode = theme.palette.type === 'dark';

  return (
    <Paper
      className={`${classes.header} ${isDarkMode ? classes.darkModeHeroBackground : classes.lightModeHeroBackground}`}
      elevation={0}
    >
      <Typography variant="h1" className={classes.title}>
        {title}
      </Typography>
      <Typography className={classes.subtitle}>{subtitle}</Typography>
    </Paper>
  );
};
