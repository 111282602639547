import { useEffect } from 'react';
import { useApi } from '@backstage/core-plugin-api';
import { Squad360ServiceApiRef } from '@runway/devkit';

export const SessionStorageLoader = () => {
  const squad360 = useApi(Squad360ServiceApiRef);

  useEffect(() => {
    const fetchUserProductGroup = async () => {
      try {
        const appDetails = await squad360.getOwnSupportedApps();
        const product = await squad360.getProduct(appDetails[0]?.product?.id);
        const productGroup = product?.productGroup?.name;
        if (productGroup) {
          sessionStorage.setItem('productGroup', productGroup);
        }
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error(`Error storing productGroup in session storage: ${error}`);
      }
    };

    fetchUserProductGroup();
  }, [squad360]);

  return null;
};
