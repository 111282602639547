import { createPlugin, createRoutableExtension } from '@backstage/core-plugin-api';

import { rootRouteRef } from './routes';

export const graphqlPlugin = createPlugin({
  id: 'graphql',
  routes: {
    root: rootRouteRef,
  },
});

export const GraphqlPage = graphqlPlugin.provide(
  createRoutableExtension({
    name: 'GraphqlPage',
    component: () => import('./components/GraphqlComponent').then(m => m.default),
    mountPoint: rootRouteRef,
  }),
);

export const PolicyManagementComponent = graphqlPlugin.provide(
  createRoutableExtension({
    name: 'PolicyManagementComponent',
    component: () => import('./components/PolicyManagementComponent').then(m => m.default),
    mountPoint: rootRouteRef,
  }),
);
