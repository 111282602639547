import { createApiRef, IdentityApi } from '@backstage/core-plugin-api';
import { TowerData } from '../components/FetchComponent/FetchComponent';
import { RestClientApi } from '@runway/devkit-common';

export interface TowerDataServiceApi {
  getTowerData(): Promise<TowerData[]>;
}

export const towerDataServiceApiRef = createApiRef<TowerDataServiceApi>({
  id: 'plugin.towerdata.service',
});

export class TowerDataService implements TowerDataServiceApi {
  constructor(
    private backendUrl: string,
    private identityApi: IdentityApi,
    private restClient: RestClientApi,
  ) {}

  getTowerData = async () => {
    const { token } = await this.identityApi.getCredentials();
    const res = await this.restClient.get<TowerData[]>(`${this.backendUrl}/api/tower-data`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    return res.data;
  };
}
