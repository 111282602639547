import { createPlugin, createRoutableExtension } from '@backstage/core-plugin-api';

import { rootRouteRef, systemDeploymentDeleteRouteRef } from './routes';

export const auditPlugin = createPlugin({
  id: 'audit',
  routes: {
    root: rootRouteRef,
    auditSystemDeploymentDelete: systemDeploymentDeleteRouteRef,
  },
});

export const AuditPage = auditPlugin.provide(
  createRoutableExtension({
    name: 'AuditPage',
    component: () => import('./components/AuditRootPage').then(m => m.AuditRootPage),
    mountPoint: rootRouteRef,
  }),
);

export const AuditSystemDeploymentDeletePage = auditPlugin.provide(
  createRoutableExtension({
    name: 'AuditSystemDeploymentDeletePage',
    component: () =>
      import('./components/AuditSystemDeleteDeploymentPage').then(m => m.AuditSystemDeleteDeploymentPage),
    mountPoint: systemDeploymentDeleteRouteRef,
  }),
);
