import { STATUS } from '../../Status';

interface ServiceResource {
  metadata: { name: string };
  spec: { externalName?: string; clusterIP?: string };
}

export interface ServicesMetadata {
  status: STATUS;
  name?: string | undefined;
  ip?: string | undefined;
  externalName?: string | undefined;
}
export type Services = Record<string, ServicesMetadata[]>;

export const getServicesStatus = (item: any, isExternal: boolean) => {
  const serviceObject = item.resources?.filter((resource: { type: string }) => {
    return resource.type === 'services';
  })[0];

  if (!serviceObject || serviceObject.resources.length === 0) return [{ status: STATUS.unretrievable }];

  const services: ServiceResource[] = serviceObject.resources.filter((resource: { metadata: { name: string } }) => {
    return isExternal
      ? resource.metadata.name?.includes('externalname')
      : !resource.metadata.name?.includes('externalname');
  });

  if (!services || services.length === 0) return [{ status: STATUS.unretrievable }];

  return services.map(service => {
    const serviceName = service.metadata?.name;
    const serviceInfo = isExternal ? service.spec?.externalName : service.spec?.clusterIP;

    if (serviceInfo && serviceName)
      return isExternal
        ? { status: STATUS.healthy, name: serviceName, externalName: serviceInfo }
        : { status: STATUS.healthy, name: serviceName, ip: serviceInfo };
    return isExternal
      ? { status: STATUS.unhealthy, name: serviceName, externalName: serviceInfo }
      : { status: STATUS.unhealthy, name: serviceName, ip: serviceInfo };
  });
};
