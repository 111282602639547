import React from 'react';
import { MenuCard } from '@runway/devkit';
import { useNavigate } from 'react-router-dom';

export const CloudsmithAccessCard: React.FC = () => {
  const navigate = useNavigate();

  const handleClick = () => {
    navigate('/cloudsmith/service-accounts');
  };

  return (
    <MenuCard
      title="Cloudsmith"
      subtitle="Manage Cloudsmith service accounts for seamless integration"
      linkText="Cloudsmith service accounts overview"
      linkHref="https://developer.aa.com/docs/default/component/devops-tech-enablement/cloudsmith/service-accounts/overview/#overview-of-cloudsmith-service-accounts"
      buttonText="Manage accounts"
      onButtonClick={handleClick}
    />
  );
};
