import React, { useRef, useState } from 'react';
import { Formik, Form, FormikProps } from 'formik';
import { Alert } from '@material-ui/lab';
import {
  TextField,
  Button,
  Typography,
  MenuItem,
  FormLabel,
  Tooltip,
  Link,
  FormControl,
  FormGroup,
  FormHelperText,
  InputAdornment,
  Box,
  IconButton,
  useMediaQuery,
  CircularProgress,
} from '@material-ui/core';
import { OrionAppRequestValues } from './orionFormValuesTypes';
import { orionAppRequestSchema } from './orionFormValidationSchema';
import { CmdbAppShortName, CostCenter, SquadInfo, SupportInformation } from '@runway/devkit';
import { AppCodePicker } from '../AppCode/AppCodePicker';
import { useStyles } from './orionFormStyles';
import { OrionFormCheckbox } from './OrionFormCheckbox';
import { OrionFormTextField } from './OrionFormTextField';
import { OrionFormDropdown } from './OrionFormDropdown';
import { OrionFormSectionADX } from './OrionFormSectionADX';
import { OrionFormSectionAEH } from './OrionFormSectionAEH';
import { OrionFormSectionMOS } from './OrionFormSectionMOS';
import { OrionFormSectionASTRA } from './OrionFormSectionASTRA';
import FeedbackIcon from '@material-ui/icons/Feedback';
import { Theme } from '@material-ui/core/styles';

type Props = {
  employeeEmail: string;
  initialValues: OrionAppRequestValues;
  onSubmit: (values: OrionAppRequestValues) => void;
  loading: boolean;
  success: boolean;
  error?: string;
  currentStep: number;
};
export const OrionForm = ({ employeeEmail, initialValues, onSubmit, loading, success, error, currentStep }: Props) => {
  const classes = useStyles();
  const formRef = useRef<FormikProps<OrionAppRequestValues>>(null);
  const isSmallScreen = useMediaQuery<Theme>(theme => theme.breakpoints.down('sm'));
  const [isLoading, setIsLoading] = useState(false);

  function openSurvey() {
    window.open('https://surveys.aa.com/assets/run.html?archer_id=189986', '_blank');
  }
  return (
    <Formik
      initialValues={initialValues}
      onSubmit={values => {
        setIsLoading(true);
        try {
          onSubmit(values);
        } finally {
          setIsLoading(false);
        }
      }}
      validationSchema={orionAppRequestSchema}
      innerRef={formRef}
    >
      {formik => (
        <Form className={classes.flexColumn}>
          <div className={classes.supportInformation}>
            <SupportInformation
              link={[
                {
                  title: 'Submit a support ticket',
                  link: 'https://developer.aa.com/create/templates/default/DEA-EDP-Issue-Template',
                },
                {
                  title: 'Orion Automation ReadMe',
                  link: 'https://github.com/AAInternal/orion-automation-ghec#readme',
                },
              ]}
              githubRepo="DEA-EDP"
            >
              <span style={{ flexDirection: 'row' }}>Helpful links for Orion automation support</span>
            </SupportInformation>
          </div>
          <div className={classes.supportInformation}>
            <Box display="flex" ml={1}>
              {isSmallScreen ? (
                <IconButton color="primary" size="small" onClick={openSurvey} data-testid="feedback-button">
                  <FeedbackIcon />
                </IconButton>
              ) : (
                <Button
                  data-testid="feedback-button"
                  aria-label="feedback"
                  color="primary"
                  onClick={openSurvey}
                  startIcon={<FeedbackIcon />}
                >
                  Feedback Survey
                </Button>
              )}
            </Box>
          </div>
          <Typography variant="h3">Orion Application Request Form</Typography>
          <Typography variant="h6" align="center">
            Please read through this wiki complete all the steps from Step 1 to 5.1 before filling out this form.
          </Typography>
          <Link
            variant="h5"
            target="_blank"
            rel="noopener noreferrer"
            href="https://wiki.aa.com/bin/view/Orion/Azure%20Platform:%20Components%20and%20Diagrams%20/00%20-%20Process:%20Getting%20Started%20with%20Azure/"
          >
            Prerequisites for Orion Application Provisioning
          </Link>
          <br />
          <Typography variant="h5">Component Information</Typography>
          <FormControl variant="outlined">
            <FormGroup>
              <div className={classes.formGroup}>
                <AppCodePicker
                  onChange={async (values: any) => {
                    formik.setFieldValue('appName', values.appName);
                  }}
                  onBlur={() => formik.setTouched({ appName: true })}
                  data-testid="appName"
                />
              </div>
              <div className={classes.componentFormGroup}>
                <FormControl
                  required
                  error={Boolean(formik.errors.componentEmpty && formik.touched.componentEmpty)}
                  component="fieldset"
                  className={classes.componentFormGroup}
                >
                  <FormLabel component="legend">Components to Provision:</FormLabel>
                  <FormGroup row>
                    <OrionFormCheckbox fieldPropName="ADX" label="ADX" tooltip="Azure Data Explorer" />
                    <OrionFormCheckbox fieldPropName="AEH" label="AEH" tooltip="Azure Event Hubs" />
                    <OrionFormCheckbox fieldPropName="MOS" label="MOS" tooltip="MOSAIC Database" />
                    <OrionFormCheckbox fieldPropName="ASTRA" label="ASTRA" tooltip="DataStax ASTRA" />
                  </FormGroup>
                  <FormHelperText>Select one or multiple components</FormHelperText>
                </FormControl>
              </div>
            </FormGroup>
          </FormControl>
          <div className={classes.formGroup}>
            <div style={{ width: 350, paddingRight: 8 }}>
              <CmdbAppShortName
                onChange={async (values: any) => {
                  formik.setFieldValue('archerShortname', values.archerId);
                  formik.setFieldValue('product', values.productName);
                }}
                onBlur={() => formik.setTouched({ archerShortname: true })}
                data-testid="name"
              />
            </div>
            <div style={{ width: 350, paddingRight: 8 }}>
              <CostCenter
                onChange={async (values: any) => {
                  formik.setFieldValue('costCenter', values.costCenter);
                }}
                onBlur={() => formik.setTouched({ costCenter: true })}
                data-testid="cost"
              />
            </div>
            <div style={{ width: 350, paddingRight: 8 }}>
              <SquadInfo
                onChange={async (values: any) => {
                  formik.setFieldValue('squadName', values.name);
                }}
                onBlur={() => formik.setTouched({ squadName: true })}
                data-testid="squad"
              />
            </div>
          </div>
          <div className={classes.componentFormGroup}>
            <OrionFormDropdown fieldPropName="environment" label="Environment" placeholder="nonprod" required>
              <MenuItem value="N">NonProd</MenuItem>
              <MenuItem value="P">Prod</MenuItem>
            </OrionFormDropdown>
            <OrionFormDropdown fieldPropName="location" label="Location" placeholder="east" required>
              <MenuItem value="eastus">East</MenuItem>
              <MenuItem value="westus">West</MenuItem>
            </OrionFormDropdown>
            <OrionFormTextField
              fieldPropName="squadPrimary"
              label="Primary Squad Lead"
              placeholder="ex: 123456"
              required
            />
            <OrionFormTextField
              fieldPropName="squadSecondary"
              label="Secondary Squad Lead"
              placeholder="ex: 123456"
              required
            />
            <OrionFormTextField
              fieldPropName="dataOwner"
              label="Data Owner"
              placeholder="ex: 123456"
              required
              tooltip="AA ID of the data steward for this source-system. Typically, it should be a Level 5 manager from
                  business side"
            />
            <OrionFormTextField
              fieldPropName="cherwellTeam"
              label="Cherwell Team Name"
              placeholder=""
              required
              tooltip="For xMatters alerts. Find at aa.xmatters.com"
            />
            <OrionFormTextField
              fieldPropName="delivTeamEmail"
              label="Delivery Team Email"
              placeholder="ex: DL_Something@aa.com"
              required
            />
            <Tooltip
              disableFocusListener
              arrow
              title={
                <h3>Please use the Azure cost calculator to calculate how much you might spend on this initiative</h3>
              }
            >
              <TextField
                id="estimatedBudget"
                key="estimatedBudget"
                data-testid="estimatedBudget"
                required
                label="Estimated Budget"
                {...formik.getFieldProps('estimatedBudget')}
                placeholder="xxxxx.xx"
                variant="outlined"
                InputProps={{
                  startAdornment: <InputAdornment position="start">$</InputAdornment>,
                }}
                error={Boolean(formik.errors.estimatedBudget && formik.touched.estimatedBudget)}
                helperText={formik.errors.estimatedBudget}
                className={classes.componentFormGroup}
              />
            </Tooltip>
            <OrionFormTextField
              fieldPropName="productGroup"
              label="Product Group"
              placeholder="ex: Business Analytics"
              required
            />
            <OrionFormDropdown fieldPropName="prefix" label="Data Sensitivity" placeholder="none" required>
              <MenuItem value="NONE">None</MenuItem>
              <MenuItem value="PII">PII</MenuItem>
              <MenuItem value="SPII">SPII</MenuItem>
            </OrionFormDropdown>
            <TextField
              id="aaEmail"
              key="aaEmail"
              label="AA Email"
              placeholder="aaEmail"
              variant="outlined"
              className={classes.componentFormGroup}
              disabled
              value={employeeEmail}
              inputProps={{ 'aria-label': 'naked' }}
            />
          </div>
          {formik.values.ADX && <OrionFormSectionADX />}
          {formik.values.AEH && <OrionFormSectionAEH />}
          {formik.values.MOS && <OrionFormSectionMOS />}
          {formik.values.ASTRA && <OrionFormSectionASTRA />}

          <div className={classes.flexColumn}>
            {(!!success || !!error) && (
              <div>
                {success && (
                  <Alert severity="success" style={{ marginBottom: 10 }} data-testid="submitSuccessMsg">
                    Application provisioning has started. You will receive an email with the link to your DevOps
                    Pipeline in 1 minute.
                  </Alert>
                )}
                {error && (
                  <Alert aria-label="error" severity="error" style={{ marginBottom: 10 }} data-testid="submitErrorMsg">
                    {error}
                  </Alert>
                )}
              </div>
            )}
            {currentStep === 0 && !formik.isValid && (
              <Alert severity="error" style={{ marginTop: 10, marginBottom: 8 }} data-testid="formInvalidMsg">
                You have missed some inputs, please double check previous values
              </Alert>
            )}
            <div className={classes.flexRow}>
              {isLoading ? (
                <CircularProgress />
              ) : (
                <Button
                  type="submit"
                  data-testid="submitButton"
                  variant={loading ? 'text' : 'contained'}
                  color="primary"
                  className={classes.button}
                  disabled={loading || !formik.isValid || !formik.dirty || success}
                >
                  Submit
                </Button>
              )}
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );
};
