/*
 * Copyright 2020 The Backstage Authors
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import React, { MouseEventHandler, useState } from 'react';
import { HelpIcon, Link } from '@backstage/core-components';
import { useApp } from '@backstage/core-plugin-api';
import {
  Box,
  Button,
  DialogActions,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  makeStyles,
  Popover,
  Theme,
  Typography,
  useMediaQuery,
} from '@material-ui/core';

export interface LinkInformation {
  link: string;
  title: string;
}

type SupportButtonProps = {
  title?: string;
  children?: React.ReactNode;
  slackChannel?: string | string[];
  email?: string | string[];
  githubRepo?: string;
  link?: LinkInformation | LinkInformation[];
};

type RunwaySupportProps = {
  title?: string;
  link?: string;
  githubRepo?: string;
};

export type SupportButtonClassKey = 'popoverList';

const useStyles = makeStyles(
  {
    popoverList: {
      minWidth: 260,
      maxWidth: 400,
    },
    supportButton: {
      float: 'right',
    },
  },
  { name: 'BackstageSupportButton' },
);

const SupportIcon = ({ icon }: { icon: string | undefined }) => {
  const app = useApp();
  const Icon = icon ? (app.getSystemIcon(icon) ?? HelpIcon) : HelpIcon;
  return <Icon />;
};

const SupportListItem = ({ url, title, icon, name }: { url?: string; title: string; icon: string; name: string }) => {
  return (
    <ListItem>
      <ListItemIcon>
        <SupportIcon icon={icon} />
      </ListItemIcon>
      <ListItemText primary={title} secondary={url ? <Link to={url}>{name ?? url}</Link> : name} />
    </ListItem>
  );
};

export function SupportInformation(props: SupportButtonProps) {
  const { title, children, slackChannel, email, link, githubRepo } = props;

  const [popoverOpen, setPopoverOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState<Element | null>(null);
  const classes = useStyles();
  const isSmallScreen = useMediaQuery<Theme>(theme => theme.breakpoints.down('sm'));

  const onClickHandler: MouseEventHandler = event => {
    setAnchorEl(event.currentTarget);
    setPopoverOpen(true);
  };

  const popoverCloseHandler = () => {
    setPopoverOpen(false);
  };

  const slackChannels = slackChannel && Array.isArray(slackChannel) ? slackChannel : [slackChannel];
  const contactEmails = email && Array.isArray(email) ? email : [email];
  const links = link && Array.isArray(link) ? link : [link];

  return (
    <div className={classes.supportButton}>
      <Box display="flex" ml={1}>
        {isSmallScreen ? (
          <IconButton color="primary" size="small" onClick={onClickHandler} data-testid="support-button">
            <HelpIcon />
          </IconButton>
        ) : (
          <Button
            data-testid="support-button"
            aria-label="support"
            color="primary"
            onClick={onClickHandler}
            startIcon={<HelpIcon />}
          >
            Support
          </Button>
        )}
      </Box>
      <Popover
        data-testid="support-button-popover"
        open={popoverOpen}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        onClose={popoverCloseHandler}
      >
        <List className={classes.popoverList}>
          {title && (
            <ListItem alignItems="flex-start">
              <Typography variant="subtitle1">{title}</Typography>
            </ListItem>
          )}
          {React.Children.map(children, (child, i) => (
            <ListItem alignItems="flex-start" key={`child-${i}`}>
              {child}
            </ListItem>
          ))}
          {slackChannels &&
            slackChannels.map(
              (item, i) =>
                item && (
                  <SupportListItem
                    name={item}
                    url={`https://americanairlines.slack.com/channels/${item.replace('#', '')}`}
                    title="Slack"
                    icon="chat"
                    key={`item-${i}`}
                  />
                ),
            )}
          {contactEmails &&
            contactEmails.map(
              (item, i) =>
                item && <SupportListItem name={item} url={item} title="Email" icon="email" key={`item-${i}`} />,
            )}
          {links &&
            links.length > 0 &&
            links.map(
              (item, i) =>
                item && (
                  <SupportListItem name={item.title} url={item.link} title="Links" icon="docs" key={`item-${i}`} />
                ),
            )}
          {githubRepo && (
            <SupportListItem
              name={`AAInternal/${githubRepo}`}
              url={`https://github.com/AAInternal/${githubRepo}/issues`}
              title="GitHub"
              icon="github"
              key="item-github"
            />
          )}
        </List>
        <DialogActions>
          <Button color="primary" onClick={popoverCloseHandler}>
            Close
          </Button>
        </DialogActions>
      </Popover>
    </div>
  );
}

export function RunwaySupportInformation(props: RunwaySupportProps) {
  const title = props.title ?? 'Runway';
  const link = props.link ?? 'https://developer.aa.com/docs/default/component/runway';
  const githubRepo = props.githubRepo ?? 'runway';

  return (
    <SupportInformation
      slackChannel="#runway"
      githubRepo={githubRepo}
      link={[
        {
          title: `${title} Documentation`,
          link,
        },
      ]}
    />
  );
}
