import { createApiRef } from '@backstage/core-plugin-api';
import { OAuth2AAPingSSOApi } from '@runway/devkit';
import { ObserveServiceApi, BucketId, ClientId } from '@runway/devkit-common';

export interface TrackerApi {
  trackUrl(route: string): void;
}

export const trackerApiRef = createApiRef<TrackerApi>({
  id: 'app.runway.tracker',
});

export class Tracker implements TrackerApi {
  constructor(
    private ssoApi: OAuth2AAPingSSOApi,
    private observeService: ObserveServiceApi,
  ) {}

  trackUrl = (routeUrl: string) => {
    this.ssoApi.getAAProfile({ optional: true, instantPopup: true }).then(profile => {
      this.observeService.createRecord({
        client_id: ClientId.RUNWAY_FRONTEND,
        bucket_id: BucketId.PAGE_VIEW,
        actor: profile?.aaId ?? '-1',
        status: 'success',
        data: {
          url: routeUrl,
          referrer: document.referrer,
          user_agent: window.navigator.userAgent,
          user_id: profile?.aaId,
        },
      });
    });
  };

  callObserveService = (url: string, data: any) => {
    return fetch(url, {
      method: 'POST',
      headers: new Headers({
        'content-type': 'application/json',
      }),
      body: JSON.stringify(data),
    });
  };
}
