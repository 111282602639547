import React, { Dispatch, SetStateAction } from 'react';
import { Dialog, DialogContent, DialogTitle, DialogActions, Button } from '@material-ui/core';
import { CreateRancherProjectAndNamespace } from '../RancherManagement/CreateRancherProjectAndNamespace';

interface CreateRancherNamespaceDialogProps {
  isOpen: boolean;
  close(): void;
  setNamespace: Dispatch<SetStateAction<string>>;
  setSelectedCluster?: Dispatch<SetStateAction<string | null>>;
  availableNamespaces?: string[];
  setAvailableNamespaces?: Dispatch<SetStateAction<string[]>>;
  onChange?: (v: string) => void;
}

export const CreateRancherNamespaceDialog = ({
  isOpen,
  close,
  setNamespace,
  setSelectedCluster,
  availableNamespaces,
  setAvailableNamespaces,
  onChange,
}: CreateRancherNamespaceDialogProps) => {
  const handleClose = async () => {
    close();
  };

  return (
    <Dialog
      open={isOpen}
      fullWidth
      maxWidth="md"
      keepMounted
      onClose={handleClose}
      aria-labelledby="alert-dialog-slide-title"
      aria-describedby="alert-dialog-slide-description"
      aria-label="create-rancher-namespace-dialog"
    >
      <DialogTitle id="alert-dialog-slide-title">Create Rancher Project and Namespace</DialogTitle>
      <DialogContent>
        {isOpen && (
          <CreateRancherProjectAndNamespace
            setNamespace={setNamespace}
            setSelectedCluster={setSelectedCluster}
            onChange={onChange}
            setAvailableNamespaces={setAvailableNamespaces}
            availableNamespaces={availableNamespaces}
          />
        )}
      </DialogContent>
      <DialogActions>
        <Button color="primary" onClick={handleClose}>
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};
