import React from 'react';
import { Content, Header, HeaderLabel, InfoCard, Page } from '@backstage/core-components';
import { AMLKVFormContainer } from './AMLKeyVaultContainer';

export const AMLKVPage = () => {
  return (
    <Page themeId="home">
      <Header title="Azure Portal Key Vault" subtitle="AZ Key Vault Request">
        <HeaderLabel label="Owner" value="Orion Infra Team" />
        <HeaderLabel label="Lifecycle" value="Alpha" />
      </Header>
      <Content>
        <div style={{ maxWidth: 1200, margin: '0 auto' }}>
          <InfoCard>
            <AMLKVFormContainer subscription="" resourceGroup="" />
          </InfoCard>
        </div>
      </Content>
    </Page>
  );
};
