import { useEntity } from '@backstage/plugin-catalog-react';
import { Card, CardContent, CardHeader, Grid, makeStyles, Typography } from '@material-ui/core';
import React from 'react';
import { ObservabilityEntity } from '@runway/plugin-observability-common';

const useStyles = makeStyles(
  /* theme => */ {
    gridItemCard: {
      display: 'flex',
      flexDirection: 'column',
      height: 'calc(100% - 10px)', // for pages without content header
      marginBottom: '10px',
    },
    gridItemCardContent: {
      flex: 1,
    },
    cardHeader: {
      marginBottom: '20px',
    },
  },
);

export function BusinessTransactionStepsCard() {
  const { entity } = useEntity<ObservabilityEntity>();
  const classes = useStyles();

  const stepList = entity.spec.steps.map((item: string, index: number) => <li key={index}>{item}</li>);

  return (
    <Card className={classes.gridItemCard}>
      <CardHeader title="Business Transaction Steps" className={classes.cardHeader} />
      <CardContent className={classes.gridItemCardContent}>
        <Grid container>
          <Grid item>
            <Typography variant="body1" color="secondary">
              Steps to this transaction:
            </Typography>
            <ol>{stepList}</ol>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
}
