import { FieldExtensionComponentProps, useTemplateSecrets } from '@backstage/plugin-scaffolder-react';
import { Grid } from '@material-ui/core';
import { FieldValidation } from '@rjsf/utils';
import React from 'react';
import RepeatableSecretComponent, { Secret } from './RepeatableSecretComponent';

export const GithubSecretsFieldPicker = ({ formData, uiSchema }: FieldExtensionComponentProps<Secret[]>) => {
  const { setSecrets } = useTemplateSecrets();
  const predeterminedSecretsInput: string[] | undefined = uiSchema?.['ui:options']
    ?.predeterminedSecretsInput as string[];
  return (
    <div>
      <Grid item xs={12}>
        <RepeatableSecretComponent
          onChange={(value: Secret[]) => {
            const backstageScaffolderSecrets: Record<string, any> = {};
            value.forEach((item: Secret) => {
              backstageScaffolderSecrets[item.secretName] = item.secretValue;
            });
            setSecrets(backstageScaffolderSecrets);
          }}
          formData={formData}
          predeterminedSecretsInput={predeterminedSecretsInput}
        />
      </Grid>
    </div>
  );
};

export const GithubSecretsFieldPickerValidation = (value: string, validation: FieldValidation) => {
  const regexp = new RegExp('^[A-Za-z0-9_]+$');
  if (!regexp.test(value)) {
    validation.addError('Please use only Alphanumeric characters and underscores...');
  }
};
