import React from 'react';
import { useAsync } from 'react-use';
import { CircularProgress, FormHelperText, TextField } from '@material-ui/core';
import ErrorIcon from '@material-ui/icons/Error';
import { Alert, Autocomplete } from '@material-ui/lab';
import { useApi } from '@backstage/core-plugin-api';
import { SimpleApp, Squad360ServiceApiRef } from '../../services';
import { MissingAppToolTip } from './MissingAppTooltip';

export type OnChangeValue = {
  shortName: string;
  archerId: string;
  costCenter: string;
  cmdbAppFullName: string;
  productName: string;
};

interface CmdbAppShortNameProps {
  onChange(value: OnChangeValue): void;
  onBlur?(): void;
  disabled?: boolean | undefined;
  getOwnApps?: boolean | undefined;
  label?: string | undefined;
  width?: string | number | undefined;
  required?: boolean;
  initialValue?: OnChangeValue;
}

export const CmdbAppShortName: React.FC<CmdbAppShortNameProps> = ({
  onChange,
  onBlur,
  disabled = false,
  getOwnApps = false,
  label = 'Short Name',
  width = 300,
  required = true,
  initialValue = undefined,
}) => {
  const squad360 = useApi(Squad360ServiceApiRef);

  const archerOptions = useAsync(async () => {
    const employeeApps: SimpleApp[] = [];

    // try to fetch a list of employee specific apps
    try {
      const ownSupportedApps = await squad360.getOwnSupportedApps();
      if (getOwnApps) {
        return ownSupportedApps;
      }
      employeeApps.push(...ownSupportedApps);
    } catch (error) {
      // only throw the error if getOwnApps is true
      // otherwise fall back on the full application list as usual
      if (getOwnApps) throw error;
    }

    const allApps = await squad360.getAllApplicationsSimple();

    return [
      ...employeeApps,

      // filter out overlap from employee apps
      ...allApps.filter(app => !employeeApps.some(employeeApp => employeeApp.shortName === app.shortName)),
    ];
  }, [squad360, getOwnApps]);

  return (
    <>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <Autocomplete
          disabled={archerOptions.error !== undefined || disabled || archerOptions.loading}
          id="cmdbAppShortName"
          data-testid="cmdbAppShortName"
          disableClearable
          autoSelect
          getOptionLabel={option =>
            `${option.shortName} - ${option.name || option.cmdbAppFullName} (${option.srcRecId || option.archerId})`
          }
          defaultValue={initialValue}
          options={!!archerOptions.value ? archerOptions.value : []}
          onChange={(_: any, application: any) => {
            const out = {
              shortName: application.shortName,
              archerId: application.srcRecId,
              costCenter: application.costCenter ? application.costCenter.replace('-', '/') : '',
              cmdbAppFullName: application.name,
              productName: application.product?.name || null,
              pci: application.pci || null,
            };
            onChange(out);
          }}
          onBlur={onBlur}
          style={{ width: width, display: 'inline-flex' }}
          renderInput={params => <TextField required={required} {...params} label={label} variant="outlined" />}
        />
        {!!archerOptions.loading && <CircularProgress style={{ marginLeft: 10 }} />}
        {!!archerOptions.error && (
          <>
            <ErrorIcon style={{ marginLeft: 10, marginRight: 10, color: 'red' }} />
            <FormHelperText>There was an error getting the Short name</FormHelperText>
          </>
        )}
        {!archerOptions.loading && !archerOptions.error && <MissingAppToolTip />}
      </div>
      <div>
        {archerOptions.value?.length === 0 && (
          <>
            <Alert style={{ marginTop: 10 }} severity="error">
              <strong>
                There was an issue getting the Squad Info.{' '}
                <a href="https://squad360-ui.cloud.aa.com/" target="_blank">
                  Click <u>Squad360 to assign a squad to your AAID</u>
                </a>
              </strong>
            </Alert>
          </>
        )}
      </div>
    </>
  );
};
