import {
  FormControlLabel,
  Grid,
  IconButton,
  Radio,
  RadioGroup,
  Tooltip,
  Typography,
  createStyles,
  makeStyles,
  useTheme,
  withStyles,
} from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import React, { useState } from 'react';
import InfoIcon from '@material-ui/icons/Info';

const useStyles = makeStyles(() =>
  createStyles({
    radioGroup: {
      flexDirection: 'row',
    },
  }),
);

export enum IngressOptions {
  AKS_PUBLIC = 'aks-public',
  NONE = 'none',
}

const NoPaddingTooltip = withStyles({
  tooltip: {
    padding: 0,
    margin: 5,
  },
})(Tooltip);

export const CreateIngress = (props: { value: string; onChange: (value: string) => void }) => {
  const { onChange, ...other } = props;
  const classes = useStyles();
  const theme = useTheme();
  const [ingressOption, setIngressOption] = useState<string>(IngressOptions.AKS_PUBLIC);

  const handleIngressOptionChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setIngressOption(event.target.value);
    onChange(event.target.value);
  };

  return (
    <Grid container spacing={2} direction="row" justifyContent="flex-start" alignItems="center">
      <Typography aria-label="create-ingress" style={{ color: theme.palette.text.secondary, padding: '30px 20px' }}>
        Create Ingress?
      </Typography>
      <RadioGroup
        className={classes.radioGroup}
        {...other}
        onChange={handleIngressOptionChange}
        aria-label="radio-options"
        name="options"
        value={ingressOption}
      >
        <FormControlLabel
          value={IngressOptions.AKS_PUBLIC}
          aria-label="radio-btn-aks"
          control={<Radio />}
          label="AKS Public"
        />
        <FormControlLabel value={IngressOptions.NONE} aria-label="radio-btn-none" control={<Radio />} label="None" />
      </RadioGroup>
      <NoPaddingTooltip
        title={<Alert severity="info">Create an ingress to enable traffic to your service/application</Alert>}
      >
        <IconButton color="primary" size="small" aria-label="info-button">
          <InfoIcon />
        </IconButton>
      </NoPaddingTooltip>
    </Grid>
  );
};
