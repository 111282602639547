import React, { useState } from 'react';
import { Grid } from '@material-ui/core';
import { FieldExtensionComponentProps } from '@backstage/plugin-scaffolder-react';
import { ClusterSelector, NamespaceSelector } from '../../components';

export const ClusterPicker = ({
  onChange,
  formData,
}: FieldExtensionComponentProps<{ cluster: string | null; namespace: string | undefined }>) => {
  if (!formData) {
    onChange({ cluster: null, namespace: undefined });
  }
  const [selectedCluster, setSelectedCluster] = useState<string | null>(formData?.cluster ?? null);
  const [selectedNamespace, setSelectedNamespace] = useState<string | undefined>(formData?.namespace ?? undefined);
  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <ClusterSelector
          includeProd={false}
          cluster={selectedCluster}
          onClick={(value: string | null) => {
            setSelectedCluster(value);
          }}
        />
      </Grid>
      <Grid item xs={12}>
        <NamespaceSelector
          selectedCluster={formData?.cluster ? formData.cluster : (selectedCluster ?? null)}
          setSelectedCluster={setSelectedCluster}
          selectedNamespaceName={selectedNamespace}
          isCreateButtonVisible
          onChange={(value: string | undefined) => {
            setSelectedNamespace(value);
            return onChange({
              cluster: formData?.cluster ? formData.cluster : (selectedCluster ?? null),
              namespace: value,
            });
          }}
        />
      </Grid>
    </Grid>
  );
};
