import { createApiRef } from '@backstage/core-plugin-api';
import { IssuesListRespData } from '../types';

export const githubIssueApiRef = createApiRef<GithubIssueApi>({
  id: 'plugin.githubissues.service',
});

export type GithubIssueApi = {
  listIssues: ({
    token,
    owner,
    repo,
    first,
    last,
    after,
    before,
    branch,
    state,
    baseUrl,
  }: {
    token: string;
    owner: string;
    repo: string;
    first: number | null;
    last: number | null;
    after: string | null;
    before: string | null;
    branch?: string;
    state?: any;
    baseUrl: string | undefined;
  }) => Promise<{
    maxTotalItems: number;
    startCursor: string;
    endCursor: string;
    issuesData: IssuesListRespData;
  }>;
};
