import React from 'react';
import { useAsync } from 'react-use';
import { CircularProgress, FormHelperText, TextField, Tooltip } from '@material-ui/core';
import ErrorIcon from '@material-ui/icons/Error';
import { Alert, Autocomplete } from '@material-ui/lab';
import { orionServiceApiRef } from '../../service/orion.service';
import { useApi } from '@backstage/core-plugin-api';

export type OnChangeValue = {
  appName: string;
};

interface AppCodeProps {
  onChange(value: OnChangeValue): void;
  onBlur(): void;
  disabled?: boolean | undefined;
  getOwnApps?: boolean | undefined;
  label?: string | undefined;
  width?: string | number | undefined;
  required?: boolean;
  initialValue?: string;
}

export const AppCodePicker: React.FC<AppCodeProps> = ({
  onChange,
  onBlur,
  disabled = false,
  label = 'App Code',
  width = 300,
  required = true,
  initialValue = undefined,
}) => {
  const orion = useApi(orionServiceApiRef);
  const appCodeOptions = useAsync(async () => {
    const allAppCodes = await orion.getAppCodes();
    const finalAppCodes = allAppCodes.data;
    return finalAppCodes;
  }, [orion]);

  return (
    <>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <Tooltip
          interactive
          arrow
          placement="right"
          title={
            <h3>
              Don't see your App Code? Register it here: <br />
              <a href="https://developer.aa.com/ba-appcode-register" target="_blank">
                https://developer.aa.com/ba-appcode-register{' '}
              </a>
            </h3>
          }
        >
          <Autocomplete
            disabled={appCodeOptions.error !== undefined || disabled || appCodeOptions.loading}
            id="appCode"
            data-testid="appCode"
            disableClearable
            autoSelect
            defaultValue={initialValue}
            options={appCodeOptions.value ?? []}
            getOptionLabel={option => option.toString()}
            onChange={(_: any, application: string) => {
              onChange({ appName: application });
            }}
            onBlur={onBlur}
            style={{ width: width, display: 'inline-flex' }}
            renderInput={params => <TextField required={required} {...params} label={label} variant="outlined" />}
          />
        </Tooltip>
        {!!appCodeOptions.loading && <CircularProgress style={{ marginLeft: 10 }} />}
        {!!appCodeOptions.error && (
          <>
            <ErrorIcon style={{ marginLeft: 10, marginRight: 10, color: 'red' }} />
            <FormHelperText>There was an error getting the App Codes. Please refresh the page.</FormHelperText>
          </>
        )}
      </div>
      <div>
        {appCodeOptions.value?.length === 0 && (
          <>
            <Alert style={{ marginTop: 10 }} severity="error">
              <strong>There was an issue getting the App Codes. </strong>
            </Alert>
          </>
        )}
      </div>
    </>
  );
};
