import combineStatus from '../../combineStatus';
import { STATUS } from '../../Status';

export interface ContainersMetadata {
  status: STATUS | undefined;
  containerCount: number | undefined;
  healthyContainerCount: number | undefined;
}
export type Containers = Record<string, ContainersMetadata>;

export const getContainer = (containerStatuses: any, isKuma: boolean) => {
  if (isKuma) return containerStatuses.filter((container: any) => container.name === 'kuma-sidecar')[0];
  return containerStatuses.filter((container: any) => container.name !== 'kuma-sidecar')[0];
};

export const getContainerStatus = (item: any, isKuma: boolean) => {
  const podObject = item.resources?.filter((resource: { type: string }) => {
    return resource.type === 'pods';
  })[0];

  if (!podObject) return { status: STATUS.unretrievable, containerCount: undefined, healthyContainerCount: undefined };

  const containerStatus: STATUS[] = podObject.resources.map((pod: any) =>
    getContainer(pod.status.containerStatuses, isKuma).ready === true ? STATUS.healthy : STATUS.unhealthy,
  );

  const healthyContainerCount = containerStatus.filter(status => status === STATUS.healthy).length;
  return { status: combineStatus(containerStatus), containerCount: containerStatus.length, healthyContainerCount };
};
