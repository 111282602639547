import axios from 'axios';
import { createApiRef, IdentityApi } from '@backstage/core-plugin-api';
import { OAuth2AAPingSSOApi } from '@runway/devkit';

export type CreateExceptionsRequest = {
  subscriptionId: string;
  resourceGroup: string;
  requestedBy: string | undefined;
  isUnsubscribed: boolean;
  startDate?: string | undefined;
  endDate?: string | undefined;
};

export type DeleteExceptionRequest = {
  subscriptionId: string;
  resourceGroup: string;
};

export type GetExceptionRequest = {
  subscriptionId: string;
  resourceGroup: string;
};

export type ListExceptionsResponse = {
  SubscriptionID: string;
  ResourceGroup: string;
  RequestedBy: string | undefined;
  IsUnsubscribed: boolean;
  StartDate?: string | undefined;
  EndDate?: string | undefined;
  id: number | undefined;
};

export interface AzVMStartStopServiceApi {
  createException(req: CreateExceptionsRequest): Promise<string>;
  getListOfExceptionsForUser(userEmail?: string): Promise<ListExceptionsResponse[]>;
  getException(req: GetExceptionRequest): Promise<ListExceptionsResponse[]>;
  deleteException(req: DeleteExceptionRequest): Promise<any>;
}

export const azVMStartStopServiceRef = createApiRef<AzVMStartStopServiceApi>({
  id: 'plugin.cost-management.azvm.service',
});

export default class AzVMStartStopService implements AzVMStartStopServiceApi {
  constructor(
    private oAuthApi: OAuth2AAPingSSOApi,
    private backendUrl: string,
    private identityApi: IdentityApi,
  ) {}

  createException = async (req: CreateExceptionsRequest) => {
    const token = await this.oAuthApi.getAccessToken();

    let data = {};
    if (req.isUnsubscribed === true) {
      data = {
        resource_group: req.resourceGroup,
        subscription_id: req.subscriptionId,
        requested_by: 'dummmyValue',
        is_unsubscribed: req.isUnsubscribed,
      };
    } else {
      data = {
        resource_group: req.resourceGroup,
        subscription_id: req.subscriptionId,
        requested_by: 'dummmyValue',
        is_unsubscribed: req.isUnsubscribed,
        start_date: req.startDate,
        end_date: req.endDate,
      };
    }

    try {
      const res = await axios.post(`${this.backendUrl}/exception/create`, data, {
        headers: {
          authorization: `Bearer ${token}`,
        },
      });

      if (res.status === 200) {
        return 'Exception has been created';
      } else if (res.status === 201) {
        return `An exception already available for Resource Group: ${req.resourceGroup}. Please verify`;
      }

      throw Error();
    } catch (e) {
      return 'Error: Failed to create an exception';
    }
  };

  getListOfExceptionsForUser = async () => {
    const token = await this.oAuthApi.getAccessToken();
    const { email } = await this.identityApi.getProfileInfo();

    try {
      const res = await axios.get(`${this.backendUrl}/exception/user/query?email=${email}`, {
        headers: {
          authorization: `Bearer ${token}`,
        },
      });

      let dataWithId: ListExceptionsResponse[];
      if (res.status === 200 && typeof res.data !== 'string') {
        dataWithId = res.data.map((expectionRecord: ListExceptionsResponse, index: number) => {
          expectionRecord.id = index + 1;
          return expectionRecord;
        });
        return dataWithId;
      }
      return [];
    } catch (e: any) {
      throw new Error('Failed to retrieve list of exceptions');
    }
  };

  getException = async (req: GetExceptionRequest) => {
    const token = await this.oAuthApi.getAccessToken();

    try {
      const res = await axios.get(
        `${this.backendUrl}/exception/query?ResourceGroup=${req.resourceGroup}&SubscriptionID=${req.subscriptionId}`,
        {
          headers: {
            authorization: `Bearer ${token}`,
          },
        },
      );

      let dataWithId: ListExceptionsResponse[];
      if (res.status === 200 && typeof res.data !== 'string') {
        dataWithId = res.data.map((expectionRecord: ListExceptionsResponse, index: number) => {
          expectionRecord.id = index + 1;
          return expectionRecord;
        });
        return dataWithId;
      }
      return [];
    } catch (e: any) {
      throw new Error('Failed to retrieve list of exceptions');
    }
  };

  deleteException = async (req: DeleteExceptionRequest) => {
    const token = await this.oAuthApi.getAccessToken();

    try {
      const res = await axios.delete(
        `${this.backendUrl}/exception/delete?ResourceGroup=${req.resourceGroup}&SubscriptionID=${req.subscriptionId}`,
        {
          headers: {
            authorization: `Bearer ${token}`,
          },
        },
      );
      return res;
    } catch (e: any) {
      throw new Error('Failed to delete exception');
    }
  };
}
