import React, { useState } from 'react';
import { Grid, Button } from '@material-ui/core';
import { ManageArgoAppDialog } from '../ManageArgoAppDialog/ManageArgoAppDialog';
import { Entity } from '@backstage/catalog-model';

type Props = {
  entity: Entity;
  isGithubRepoAdmin: boolean;
};

export const ManageArgoButton = ({ entity, isGithubRepoAdmin }: Props) => {
  const [manageAppDialogOpen, setManageAppDialogOpen] = useState(false);
  const onClick = () => {
    setManageAppDialogOpen(true);
  };

  return (
    <Grid container>
      <Grid item>
        <Button
          disabled={!isGithubRepoAdmin}
          variant="contained"
          color="primary"
          onClick={onClick}
          aria-label="manage-button"
        >
          Manage Deployments
        </Button>
        <ManageArgoAppDialog isOpen={manageAppDialogOpen} close={() => setManageAppDialogOpen(false)} entity={entity} />
      </Grid>
    </Grid>
  );
};
