import React, { useEffect, useState } from 'react';
import { useApi } from '@backstage/core-plugin-api';
import { Grid, IconButton, useTheme } from '@material-ui/core';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import { aaPingSSOAuthApiRef, useTrackedRequest, ConfirmDeleteDialog } from '@runway/devkit';
import { KeyboardArrowDown, KeyboardArrowUp } from '@mui/icons-material';
import { BucketId, ClientId, PluginId } from '@runway/devkit-common';
import { AsyncState } from 'react-use/lib/useAsyncFn';
import { UpdateArgoAppForm } from '../UpdateArgoAppForm/UpdateArgoAppForm';
import { ArgoCDServiceApiRef } from '../../services/argocd.catalog.service';
import { Alert, AlertTitle } from '@material-ui/lab';
import { Progress } from '@backstage/core-components';
import { DeleteToolTip } from './DeleteToolTip';
import { UpdateToolTip } from './UpdateToolTip';
import Chip from '@material-ui/core/Chip';
import { makeStyles } from '@material-ui/core/styles';
import { ArgoData, DeleteStatus } from './utils';

const boxStyles = makeStyles({
  customBox: {
    height: 400,
    width: '100%',
    margin: 'auto',
  },
  centerText: {
    position: 'relative',
    bottom: '0px',
    textAlign: 'center',
    lineHeight: '350px',
  },
});

const useStyles = makeStyles({
  customChip: {
    margin: '0px',
  },
});

const DisplayMessageChip = (props: any | undefined) => {
  const { message } = props;
  const classes = useStyles();
  return <Chip label={message} className={classes.customChip} />;
};

const CopyText = (props: { appName: string; clusterName: string }) => {
  const { appName, clusterName } = props;
  return (
    <div>
      The action <strong>cannot be undone.</strong> This will permanently delete the <strong>{appName}</strong> from
      cluster <strong>{clusterName}</strong> .
      <br />
      <br />
      Please type <strong>{appName}</strong> to confirm.
      <br />
      <br />
    </div>
  );
};

export const ManageArgoAppTable = (props: {
  data: AsyncState<ArgoData[]>;
  isOpen: boolean;
  setClose: () => void;
  setLoading: (v: boolean) => void;
  setMessage: (v: string) => void;
  setSnackbarOpen: (v: boolean) => void;
  appSelector: string;
}) => {
  const { data, setClose, setLoading, setMessage, setSnackbarOpen, appSelector, isOpen } = props;
  const theme = useTheme();
  const argoCdService = useApi(ArgoCDServiceApiRef);
  const currentIdentity = useApi(aaPingSSOAuthApiRef);

  const { createRecord } = useTrackedRequest({
    bucket_id: BucketId.USE_METRIC,
    client_id: ClientId.RUNWAY_FRONTEND,
    pluginId: PluginId.ARGO_DELETE.id,
  });
  const [isConfirmationDialogOpen, setIsConfirmationDialogOpen] = useState<boolean>(false);
  const [expanded, setExpanded] = useState(false);
  const [argoAppName, setArgoAppName] = useState<string>('');
  const [clusterName, setClusterName] = useState<string>('');
  const [errorDetails, setErrorDetails] = useState<
    | ({
        argoInstance: string;
        argoAppName: string;
        alertType: 'error' | 'info';
      } & (
        | {
            projectError?: string;
            applicationError?: string;
          }
        | { errorMessage: string }
      ))
    | undefined
  >();
  const [selectedDeployment, setDeployment] = useState({ appName: '', clusterName: '', appSelector: '' });
  const [updateOptionsOpen, setUpdateOptionsOpen] = useState(false);
  const [statusMap, setStatusMap] = useState(new Map<string, DeleteStatus>());
  const [visible, setVisible] = useState(new Map<string, boolean>());
  const classes = boxStyles();

  const onClickHandler = (row: { row: { appName: string; clusterName: string } }) => {
    setArgoAppName(row.row.appName);
    setClusterName(row.row.clusterName);
    setIsConfirmationDialogOpen(true);
  };

  const handleDelete = async () => {
    setErrorDetails(undefined);
    setSnackbarOpen(false);
    setStatusMap(map => new Map(map.set(argoAppName, DeleteStatus.PENDING).set(clusterName, DeleteStatus.PENDING)));
    setVisible(visibleMap => new Map(visibleMap.set(argoAppName, false).set(clusterName, false)));
    setIsConfirmationDialogOpen(false);
    const userEmployeeId = (await currentIdentity.getAAProfile())?.aaId ?? '-1';
    const metadata = {
      argoAppName,
      clusterName,
      isAppDeleted: true,
    };
    try {
      setLoading(true);
      const response = await argoCdService.deleteArgoCd({ clusterName, appName: argoAppName });

      if (response.deleteAppDetails?.status === 'pending') {
        setErrorDetails({
          alertType: 'info',
          argoAppName: response.argoAppName,
          argoInstance: response.argoInstanceName,
          projectError: response.deleteProjectDetails?.message,
          applicationError: response.deleteAppDetails.message,
        });
      } else if (response.deleteProjectDetails?.status === 'failed' || response.deleteAppDetails?.status === 'failed') {
        setErrorDetails({
          alertType: 'error',
          argoAppName: response.argoAppName,
          argoInstance: response.argoInstanceName,
          projectError:
            response.deleteProjectDetails?.status === 'failed' ? response.deleteProjectDetails?.message : undefined,
          applicationError:
            response.deleteAppDetails?.status !== 'success' ? response.deleteAppDetails?.message : undefined,
        });
        setStatusMap(
          map => new Map(map.set(argoAppName, DeleteStatus.NOT_DELETED).set(clusterName, DeleteStatus.NOT_DELETED)),
        );
      } else {
        setMessage(
          `Successfully deleted application/project: ${response.argoAppName} on the cluster: ${response.argoInstanceName}.`,
        );
        setSnackbarOpen(true);
        setErrorDetails(undefined);
        setClose();
        setStatusMap(map => new Map(map.set(argoAppName, DeleteStatus.DELETED).set(clusterName, DeleteStatus.DELETED)));
      }

      setLoading(false);

      await createRecord(
        {
          userEmployeeId,
          data: { ...metadata, isProjectDeleted: response.deleteProjectDetails?.status !== 'failed' },
        },
        'success',
      );
    } catch (e: any) {
      setStatusMap(
        map => new Map(map.set(argoAppName, DeleteStatus.NOT_DELETED).set(clusterName, DeleteStatus.NOT_DELETED)),
      );
      setVisible(visibleMap => new Map(visibleMap.set(argoAppName, true).set(clusterName, true)));
      setLoading(false);
      setErrorDetails({
        alertType: 'error',
        argoAppName,
        argoInstance: clusterName,
        errorMessage: e.message ?? 'Error Deleting Argo Application',
      });
      await createRecord(
        { userEmployeeId, data: { ...metadata, isAppDeleted: false, isProjectDeleted: false } },
        'failed',
        e.stack,
      );
    }
  };

  const handleCancel = () => {
    setArgoAppName('');
    setClusterName('');
    setIsConfirmationDialogOpen(false);
  };

  let columns: GridColDef<ArgoData>[] = [];
  data.value?.forEach(() => {
    columns = [
      { field: 'appName', headerName: 'App Name', width: 300 },
      { field: 'clusterName', headerName: 'Cluster Name', width: 300 },
      {
        field: 'delete_update',
        headerName: 'Delete / Update',
        width: 300,
        renderCell: (row: any) => {
          return (
            <>
              <>
                <DeleteToolTip
                  handleClick={() => onClickHandler(row)}
                  title={`Delete ${row.row.appName} in ${row.row.clusterName}`}
                  appNameDeleteStatus={statusMap.get(row.row.appName) ?? DeleteStatus.NOT_DELETED}
                  clusterNameDeleteStatus={statusMap.get(row.row.clusterName) ?? DeleteStatus.NOT_DELETED}
                  appNameIsVisible={visible.get(row.row.appName) ?? true}
                  clusterNameIsVisible={visible.get(row.row.clusterName) ?? true}
                />
                <UpdateToolTip
                  handleClick={() => {
                    setDeployment({ appName: row.row.appName, clusterName: row.row.clusterName, appSelector });
                    setUpdateOptionsOpen(true);
                  }}
                  title={`Update ${row.row.appName} in ${row.row.clusterName}`}
                  appNameDeleteStatus={statusMap.get(row.row.appName) ?? DeleteStatus.NOT_DELETED}
                  clusterNameDeleteStatus={statusMap.get(row.row.clusterName) ?? DeleteStatus.NOT_DELETED}
                  appNameIsVisible={visible.get(row.row.appName) ?? true}
                  clusterNameIsVisible={visible.get(row.row.clusterName) ?? true}
                />
              </>
              {statusMap.get(row.row.appName) === DeleteStatus.PENDING &&
                statusMap.get(row.row.clusterName) === DeleteStatus.PENDING && (
                  <DisplayMessageChip message="Delete Pending..." />
                )}
            </>
          );
        },
      },
    ];
  });

  useEffect(() => {
    if (isOpen === false) {
      setErrorDetails(undefined);
    }
  }, [isOpen]);

  if (data.loading) {
    return (
      <>
        <div className={classes.customBox}>
          <div className={classes.centerText}>
            <h2>Loading...</h2>
          </div>
        </div>
        <Progress />
      </>
    );
  }
  if (data.error) {
    return <Alert severity="error">{data.error.message}</Alert>;
  }

  return (
    <>
      <div className={classes.customBox}>
        <DataGrid
          style={{ color: theme.palette.text.primary }}
          initialState={{
            pagination: {
              paginationModel: {
                pageSize: 5,
              },
            },
          }}
          rows={data.value ?? []}
          columns={columns}
          pageSizeOptions={[5]}
          getRowId={row => `${row.clusterName}-${row.appName}`}
          disableRowSelectionOnClick
        />
      </div>
      {isConfirmationDialogOpen && (
        <ConfirmDeleteDialog
          confirmationText={argoAppName}
          isOpen={isConfirmationDialogOpen}
          onDelete={handleDelete}
          onCancel={handleCancel}
        >
          <>
            <br />
            <Alert severity="warning">
              <AlertTitle>Removing Deployments</AlertTitle>
              This will delete all Argo CD Deployments from the Kubernetes Enterprise Rancher cluster using this app
              name.
            </Alert>
            <br />
            <CopyText appName={argoAppName} clusterName={clusterName} />
          </>
        </ConfirmDeleteDialog>
      )}
      {updateOptionsOpen && (
        <>
          <UpdateArgoAppForm
            close={() => setUpdateOptionsOpen(false)}
            isOpen={updateOptionsOpen}
            deployment={selectedDeployment}
          />
        </>
      )}
      {errorDetails ? (
        <Grid container direction="row" justifyContent="center" alignItems="center" style={{ paddingTop: '8px' }}>
          <Grid item md={11}>
            <Alert
              severity={errorDetails.alertType}
              aria-label={`${errorDetails.alertType}-alert`}
              action={
                <IconButton
                  color="inherit"
                  size="small"
                  onClick={() => setExpanded(prevExpanded => !prevExpanded)}
                  aria-label="dropdown"
                >
                  {expanded ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
                </IconButton>
              }
            >
              <AlertTitle>
                Argo Application {errorDetails.alertType === 'info' ? 'Pending Deletion' : 'Deletion Failed'}
              </AlertTitle>
              {errorDetails.alertType === 'error' && (
                <>
                  Reach out to us via slack #
                  <a
                    href="https://americanairlines.slack.com/archives/C0112F1JKNG"
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{ textDecoration: 'underline' }}
                  >
                    runway
                  </a>
                </>
              )}
              {expanded && (
                <div>
                  {'errorMessage' in errorDetails && (
                    <>
                      <br />
                      <strong>Some Error Occurred:</strong> {errorDetails.errorMessage}
                    </>
                  )}
                  {'applicationError' in errorDetails && (
                    <>
                      <br />
                      <strong>Argo Application Status:</strong> {errorDetails.applicationError}
                    </>
                  )}
                  {'projectError' in errorDetails && (
                    <>
                      <br />
                      <strong>Argo Project Status:</strong> {errorDetails.projectError}
                    </>
                  )}
                </div>
              )}
            </Alert>
          </Grid>
        </Grid>
      ) : null}
    </>
  );
};
