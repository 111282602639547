import axios, { AxiosInstance, AxiosResponse } from 'axios';
import { Logger, RestClientApi, RestClientError, RestClientRequestConfig, RestClientResponse } from './restClientApi';

/**
 * Safe wrapper class for rect service integrations
 */
export class RestClient implements RestClientApi {
  private axios: AxiosInstance;

  /**
   * @param logger generic logger
   */
  constructor(private logger: Logger) {
    this.axios = axios.create();
    this.axios.interceptors.response.use(this.httpResponseInterceptor, this.httpErrorInterceptor);
  }

  private httpErrorInterceptor = async (error: RestClientError | Error): Promise<RestClientError | Error> => {
    this.logger.error(error.message);
    if (this.isRestError(error)) {
      delete error.request?.headers?.Authorization;
      delete error.request?.headers?.authorization;
      delete error.request?._header;
      delete error.response?.request?.headers?.Authorization;
      delete error.response?.request?.headers?.authorization;
      delete error.response?.request?._headers;
      delete error.config?.headers?.Authorization;
      delete error.config?.headers?.authorization;
      delete error.request?._redirectable;
    }
    return Promise.reject(error);
  };

  private httpResponseInterceptor = async (response: AxiosResponse): Promise<AxiosResponse> => {
    return response;
  };

  /**
   * Make request from axios config
   * @param config client config
   */
  request<T = any, R = RestClientResponse<T>, D = any>(config: RestClientRequestConfig<D>): Promise<R> {
    return this.axios.request<T, R, D>(config);
  }

  /**
   * Request alias for get method
   * @param url
   * @param config client config
   */
  get<T = any, R = RestClientResponse<T>, D = any>(url: string, config?: RestClientRequestConfig<D>): Promise<R> {
    return this.axios.get<T, R, D>(url, config);
  }

  /**
   * Request alias for delete method
   * @param url
   * @param config client config
   */
  delete<T = any, R = RestClientResponse<T>, D = any>(url: string, config?: RestClientRequestConfig<D>): Promise<R> {
    return this.axios.delete<T, R, D>(url, config);
  }

  /**
   * Request alias for post method
   * @param url
   * @param data request payload
   * @param config client config
   */
  post<T = any, R = RestClientResponse<T>, D = any>(
    url: string,
    data?: D,
    config?: RestClientRequestConfig<D>,
  ): Promise<R> {
    return this.axios.post<T, R, D>(url, data, config);
  }

  /**
   * Request alias for put method
   * @param url
   * @param data request payload
   * @param config client config
   */
  put<T = any, R = RestClientResponse<T>, D = any>(
    url: string,
    data?: D,
    config?: RestClientRequestConfig<D>,
  ): Promise<R> {
    return this.axios.put<T, R, D>(url, data, config);
  }

  /**
   * Request alias for patch method
   * @param url
   * @param data request payload
   * @param config client config
   */
  patch<T = any, R = RestClientResponse<T>, D = any>(
    url: string,
    data?: D,
    config?: RestClientRequestConfig<D>,
  ): Promise<R> {
    return this.axios.patch<T, R, D>(url, data, config);
  }

  /**
   * Determines whether the payload is an error thrown by RestClient
   *
   * @param {*} payload The value to test
   *
   * @returns {boolean} True if the payload is an error thrown by RestClient, otherwise false
   */
  isRestError<T = any, D = any>(payload: any): payload is RestClientError<T, D> {
    const isObject = (thing: any) => thing !== null && typeof thing === 'object';
    return isObject(payload) && payload.isAxiosError === true;
  }
}
