import { identityApiRef, useApi } from '@backstage/core-plugin-api';
import { FieldExtensionComponentProps } from '@backstage/plugin-scaffolder-react';
import { FormControl, Grid, makeStyles, TextField, TextFieldProps, Typography } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import React, { useEffect, useState } from 'react';
import { useAsync } from 'react-use';
import { AzureSubscription, getAAId, VerticalDropdown } from '../../../components';
import { PaasFormFields, paasItemsFieldDefaultValues, paasSpRgItemsFieldsDefaultValues } from './types';

const useStyles = makeStyles(() => ({
  root: {
    maxWidth: 500,
    marginBottom: 10,
    padding: 10,
  },
  container: {
    marginBottom: 2,
  },
}));

export const paasAdminItemsFieldsDefaultValues = {
  env: 'nonprod',
  vertical: '',
  subscription: '',
  region: 'eastus',
};

export const PaasAdminItemsField = ({ formData, onChange }: FieldExtensionComponentProps<PaasFormFields>) => {
  if (!formData) {
    onChange({
      paas: true,
      ...paasAdminItemsFieldsDefaultValues,
      ...paasSpRgItemsFieldsDefaultValues,
      ...paasItemsFieldDefaultValues,
    });
  }
  const classes = useStyles();

  const identityApi = useApi(identityApiRef);
  const eid = useAsync(async () => {
    return await getAAId(identityApi);
  });
  const [env, setEnv] = useState(formData?.env ?? 'nonprod');
  const [region, setRegion] = useState(formData?.region ?? 'eastus');

  useEffect(() => {
    onChange({ ...formData!, region, env });
  }, [eid, formData, region, env, onChange]);

  const envOptions = [
    { title: 'Non-Prod', value: 'nonprod' },
    { title: 'Prod', value: 'prod' },
  ];
  const regionOptions = [
    { title: 'East US', value: 'eastus' },
    { title: 'West US', value: 'westus' },
  ];

  return (
    <Grid container spacing={3} className={classes.root}>
      <Grid item xs={12} className={classes.container}>
        <Typography variant="body1">General PaaS Information</Typography>
      </Grid>
      <Grid item xs={12} className={classes.container}>
        <AzureSubscription
          onChange={(values: any) => {
            onChange({ ...formData!, subscription: values.subscriptionId });
          }}
          employeeId={eid.value ?? undefined}
          disableUnavailableOptions={false}
        />
      </Grid>
      <Grid item xs={12} className={classes.container}>
        <FormControl variant="outlined">
          <VerticalDropdown
            onChange={(values: any) => {
              onChange({ ...formData!, vertical: values.vertical });
            }}
            initialValue={formData?.vertical}
          />
        </FormControl>
      </Grid>
      <Grid item xs={12} className={classes.container}>
        <Autocomplete
          id="environment"
          defaultValue={
            formData?.env
              ? envOptions.filter(item => formData.env === item.value)[0]
              : { title: 'Non-Prod', value: 'nonprod' }
          }
          onInputChange={(_, newInputValue) => {
            const filtered = envOptions.filter(item => newInputValue === item.title);
            setEnv(filtered[0]?.value);
            onChange({ ...formData!, env: filtered[0]?.value });
          }}
          options={envOptions}
          getOptionLabel={(option: { title: string; value: string }) => option.title}
          style={{ width: 300 }}
          disableClearable
          data-testid="environment"
          getOptionSelected={(option, value) => option.value === value.value}
          renderInput={(params: React.JSX.IntrinsicAttributes & TextFieldProps) => (
            <TextField {...params} label="Environment" variant="outlined" />
          )}
        />
      </Grid>
      <Grid item xs={12} className={classes.container}>
        <Autocomplete
          id="region"
          defaultValue={
            formData?.region
              ? regionOptions.filter(item => formData.region === item.value)[0]
              : { title: 'East US', value: 'eastus' }
          }
          onInputChange={(_, newInputValue) => {
            const filtered = regionOptions.filter(item => newInputValue === item.title);
            setRegion(filtered[0]?.value);
            onChange({ ...formData!, region: filtered[0]?.value });
          }}
          options={regionOptions}
          getOptionLabel={(option: { title: string; value: string }) => option.title}
          style={{ width: 300 }}
          disableClearable
          data-testid="region"
          getOptionSelected={(option, value) => option.value === value.value}
          renderInput={(params: React.JSX.IntrinsicAttributes & TextFieldProps) => (
            <TextField {...params} label="Region" variant="outlined" />
          )}
        />
      </Grid>
    </Grid>
  );
};
