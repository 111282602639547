import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import React from 'react';
import { DeploymentsMetadata, HpasMetadata, ReplicaSetsMetadata } from '../services';
import { STATUS, STATUS_ICON, STATUS_TEXT } from '../Status';

type MetadataConstraint = {
  replicas?: number | undefined;
  readyReplicas?: number | undefined;
  currentReplicas?: number;
  desiredReplicas?: number;
  name?: string | undefined;
  status: STATUS;
};

const determineTypeMetadata = (metadata: DeploymentsMetadata | HpasMetadata | ReplicaSetsMetadata) => {
  if ('desiredReplicas' in metadata)
    return { name: metadata.name, firstRep: metadata.currentReplicas, secondRep: metadata.desiredReplicas };

  if ('readyReplicas' in metadata)
    return { name: metadata.name, firstRep: metadata.readyReplicas, secondRep: metadata.replicas };
  if ('error' in metadata) return { name: metadata.name, error: metadata.error };
  return { name: metadata.name, firstRep: undefined, secondRep: undefined };
};

export function getClusterDetails<Metadata extends MetadataConstraint>(
  name: string,
  cluster: Metadata[],
): React.JSX.Element[] {
  const resultArray: React.JSX.Element[] = [];
  Object.values(cluster).forEach((deployment, index) => {
    const typeMetadata = determineTypeMetadata(deployment);
    if (typeMetadata.firstRep === undefined || typeMetadata.secondRep === undefined) {
      if (typeMetadata.error !== undefined) {
        resultArray.push(
          <li key={`${name}-${typeMetadata.name}-${index.toString()}`}>
            {name}: Name: {typeMetadata.name}, {typeMetadata.error}
          </li>,
        );
      } else {
        resultArray.push(
          <li key={`${name}-${typeMetadata.name}-${index.toString()}`}>
            {name}: Name: {typeMetadata.name}, replica-count information is missing
          </li>,
        );
      }
    } else {
      resultArray.push(
        <li key={`${name}-${typeMetadata.name}-${index.toString()}`}>
          {name}: Name: {typeMetadata.name}, {typeMetadata.firstRep}/{typeMetadata.secondRep} replica(s) ready
        </li>,
      );
    }
  });

  return resultArray;
}

export function getStatusDetails2D<P>(
  status: STATUS,
  clusters: Record<string, P[]> | undefined,
  details: (name: string, cluster: P[]) => React.ReactNode,
  resource: string,
): React.JSX.Element | string {
  if (status === STATUS.loading) return 'Loading...';
  if (status === STATUS.unretrievable) return `Unable to retrieve ${resource} information`;
  return <ul>{clusters && Object.entries(clusters).map(([name, cluster]) => details(name, cluster))}</ul>;
}

export function getStatusDetails<P>(
  status: STATUS,
  clusters: Record<string, P> | undefined,
  details: (cluster: P) => React.ReactNode,
  resource: string,
): React.JSX.Element | string {
  if (status === STATUS.loading) return 'Loading...';
  if (status === STATUS.unretrievable) return `Unable to retrieve ${resource} information`;
  return (
    <ul>
      {clusters &&
        Object.entries(clusters).map(([name, cluster]) => (
          <li key={name}>
            {name}: {details(cluster)}
          </li>
        ))}
    </ul>
  );
}

export const Layer = ({
  title,
  status,
  subheader,
}: {
  title: string;
  status: STATUS;
  subheader: string | React.JSX.Element;
}) => (
  <Card role="listitem" aria-labelledby={`dataFlow.layers.${title.replace(/ /g, '-')}`}>
    <CardHeader
      title={<span id={`dataFlow.layers.${title.replace(/ /g, '-')}`}>{title}</span>}
      avatar={
        <span role="status">
          {STATUS_ICON[status]}
          <span style={{ display: 'none' }}>{STATUS_TEXT[status]}</span>
        </span>
      }
      subheader={
        <span role="region" aria-label="Status Details">
          {subheader}
        </span>
      }
    />
  </Card>
);
