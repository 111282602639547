import React, { useState } from 'react';
import { InputLabel, Select, MenuItem, CircularProgress, FormHelperText } from '@material-ui/core';
import { adServiceApiRef } from '../../services/ad.service';
import { useAsync } from 'react-use';
import { useApi } from '@backstage/core-plugin-api';
import ErrorIcon from '@material-ui/icons/Error';

export type LDAPOnChange = {
  ldapGroup: string;
};

interface LDAPGroupDropdownProps {
  onChange(value: LDAPOnChange): void;
  error?: boolean | undefined;
  initialValue?: string | undefined;
  label?: string;
}

export const LDAPGroupDropdown: React.FC<LDAPGroupDropdownProps> = ({
  onChange,
  error,
  initialValue = undefined,
  label = 'LDAP Group',
}) => {
  const [ldapGroupSelected, setLdapGroup] = useState(initialValue || '');

  const adService = useApi(adServiceApiRef);
  const ldapGroups = useAsync(async () => {
    return await adService.getUsersGroupMembershipLDAP();
  }, []);

  return (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <InputLabel id="ldapGroup-label" required>
        {label}
      </InputLabel>
      <Select
        labelId="ldapGroup-label"
        value={ldapGroupSelected || ''}
        inputProps={{ 'data-testid': 'ldapGroup' }}
        error={error}
        disabled={!!ldapGroups.error}
        labelWidth={65}
        style={{ width: 300, display: 'inline-flex' }}
        onChange={e => {
          onChange({
            ldapGroup: e.target.value as string,
          });
          setLdapGroup(e.target.value as string);
        }}
      >
        {ldapGroups.value?.map(item => (
          <MenuItem value={item} key={item}>
            {item}
          </MenuItem>
        ))}
      </Select>
      {!!ldapGroups.loading && <CircularProgress style={{ marginLeft: 10 }} />}
      {!!ldapGroups.error && (
        <>
          <ErrorIcon style={{ marginLeft: 10, marginRight: 10, color: 'red' }} />
          <FormHelperText>There was an error getting the LDAP Groups</FormHelperText>
        </>
      )}
    </div>
  );
};
