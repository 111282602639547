import { createPlugin, createRoutableExtension } from '@backstage/core-plugin-api';
import { rootRouteRef } from './routes';

export const usermapPlugin = createPlugin({
  id: 'usermap',
});

export const UsermapPage = usermapPlugin.provide(
  createRoutableExtension({
    name: 'usermap',
    component: () => import('./components/UsermapComponent').then(m => m.UsermapComponent),
    mountPoint: rootRouteRef,
  }),
);
