import { Grid } from '@material-ui/core';
import { Alert, AlertTitle } from '@material-ui/lab';
import React, { useContext } from 'react';
import FormContext from '../FormContext';
import { ObjectLike, SuccessAlertProps } from '../types';
import { componentGridStyle } from '../styles';

/**
 * Use this component to display a successful alert in your form.
 * @param message text to display in the alert
 * @param style optional style object
 * @param size optional size of the component
 */
export function FormSuccessAlert<T extends ObjectLike>({
  style,
  size = 12,
  title,
  renderCallback,
}: SuccessAlertProps<T>) {
  const { state, ctx } = useContext(FormContext);
  if (!(state.status === 'success')) return <></>;
  return (
    <Grid item xs={12} sm={12} md={size} style={componentGridStyle}>
      <Alert severity="success" aria-label="success-message" style={style}>
        <AlertTitle>{title}</AlertTitle>
        <b aria-label="dynamic-success-message">{renderCallback(ctx.getValues())}</b>
      </Alert>
    </Grid>
  );
}
