import React from 'react';
import { PodsMetadata } from '../services';
import { STATUS } from '../Status';
import { getStatusDetails, Layer } from './Layer';

const getClusterDetails = (cluster: PodsMetadata) => {
  if (cluster.healthyPodCount === undefined || cluster.podCount === undefined)
    return 'pod-count information is missing';
  return `${cluster.healthyPodCount}/${cluster.podCount} pod(s) healthy`;
};

export const PodsLayer = ({
  status,
  clusters,
}: {
  status: STATUS;
  clusters: Record<string, PodsMetadata> | undefined;
}) => (
  <Layer
    title="Pods"
    status={status}
    subheader={getStatusDetails<PodsMetadata>(status, clusters, getClusterDetails, 'pod')}
  />
);
