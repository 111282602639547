import { amlCIFormValueTypes } from './amlCIFormValueTypes';

export const amlCIFormInitializeValues = ({
  subscription,
  resourceGroup,
  amlWorkspace,
  computeSize,
  computeType,
}: {
  subscription: string;
  resourceGroup: string;
  amlWorkspace: string;
  computeSize: string;
  computeType: string;
}): amlCIFormValueTypes => ({
  subscription,
  resourceGroup,
  amlWorkspace,
  computeSize,
  computeType,
});
