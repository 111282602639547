import React, { useState } from 'react';
import { InputLabel, Select, MenuItem, FormControl, TextField, Grid, FormLabel } from '@material-ui/core';
import location from './location.json';
import department from './department.json';
import srvFunction from './function.json';
import environment from './environment.json';

export type OnChangeValueServerNaming = {
  locationName: string;
  departmentName: string;
  functionName: string;
  environmentName: string;
  secuenceNumber: string;
  serverName: string;
};

interface ServerNamingDropdownProps {
  onChange(value: OnChangeValueServerNaming): void;
  error?: boolean | undefined;
  required?: boolean;
  initialValue?: OnChangeValueServerNaming;
}

export const ServerNamingDropdown: React.FC<ServerNamingDropdownProps> = ({
  onChange,
  error,
  required = true,
  initialValue = undefined,
}) => {
  const [locationNamingSelected, setLocationNaming] = useState(
    initialValue?.locationName ? initialValue?.locationName : '',
  );
  const [departmentNamingSelected, setDepartmentNaming] = useState(
    initialValue?.departmentName ? initialValue.departmentName : '',
  );
  const [functionNamingSelected, setFunctionNaming] = useState(
    initialValue?.functionName ? initialValue?.functionName : '',
  );
  const [environmentNamingSelected, setEnvironmentNaming] = useState(
    initialValue?.environmentName ? initialValue?.environmentName : '',
  );
  const [secuenceNumberSelected, setSecuenceNumber] = useState(
    initialValue?.secuenceNumber ? initialValue?.secuenceNumber : '',
  );

  return (
    <div>
      <FormLabel>Server Name</FormLabel>
      <Grid container spacing={2}>
        <Grid item>
          <FormControl required>
            <InputLabel id="department-label">Department</InputLabel>
            <Select
              labelId="department-label"
              id="department"
              inputProps={{ 'data-testid': 'department' }}
              value={departmentNamingSelected || ''}
              required={required}
              label="Department"
              error={error}
              labelWidth={120}
              style={{ width: 250 }}
              onChange={e => {
                onChange({
                  locationName: locationNamingSelected,
                  departmentName: e.target.value as string,
                  functionName: functionNamingSelected,
                  environmentName: environmentNamingSelected,
                  secuenceNumber: secuenceNumberSelected,
                  serverName: [
                    e.target.value as string,
                    locationNamingSelected,
                    functionNamingSelected,
                    environmentNamingSelected,
                    secuenceNumberSelected,
                  ].join(''),
                });
                setDepartmentNaming(e.target.value as string);
              }}
            >
              {department.map(item => (
                <MenuItem value={item.abbreviation} key={item.key}>
                  {item.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item>
          <FormControl required>
            <InputLabel id="location-label">Location</InputLabel>
            <Select
              labelId="location-label"
              id="location"
              inputProps={{ 'data-testid': 'location' }}
              value={locationNamingSelected || ''}
              required={required}
              label="Location"
              error={error}
              labelWidth={120}
              style={{ width: 250 }}
              onChange={e => {
                onChange({
                  locationName: e.target.value as string,
                  departmentName: departmentNamingSelected,
                  functionName: functionNamingSelected,
                  environmentName: environmentNamingSelected,
                  secuenceNumber: secuenceNumberSelected,
                  serverName: [
                    departmentNamingSelected,
                    e.target.value as string,
                    functionNamingSelected,
                    environmentNamingSelected,
                    secuenceNumberSelected,
                  ].join(''),
                });
                setLocationNaming(e.target.value as string);
              }}
            >
              {location.map(item => (
                <MenuItem value={item.abbreviation} key={item.key}>
                  {item.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item>
          <FormControl required>
            <InputLabel id="function-label">Function</InputLabel>
            <Select
              labelId="function-label"
              id="function"
              inputProps={{ 'data-testid': 'function' }}
              value={functionNamingSelected || ''}
              required={required}
              label="Function"
              error={error}
              labelWidth={120}
              style={{ width: 250 }}
              onChange={e => {
                onChange({
                  locationName: locationNamingSelected,
                  departmentName: departmentNamingSelected,
                  functionName: e.target.value as string,
                  environmentName: environmentNamingSelected,
                  secuenceNumber: secuenceNumberSelected,
                  serverName: [
                    departmentNamingSelected,
                    locationNamingSelected,
                    e.target.value as string,
                    environmentNamingSelected,
                    secuenceNumberSelected,
                  ].join(''),
                });
                setFunctionNaming(e.target.value as string);
              }}
            >
              {srvFunction.map(item => (
                <MenuItem value={item.abbreviation} key={item.key}>
                  {item.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item>
          <FormControl required>
            <InputLabel id="environment-label">Environment</InputLabel>
            <Select
              labelId="environment-label"
              id="environment"
              inputProps={{ 'data-testid': 'environment' }}
              value={environmentNamingSelected || ''}
              required={required}
              label="Environment"
              error={error}
              labelWidth={120}
              style={{ width: 250 }}
              onChange={e => {
                onChange({
                  locationName: locationNamingSelected,
                  departmentName: departmentNamingSelected,
                  environmentName: e.target.value as string,
                  functionName: functionNamingSelected,
                  secuenceNumber: secuenceNumberSelected,
                  serverName: [
                    departmentNamingSelected,
                    locationNamingSelected,
                    functionNamingSelected,
                    e.target.value as string,
                    secuenceNumberSelected,
                  ].join(''),
                });
                setEnvironmentNaming(e.target.value as string);
              }}
            >
              {environment.map(item => (
                <MenuItem value={item.abbreviation} key={item.key}>
                  {item.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item>
          <FormControl>
            <TextField
              id="outlined-basic"
              inputProps={{ 'data-testid': 'sequence' }}
              label="Sequence Number"
              variant="outlined"
              required
              value={secuenceNumberSelected || ''}
              onChange={e => {
                onChange({
                  locationName: locationNamingSelected,
                  departmentName: departmentNamingSelected,
                  environmentName: environmentNamingSelected,
                  functionName: functionNamingSelected,
                  secuenceNumber: e.target.value as string,
                  serverName: [
                    departmentNamingSelected,
                    locationNamingSelected,
                    functionNamingSelected,
                    environmentNamingSelected,
                    e.target.value as string,
                  ].join(''),
                });
                setSecuenceNumber(e.target.value as string);
              }}
            />
          </FormControl>
        </Grid>
      </Grid>
    </div>
  );
};
