import {
  Box,
  Button,
  Chip,
  Collapse,
  Drawer,
  Grid,
  IconButton,
  Link,
  makeStyles,
  Table,
  TableCell,
  TableRow,
  Typography,
  useTheme,
} from '@material-ui/core';
import React from 'react';
import { useAsync } from 'react-use';
import { graphql } from '@octokit/graphql';
import { configApiRef, githubAuthApiRef, useApi } from '@backstage/core-plugin-api';
import jsyaml from 'js-yaml';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import CloseIcon from '@material-ui/icons/Close';
import softwareTemplateFunctionality from './softwareTemplateFunctionality.json';
import softwareTemplateFunctionalitySets from './softwareTemplateFunctionalitySets.json';
import CancelIcon from '@material-ui/icons/Cancel';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import RemoveIcon from '@material-ui/icons/Remove';
import { generatePath } from 'react-router';
import { LinkButton } from '@backstage/core-components';
import { Alert } from '@material-ui/lab';
import { catalogApiRef } from '@backstage/plugin-catalog-react';
import gitUrlParse from 'git-url-parse';

type SoftwareTemplateFunctionality = {
  name: string;
  criticalBaselineControls?: number;
};

const useStyles = makeStyles({
  paper: {
    padding: '2em',
  },
  header: {
    display: 'flex',
    flexDirection: 'row',
    paddingBottom: '2em',
  },
  button: {
    textTransform: 'none',
    justifyContent: 'flex-start',
    fontWeight: 'bold',
  },
  icon: {
    fontSize: 20,
  },
  content: {
    width: '100%',
  },
  success: {
    color: 'green',
  },
  failure: {
    color: 'red',
  },
  table: {
    width: '100%',
  },
  tableRow: {
    width: '100%',
  },
  tableCell: {
    padding: '5px',
  },
  drawer: {
    maxWidth: '800px',
  },
  alert: {
    marginBottom: '10px',
  },
});

function FunctionalityIcon(props: {
  repoData: any;
  softwareTemplateFunctionalitySets: any;
  row: SoftwareTemplateFunctionality;
}) {
  const classes = useStyles();
  const rowName = props.row.name;
  if (
    props.repoData.value &&
    props.softwareTemplateFunctionalitySets[props.repoData.value][rowName] &&
    props.softwareTemplateFunctionalitySets[props.repoData.value][rowName].value === false
  ) {
    return <RemoveIcon />;
  } else if (props.repoData.value && props.softwareTemplateFunctionalitySets[props.repoData.value][rowName]) {
    return <CheckCircleIcon className={classes.success} />;
  }
  return <CancelIcon className={classes.failure} />;
}

export const DetailsDrawerComponent = (props: { templateProps: any; templateOwner: any }): React.JSX.Element => {
  const [state, setState] = React.useState(false);
  const classes = useStyles();
  const toggleDrawer = (open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
    if (
      event.type === 'keydown' &&
      ((event as React.KeyboardEvent).key === 'Tab' || (event as React.KeyboardEvent).key === 'Shift')
    ) {
      return;
    }

    setState(open);
  };
  const href = generatePath(`/create/templates/default/:templateName`, {
    templateName: props.templateProps.name,
  });
  const config = useApi(configApiRef);
  const ghecAuthApi = useApi(githubAuthApiRef);
  const githubGraphUrl = config.getString('github.ghec.graphUrl');
  const catalogApi = useApi(catalogApiRef);
  const repos = useAsync(async () => {
    const entity = await catalogApi.getEntityByRef({
      namespace: 'default',
      kind: 'template',
      name: props.templateProps.name,
    });
    const entityName = entity?.metadata.annotations?.['backstage.io/view-url'] as string;
    const gitUrlParseOut = gitUrlParse(entityName);
    const [ghecAccessToken] = await Promise.all([ghecAuthApi.getAccessToken()]);
    const path = gitUrlParseOut.filepath && `${gitUrlParseOut.filepath}`;
    const { repository }: any = await graphql(
      `
        {
          repository(name: "${gitUrlParseOut.name}", owner: "${gitUrlParseOut.owner}") {
            template: object(expression: "${gitUrlParseOut.ref}:${path}") {
              ... on Blob {
                text
              }
            }
          }
        }
      `,
      {
        baseUrl: githubGraphUrl,
        headers: {
          authorization: `token ${ghecAccessToken}`,
        },
      },
    );
    const resp: any = jsyaml.load(repository.template.text);
    return resp.metadata.functionalitySet;
  });

  function Row(rowProps: { row: SoftwareTemplateFunctionality; index: number }) {
    const { row, index } = rowProps;
    const data = softwareTemplateFunctionalitySets as any;
    const [open, setOpen] = React.useState(false);
    const rowName = row.name;
    const runwayTheme = useTheme();
    return (
      <React.Fragment>
        <TableRow
          style={
            index % 2
              ? { background: runwayTheme.palette.background.default }
              : { background: runwayTheme.palette.background.paper }
          }
          className={classes.tableRow}
        >
          <TableCell component="th" scope="row" className={classes.tableCell}>
            {rowName}
            <strong>
              {data[repos.value][rowName] &&
                data[repos.value][rowName].value &&
                ` - ${data[repos.value][rowName].value}`}
            </strong>
          </TableCell>
          <TableCell align="center" className={classes.tableCell}>
            {row.criticalBaselineControls && (
              <Chip
                label={`CBC.${row.criticalBaselineControls.toString().length > 1 ? row.criticalBaselineControls : `0${row.criticalBaselineControls}`}`}
                color="primary"
                size="small"
                clickable
                title={`CBC.${row.criticalBaselineControls}`}
                onClick={() => window.open('https://wiki.aa.com/bin/view/Cybersecurity/Bakers%20Dozen/', '_blank')}
              />
            )}
          </TableCell>
          <TableCell align="right" className={classes.tableCell}>
            <FunctionalityIcon repoData={repos} softwareTemplateFunctionalitySets={data} row={row} />
          </TableCell>
          <TableCell className={classes.tableCell}>
            {data[repos.value][rowName] && data[repos.value][rowName].description && (
              <Button aria-label="expand row" size="small" title="Open specific details" onClick={() => setOpen(!open)}>
                {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />} Details
              </Button>
            )}
          </TableCell>
        </TableRow>
        <TableRow className={classes.tableRow}>
          <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
            <Collapse in={open} timeout="auto" unmountOnExit>
              <Box margin={0}>
                <Typography
                  variant="body2"
                  gutterBottom
                  component="div"
                  // className={classes.description}
                >
                  {data[repos.value][rowName] && data[repos.value][rowName].description}
                </Typography>
              </Box>
            </Collapse>
          </TableCell>
        </TableRow>
      </React.Fragment>
    );
  }

  const drawerContents = () => (
    <>
      <div className={classes.header}>
        <Grid container direction="column" justifyContent="flex-start" alignItems="flex-start">
          <Grid item>
            <Typography variant="h5">{props.templateProps.title}</Typography>
          </Grid>
        </Grid>
        <IconButton key="dismiss" title="Close the drawer" onClick={() => setState(false)} color="inherit">
          <CloseIcon />
        </IconButton>
      </div>
      <div>
        <Grid container direction="column" justifyContent="flex-start" alignItems="flex-start">
          <Grid item>
            <Alert severity="info" className={classes.alert}>
              It is the responsibility of each team to ensure that proper security measures are in place for their
              applications. Use of this tool does not imply all compliance measure have be accounted for. Please ensure
              you're familiar with the{' '}
              <Link
                href="https://wiki.aa.com/bin/view/Cybersecurity/Bakers%20Dozen/"
                target="_blank"
                title="Critical Baseline Controls Link"
              >
                Critical Baseline Controls.
              </Link>{' '}
              <br />
              Please click on the details button to learn more about the specifics on what the templates provide.
            </Alert>
          </Grid>
        </Grid>
      </div>
      <div className={classes.content}>
        {/* <TableContainer component={Paper}> */}
        <Table className={classes.table} aria-label="simple table">
          <tbody>
            {softwareTemplateFunctionality.map((row: SoftwareTemplateFunctionality, index: number) => (
              <Row key={row.name} row={row} index={index} />
            ))}
          </tbody>
        </Table>
      </div>
    </>
  );

  return (
    <React.Fragment>
      {repos.value && (
        <Button color="primary" title="Open Details on Template" onClick={toggleDrawer(true)}>
          Details
        </Button>
      )}
      <Drawer anchor="right" open={state} onClose={toggleDrawer(false)}>
        <Box m={2} className={classes.drawer}>
          {drawerContents()}
        </Box>
        <LinkButton
          color="primary"
          to={href}
          variant="outlined"
          aria-label={`Choose ${props.templateProps.title}`}
          style={{ margin: '2em' }}
        >
          Choose
        </LinkButton>
      </Drawer>
    </React.Fragment>
  );
};
