import {
  Containers,
  Deployments,
  getContainerStatus,
  getDeploymentStatus,
  getHpaStatus,
  getPodStatus,
  getReplicaSetStatus,
  getServicesStatus,
  Hpas,
  Pods,
  ReplicaSets,
  Services,
} from '.';
import { Status } from '../../Status';
import { K8sItemsResponse } from '../kubernetes.service';

export const getStatus = (items: K8sItemsResponse[]): Status => {
  const clusters = items;
  const pods: Pods = {};
  const replicaSets: ReplicaSets = {};
  const services: Services = {};
  const externalServices: Services = {};
  const deployments: Deployments = {};
  const containers: Containers = {};
  const kumaSideCars: Containers = {};
  const hpas: Hpas = {};

  clusters.forEach((i: { cluster: { name: string } }) => {
    pods[i.cluster.name] = getPodStatus(i);
    replicaSets[i.cluster.name] = getReplicaSetStatus(i);
    services[i.cluster.name] = getServicesStatus(i, false);
    externalServices[i.cluster.name] = getServicesStatus(i, true);
    deployments[i.cluster.name] = getDeploymentStatus(i);
    hpas[i.cluster.name] = getHpaStatus(i);
    containers[i.cluster.name] = getContainerStatus(i, false);
    kumaSideCars[i.cluster.name] = getContainerStatus(i, true);
  });

  return { pods, replicaSets, services, externalServices, deployments, hpas, containers, kumaSideCars };
};
