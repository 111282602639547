import { CircularProgress } from '@material-ui/core';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import React from 'react';
import {
  ContainersMetadata,
  DeploymentsMetadata,
  HpasMetadata,
  PodsMetadata,
  ReplicaSetsMetadata,
  ServicesMetadata,
} from './services';

export enum STATUS {
  healthy = 'healthy',
  partiallyHealthy = 'partiallyHealthy',
  unhealthy = 'unhealthy',
  unretrievable = 'unretrievable',
  loading = 'loading',
}

export const STATUS_TEXT = {
  [STATUS.healthy]: 'Healthy',
  [STATUS.partiallyHealthy]: 'Partially healthy',
  [STATUS.unhealthy]: 'Unhealthy',
  [STATUS.unretrievable]: 'Unable to retrieve information',
  [STATUS.loading]: 'Loading',
};

export const STATUS_ICON = {
  [STATUS.healthy]: <FiberManualRecordIcon fontSize="large" style={{ color: 'green' }} />,
  [STATUS.partiallyHealthy]: <FiberManualRecordIcon fontSize="large" style={{ color: 'orange' }} />,
  [STATUS.unhealthy]: <FiberManualRecordIcon fontSize="large" style={{ color: 'red' }} />,
  [STATUS.unretrievable]: <FiberManualRecordIcon fontSize="large" style={{ color: 'gray' }} />,
  [STATUS.loading]: <CircularProgress size={30} />,
};

export type Status = {
  pods: Record<string, PodsMetadata>;
  replicaSets: Record<string, ReplicaSetsMetadata[]>;
  services: Record<string, ServicesMetadata[]>;
  externalServices: Record<string, ServicesMetadata[]>;
  deployments: Record<string, DeploymentsMetadata[]>;
  hpas: Record<string, HpasMetadata[]>;
  containers: Record<string, ContainersMetadata>;
  kumaSideCars: Record<string, ContainersMetadata>;
};

export type AllStatus = Record<string, STATUS>;
