import React from 'react';
import { Entity } from '@backstage/catalog-model';
import { Route, Routes } from 'react-router';
import IssuePage from './IssuePage';
import { GITHUB_ISSUE_ANNOTATION } from './useProjectName';
import { MissingAnnotationEmptyState, useEntity } from '@backstage/plugin-catalog-react';

export const isGithubIssueAvailable = (entity: Entity) =>
  Boolean(entity.metadata.annotations?.[GITHUB_ISSUE_ANNOTATION]);

type Props = {
  /** @deprecated The entity is now grabbed from context instead */
  entity?: Entity;
};

export const Router = (_props: Props) => {
  const { entity } = useEntity();
  return !isGithubIssueAvailable(entity) ? (
    <MissingAnnotationEmptyState annotation={GITHUB_ISSUE_ANNOTATION} />
  ) : (
    <Routes>
      <Route path="/" element={<IssuePage entity={entity} />} />
    </Routes>
  );
};
