import React, { useRef, useState } from 'react';
import { Formik, Form, FormikProps } from 'formik';
import { Alert } from '@material-ui/lab';
import { Button, Typography, MenuItem, Link } from '@material-ui/core';
import { useStyles } from '../OrionRequestForm/orionFormStyles';
import { amlCIFormValueTypes } from './amlCIFormValueTypes';
import { amlCIFormValidationSchema } from './amlCIFormValidationSchema';
import { AMLCIFormDropdown } from './AMLCIFormDropdown';
import { ResourceGroupDropdown } from '../ResourceGroupDropdown/ResourceGroupDropdown';
import { SubscriptionDropdown } from '../SubscriptionDropdown/SubscriptionDropdown';
import { AMLWorkspaceDropdown } from '../AMLWorkspaceDropdown/AMLWorkspaceDropdown';

type Props = {
  initialValues: amlCIFormValueTypes;
  onSubmit: (values: amlCIFormValueTypes) => void;
  loading: boolean;
  success: boolean;
  error?: string;
};

export const AMLCIForm = ({ initialValues, onSubmit, loading, success, error }: Props) => {
  const classes = useStyles();
  const formRef = useRef<FormikProps<amlCIFormValueTypes>>(null);
  const [isLoading, setIsLoading] = useState(false);

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={values => {
        setIsLoading(true);
        try {
          onSubmit(values);
        } finally {
          setIsLoading(false);
        }
      }}
      validationSchema={amlCIFormValidationSchema}
      innerRef={formRef}
    >
      {formik => (
        <Form className={classes.flexColumn}>
          <Typography variant="h3">AML Compute Instance Request</Typography>
          <br />
          <SubscriptionDropdown
            onChange={async (values: { subscription: string }) => {
              formik.setFieldValue('subscription', values.subscription);
            }}
            onBlur={() => formik.setTouched({ subscription: true })}
            required
          />
          <ResourceGroupDropdown
            onChange={async (values: { resourceGroup: string }) => {
              formik.setFieldValue('resourceGroup', values.resourceGroup);
            }}
            onBlur={() => formik.setTouched({ resourceGroup: true })}
            disabled={formik.values.subscription.length === 0}
            required
            subscriptionId={formik.values.subscription}
          />
          <AMLWorkspaceDropdown
            onChange={async (values: { amlWorkspace: string }) => {
              formik.setFieldValue('amlWorkspace', values.amlWorkspace);
            }}
            onBlur={() => formik.setTouched({ amlWorkspace: true })}
            disabled={formik.values.resourceGroup.length === 0}
            required
            resourceGroup={formik.values.resourceGroup}
          />
          <AMLCIFormDropdown fieldPropName="computeType" label="Type" placeholder="CPU" required>
            <MenuItem value="CPU">CPU</MenuItem>
            <MenuItem value="GPU">GPU</MenuItem>
          </AMLCIFormDropdown>
          <AMLCIFormDropdown fieldPropName="computeSize" label="Size" placeholder="XS" required>
            <MenuItem value="XS">Extra Small</MenuItem>
            <MenuItem value="S">Small</MenuItem>
            <MenuItem value="M">Medium</MenuItem>
            <MenuItem value="L">Large</MenuItem>
          </AMLCIFormDropdown>
          <div className={classes.flexColumn}>
            {(!!success || !!error) && (
              <div>
                {success && (
                  <Alert severity="success" style={{ marginBottom: 10 }} data-testid="submitSuccessMsg">
                    Compute Instance provisioning has started.{' '}
                    <Link
                      href="https://github.com/AAInternal/orion-ml-provisioning/actions/workflows/aml_ci_provision.yaml"
                      rel="noopener noreferrer"
                      target="_blank"
                    >
                      You can track its progress here.
                    </Link>
                  </Alert>
                )}
                {error && (
                  <Alert aria-label="error" severity="error" style={{ marginBottom: 10 }} data-testid="submitErrorMsg">
                    {error}
                  </Alert>
                )}
              </div>
            )}
            {!formik.isValid && !formik.errors && (
              <Alert severity="error" style={{ marginTop: 10, marginBottom: 8 }} data-testid="formInvalidMsg">
                You have missed some inputs, please double check previous values
              </Alert>
            )}
            <div className={classes.flexRow}>
              <Button
                type="submit"
                variant={loading ? 'text' : 'contained'}
                color="primary"
                className={classes.button}
                disabled={isLoading || !formik.isValid || !formik.dirty || success}
              >
                Submit
              </Button>
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );
};
