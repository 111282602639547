import { createPlugin, createRoutableExtension } from '@backstage/core-plugin-api';

import { rootRouteRef } from './routes';

export const assetManagementPlugin = createPlugin({
  id: 'assetManagement',
  routes: {
    root: rootRouteRef,
  },
});

export const AssetManagementPage = assetManagementPlugin.provide(
  createRoutableExtension({
    name: 'AssetManagementPage',
    component: () => import('./components/AssetManagement').then(m => m.AssetManagementComponent),
    mountPoint: rootRouteRef,
  }),
);
