import React, { useEffect, useState } from 'react';
import { LinearProgress, TextField } from '@material-ui/core';
import { Alert, Autocomplete } from '@material-ui/lab';
import { configApiRef, useApi } from '@backstage/core-plugin-api';
import axios from 'axios';
import { useAsync } from 'react-use';
import { FieldValidation } from '@rjsf/utils';
import { Squad360ServiceApiRef, aaPingSSOAuthApiRef } from '../../../services';

type ProjectAndNamepsace = {
  name: string;
  namespace: string;
};

export const Namespace = (props: { cluster: string; formData: string; onChange: (namespace: string) => void }) => {
  const { cluster, formData, onChange } = props;

  const ssoApi = useApi(aaPingSSOAuthApiRef);
  const squad360 = useApi(Squad360ServiceApiRef);
  const config = useApi(configApiRef);
  const [availableNamespaces, setAvailableNamespaces] = useState<string[]>([]);
  const [selectedNamespace, setSelectedNamespace] = useState<string>(formData);

  const filterNamespaces = async (projectsAndNamespaces: ProjectAndNamepsace[]) => {
    const ownSupportedApps = await squad360.getOwnSupportedApps();
    const appShortNames: string[] = ownSupportedApps.map((archerApp: any) => archerApp.shortName).filter(n => n);
    const returnedNamespaces: string[] = [];
    for (const object of projectsAndNamespaces) {
      for (const appShortName of appShortNames) {
        if (object.namespace.includes(appShortName.toLowerCase()) || object.name.includes(appShortName.toLowerCase())) {
          returnedNamespaces.push(object.namespace);
        }
      }
    }
    return returnedNamespaces;
  };

  const getNamespaces = useAsync(async () => {
    if (!cluster) {
      return;
    }
    try {
      const token = await ssoApi.getAccessToken();
      const resp: any = await axios.get(
        `${config.getString('clusterService.baseUrl')}/projects?clusterNames=${cluster}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      );
      const projectsAndNamespaces: ProjectAndNamepsace[] = resp.data;
      if (projectsAndNamespaces.length > 0) {
        const filteredNamespacesResp = await filterNamespaces(projectsAndNamespaces);
        setAvailableNamespaces(filteredNamespacesResp);
      }
    } catch (error: any) {
      throw new Error('Cannot retrieve namespaces');
    }
  }, [cluster]);

  useEffect(() => {
    if (selectedNamespace && !availableNamespaces.includes(selectedNamespace)) {
      setAvailableNamespaces([selectedNamespace, ...availableNamespaces]);
      onChange(selectedNamespace);
    }
    // will cause infite renders if all deps are in the array
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formData]);

  return (
    <>
      <Autocomplete
        id="namespace"
        data-testid="namespace_selector"
        aria-label="namespace-dropdown"
        options={availableNamespaces}
        value={selectedNamespace}
        onChange={(_: any, namespace: any) => {
          setSelectedNamespace(namespace);
          onChange(namespace);
        }}
        onInputChange={(_: any, namespace: string) => {
          onChange(namespace);
        }}
        getOptionLabel={option => option}
        getOptionSelected={option => option}
        style={{ width: '100%' }}
        renderInput={params => <TextField required {...params} label="Rancher Namespace" variant="outlined" />}
      />
      {getNamespaces.loading && (
        <LinearProgress
          style={{
            marginTop: 30,
            marginLeft: 10,
            marginRight: 10,
            width: '100%',
          }}
        />
      )}
      {getNamespaces.error && <Alert severity="error">Cannot get namespaces for cluster {cluster}</Alert>}
    </>
  );
};

export const namespaceValidation = (value: string, validation: FieldValidation) => {
  if (!value) {
    validation.addError('Please select a namespace');
  }
};
