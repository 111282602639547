export const verticalDropdownItems = [
  {
    name: 'Technology and Transformation',
    value: 'dt',
    key: 1,
  },
  {
    name: 'Airline Operations Technology',
    value: 'aot',
    key: 2,
  },
  {
    name: 'Enterprise Technology',
    value: 'ets',
    key: 3,
  },
  {
    name: 'Business Analytics',
    value: 'ba',
    key: 4,
  },
  {
    name: 'Corporate Technology',
    value: 'corp',
    key: 5,
  },
  {
    name: 'Customer Technology',
    value: 'ct',
    key: 6,
  },
  {
    name: 'Cyber Security and Risk Technology',
    value: 'cstrm',
    key: 7,
  },
  {
    name: 'Regionals',
    value: 'rgn',
    key: 8,
  },
  {
    name: 'Office of the CTO',
    value: 'octo',
    key: 9,
  },
];
