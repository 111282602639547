import axios from 'axios';
import { createApiRef } from '@backstage/core-plugin-api';
import { OAuth2AAPingSSOApi } from '@runway/devkit';

export type VdiPoolListResponseEntry = {
  display_name: string;
  name: string;
  id: string;
  description: string;
};

export type VdiPoolListResponse = VdiPoolListResponseEntry[];

export interface VdiPoolListApi {
  retrievePoolList(): Promise<any>;
}

export const vdiPoolListServiceApiRef = createApiRef<VdiPoolListApi>({
  id: 'plugin.create-project.vdi-pool-list.service',
});

export default class VdiPoolList implements VdiPoolListApi {
  constructor(
    private oAuthApi: OAuth2AAPingSSOApi,
    private backendUrl: string,
  ) {}

  retrievePoolList = async () => {
    const token = await this.oAuthApi.getAccessToken();

    let res = {};
    await axios
      .get(`${this.backendUrl}/pools`, {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      })
      .then(function handleResponse(response) {
        res = response.data;
      })
      .catch(function handleError(error) {
        if (error.message) {
          res = {
            error: true,
            msg: `${error.message || 'Error'}: ${
              error.response?.data.msg || error.response?.data.message || error.response?.data
            }`,
          };
        } else if (error.request) {
          res = {
            error: true,
            msg: error.request,
          };
        } else {
          const response = error.response;
          res = {
            error: true,
            msg: `Error ${response.status}: ${response.data}`,
          };
        }
      });

    return res;
  };
}
