import axios from 'axios';
import { IdentityApi, ProfileInfo, createApiRef } from '@backstage/core-plugin-api';
import { AzureSignInLogEntry } from '@runway/devkit-common';

export type Entry = {
  subjectAreaId: number;
  subjectAreaCode: string;
  subjectAreaDesc: string;
  subjectAreaFullName: string;
  environment: string;
  needApproval: string;
  cherwellTeam: string;
  adGrps: string[];
  azDlsAdGrp: string;
  archerIds: string;
};

export type AdServiceResponse = {
  status: string;
  successEntries: any | undefined;
  errorEntries: any | undefined;
  alreadyAddedEntries: any | undefined;
};

export type AzureADGroupMembership = {
  user: string;
  outputGroups: { groupId: string; groupName: string }[];
};

type EmailData = {
  subject: any | undefined;
  body: any | undefined;
};

export interface AdServiceApi {
  getAccessToData(
    accessType: string,
    myGroupList: Entry[],
    emailData: EmailData,
    accountNum?: string,
  ): Promise<AdServiceResponse>;
  getUsersGroupMembershipAzureAD(): Promise<AzureADGroupMembership>;
  getUsersGroupMembershipLDAP(): Promise<string[]>;
  getServicePrincipalSignIns(servicePrincipalName: string): Promise<AzureSignInLogEntry[]>;
}

export const adServiceApiRef = createApiRef<AdServiceApi>({
  id: 'plugin.access-control.ad.service',
});

export default class AdService implements AdServiceApi {
  constructor(
    private backendUrl: string,
    private identityApi: IdentityApi,
  ) {}

  getAccessToData = async (accessType: string, myGroupList: Entry[], emailData: EmailData, accountNum?: string) => {
    const aaId = ((await this.identityApi.getProfileInfo()) as unknown as ProfileInfo & { aaId: string }).aaId;
    if (aaId === undefined) {
      throw new Error();
    }

    let accountToAdd = aaId;

    // If "myself" was chosen, then request on behalf of the requesting
    // username.  Otherwise, request on behalf on what was entered.
    if (accountNum !== undefined) {
      accountToAdd = accountNum;
    }

    const { token } = await this.identityApi.getCredentials();
    const res = await axios.post(
      `${this.backendUrl}/api/activeDirectoryService/addUserToGroup`,
      {
        accessType: accessType,
        accountToAdd: accountToAdd,
        requestingAcct: aaId,
        groups: myGroupList,
        emailData,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    );

    if (res.status !== 200) {
      throw new Error();
    }

    return res.data;
  };

  getUsersGroupMembershipAzureAD = async () => {
    const profile = (await this.identityApi.getProfileInfo()) as unknown as ProfileInfo & { aaId: string };
    const { token } = await this.identityApi.getCredentials();
    const res = await axios.get(
      `${this.backendUrl}/api/activeDirectoryService/getUserMembership/azure/${profile.aaId}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    );

    if (res.status !== 200) {
      throw new Error('Cannot get User group membership for Azure AD');
    }

    return res.data;
  };

  getUsersGroupMembershipLDAP = async () => {
    const profile = (await this.identityApi.getProfileInfo()) as unknown as ProfileInfo & { aaId: string };
    const { token } = await this.identityApi.getCredentials();
    const userId = profile?.aaId;
    const name = profile?.displayName;
    const res = await axios.get(
      `${this.backendUrl}/api/activeDirectoryService/getUserMembership/ldap/${userId}/${name}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    );

    if (res.status !== 200) {
      throw new Error('Cannot get User group membership for LDAP');
    }

    return res.data;
  };

  getServicePrincipalSignIns = async (servicePrincipalName: string): Promise<AzureSignInLogEntry[]> => {
    const { token } = await this.identityApi.getCredentials();
    const res = await axios.get<AzureSignInLogEntry[]>(
      `${this.backendUrl}/api/activeDirectoryService/service-principals/${servicePrincipalName}/sign-ins`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    );
    return res.data;
  };
}
