import axios from 'axios';
import { IdentityApi, createApiRef } from '@backstage/core-plugin-api';
import { OAuth2AAPingSSOApi } from '@runway/devkit';
import { validateChangeNumResp } from '@runway/devkit-backend';

export type TaniumResponse = {
  id: string;
  vmName: string;
  ipAddress: string;
  osName: string;
  location: string;
  environment: string;
  vendor: string;
  appName: string;
  appShortName: string;
  appOwner: string;
  architect: string;
  managingDirector: string;
  vicePresident: string;
  productServiceName: string;
  lastRebooted: string;
  criticality: string;
  lastSeenAt: string;
  userName: string;
};

export type PatchRequestVm = {
  vmName: string;
  shortName: string;
  serverEnv: string;
  crNumber: string;
  reason: string;
  appOwner: string;
  appArchitect: string;
  notifyOwners: boolean;
};

type VmPatchJobStatusResponseDTO = {
  jobId: number;
  started: string;
  finished: string;
  status: string;
};

export type VmPatchJobStatusResponseSuccess = {
  'Job ID': number;
  Started: string;
  Finished: string;
  Status: string;
};

export type VmPatchJobStatusResponseError = {
  error: string;
};

export type PatchJobStatus = {
  id: number | null;
  status: string;
};

export interface PatchNowServiceApi {
  getVm(vmName: string): Promise<TaniumResponse>;

  patchNow(patchRequest: PatchRequestVm[]): Promise<PatchJobStatus>;

  validateChangeNum(changeId: number): Promise<validateChangeNumResp>;

  getPatchJobStatus(
    jobId: string,
    env: string,
  ): Promise<VmPatchJobStatusResponseSuccess | VmPatchJobStatusResponseError>;
}

export const patchNowServiceApiRef = createApiRef<PatchNowServiceApi>({
  id: 'plugin.infrastructure.patchnow.service',
});

export default class PatchNowService implements PatchNowServiceApi {
  constructor(
    private oAuthApi: OAuth2AAPingSSOApi,
    private identityApi: IdentityApi,
    private backendUrl: string,
    private baseBackendUrl: string,
  ) {}

  getVm = async (vmName: string) => {
    const token = await this.oAuthApi.getAccessToken();
    const res = await axios.get(`${this.backendUrl}/manage/vm/${vmName}`, {
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
    });
    return res.data;
  };

  patchNow = async (patchRequest: PatchRequestVm[]) => {
    const token = await this.oAuthApi.getAccessToken();
    const response = await axios.patch(`${this.backendUrl}/manage/vm/patch`, patchRequest, {
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
    });
    return response.data;
  };

  validateChangeNum = async (changeId: number): Promise<validateChangeNumResp> => {
    const { token } = await this.identityApi.getCredentials();
    const response = await axios.get(`${this.baseBackendUrl}/api/cloudElevatedAccess/validateChangeNum/${changeId}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  };

  getPatchJobStatus = async (
    jobId: string,
    env: string,
  ): Promise<VmPatchJobStatusResponseSuccess | VmPatchJobStatusResponseError> => {
    const token = await this.oAuthApi.getAccessToken();
    const res = await axios.get(`${this.backendUrl}/manage/vm/check-patch-job-status/${jobId}/${env}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    const data = res.data as VmPatchJobStatusResponseDTO | VmPatchJobStatusResponseError;
    if ('jobId' in data) {
      return {
        'Job ID': data.jobId,
        Started: data.started,
        Finished: data.finished,
        Status: data.status.charAt(0).toUpperCase() + data.status.slice(1),
      };
    }
    return {
      error: data.error,
    };
  };
}
