import React, { FC } from 'react';
import { Grid } from '@material-ui/core';
import { Page, Content, ContentHeader } from '@backstage/core-components';
import { IssuesTable } from '../IssueTable';
import { Entity } from '@backstage/catalog-model';
import { RunwaySupportInformation } from '@runway/devkit';

const IssuePage: FC<{ entity: Entity }> = ({ entity }) => (
  <Page themeId="tool">
    <Content>
      <ContentHeader title="Github Issues plugin">
        <RunwaySupportInformation />
      </ContentHeader>
      <Grid container spacing={3} direction="column">
        <Grid item>
          <IssuesTable entity={entity} />
        </Grid>
      </Grid>
    </Content>
  </Page>
);

export default IssuePage;
