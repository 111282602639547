import { createPlugin, createRoutableExtension } from '@backstage/core-plugin-api';

import { rootRouteRef } from './routes';

export const migrationGuidancePlugin = createPlugin({
  id: 'migration-guidance',
  routes: {
    root: rootRouteRef,
  },
});

export const MigrationGuidancePage = migrationGuidancePlugin.provide(
  createRoutableExtension({
    name: 'MigrationGuidancePage',
    component: () => import('./components/MigrationFormComponent').then(m => m.MigrationFormComponent),
    mountPoint: rootRouteRef,
  }),
);
