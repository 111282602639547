import {
  Button,
  Card,
  CardActions,
  CardContent,
  Checkbox,
  Chip,
  CircularProgress,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  FormLabel,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  Link,
  ListItemText,
  MenuItem,
  Popover,
  Radio,
  RadioGroup,
  Select,
  TextField,
  Typography,
  withStyles,
} from '@material-ui/core';
import { FieldExtensionComponentProps } from '@backstage/plugin-scaffolder-react';
import { FieldValidation } from '@rjsf/utils';
import React, { useEffect, useState, useRef } from 'react';
import { useApi } from '@backstage/core-plugin-api';
import { useAsyncFn } from 'react-use';
import { Alert } from '@material-ui/lab';
import InfoIcon from '@material-ui/icons/Info';
import { debounce } from 'lodash';
import AddIcon from '@material-ui/icons/Add';
import { ClusterSelector, NamespaceSelector } from '../../components';
import { GithubEnterpriseClientApiGraphRef } from '../../services';

export type ReturnedData = {
  vaultName: string | undefined;
  adminAdGroup: string | undefined;
  readerAdGroup?: string;
  hostClusters?: string[];
  k8s_namespace?: string;
  adSecretEngine?: boolean;
  authmethods?: AuthMethods[];
  githubRepoNames?: string[];
  metadata?: Metadata | undefined;
};

type Metadata = {
  appShortname?: string | undefined;
  squadName?: string | undefined;
  squadId?: string | undefined;
  date?: string | undefined;
};
enum Environment {
  Nonprod = 'n',
  Prod = 'p',
}

export enum AuthMethods {
  SHARED = 'Kubernetes Shared',
  GITHUB_OIDC = 'Github OIDC',
}

const authMethodsArray = Object.values(AuthMethods);
const secretsEngineArray = ['Active Directory Secrets Engine'];

export const SecretVaultForm = ({ onChange, formContext }: FieldExtensionComponentProps<ReturnedData>) => {
  const shortNameValue = formContext?.formData?.cmdbAppShortName?.shortName.toLowerCase() ?? undefined;
  const [label, setLabel] = useState<string>();
  const [env, setEnv] = useState<string>(Environment.Prod);
  const [returnedData, setReturnedData] = useState<ReturnedData>({
    vaultName: undefined,
    adminAdGroup: '',
    readerAdGroup: '',
  });

  const [selectedCluster, setSelectedCluster] = useState<string | undefined>(formContext?.formData?.cluster);
  const [selectedNamespace, setSelectedNamespace] = useState<string | undefined>(formContext?.formData?.namespace);
  const [authMethods, setAuthMethods] = React.useState<string[]>([]);
  const [secretsEngine, setSecretsEngine] = React.useState<string[]>([]);
  const [showSummary, setShowSummary] = useState(false);
  const [labelError, setLabelError] = useState(false);
  const [adDescAnchorEl, setAdDescAnchorEl] = React.useState(null);
  const [authAndSecretAnchorEl, setAuthAndSecretAnchorEl] = React.useState(null);

  const handleADDescPopoverOpen = (event: any) => {
    setAdDescAnchorEl(event.currentTarget);
  };

  const handleAdDescPopoverClose = () => {
    setAdDescAnchorEl(null);
  };
  const openAdDesc = Boolean(adDescAnchorEl);

  const handleAuthAndSecretPopoverOpen = (event: any) => {
    setAuthAndSecretAnchorEl(event.currentTarget);
  };

  const handleAuthAndSecretPopoverClose = () => {
    setAuthAndSecretAnchorEl(null);
  };
  const openAuthAndSecret = Boolean(authAndSecretAnchorEl);

  const handlesecretEngineSelect = (event: any) => {
    const {
      target: { value },
    } = event;
    const secretEngineCopy = typeof value === 'string' ? value.split(',') : value;
    setSecretsEngine(secretEngineCopy);
    if (secretEngineCopy.includes('Active Directory Secrets Engine')) {
      setReturnedData(prev => ({ ...prev, adSecretEngine: true }));
    } else {
      const copyReturnedData = { ...returnedData };
      delete copyReturnedData.adSecretEngine;
      setReturnedData(copyReturnedData);
    }
  };

  const handleAuthSelect = (event: any) => {
    const {
      target: { value },
    } = event;
    setAuthMethods(typeof value === 'string' ? value.split(',') : value);
    if (Array.isArray(value) && value.length > 0) {
      if (value.length === 1 && !value.includes(AuthMethods.SHARED)) {
        const copyReturnedData = { ...returnedData };
        delete copyReturnedData.k8s_namespace;
        delete copyReturnedData.hostClusters;
        copyReturnedData.authmethods = value;
        setReturnedData(copyReturnedData);
      } else if (value.length === 1 && !value.includes(AuthMethods.GITHUB_OIDC)) {
        const copyReturnedData = { ...returnedData };
        delete copyReturnedData.githubRepoNames;
        copyReturnedData.authmethods = value;
        setReturnedData(copyReturnedData);
      } else {
        setReturnedData(prev => ({ ...prev, authmethods: value }));
      }
    } else if (Array.isArray(value) && value.length === 0) {
      const copyReturnedData = { ...returnedData };
      delete copyReturnedData.authmethods;
      delete copyReturnedData.k8s_namespace;
      delete copyReturnedData.hostClusters;
      delete copyReturnedData.githubRepoNames;
      setReturnedData(copyReturnedData);
    }
  };

  const handleEnvironmentChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEnv(event.target.value);
  };

  const handleVaultLabelChange = ({ target: { value } }: any) => {
    setLabel(value.toLowerCase());
    setReturnedData(prev => ({
      ...prev,
      adminAdGroup: value ? `L_${shortNameValue}_${value.toLowerCase()}_SM_Admin` : '',
      readerAdGroup: value ? `L_${shortNameValue}_${value.toLowerCase()}_SM_Reader` : '',
    }));
    const regex = '^[a-z0-9]+$';
    if (!value.toLowerCase()?.match(regex)) {
      setLabelError(true);
    } else {
      setLabelError(false);
    }
  };

  const handleClusterChange = (value: any) => {
    setSelectedCluster(value);
  };

  const handleNamespaceChange = (value: any) => {
    setSelectedNamespace(value);
    setReturnedData(prev => ({ ...prev, hostClusters: [selectedCluster] as string[], k8s_namespace: value }));
  };

  const handleexpandsummary = () => {
    setShowSummary(!showSummary);
  };
  const [repoNames, setRepoNames] = useState<string[]>([]);
  const [newRepoName, setNewRepoName] = useState('');
  const [repoNameValue, setRepoNameValue] = useState<string>('');
  const handleAddRepoName = () => {
    if (newRepoName.trim() !== '') {
      setRepoNames(prev => [...prev, newRepoName.trim()]);
      setNewRepoName('');
      if (returnedData.githubRepoNames) {
        setReturnedData(prev => ({
          ...prev,
          githubRepoNames: [...(prev.githubRepoNames as string[]), newRepoName.trim()],
        }));
      } else {
        setReturnedData(prev => ({ ...prev, githubRepoNames: [newRepoName.trim()] }));
      }
      setRepoNameValue('');
    }
  };
  const handleKeyDownAddRepoName = (event: any) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      handleAddRepoName();
    }
  };
  const handleDeleteRepoName = (index: any) => {
    const newRepoNames = [...repoNames];
    newRepoNames.splice(index, 1);
    setRepoNames(newRepoNames);
    const returnedDataCopy = { ...returnedData };
    if (newRepoNames.length === 0) {
      delete returnedDataCopy.githubRepoNames;
      setReturnedData(returnedDataCopy);
    } else {
      setReturnedData(prev => ({
        ...prev,
        githubRepoNames: newRepoNames,
      }));
    }
  };

  const githubEnterpriseClientGraph = useApi(GithubEnterpriseClientApiGraphRef);
  const [validateRepoNameState, validateRepoName] = useAsyncFn(async (repo: string) => {
    if (repo === '') {
      return '';
    }
    const isUniqueRepo = await githubEnterpriseClientGraph.isUniqueRepo('AAInternal', repo);
    if (isUniqueRepo) {
      setNewRepoName('');
      return `Repo: ${repo} does not exist! Cannot enable Authentication Method`;
    }
    setNewRepoName(repo);
    throw new Error('Repo Name exists!');
  });

  const validateRepoNameRef = useRef(debounce(validateRepoName, 300));

  useEffect(() => {
    validateRepoNameRef.current = debounce(validateRepoName, 300);
  }, [validateRepoName]);

  useEffect(() => {
    const vaultName = `${shortNameValue}-${label}-${env}`;
    onChange({ ...returnedData, vaultName: vaultName });
  }, [returnedData, onChange, env, label, shortNameValue]);

  const StyledFormHelperText = withStyles(theme => ({
    root: {
      color: theme.palette.secondary.main,
      fontWeight: 'bold',
    },
  }))(FormHelperText);

  return (
    <div>
      <b>BETA</b> For questions regarding this template visit:{' '}
      <b>
        <a
          href="https://americanairlines.slack.com/app_redirect?channel=CTMAVENP3"
          target="_blank"
          rel="noopener noreferrer"
        >
          #cstrm-secrets-management
        </a>
      </b>
      <br />
      <br />
      <div>
        <FormControl>
          <FormLabel>Environment</FormLabel>
          <FormGroup>
            <RadioGroup row value={env} onChange={handleEnvironmentChange}>
              <FormControlLabel value={Environment.Nonprod} control={<Radio />} label="Nonprod" />
              <FormControlLabel value={Environment.Prod} control={<Radio />} label="Prod" />
            </RadioGroup>
          </FormGroup>
        </FormControl>
      </div>
      <div style={{ paddingBottom: 8, paddingTop: 8 }}>
        <TextField
          id="namespace name"
          label="Vault Label"
          variant="outlined"
          onChange={handleVaultLabelChange}
          error={labelError}
          style={{ minWidth: 360 }} // overriding default width due to its was originally set to helper text.
          helperText={
            labelError ? (
              <div style={{ color: '#f44336' }}>Vault Label Requires alphanumeric only</div>
            ) : (
              `One word descriptive label describing the vault's purpose`
            )
          }
        />
      </div>
      <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
        <span>Here are the Names of Active Directory Group(s) for user access to Hashicorp Vault.</span>
        {label && (
          <div
            aria-owns={openAdDesc ? 'mouse-over-popover' : undefined}
            aria-haspopup="true"
            onMouseEnter={handleADDescPopoverOpen}
            onMouseLeave={handleAdDescPopoverClose}
            data-testid="ad-description-icon"
          >
            <IconButton color="primary" size="small" data-testid="support-button">
              <InfoIcon />
            </IconButton>
          </div>
        )}
        <Popover
          id="mouse-over-popover"
          open={openAdDesc}
          anchorEl={adDescAnchorEl}
          style={{ pointerEvents: 'none' }}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
          onClose={handleAdDescPopoverClose}
          disableRestoreFocus
        >
          <Card>
            <CardContent>
              <Typography variant="body2">
                <strong>
                  <h3 style={{ borderBottom: '1px solid #fff', margin: 0 }}>AD Group Description</h3>
                </strong>
              </Typography>

              <Typography data-testid="ad-description-card-content">
                <div>
                  <div>
                    <p style={{ margin: 0, paddingTop: 8 }}>
                      <strong>Admin AD Group: </strong>
                      {returnedData.adminAdGroup && `${returnedData.adminAdGroup}`}
                    </p>
                    <p style={{ margin: 0, paddingBottom: 8 }}>
                      <strong>Reader AD Group: </strong>
                      {returnedData.readerAdGroup && `${returnedData.readerAdGroup}`}
                    </p>
                  </div>
                  <div>
                    <p style={{ margin: 0, paddingBottom: 8 }}>
                      <strong>Current Status: </strong>
                      {formContext?.formData?.aaId} will be added as an admin for this Active Directory group, which
                      will be automatically created by creating your vault Namespace. Currently you are the only admin
                      for this active directory group with the name of the groups as shown above. You can manage the
                      members of this group by following the below steps.
                    </p>
                  </div>
                  <p style={{ margin: 0 }}>
                    <strong>Manage Members : </strong>You can manage members in admin group as well as reader group by
                    checking out to the URL in the below Summary card.
                  </p>
                </div>
              </Typography>
            </CardContent>
          </Card>
        </Popover>
      </div>
      <div style={{ paddingLeft: 8, paddingTop: 8, paddingBottom: 8 }}>
        <TextField
          disabled
          id="Admin Active Directory Group"
          label="Admin Access Group"
          variant="outlined"
          value={returnedData.adminAdGroup}
        />
      </div>
      <div style={{ paddingLeft: 8, paddingTop: 8, paddingBottom: 8 }}>
        <TextField
          disabled
          id="Reader Ad Group"
          label="Read Only Access Group"
          variant="outlined"
          value={returnedData.readerAdGroup}
        />
      </div>
      {shortNameValue && label && env && (
        <div style={{ paddingTop: 16, paddingBottom: 8 }}>
          <span style={{ borderBottom: '1px solid #fff' }}>
            <strong>Final vault namespace: </strong> {`${shortNameValue}-${label}-${env}`}
          </span>
        </div>
      )}
      <div>
        <div>
          <h1>Authentication Methods and Secret Engines</h1>
        </div>
        <div style={{ paddingLeft: 8 }}>
          <div style={{ display: 'flex', gap: '8px', alignItems: 'center' }}>
            <h3 style={{ margin: 0 }}>
              Some of the default Authentication Methods and Secrets Engines have been provided.
            </h3>
            <div
              aria-owns={openAdDesc ? 'mouse-over-popover' : undefined}
              aria-haspopup="true"
              onMouseEnter={handleAuthAndSecretPopoverOpen}
              onMouseLeave={handleAuthAndSecretPopoverClose}
              data-testid="auth-and-secret-description-icon"
            >
              <IconButton color="primary" size="small" data-testid="support-auth-button">
                <InfoIcon />
              </IconButton>
            </div>
          </div>

          <Popover
            id="mouse-over-popover"
            open={openAuthAndSecret}
            anchorEl={authAndSecretAnchorEl}
            style={{ pointerEvents: 'none' }}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'left',
            }}
            onClose={handleAuthAndSecretPopoverClose}
            disableRestoreFocus
          >
            <Card>
              <CardContent data-testid="authmethod-card-content">
                <div>
                  <h3 style={{ margin: 0 }}>List of Authentication Methods and secrets Engine</h3>
                </div>

                <Typography variant="body2">
                  <strong>
                    <h3 style={{ borderBottom: '1px solid #fff', marginBottom: 6 }}>Default Authentication Methods</h3>
                  </strong>
                  <p style={{ margin: 0, paddingTop: 4, paddingLeft: 8 }}>
                    <strong>1. LDAP Authentication Method</strong>
                    <div
                      style={{
                        borderRadius: '4px',
                        paddingLeft: '16px',
                        marginTop: 8,
                      }}
                    >
                      <p style={{ margin: 0, paddingTop: 0 }}>
                        NOTE : Enabled and configured the Active Directory authentication method as your default login
                        authentication. Admin group will be given admin policy and the reader group with reader policy.
                      </p>
                    </div>
                  </p>
                  <p style={{ margin: 0, paddingTop: 8, paddingLeft: 8 }}>
                    <strong>2. AppRole Authentication Method </strong>
                    <div
                      style={{
                        borderRadius: '4px',
                        paddingLeft: '16px',
                        paddingBottom: 8,
                        marginTop: 8,
                      }}
                    >
                      <p style={{ margin: 0, paddingTop: 0 }}>
                        NOTE : Enabled and configured your approle authentication method with the role "Applicationrole"
                        and provisioned the application policy for read access. We have stored your initial role-id and
                        secret-id in your vault namespace, secrets engine path /secrets/approle-secrets.
                      </p>
                    </div>
                  </p>
                  <strong>
                    <h3 style={{ borderBottom: '1px solid #fff', marginBottom: 6 }}>Default Secrets Engine</h3>
                  </strong>
                  <p style={{ margin: 0, paddingTop: 4, paddingLeft: 8 }}>
                    <strong>1. kv-v2 Secrets Engine</strong>
                    <div
                      style={{
                        borderRadius: '4px',
                        paddingLeft: '16px',
                        marginTop: 8,
                      }}
                    >
                      <p style={{ margin: 0, paddingTop: 0 }}>
                        NOTE : Enabled and configured Kv-v2. For additional information about kv-v2 Secrets Engine,
                        visit Docs in the below Summary card.
                      </p>
                    </div>
                  </p>
                </Typography>
              </CardContent>
            </Card>
          </Popover>
        </div>
      </div>
      <div
        style={{
          paddingLeft: '8px',
        }}
      >
        <div id="Additional Option" style={{ paddingTop: 8, paddingBottom: 8 }}>
          <strong>Select Additional Authentication Methods to Use Vault Secrets (Optional)</strong>
        </div>
        <FormControl>
          <InputLabel id="auth-methods-select" style={{ paddingLeft: '8px' }}>
            {' '}
            Auth Methods
          </InputLabel>
          <Select
            labelId="auth-methods-label-id"
            id="auth-methods-select"
            multiple
            value={authMethods}
            onChange={handleAuthSelect}
            renderValue={(selected: any) => selected.join(', ')}
            variant="outlined"
            style={{ width: 300, display: 'inline-flex' }}
          >
            {authMethodsArray.map(authMethod => (
              <MenuItem key={authMethod} value={authMethod}>
                <Checkbox checked={authMethods.indexOf(authMethod) > -1} data-testid="auth-checkbox" />
                <ListItemText primary={authMethod} />
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        {authMethods.includes(AuthMethods.SHARED) && (
          <div
            style={{
              border: '1px solid rgba(255, 255, 255, 0.3)',
              borderRadius: '4px',
              paddingLeft: 8,
              paddingTop: 8,
              paddingBottom: 8,
              padding: '12px',
              marginTop: 8,
            }}
            data-testid="kubernetes-authentication"
          >
            <h3> 3. Selected Kubernetes authentication Method :</h3>
            <b>For details regarding this Kubernetes authentication method, visit:</b>{' '}
            <b>
              <Link href="https://developer.hashicorp.com/vault/docs/auth/kubernetes" target="_blank" rel="noreferrer">
                #Kubernetes authentication Method
              </Link>
            </b>
            <br />
            <br />
            <div
              style={{
                borderRadius: '4px',
                paddingLeft: '12px',
                marginTop: 8,
              }}
            >
              <p style={{ margin: 0, paddingTop: 8 }}>
                NOTE : Enabled and configured your kubernetes authentication method. With the two inputs, a kubernetes
                cluster name and your selected kubernetes namespace name, we will complete the pre-requisite to
                configure and create your kubernetes authentication engine. For additional requirements, visit:
                <Link href="https://github.com/AAInternal/vault-secrets-operator" target="_blank" rel="noreferrer">
                  #Github
                </Link>
              </p>
            </div>
            <br />
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <ClusterSelector
                  includeProd
                  cluster={selectedCluster}
                  onClick={handleClusterChange}
                  data-testid="cluster-selector"
                />
              </Grid>
              <Grid item xs={12}>
                <NamespaceSelector
                  data-testid="namespace-selector"
                  selectedCluster={selectedCluster as string}
                  selectedNamespaceName={selectedNamespace}
                  isCreateButtonVisible
                  onChange={handleNamespaceChange}
                />
              </Grid>
            </Grid>
          </div>
        )}
        {authMethods.includes(AuthMethods.GITHUB_OIDC) && (
          <div
            style={{
              border: '1px solid rgba(255, 255, 255, 0.3)',
              borderRadius: '4px',
              paddingLeft: 8,
              paddingTop: 8,
              paddingBottom: 8,
              padding: '12px',
              marginTop: 8,
            }}
          >
            <h3> 3. Selected Github OIDC authentication Method :</h3>
            <b>For details regarding this Github OIDC authentication method, visit:</b>{' '}
            <b>
              <Link
                href="https://developer.hashicorp.com/vault/tutorials/auth-methods/oidc-auth"
                target="_blank"
                rel="noreferrer"
              >
                #Github OIDC authentication Method
              </Link>
            </b>
            <br />
            <br />
            <Grid container>
              <Grid item xs={3}>
                <TextField
                  data-testid="github-repo-name"
                  id="GitHub OIDC"
                  label="GitHub Repository Name"
                  FormHelperTextProps={{
                    style: { color: 'secondary' },
                  }}
                  helperText={
                    <StyledFormHelperText>
                      Click on the Plus sign (or) Enter key to add multiple repositories
                    </StyledFormHelperText>
                  }
                  style={{ width: '100%' }}
                  variant="outlined"
                  value={repoNameValue}
                  onKeyDown={handleKeyDownAddRepoName}
                  onChange={({ target: { value } }) => {
                    validateRepoNameRef.current(value.trim());
                    setRepoNameValue(value);
                  }}
                  error={!!validateRepoNameState.value}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          onClick={handleAddRepoName}
                          data-testid="add-repo-button"
                          color="primary"
                          aria-label="Add repository"
                          disabled={!!validateRepoNameState.value}
                        >
                          <AddIcon />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                {repoNames.map((repoName, index) => (
                  <Chip
                    key={index}
                    label={repoName}
                    onDelete={() => handleDeleteRepoName(index)}
                    data-testid={`delete-repo-chip-${repoName}`}
                    style={{ margin: '4px' }}
                    color="primary"
                  />
                ))}
              </Grid>
              {validateRepoNameState.loading && (
                <Grid item xs={4}>
                  <CircularProgress style={{ padding: 5 }} />
                </Grid>
              )}
              {validateRepoNameState.error && (
                <Grid item xs={4}>
                  <Alert severity="success">{validateRepoNameState.error.message}</Alert>
                </Grid>
              )}
              {validateRepoNameState.value && !validateRepoNameState.error && (
                <Grid item xs={4}>
                  <Alert severity="error">{validateRepoNameState.value}</Alert>
                </Grid>
              )}
            </Grid>
          </div>
        )}
      </div>
      <div
        style={{
          marginTop: 8,
          marginLeft: 0,
        }}
      >
        <div id="Additional Option" style={{ paddingLeft: 8, paddingBottom: 8 }}>
          <strong>Select Additional Secrets Engine to Use Vault Secrets (Optional)</strong>
          <div style={{ paddingTop: 8 }}>
            <FormControl>
              <InputLabel id="secret-engine-select" style={{ paddingLeft: '8px' }}>
                {' '}
                Secrets Engine
              </InputLabel>
              <Select
                labelId="secrets-engine-label-id"
                id="secret-engine-select"
                multiple
                value={secretsEngine}
                onChange={handlesecretEngineSelect}
                renderValue={(selected: any) => selected.join(', ')}
                variant="outlined"
                style={{ width: 300, display: 'inline-flex' }}
              >
                {secretsEngineArray.map(secretVal => (
                  <MenuItem key={secretVal} value={secretVal}>
                    <Checkbox checked={secretsEngine.indexOf(secretVal) > -1} />
                    <ListItemText primary={secretVal} />
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>
        </div>
        {secretsEngine.includes('Active Directory Secrets Engine') && (
          <div
            style={{
              border: '1px solid rgba(255, 255, 255, 0.3)',
              borderRadius: '4px',
              paddingLeft: '24px',
              paddingTop: 8,
              marginTop: 8,
            }}
          >
            <h3> 2. Selected Active Directory Secrets Engine :</h3>

            <div
              style={{
                borderRadius: '4px',
                padding: '12px',
                marginTop: 8,
              }}
            >
              <p style={{ margin: 0, paddingTop: 0 }}>
                NOTE : Enabled and configured Active Directory Secrets Engine. For further assistance on account
                rotation in vault, please contact secrets management team at this time &nbsp; rotation in vault, please
                contact secrets management team at this time &nbsp;
                <Link href="https://americanairlines.slack.com/archives/CTMAVENP3" target="_blank" rel="noreferrer">
                  #SLACK CHANNEL
                </Link>
              </p>
            </div>
            <br />
          </div>
        )}
      </div>
      {label && (
        <Card>
          <CardContent>
            <Typography variant="body2">
              <strong>
                <h3 style={{ borderBottom: '1px solid #fff', margin: 0 }}>Complete Summary</h3>
              </strong>
            </Typography>
            {showSummary && (
              <Typography data-testid="show-summary-card-content">
                <div
                  style={{
                    borderRadius: '4px',
                    padding: '12px',
                  }}
                >
                  <p style={{ margin: '0 0 2px 0' }}>
                    <strong>Current status : </strong>
                  </p>

                  <p style={{ margin: 0, paddingLeft: 16 }}>
                    <strong>Vault Namespace: </strong> {`${shortNameValue}-${label}-${env}`}
                  </p>
                  <p style={{ margin: 0, paddingLeft: 16 }}>
                    <strong>Admin AD Group: </strong>
                    <span>{returnedData.adminAdGroup && `${returnedData.adminAdGroup}`}</span>&nbsp;&nbsp;&nbsp;
                    <span>(Note: These group members will have admin Policies)</span>
                  </p>
                  <p style={{ margin: 0, paddingLeft: 16 }}>
                    <strong>Reader AD Group: </strong>
                    <span>{returnedData.readerAdGroup && `${returnedData.readerAdGroup}`}</span>&nbsp;&nbsp;&nbsp;
                    <span>
                      (Note: These group members will have reader Policies, in which they can olny retrieve the secrets)
                    </span>
                  </p>
                  <p style={{ margin: 0, paddingLeft: 16 }}>
                    <strong>Manage Members : </strong>You can manage members in admin group as well as reader group by
                    checking out to this URL{' '}
                    <strong>
                      <Link href="https://technologyservices.aa.com/" target="_blank" rel="noreferrer">
                        #ManageGroups
                      </Link>
                    </strong>
                  </p>
                  <p style={{ margin: 0, paddingTop: '16px' }}>
                    <strong>Authentication methods : </strong>
                    <p style={{ margin: 0, paddingLeft: '16px' }}>
                      1. <strong>LDAP authentication Method</strong> is provided by default with Admin and reader Active
                      directory groups with Policies{' '}
                      <strong>
                        <Link
                          href="https://developer.hashicorp.com/vault/docs/auth/ldap"
                          target="_blank"
                          rel="noreferrer"
                        >
                          #LDAPDocs
                        </Link>
                      </strong>
                    </p>
                    <p style={{ margin: 0, paddingLeft: '16px' }}>
                      2. <strong>AppRole authentication method</strong> is provided by default with your role-id and
                      secret-id stored in the vault secrets engine admin and reader respectively.{' '}
                      <strong>
                        <Link
                          href="https://developer.hashicorp.com/vault/docs/auth/approle"
                          target="_blank"
                          rel="noreferrer"
                        >
                          #AppRoleDocs
                        </Link>
                      </strong>
                    </p>
                    {authMethods.length > 0 && (
                      <div style={{ margin: 0, paddingLeft: '16px' }}>
                        3. <strong>{authMethods.join(', ')} </strong>authentication method/s is provided based on your
                        selection.{' '}
                        {authMethods.includes(AuthMethods.SHARED) && (
                          <strong>
                            <Link
                              href="https://developer.hashicorp.com/vault/docs/auth/kubernetes"
                              target="_blank"
                              rel="noreferrer"
                            >
                              #KubernetesDocs
                            </Link>
                          </strong>
                        )}
                        {' , '}
                        {authMethods.includes(AuthMethods.GITHUB_OIDC) && (
                          <strong>
                            <Link
                              href="https://developer.hashicorp.com/vault/tutorials/auth-methods/oidc-auth"
                              target="_blank"
                              rel="noreferrer"
                            >
                              #Github OIDC Docs
                            </Link>
                          </strong>
                        )}
                      </div>
                    )}
                  </p>
                  <p style={{ margin: 0, paddingTop: '16px' }}>
                    <strong>Secrets Engine : </strong>
                    <p style={{ margin: 0, paddingLeft: '16px' }}>
                      1. <strong>KV-V2 Secrets Engine</strong> is enabled by default with the path /secrets/kv-v2{' '}
                      <strong>
                        <Link
                          href="https://developer.hashicorp.com/vault/docs/secrets/kv/kv-v2"
                          target="_blank"
                          rel="noreferrer"
                        >
                          #Kv-v2Docs
                        </Link>
                      </strong>
                    </p>
                    {secretsEngine.includes('Active Directory Secrets Engine') && (
                      <div style={{ margin: 0, paddingLeft: '16px' }}>
                        2. <strong>{secretsEngine.join(', ')} </strong> is enabled based on your selection.{' '}
                        <strong>
                          <Link
                            href="https://developer.hashicorp.com/vault/docs/secrets/ad"
                            target="_blank"
                            rel="noreferrer"
                          >
                            #ADSecretsEngineDocs
                          </Link>
                        </strong>
                      </div>
                    )}
                  </p>
                </div>
              </Typography>
            )}
          </CardContent>
          <CardActions>
            <Button size="small" onClick={handleexpandsummary} data-testid="show-summary-card-action">
              {showSummary ? <span>Show Less</span> : <span>Show More</span>}
            </Button>
          </CardActions>
        </Card>
      )}
    </div>
  );
};

export const secretVaultFormFieldValidation = (value: ReturnedData, validation: FieldValidation) => {
  const regex = '^[a-z0-9]+-[a-z0-9]+-[np]$';
  if (!value.vaultName?.match(regex)) {
    validation.addError('Vault Name Requires alphanumeric shortname, label and environment');
  }
  if (!value.adminAdGroup) {
    validation.addError('Admin AD Group Required');
  }
  if (value.authmethods?.includes(AuthMethods.GITHUB_OIDC)) {
    if (!value.githubRepoNames) {
      validation.addError('Please verify Github repo name and add it by clicking "plus sign" or "enter key".');
    }

    if (value.githubRepoNames && value.githubRepoNames.length > 0) {
      const duplicates = value.githubRepoNames.filter(
        (item, index) => value.githubRepoNames?.indexOf(item.toLocaleLowerCase()) !== index,
      );
      if (duplicates.length > 0) {
        validation.addError('Duplicate Github repo name not allowed');
      }
    }
  }
  if (value.authmethods?.includes(AuthMethods.SHARED)) {
    if (!value.k8s_namespace) {
      validation.addError("Kubernetes shared cluster's Namespace Required");
    }
    if (!value.hostClusters) {
      validation.addError('Kubernetes shared cluster Required');
    }
  }
};
