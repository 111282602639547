import { TextField } from '@material-ui/core';
import { useFormikContext } from 'formik';
import React from 'react';
import { useStyles } from '../OrionRequestForm/orionFormStyles';
import { amlCCFormValueTypes } from './amlCCFormValueTypes';

type Props = {
  fieldPropName: keyof amlCCFormValueTypes;
  label: string;
  placeholder: string;
  required?: boolean;
};

type Ref = ((instance: HTMLDivElement | null) => void) | React.RefObject<HTMLDivElement> | null | undefined;

export const AMLCCFormTextField = React.forwardRef(
  ({ fieldPropName, label, placeholder, required }: Props, ref?: Ref) => {
    const classes = useStyles();
    const formik = useFormikContext<amlCCFormValueTypes>();

    return (
      <TextField
        ref={ref}
        id={fieldPropName}
        key={fieldPropName}
        required={required}
        label={label}
        {...formik.getFieldProps(fieldPropName)}
        placeholder={placeholder}
        variant="outlined"
        error={Boolean(formik.errors[fieldPropName] && formik.touched[fieldPropName])}
        helperText={formik.errors[fieldPropName]}
        className={classes.componentFormGroup}
      />
    );
  },
);
