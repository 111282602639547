import { STATUS } from '../../Status';

interface ReplicaSetResource {
  metadata: { name: string };
  status: { replicas: number; readyReplicas: number };
}
export interface ReplicaSetsMetadata {
  name?: string | undefined;
  status: STATUS;
  replicas?: number | undefined;
  readyReplicas?: number | undefined;
}
export type ReplicaSets = Record<string, ReplicaSetsMetadata[]>;

const combineReplicaSetStatus = (replicaSet: any) => {
  if (!replicaSet.status.readyReplicas) return STATUS.unhealthy;
  if (replicaSet.status.readyReplicas === 0) return STATUS.unhealthy;
  if (replicaSet.status.readyReplicas === replicaSet.status.replicas) return STATUS.healthy;
  return STATUS.partiallyHealthy;
};

export const getReplicaSetStatus = (item: any): ReplicaSetsMetadata[] => {
  const rsObject = item.resources?.filter((resource: { type: string }) => {
    return resource.type === 'replicasets';
  })[0];

  if (!rsObject) return [{ status: STATUS.unretrievable, replicas: undefined, readyReplicas: undefined }];

  const replicaSets = rsObject.resources?.filter((resource: any) => {
    return resource.status.replicas > 0;
  });

  if (!replicaSets || replicaSets.length === 0)
    return [{ status: STATUS.unretrievable, replicas: undefined, readyReplicas: undefined }];

  return replicaSets.map((rs: ReplicaSetResource) => {
    return {
      name: rs.metadata.name,
      status: combineReplicaSetStatus(rs),
      replicas: rs.status.replicas,
      readyReplicas: rs.status.readyReplicas,
    };
  });
};
