import React from 'react';
import { HpasMetadata } from '../services';
import { STATUS } from '../Status';
import { getClusterDetails, getStatusDetails2D, Layer } from './Layer';

export const HpasLayer = ({
  status,
  clusters,
}: {
  status: STATUS;
  clusters: Record<string, HpasMetadata[]> | undefined;
}) => (
  <Layer
    title="HPAs"
    status={status}
    subheader={getStatusDetails2D<HpasMetadata>(status, clusters, getClusterDetails, 'hpa')}
  />
);
