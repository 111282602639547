import { EntityBadgesDialog } from '@backstage-community/plugin-badges';
import { EntityLayout } from '@backstage/plugin-catalog';
import BadgeIcon from '@material-ui/icons/CallToAction';
import React, { ReactNode, useMemo, useState } from 'react';

export const EntityLayoutWrapper = (props: { children?: ReactNode }) => {
  const [badgesDialogOpen, setBadgesDialogOpen] = useState(false);

  const extraMenuItems = useMemo(() => {
    return [
      {
        title: 'Badges',
        Icon: BadgeIcon,
        onClick: () => setBadgesDialogOpen(true),
      },
    ];
  }, []);

  return (
    <>
      <EntityLayout UNSTABLE_extraContextMenuItems={extraMenuItems}>{props.children}</EntityLayout>
      <EntityBadgesDialog open={badgesDialogOpen} onClose={() => setBadgesDialogOpen(false)} />
    </>
  );
};
