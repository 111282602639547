import { Checkbox, FormControlLabel, IconButton, Tooltip, useTheme, withStyles } from '@material-ui/core';
import React from 'react';
import InfoIcon from '@material-ui/icons/Info';
import { Alert } from '@material-ui/lab';

const NoPaddingTooltip = withStyles({
  tooltip: {
    padding: 0,
    margin: 5,
  },
})(Tooltip);

export const MicrogatewayProxy = (props: {
  value: boolean;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
}) => {
  const theme = useTheme();

  return (
    <>
      <FormControlLabel
        control={<Checkbox {...props} />}
        label="Microgateway API Proxy"
        style={{ color: theme.palette.text.secondary, padding: '0px 20px' }}
      />
      <NoPaddingTooltip
        title={<Alert severity="info">For more information, visit the apigee with runway information page</Alert>}
      >
        <IconButton
          color="primary"
          size="small"
          target="_blank"
          aria-label="MicrogatewayInfoButton"
          href="https://developer.aa.com/docs/default/component/apigee-docs/Runway/apigee-plugin/"
        >
          <InfoIcon />
        </IconButton>
      </NoPaddingTooltip>
    </>
  );
};
