import { Button, FormControl, InputLabel, MenuItem, Select, TextField } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Remove from '@material-ui/icons/Remove';
import ViewColumn from '@material-ui/icons/ViewColumn';
import React, { forwardRef, useEffect, useState } from 'react';
import MaterialTable, { Icons } from '@material-table/core';
import AddBox from '@material-ui/icons/AddBox';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import Check from '@material-ui/icons/Check';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Clear from '@material-ui/icons/Clear';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import Edit from '@material-ui/icons/Edit';
import FilterList from '@material-ui/icons/FilterList';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import SaveAlt from '@material-ui/icons/SaveAlt';
import Search from '@material-ui/icons/Search';
import { AzureManagementServiceApiRef } from '../../services/';
import { useApi } from '@backstage/core-plugin-api';
import * as Yup from 'yup';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    formGroup: {
      marginBottom: theme.spacing(2),
      minWidth: 195,
    },
    formControl: {
      marginRight: theme.spacing(2),
      minWidth: 300,
      maxWidth: 750,
    },
    button: {
      marginTop: theme.spacing(1),
      marginLeft: theme.spacing(2),
    },
    customWidth: {
      maxWidth: 300,
    },
    errorAlert: {
      whiteSpace: 'pre-line',
      margin: 10,
      maxWidth: 500,
    },
    selectEmpty: {
      marginTop: theme.spacing(2),
    },
    margin: {
      marginTop: 20,
      marginBottom: 5,
    },
  }),
);

const tableIcons: Icons<UserData> = {
  Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
  DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
  Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
  ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
};
interface ADGUserManagementProps {
  handleOnClick(value: UserData[]): void;
  tenant: string;
}

export type UserData = {
  id: string;
  employeeName: string;
  userType: string;
};

const userTypes = ['Member', 'Owner'];

const validators = {
  employeeId: Yup.string().matches(/^[0-9]{6}$/g, 'Must be 6 numeric characters'),
};

export const validationSchema = Yup.object(validators);

export const UserManagement = ({ handleOnClick, tenant }: ADGUserManagementProps) => {
  const classes = useStyles();
  const [previousTenant, setPreviousTenant] = useState('');
  const [userDatas, setUserDatas] = useState<UserData[]>();
  const [employeeId, setEmployeeId] = useState('');
  const [userType, setUserType] = useState('');
  const [buttonDisabled, setButtonDisabled] = useState(true);
  const [errorMsg, setErrorMsg] = useState('');
  const [isError, setIsError] = useState(false);
  const ams = useApi(AzureManagementServiceApiRef);

  useEffect(() => {
    const evaluateButton = () => {
      if (userDatas) {
        if (
          userDatas.findIndex(user => user.id === employeeId && user.userType === userType) > -1 &&
          errorMsg === '' &&
          userType !== ''
        ) {
          setButtonDisabled(true);
          return;
        }
        if (errorMsg || employeeId === '') {
          setButtonDisabled(true);
          return;
        }
        setButtonDisabled(false);
      } else {
        if (errorMsg === '' && userType !== '' && employeeId !== '') {
          setButtonDisabled(false);
          return;
        }
      }
    };

    if (previousTenant !== tenant) {
      setUserDatas(undefined);
      setButtonDisabled(true);
      setPreviousTenant(tenant);
      setErrorMsg('');
      setIsError(false);
      handleOnClick([]);
    } else {
      evaluateButton();
    }
  }, [employeeId, userDatas, userType, previousTenant, tenant, errorMsg, handleOnClick]);

  const deleteRow = (rowData: UserData | UserData[]) => {
    if (!Array.isArray(rowData)) {
      const updatedUserDatas = userDatas?.filter(userData => {
        return !(userData.id === rowData.id && userData.userType === rowData.userType);
      });
      handleOnClick(updatedUserDatas ? updatedUserDatas : []);
      setUserDatas(updatedUserDatas);
    }
  };

  const validateTextField = async (value: string) => {
    try {
      await validationSchema.validate({ employeeId: value });
      setIsError(false);
      setErrorMsg('');
      if (userDatas) {
        if (
          userDatas.findIndex(user => user.id === value && user.userType === userType) < 0 &&
          userType !== '' &&
          value !== ''
        ) {
          setButtonDisabled(false);
        } else if (userType !== '' || value !== '') {
          setButtonDisabled(true);
        } else {
          setButtonDisabled(false);
        }
      } else {
        if (userType !== '' && value !== '') {
          setButtonDisabled(false);
        } else {
          setButtonDisabled(true);
        }
      }
    } catch (err) {
      setIsError(true);
      setErrorMsg(String(err).replace('ValidationError: ', ''));
      setButtonDisabled(true);
    }
  };
  const addUserToTable = async () => {
    const req = {
      tenant: tenant,
      employee_id: employeeId,
    };

    try {
      const response = await ams.getUser(req);
      if (response.includes('User not found') || response.includes('Failed to get user info')) {
        setErrorMsg(response);
        setIsError(true);
      } else {
        const employeeName = response;
        if (userDatas) {
          const updatedUserDatas = [...userDatas];
          updatedUserDatas.push({ id: employeeId, employeeName: employeeName, userType: userType });
          setUserDatas(updatedUserDatas);
          handleOnClick(updatedUserDatas);
        } else {
          setUserDatas([{ id: employeeId, employeeName: employeeName, userType: userType }]);
          handleOnClick([{ id: employeeId, employeeName: employeeName, userType: userType }]);
        }
      }
    } catch {
      setErrorMsg('Error communicating with backend services');
      setIsError(true);
    }
  };
  return (
    <>
      <div className={classes.formControl}>
        <TextField
          id="employeeId"
          label="Employee ID"
          required
          style={{ width: 300, marginRight: 16, marginBottom: 16 }}
          inputProps={{ 'data-testid': 'employeeId' }}
          variant="outlined"
          error={isError}
          helperText={errorMsg}
          value={employeeId}
          onChange={event => {
            setEmployeeId(event.target.value);
            validateTextField(event.target.value);
          }}
        />
        <FormControl variant="outlined" className={classes.formGroup} required>
          <InputLabel id="user-type">User Type</InputLabel>
          <Select
            labelId="user-type"
            label="User Type"
            value={userType ? userType : ''}
            onChange={event => {
              setUserType(event.target.value as string);
            }}
          >
            {userTypes.map(value => (
              <MenuItem key={value} value={value}>
                {value}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <Button
          className={classes.button}
          variant="contained"
          onClick={() => {
            addUserToTable();
          }}
          disabled={buttonDisabled}
        >
          Add User
        </Button>
      </div>
      <MaterialTable
        title="Security Group Members"
        icons={tableIcons}
        key={Date.now()}
        style={{ width: '100%', maxWidth: 630 }}
        columns={[
          {
            title: 'Employee ID',
            field: 'id',
          },
          {
            title: 'Name',
            field: 'employeeName',
          },
          {
            title: 'Type',
            field: 'userType',
          },
        ]}
        data={userDatas ? userDatas : []}
        actions={
          userDatas
            ? [
                {
                  icon: () => <DeleteOutline />,
                  tooltip: 'Delete User',
                  onClick: (_event, rowData) => deleteRow(rowData),
                },
              ]
            : []
        }
        options={{
          actionsColumnIndex: -1,
          sorting: undefined,
        }}
      />
    </>
  );
};
