import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  makeStyles,
  Radio,
  RadioGroup,
  TextField,
} from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { KeyVaultFields, PaasFormExtensionProps } from '../types';

enum KeyVaultSkuField {
  Premium = 'premium',
  Standard = 'standard',
}

const useStyles = makeStyles(runwayTheme => ({
  root: {
    backgroundColor: runwayTheme.palette.background.default,
    maxWidth: 500,
    marginBottom: 10,
  },
  container: {
    marginBottom: 20,
  },
}));

const formDefaults: KeyVaultFields = {
  keyVault: true,
  keyVaultName: '',
  keyVaultSku: KeyVaultSkuField.Premium,
  postgresFlexibleServer: false,
};

export const KeyVaultItemsField = ({ onChange, formData }: PaasFormExtensionProps) => {
  const classes = useStyles();
  const [visible, setVisible] = useState(formData?.keyVault ?? false);
  const [errorMessage, setErrorMessage] = useState('');
  const [error, setError] = useState(false);

  useEffect(() => {
    if (formData?.postgresFlexibleServer) {
      delete formData.keyVaultName;
      delete formData.keyVaultSku;
      setVisible(false);
    }
  }, [formData, formData?.postgresFlexibleServer]);

  const handleInputChange = (value: boolean) => {
    if (!value) {
      delete formData.keyVaultName;
      delete formData.keyVaultSku;
      onChange({ ...formData, keyVault: value });
    } else {
      onChange({ ...formData, ...formDefaults, keyVault: value });
    }
  };
  const validateInput = (inputText: string) => {
    if (!inputText.match(/^[a-zA-Z](?!.*--)[a-zA-Z\d-]{2,23}$/g) || inputText.endsWith('-')) {
      setError(true);
      setErrorMessage(
        "A vault's name must be between 3-24 alphanumeric characters. Must start with a letter, end with a letter or digit, and not contain consecutive hyphens.",
      );
    } else {
      setError(false);
      setErrorMessage('');
    }
  };
  return (
    <Grid container spacing={3} className={classes.root}>
      <Grid item xs={12} className={classes.container}>
        <FormControlLabel
          control={
            <Checkbox
              checked={visible}
              disabled={formData?.postgresFlexibleServer}
              onChange={(event: any) => {
                handleInputChange(event.target.checked);
                setVisible(!visible);
              }}
              color="primary"
            />
          }
          label="Create Key Vault"
        />
        {visible && (
          <>
            <Grid item xs={12} className={classes.container}>
              <TextField
                required
                id="key-vault-name"
                label="Key Vault Name"
                style={{ width: 300, display: 'inline-flex' }}
                defaultValue={formData?.keyVaultName}
                variant="outlined"
                error={error}
                helperText={errorMessage || 'Valid characters are [a-z0-9] | Length of 3-24'}
                onChange={(event: any) => {
                  onChange({
                    ...formData,
                    keyVaultName: event.target.value as string,
                  });
                  validateInput(event.target.value);
                }}
              />
            </Grid>
            <Grid item xs={12} className={classes.container}>
              <FormControl variant="outlined">
                <FormLabel id="key-vault-sku">Account Tier</FormLabel>
                <RadioGroup
                  row
                  aria-label="key-vault-sku"
                  onChange={(event: any) => {
                    onChange({
                      ...formData,
                      keyVaultSku: event.target.value as string,
                    });
                  }}
                  style={{ width: 300, display: 'inline-flex' }}
                  value={formData.keyVaultSku ?? KeyVaultSkuField.Premium}
                  name="key-vault-sku-select"
                >
                  <FormControlLabel value="standard" control={<Radio />} label="Standard" />
                  <FormControlLabel value="premium" control={<Radio />} label="Premium" />
                </RadioGroup>
              </FormControl>
            </Grid>
          </>
        )}
      </Grid>
    </Grid>
  );
};
