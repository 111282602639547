import React, { useState } from 'react';
import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormLabel,
  LinearProgress,
  Radio,
  RadioGroup,
  Snackbar,
} from '@material-ui/core';
import { Alert, AlertTitle } from '@material-ui/lab';
import { useApi } from '@backstage/core-plugin-api';
import { useNavigate } from 'react-router-dom';
import {
  ANNOTATION_ORIGIN_LOCATION,
  Entity,
  ANNOTATION_LOCATION,
  ANNOTATION_SOURCE_LOCATION,
} from '@backstage/catalog-model';
import { catalogProjectsServiceApiRef } from '../../services/catalogProjects.service';
import gitUrlParse from 'git-url-parse';
import { catalogApiRef } from '@backstage/plugin-catalog-react';
import { useAsync } from 'react-use';
import { BucketId, ClientId, ConfirmDeleteDialog, aaPingSSOAuthApiRef, useTrackedRequest } from '@runway/devkit';
import { PluginId } from '@runway/devkit-common';
import { ArgoCDServiceApiRef } from '../../services/argocd.catalog.service';
import { ArgoNameAndCluster } from '../../services/argocd.catalog.types';

interface DeleteAppDialogProps {
  isOpen: boolean;
  close(): void;
  entity: Entity;
}

const CopyText = (props: { repoName: string }) => {
  const { repoName } = props;
  return (
    <div>
      The action <strong>cannot be undone.</strong> This will permanently delete the <strong>{repoName}</strong>{' '}
      repository, wiki, issues, comments, packages, secrets, workflow runs, and remove all team associations.
      <br />
      <br />
      Please type <strong>{repoName}</strong> to confirm.
      <br />
      <br />
    </div>
  );
};

export const DeleteRepoAndCatalogDialog = ({ isOpen, close, entity }: DeleteAppDialogProps) => {
  const catalogProjectsApi = useApi(catalogProjectsServiceApiRef);
  const catalogApi = useApi(catalogApiRef);
  const currentIdentity = useApi(aaPingSSOAuthApiRef);

  const { createRecord } = useTrackedRequest({
    bucket_id: BucketId.USE_METRIC,
    client_id: ClientId.RUNWAY_FRONTEND,
    pluginId: PluginId.CATALOG_DELETE.id,
  });

  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [success, setSuccess] = useState<string | null>(null);
  const [deleteRepoDialogOpen, setDeleteRepoDialogOpen] = useState(true);
  const locationRef = entity.metadata.annotations?.[ANNOTATION_SOURCE_LOCATION];
  const argoCdService = useApi(ArgoCDServiceApiRef);
  const appSelector = entity.metadata.annotations?.['argocd/app-selector']!;

  const errorMsgs: string[] = [];
  const successMsgs: string[] = [];

  const argoResources = useAsync(async () => {
    if (!isOpen) {
      setError(null);
      setLoading(false);
      return null;
    }

    if (!appSelector) {
      setError(null);
      setLoading(false);
      return [];
    }

    const data: ArgoNameAndCluster[] = await argoCdService.getArgoNameAndCluster(appSelector);
    return data;
  }, [isOpen, appSelector, argoCdService]);

  const unRegisterEntityPrerequisites = useAsync(async () => {
    let unregisterEntities: Entity[] = [];
    const unregisterLocations: string[] = [];

    if (locationRef) {
      const locationAnnotationFilter = `metadata.annotations.${ANNOTATION_SOURCE_LOCATION}`;
      const catalogListResponse = await catalogApi.getEntities({
        filter: {
          [locationAnnotationFilter]: locationRef,
        },
        fields: ['kind', 'metadata'],
      });

      unregisterEntities = catalogListResponse.items;

      for (const e of catalogListResponse.items) {
        const locationReference = e.metadata.annotations?.[ANNOTATION_ORIGIN_LOCATION];
        const mylocation = await catalogApi.getLocationByRef(locationReference!);

        if (mylocation !== undefined) {
          unregisterLocations.push(mylocation.id);
        }
      }
    }

    return {
      locations: unregisterLocations,
      entities: unregisterEntities,
    };
  }, [catalogApi, entity]);

  const [removeAdditionalComponents, setRemoveAdditionalComponents] = useState({
    unregisterEntity: false,
    archiveGithubRepository: false,
    deleteGithubRepository: false,
    entityName: entity.metadata.name,
    entityUrl: entity.metadata.annotations?.[ANNOTATION_LOCATION] as string,
  });

  const handleRemoveAdditionalComponentsChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRemoveAdditionalComponents(prevRemoveAdditionalComponents => ({
      ...prevRemoveAdditionalComponents,
      [event.target.name]: event.target.checked,
    }));
  };

  const [repoOutcome, setRepoOutcome] = useState('keep');

  const handleRepoOutcome = (event: React.ChangeEvent<{ value: unknown }>) => {
    const outcome = event.target.value as string;
    setRepoOutcome(outcome);
    if (outcome === 'archiveRepo') {
      setRemoveAdditionalComponents(prevRemoveAdditionalComponents => ({
        ...prevRemoveAdditionalComponents,
        archiveGithubRepository: true,
        deleteGithubRepository: false,
      }));
    } else if (outcome === 'deleteRepo') {
      setDeleteRepoDialogOpen(true);
      setRemoveAdditionalComponents(prevRemoveAdditionalComponents => ({
        ...prevRemoveAdditionalComponents,
        unregisterEntity: true,
        archiveGithubRepository: false,
        deleteGithubRepository: true,
      }));
    } else {
      setRemoveAdditionalComponents(prevRemoveAdditionalComponents => ({
        ...prevRemoveAdditionalComponents,
        archiveGithubRepository: false,
        deleteGithubRepository: false,
      }));
    }
  };

  const navigate = useNavigate();

  const handleClose =
    (cancelled = true) =>
    async () => {
      if (cancelled) {
        close();
        return;
      }

      setLoading(true);

      let githubRepoPromise;
      const locationAnnotation = entity.metadata.annotations?.[ANNOTATION_LOCATION];
      if (
        locationAnnotation &&
        (removeAdditionalComponents.deleteGithubRepository || removeAdditionalComponents.archiveGithubRepository)
      ) {
        const gitUrlParseOut = gitUrlParse(locationAnnotation.replace('url:', ''));
        githubRepoPromise = catalogProjectsApi.deleteGithubRepo({
          githubOwner: gitUrlParseOut.owner,
          githubRepo: gitUrlParseOut.name,
          action: removeAdditionalComponents.deleteGithubRepository ? 'delete' : 'archive',
        });
      }

      let unRegisterEntityPromise;
      let unRegisterEntityLocationPromise;
      if (removeAdditionalComponents.unregisterEntity || removeAdditionalComponents.deleteGithubRepository) {
        const { locations, entities } = unRegisterEntityPrerequisites.value!;

        unRegisterEntityPromise = Promise.allSettled(
          entities.map(e => {
            catalogApi.removeEntityByUid(e.metadata.uid!);
          }),
        );
        unRegisterEntityLocationPromise = Promise.allSettled(
          locations.map(e => {
            catalogApi.removeLocationById(e);
          }),
        );
      }

      const promiseResp = await Promise.all([
        unRegisterEntityLocationPromise?.catch(e => e),
        unRegisterEntityPromise?.catch(e => e),
        githubRepoPromise?.catch(e => e),
      ]);

      promiseResp.forEach(function loopResp(entry) {
        if (typeof entry === 'object') {
          if ('message' in entry) {
            errorMsgs.push(entry.message);
          } else if ('status' in entry) {
            if (entry.status === 200 || entry.status === 204) {
              // Success
              successMsgs.push(entry.data.msg);
            } else {
              // Failure
              errorMsgs.push(entry.data.msg);
            }
          }
        }
      });

      if (errorMsgs?.length > 0) {
        setError(errorMsgs?.toString());
      } else {
        setSuccess(successMsgs?.toString());
        setSnackbarOpen(true);
        close();
        setTimeout(() => navigate('/catalog'), 2000);
      }

      let repositoryAction;
      if (removeAdditionalComponents.archiveGithubRepository) repositoryAction = 'archive';
      else if (removeAdditionalComponents.deleteGithubRepository) repositoryAction = 'delete';
      else repositoryAction = 'keep';
      const status = errorMsgs?.length > 0 ? 'failed' : 'success';
      await createRecord(
        {
          catalog: removeAdditionalComponents.unregisterEntity,
          repository: repositoryAction,
          userEmployeeId: (await currentIdentity.getAAProfile())?.aaId ?? '-1',
        },
        status,
      );

      setLoading(false);
    };

  const handleDeleteRepoClose = () => {
    setRemoveAdditionalComponents(prevRemoveAdditionalComponents => ({
      ...prevRemoveAdditionalComponents,
      unregisterEntity: false,
      deleteGithubRepository: false,
    }));
    setRepoOutcome('keep');
  };

  return (
    <>
      <Dialog
        open={isOpen}
        fullWidth
        maxWidth="md"
        keepMounted
        onClose={handleClose()}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle>Delete Repo And Catalog Entity</DialogTitle>
        <DialogContent>
          {argoResources.loading && (
            <div>
              <LinearProgress />
            </div>
          )}
          {(argoResources.value?.length || argoResources.error) && (
            <Alert severity="warning">
              <AlertTitle>Warning</AlertTitle>
              {argoResources.error && (
                <>
                  <p>Unable to determine if deployment resources exist in ArgoCD.</p>
                  <p>{argoResources.error.message}</p>
                </>
              )}
              {argoResources.value?.length && <p>Deployment resources exist in ArgoCD.</p>}
              <p>
                Please head to <strong>MANAGE DEPLOYMENT</strong> to delete the following resources before proceeding:
              </p>
              <p>
                <strong>{argoResources.value?.map(resource => resource.appName).join(',')}</strong>
              </p>
              <a
                href="runway/docs/getting-started/userguides/created-apps/#delete-application"
                style={{ color: 'hsl(210, 50%, 45%)' }}
              >
                How to Delete Application?
              </a>
            </Alert>
          )}
          <FormControlLabel
            control={
              <Checkbox
                checked={
                  removeAdditionalComponents.deleteGithubRepository || removeAdditionalComponents.unregisterEntity
                }
                disabled={
                  removeAdditionalComponents.deleteGithubRepository ||
                  !argoResources.value ||
                  argoResources.value?.length > 0
                }
                onChange={handleRemoveAdditionalComponentsChange}
                name="unregisterEntity"
                color="primary"
              />
            }
            label="Delete from Runway Catalog"
          />
          <br />
          {(removeAdditionalComponents.deleteGithubRepository || removeAdditionalComponents.unregisterEntity) && (
            <Alert severity="warning">
              <AlertTitle>Unregistering entity</AlertTitle>
              This will unregister the following entity:
              <li>{removeAdditionalComponents.entityName}</li>
              <br />
              That is located at the following location:
              <li>{removeAdditionalComponents.entityUrl}</li>
              <br />
              To undo, just re-register the entity in Runway.
            </Alert>
          )}

          <FormControl
            aria-label="githubRepositoryOutcomeField"
            component="fieldset"
            style={{ marginTop: '10px', display: 'block' }}
            disabled={!argoResources.value || argoResources.value.length > 0}
          >
            <FormLabel component="legend">Github Repository</FormLabel>
            <RadioGroup
              aria-label="githubRepositoryOutcomeField"
              name="githubRepositoryOutcomeField"
              value={repoOutcome}
              onChange={handleRepoOutcome}
            >
              <FormControlLabel value="keep" control={<Radio />} label="Keep" />
              <FormControlLabel value="archiveRepo" control={<Radio />} label="Archive Repo" />
              <FormControlLabel value="deleteRepo" control={<Radio />} label="Delete Repo" />
            </RadioGroup>
          </FormControl>
          <br />
          {removeAdditionalComponents.deleteGithubRepository && (
            <ConfirmDeleteDialog
              confirmationText={entity.metadata.name}
              isOpen={deleteRepoDialogOpen}
              onDelete={() => setDeleteRepoDialogOpen(false)}
              onCancel={() => {
                setDeleteRepoDialogOpen(false);
                handleDeleteRepoClose();
              }}
            >
              <CopyText repoName={entity.metadata.name} />
            </ConfirmDeleteDialog>
          )}
          {error ? <Alert severity="error">{error}</Alert> : null}
          {success ? <Alert severity="success">{success}</Alert> : null}
        </DialogContent>
        <DialogActions>
          <Button color="primary" onClick={handleClose()}>
            Cancel
          </Button>
          <Button
            variant="contained"
            data-testid="deleteBtn"
            color="secondary"
            onClick={handleClose(false)}
            disabled={
              (!removeAdditionalComponents.archiveGithubRepository &&
                !removeAdditionalComponents.deleteGithubRepository &&
                !removeAdditionalComponents.unregisterEntity) ||
              unRegisterEntityPrerequisites.value === undefined
            }
          >
            Delete
          </Button>
        </DialogActions>
        <LinearProgress hidden={!loading} />
      </Dialog>

      <Snackbar
        open={snackbarOpen}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        autoHideDuration={5000}
        onClose={() => setSnackbarOpen(false)}
      >
        <Alert
          onClose={() => setSnackbarOpen(false)}
          severity="success"
          style={{
            boxShadow: '0 0 5px 1px #000',
            WebkitBoxShadow: '0 0 5px 1px #000',
            borderColor: '#004F0C',
          }}
        >
          Resources queued for deletion
        </Alert>
      </Snackbar>
    </>
  );
};
