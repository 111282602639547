import { createApiRef } from '@backstage/core-plugin-api';
import { OAuth2AAPingSSOApi } from '@runway/devkit/src/services/runwayAuthApiRefs';
import axios from 'axios';

export const vmDecomDisplayApiRef = createApiRef<VmDecomDisplayApi>({
  id: 'plugin.infrastructure.vmdecomdisplay.service',
});

export type VmDecomDisplayResponse = {
  id: string;
  vmName: string;
  createdAt: string;
  status: string;
  description: string | null;
};

export type VmDecomDisplayPickledResponse = {
  id: string;
  vmName: string;
  createdAt: string;
  status: string;
  description: string | null;
};

export interface VmDecomDisplayApi {
  getVmDecomDisplay(): Promise<VmDecomDisplayPickledResponse[]>;
}

export default class VMDecomDisplayService implements VmDecomDisplayApi {
  constructor(
    private oAuthApi: OAuth2AAPingSSOApi,
    private baseUrl: string,
  ) {}
  async getVmDecomDisplay(): Promise<VmDecomDisplayPickledResponse[]> {
    const complianceResponse = await axios.get<VmDecomDisplayResponse[]>(`${this.baseUrl}/decom/own-requests`, {
      headers: { Authorization: `Bearer ${await this.oAuthApi.getAccessToken()}` },
    });
    const decomDisplaySubset: VmDecomDisplayPickledResponse[] = [];
    for (const res of complianceResponse.data) {
      const picked: VmDecomDisplayPickledResponse = (({ id, vmName, createdAt, status, description }) => ({
        id,
        vmName,
        createdAt,
        status,
        description,
      }))(res);
      decomDisplaySubset.push(picked);
    }
    return decomDisplaySubset;
  }
}
