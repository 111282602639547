import { createApiRef, IdentityApi } from '@backstage/core-plugin-api';
import { ObserveService as ObserveServiceCommon, ObserveServiceApi } from '@runway/devkit-common';

export const ObserveServiceApiRef = createApiRef<ObserveServiceApi>({
  id: 'plugin.observe.service',
});

class ObserveService extends ObserveServiceCommon {
  constructor(
    backendUrl: string,
    private identityApi: IdentityApi,
  ) {
    super(backendUrl, '');
  }
  getToken = async () => (await this.identityApi.getCredentials()).token ?? '';
}

export default ObserveService;
