import React from 'react';
import { FieldExtensionComponentProps } from '@backstage/plugin-scaffolder-react';
import { LDAPGroupDropdown, LDAPOnChange } from '../../components';

export const LDAPGroupField = ({ onChange, formData }: FieldExtensionComponentProps<string>) => {
  return (
    <LDAPGroupDropdown
      onChange={(values: LDAPOnChange) => {
        onChange(values.ldapGroup);
      }}
      initialValue={formData}
    />
  );
};
