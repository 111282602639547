import {
  FormControl,
  FormControlLabel,
  FormLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Tooltip,
  Typography,
} from '@material-ui/core';
import React from 'react';
import { useStyles } from './orionFormStyles';
import { OrionFormDropdown } from './OrionFormDropdown';
import { OrionFormTextField } from './OrionFormTextField';
import { useFormikContext } from 'formik';
import { OrionAppRequestValues } from './orionFormValuesTypes';

export const OrionFormSectionASTRA = () => {
  const classes = useStyles();
  const formik = useFormikContext<OrionAppRequestValues>();

  return (
    <>
      <Typography variant="h5" className={classes.centeredText} style={{ paddingBottom: 16 }}>
        DataStax Astra Information
      </Typography>
      <div className={classes.flexRowMos}>
        <OrionFormDropdown
          fieldPropName="astraKeyspacePurpose"
          label="Astra Keyspace Purpose"
          placeholder="analytics"
          required
          tooltip='Only select option "Other" if you have a custom naming requirement'
        >
          <MenuItem value="analytics">Analytics</MenuItem>
          <MenuItem value="operations">Operations</MenuItem>
          <MenuItem value="other">Other</MenuItem>
        </OrionFormDropdown>
      </div>
      {formik.values.astraKeyspacePurpose === 'other' && (
        <div className={classes.formGroup}>
          <OrionFormTextField
            fieldPropName="astraAppendName"
            label="Append Name"
            placeholder="ex: tst08_(append)_etc"
            tooltip="Only fill out this field if you have a custom requirement
          for the naming of your keyspace. Only use the 'Other' option to create additional keyspaces. This field will be appended
          to the keyspace name. E.g. tst08_{AppendName}_keysp"
          />
        </div>
      )}
      <div className={classes.formGroup}>
        <FormControl>
          <Tooltip
            disableFocusListener
            arrow
            title={
              <h3>
                {' '}
                Selecting "Yes" means your keyspace will be provisioned in dual-regions. Selecting "No" will create only
                in East.{' '}
              </h3>
            }
          >
            <FormLabel id="astraCriticalApp" required>
              Critical App?
            </FormLabel>
          </Tooltip>
          <RadioGroup
            row
            data-testid="astraCriticalApp"
            id="astraCriticalApp"
            aria-labelledby="astraCriticalApp"
            {...formik.getFieldProps('astraCriticalApp')}
            name="astraCriticalApp"
            defaultValue="N"
          >
            <FormControlLabel value="Y" control={<Radio color="primary" />} label="Yes" />
            <FormControlLabel value="N" control={<Radio color="primary" />} label="No" />
          </RadioGroup>
        </FormControl>
      </div>
    </>
  );
};
