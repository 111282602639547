import {
  Button,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  Typography,
  TextField,
  FormControl,
  CircularProgress,
  Collapse,
  Grid,
  Tooltip,
  IconButton,
} from '@material-ui/core';
import { createStyles, makeStyles, Theme, useTheme, withStyles } from '@material-ui/core/styles';
import { Alert, Autocomplete } from '@material-ui/lab';
import * as yup from 'yup';
import React, { Ref, useEffect, useState } from 'react';
import { Controller, ControllerRenderProps, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { ProfileInfo, configApiRef, identityApiRef, useApi } from '@backstage/core-plugin-api';
import { BucketId, ClientId, PluginId } from '@runway/devkit-common';
import { useAsync } from 'react-use';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import InfoIcon from '@material-ui/icons/Info';
import axios from 'axios';
import { aaPingSSOAuthApiRef, adServiceApiRef } from '../../../services';
import { useTrackedRequest } from '../../../hooks';
import { CmdbAppShortName, RANCHER_ENVIRONMENT_OPTIONS } from '../../../components';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      backgroundColor: theme.palette.background.default,
      borderRadius: 4,
      padding: theme.spacing(3),
      border: `1px solid ${theme.palette.primary.light}`,
      minHeight: '150px',
    },
    cancelAndCreate: {
      margin: '2px',
    },
    center: {
      display: 'flex',
      justifyContent: 'center',
    },
    radioGroup: {
      flexDirection: 'row',
    },
  }),
);

const RESOURCE_SIZE_OPTIONS = [
  { name: 'small (cpu-14000m & mem-25600Mi)', value: 'small' },
  { name: 'medium (cpu-28000m & mem-51200Mi)', value: 'medium' },
  { name: 'large (cpu-56000m & mem-102400Mi)', value: 'large' },
  { name: 'x-large (cpu-84000m & mem-153600Mi)', value: 'x-large' },
];

const defaultValues = {
  location: 'nonprod',
  cluster: '',
  project: '',
  namespace: '',
  aadGroup: '',
  environment: '',
  resourceSize: '',
  pci: 'non-pci',
};

enum LocationOptions {
  PROD = 'prod',
  NON_PROD = 'nonprod',
}

enum PCIOptions {
  PCI = 'pci',
  NON_PCI = 'non-pci',
}

enum FormFields {
  LOCATION = 'location',
  PCI = 'pci',
  CLUSTER = 'cluster',
  PROJECT = 'project',
  NAMESPACE = 'namespace',
  AADGROUP = 'aadGroup',
  ENVIRONMENT = 'environment',
  RESOURCE_SIZE = 'resourceSize',
}

enum State {
  INIT,
  LOADING,
  SUCCESS,
  ERROR,
}

type ClusterType = {
  name: string;
  displayName: string;
  production: boolean;
  deployable: boolean;
  allowedAadGroups?: { name: string }[];
} & Record<string, any>;

type CurrentStateType = {
  state: State;
  message?: string;
};

type CreateRNSDropdownProps = {
  onSubmit: (data: FormType) => Promise<void>;
  handleToggle: () => void;
  currentState: CurrentStateType;
};

type AzureGroup = {
  groupName: string;
  groupId: string;
};

const Schema = yup.object({
  location: yup.string().required(),
  pci: yup.string().required(),
  cluster: yup.string().required(),
  project: yup.string().required(),
  namespace: yup
    .string()
    .required()
    .matches(/^[a-z0-9-]*$/g, 'Must not contain uppercase letters or underscores')
    .test('not-match', 'Name must not end with an environment designator', input => {
      for (const { value } of RANCHER_ENVIRONMENT_OPTIONS) {
        if (input.endsWith(value)) {
          return false;
        }
      }
      return true;
    }),
  aadGroup: yup.string().required(),
  resourceSize: yup
    .string()
    .oneOf(RESOURCE_SIZE_OPTIONS.map(size => size.value))
    .required(),
  environment: yup
    .string()
    .oneOf(RANCHER_ENVIRONMENT_OPTIONS.map(env => env.value))
    .required(),
});

type FormType = yup.InferType<typeof Schema>;

const LocationRadioGroup = React.forwardRef((props: ControllerRenderProps, ref: Ref<unknown>) => {
  const classes = useStyles();
  const theme = useTheme();

  return (
    <>
      <Grid container spacing={2} direction="row" justifyContent="flex-start" alignItems="center">
        <Typography aria-label="location" style={{ color: theme.palette.text.secondary, paddingRight: '20px' }}>
          Namespace Location:
        </Typography>
        <RadioGroup className={classes.radioGroup} {...props} ref={ref} aria-label="radio-options" name="options">
          <FormControlLabel
            value={LocationOptions.NON_PROD}
            aria-label="radio-btn-nonprod"
            control={<Radio />}
            label="Non-Prod"
          />
          <FormControlLabel value={LocationOptions.PROD} aria-label="radio-btn-prod" control={<Radio />} label="Prod" />
        </RadioGroup>
      </Grid>
    </>
  );
});

const NoPaddingTooltip = withStyles({
  tooltip: {
    padding: 0,
    margin: 5,
  },
})(Tooltip);

const PCIRadioGroup = React.forwardRef((props: ControllerRenderProps, ref: Ref<unknown>) => {
  const classes = useStyles();
  const theme = useTheme();

  return (
    <>
      <Grid container spacing={2} direction="row" justifyContent="flex-start" alignItems="center">
        <Typography aria-label="pci" style={{ color: theme.palette.text.secondary, padding: '0px 10px' }}>
          PCI:
        </Typography>
        <RadioGroup className={classes.radioGroup} {...props} ref={ref} aria-label="radio-options" name="options">
          <FormControlLabel value={PCIOptions.PCI} aria-label="radio-btn-pci" control={<Radio />} label="PCI" />
          <FormControlLabel
            value={PCIOptions.NON_PCI}
            aria-label="radio-btn-non-pci"
            control={<Radio />}
            label="Non-PCI"
          />
        </RadioGroup>
        <Grid md={3} item>
          <NoPaddingTooltip
            title={<Alert severity="info">PCI clusters are compliant with Payment Card Industry standards</Alert>}
          >
            <IconButton color="primary" size="small" aria-label="info-button">
              <InfoIcon aria-label="info-icon" />
            </IconButton>
          </NoPaddingTooltip>
        </Grid>
      </Grid>
    </>
  );
});

const ClusterDropdown = React.forwardRef(
  (props: ControllerRenderProps & { location: string; pci: string }, ref: Ref<unknown>) => {
    const { location, pci, ...other } = props;

    const config = useApi(configApiRef);
    const aadGroups = useApi(adServiceApiRef);
    const [clusters, setClusters] = useState<ClusterType[]>([]);
    const [userAadGroups, setUsersAdGroups] = useState<AzureGroup[]>([]);

    const filterClusters = (clustersList: ClusterType[], loc: string) =>
      clustersList
        .filter(cluster =>
          cluster.allowedAadGroups
            ? cluster.allowedAadGroups.some(group =>
                userAadGroups.some((userGroup: AzureGroup) => userGroup.groupName === group.name),
              )
            : true,
        )
        .filter(cluster => (cluster.production ? loc === LocationOptions.PROD : loc === LocationOptions.NON_PROD))
        .filter(cluster => (cluster.pci ? pci === PCIOptions.PCI : pci === PCIOptions.NON_PCI))
        .filter(cluster => cluster.deployable);

    useAsync(async () => {
      const resp = await aadGroups.getUsersGroupMembershipAzureAD();
      setUsersAdGroups(resp?.outputGroups);
    });

    useEffect(() => {
      const kubernetesConfigArray = config.getConfigArray('kubernetes.clusterLocatorMethods')[0];
      setClusters(filterClusters((kubernetesConfigArray as any).data.clusters, location));
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [config, location, pci]);

    return (
      <>
        <InputLabel htmlFor="cluster-select">Cluster</InputLabel>
        <Select
          {...other}
          ref={ref}
          id="cluster-select"
          inputProps={{ 'aria-label': 'cluster-dropdown' }}
          label="cluster"
        >
          {clusters.length === 0 ? (
            <MenuItem value="">
              <em>No clusters available</em>
            </MenuItem>
          ) : (
            clusters.map((cluster: ClusterType) => (
              <MenuItem
                key={cluster.name}
                value={cluster.name}
                aria-label={`cluster-option-${cluster.name.toLowerCase()}`}
              >
                {cluster.displayName}
              </MenuItem>
            ))
          )}
        </Select>
      </>
    );
  },
);

const NamespaceTextField = React.forwardRef(
  (props: ControllerRenderProps & { error: boolean; helperText: string }, ref: Ref<HTMLDivElement>) => {
    const { error, helperText } = props;
    const other = {
      onChange: props.onChange,
      onBlur: props.onBlur,
      value: props.value,
    };

    return (
      <>
        <TextField
          id="namespace"
          ref={ref}
          label="Namespace"
          name="namespace"
          aria-label="namespace-input"
          fullWidth
          required
          error={error}
          helperText={helperText}
          {...other}
        />
      </>
    );
  },
);

const AADGroupNameDropdown = (props: {
  value: string;
  error: boolean;
  helperText: string;
  onChange: (event: unknown) => void;
}) => {
  const { onChange, value, error, helperText, ...other } = props;
  const identityApi = useApi(identityApiRef);
  const configApi = useApi(configApiRef);

  const [aadGroups, setAadGroups] = useState<string[]>([]);

  const getAAProfile = async () => {
    const employeeProfile = (await identityApi.getProfileInfo()) as ProfileInfo & { aaId?: string };
    const { token } = await identityApi.getCredentials();
    return { id: employeeProfile.aaId || '-1', token };
  };

  const getAADGroup = async (baseUrl: string) => {
    try {
      const { id, token } = await getAAProfile();
      if (!id) return undefined;
      const resp = await axios.get(
        `${baseUrl}/api/infrastructure/azure/userActiveDirectoryGroups?employeeId=${id.replace(/^00/, '')}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      );
      return (resp.data as string[]).sort();
    } catch (err: any) {
      throw new Error(
        'Cannot get AAD Group Names. Please try again and if the problem persists, reach out to #Runway on Slack',
      );
    }
  };

  const { loading } = useAsync(async () => {
    const groups = await getAADGroup(configApi.getString('backend.baseUrl'));
    setAadGroups(groups ?? []);
  });

  return (
    <>
      {loading && <CircularProgress style={{ marginLeft: 10 }} aria-label="circular-spinner" />}
      <Autocomplete
        freeSolo
        autoComplete
        includeInputInList
        filterSelectedOptions
        loading={!aadGroups}
        loadingText="Loading AD Groups"
        value={value}
        onChange={(_, newValue) => onChange(newValue)}
        options={aadGroups ?? []}
        renderInput={params => (
          <TextField
            {...params}
            {...props}
            variant="outlined"
            label="Azure AD Group Name"
            aria-label="aadgroup-input"
            InputProps={{ ...params.InputProps }}
            error={error}
            helperText={helperText}
            required
          />
        )}
        {...other}
      />
    </>
  );
};

const Dropdown = React.forwardRef(
  (
    props: ControllerRenderProps & {
      fieldName: string;
      title: string;
      fieldOptions: { name: string; value: string }[];
    },
    ref: Ref<unknown>,
  ) => {
    const { fieldName, title, fieldOptions } = props;
    const other = {
      onChange: props.onChange,
      onBlur: props.onBlur,
      value: props.value,
    };
    return (
      <>
        <InputLabel htmlFor={`${fieldName}-dropdown`}>{title}</InputLabel>
        <Select
          id={`${fieldName}-dropdown`}
          inputProps={{ 'aria-label': `${fieldName}-dropdown` }}
          label={fieldName}
          ref={ref}
          {...other}
        >
          {fieldOptions.map(item => (
            <MenuItem
              key={item.value}
              value={item.value}
              aria-label={`${fieldName}-option-${item.value.toLowerCase()}`}
            >
              {item.name}
            </MenuItem>
          ))}
        </Select>
      </>
    );
  },
);

const CreateRNSDropdown = ({ onSubmit, handleToggle, currentState }: CreateRNSDropdownProps) => {
  const classes = useStyles();
  const theme = useTheme();

  const { handleSubmit, control, watch, formState } = useForm<FormType>({
    defaultValues,
    mode: 'onChange',
    shouldUnregister: true,
    resolver: yupResolver(Schema),
  });

  const namespace = watch('namespace', '<Namespace>');
  const environment = watch('environment', '<Environment>');
  const location = watch('location');
  const pci = watch('pci');

  return (
    <>
      <Grid
        container
        spacing={2}
        direction="row"
        justifyContent="space-around"
        alignItems="center"
        style={{ padding: '6px' }}
      >
        <Grid item md={12}>
          {currentState.state === State.ERROR && (
            <Alert severity="error" aria-label="error-message">
              {currentState.message}
            </Alert>
          )}
        </Grid>
        <Grid item md={6}>
          <FormControl variant="outlined" fullWidth required>
            <Controller name={FormFields.PCI} control={control} render={({ field }) => <PCIRadioGroup {...field} />} />
          </FormControl>
        </Grid>
        <Grid item md={6}>
          <FormControl variant="outlined" fullWidth required>
            <Controller
              name={FormFields.LOCATION}
              control={control}
              render={({ field }) => <LocationRadioGroup {...field} />}
            />
          </FormControl>
        </Grid>
        <Grid item md={4}>
          <FormControl variant="outlined" fullWidth required>
            <Controller
              name={FormFields.PROJECT}
              control={control}
              render={({ field: { onChange } }) => (
                <CmdbAppShortName onChange={value => onChange(value.shortName)} required width={600} />
              )}
            />
          </FormControl>
        </Grid>
        <Grid item md={4}>
          <FormControl variant="outlined" fullWidth required>
            <Controller
              name={FormFields.CLUSTER}
              control={control}
              render={({ field }) => <ClusterDropdown location={location} pci={pci} {...field} />}
            />
          </FormControl>
        </Grid>
        <Grid item md={4}>
          <FormControl variant="outlined" fullWidth required>
            <Controller
              name={FormFields.ENVIRONMENT}
              control={control}
              render={({ field }) => (
                <Dropdown {...field} fieldName="env" title="Environment" fieldOptions={RANCHER_ENVIRONMENT_OPTIONS} />
              )}
            />
          </FormControl>
        </Grid>
        <Grid item md={4}>
          <FormControl variant="outlined" fullWidth required>
            <Controller
              name={FormFields.RESOURCE_SIZE}
              control={control}
              render={({ field }) => (
                <Dropdown {...field} fieldName="resource" title="Resource Size" fieldOptions={RESOURCE_SIZE_OPTIONS} />
              )}
            />
          </FormControl>
        </Grid>
        <Grid item md={4}>
          <FormControl variant="outlined" fullWidth required>
            <Controller
              name={FormFields.NAMESPACE}
              control={control}
              render={({ field, fieldState: { error } }) => (
                <NamespaceTextField {...field} error={!!error} helperText={error?.message ? error.message : ''} />
              )}
            />
          </FormControl>
        </Grid>
        <Grid item md={4}>
          <FormControl variant="outlined" fullWidth required>
            <Controller
              name={FormFields.AADGROUP}
              control={control}
              render={({ field: { onChange, value }, fieldState: { error } }) => (
                <AADGroupNameDropdown
                  value={value}
                  onChange={onChange}
                  error={!!error}
                  helperText={error?.message ? error.message : ''}
                />
              )}
            />
          </FormControl>
        </Grid>
        <Grid xs={4} item>
          <Typography aria-label="rns-preview" variant="body1">
            Rancher Namespace:{' '}
            <span style={{ color: theme.palette.text.secondary }}>
              {namespace}-{environment}
            </span>
          </Typography>
        </Grid>
        <Grid item xs={5} />
        <Grid xs={3} item className={classes.center}>
          <Button
            variant="outlined"
            className={classes.cancelAndCreate}
            aria-label="btn-close-form"
            onClick={handleToggle}
            disabled={currentState.state === State.LOADING}
          >
            CANCEL
          </Button>
          <Button
            variant="outlined"
            className={classes.cancelAndCreate}
            aria-label="btn-submit-form"
            onClick={handleSubmit(onSubmit)}
            disabled={currentState.state === State.LOADING || !formState.isValid}
          >
            CREATE
          </Button>
        </Grid>
      </Grid>
    </>
  );
};

export const CreateRancherNamespace = () => {
  const classes = useStyles();
  const theme = useTheme();

  const { tracker } = useTrackedRequest({
    bucket_id: BucketId.USE_METRIC,
    client_id: ClientId.RUNWAY_FRONTEND,
    pluginId: PluginId.TEMPLATE_CREATE_RANCHER_NAMESPACE.id,
  });

  const config = useApi(configApiRef);
  const ssoApi = useApi(aaPingSSOAuthApiRef);

  const [isOpen, setIsOpen] = useState(false);
  const [currentState, setCurrentState] = useState<CurrentStateType>({ state: State.INIT });

  const handleToggle = () => {
    setIsOpen(prevIsOpen => !prevIsOpen);
  };

  const onSubmit = async (data: FormType) => {
    setCurrentState({ state: State.LOADING });
    try {
      const identity = await ssoApi.getAAProfile();
      const metadata = {
        clusterName: data.cluster,
        appShortName: data.project,
        groupName: data.aadGroup,
        resourceSize: data.resourceSize,
        userEmployeeId: identity?.aaId ? identity.aaId : '-1',
      };
      await tracker(async () => {
        const token = await ssoApi.getAccessToken();
        await axios.post(
          `${config.getString('clusterService.baseUrl')}/projects`,
          {
            cluster_name: data.cluster,
            project_name: data.project,
            namespace_name: `${data.namespace}-${data.environment}`,
            aad_group_name: data.aadGroup,
            resource_size: data.resourceSize,
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          },
        );
        handleToggle();
        setCurrentState({ state: State.SUCCESS });
      }, metadata);
    } catch (error: any) {
      const message = error.response?.data?.detail || error.message;
      setCurrentState({ state: State.ERROR, message: `Error: ${message}` });
    }
  };

  return (
    <div className={classes.container}>
      <Grid container spacing={2} direction="row" justifyContent="space-around" alignItems="flex-end">
        <Grid md={10} item>
          <Typography variant="h6" aria-label="title">
            Create Rancher Namespace
          </Typography>
          <Typography variant="subtitle2" aria-label="description">
            Rancher Namespace allows a user to divide cluster's resources, such as CPU, memory, and storage, into
            multiple virtual clusters. Each namespace can have its own set of resources and configurations, creating a
            separation between different teams, projects, or applications within the same cluster.
          </Typography>
        </Grid>
        <Grid md={2} item className={classes.center}>
          {!isOpen && currentState.state !== State.SUCCESS && currentState.state !== State.LOADING && (
            <Button
              variant="contained"
              onClick={handleToggle}
              aria-label="btn-open-form"
              style={{ marginBottom: '10px' }}
            >
              CREATE
            </Button>
          )}
          {!isOpen && currentState.state === State.SUCCESS && (
            <FontAwesomeIcon
              size="3x"
              icon={faCheck}
              aria-label="success-icon"
              style={{ color: theme.palette.success.main }}
            />
          )}
          {currentState.state === State.LOADING && <CircularProgress aria-label="submit-spinner" />}
        </Grid>
        <Collapse in={isOpen}>
          {isOpen && <CreateRNSDropdown onSubmit={onSubmit} handleToggle={handleToggle} currentState={currentState} />}
        </Collapse>
      </Grid>
    </div>
  );
};
