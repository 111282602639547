import { Entity } from '@backstage/catalog-model';
import { useApi } from '@backstage/core-plugin-api';
import { Dialog, DialogContent, DialogTitle } from '@material-ui/core';
import { BucketId, ClientId, aaPingSSOAuthApiRef, useTrackedRequest } from '@runway/devkit';
import { PluginId } from '@runway/devkit-common';
import axios from 'axios';
import React, { useState } from 'react';
import { useAsyncFn } from 'react-use';
import { ArgoCDServiceApiRef } from '../../services/argocd.catalog.service';
import { RegisterAppForm, RegisterAppFormValues } from './RegisterAppForm';

interface RegisterAppDialogProps {
  isOpen: boolean;
  setRegisterAppComponentOpen: any;
  setSnackbarOpen: any;
  close(): void;
  entity: Entity;
}

export const RegisterAppDialog = ({
  isOpen,
  setRegisterAppComponentOpen,
  setSnackbarOpen,
  close,
  entity,
}: RegisterAppDialogProps) => {
  const argoCatalog = useApi(ArgoCDServiceApiRef);
  const identity = useApi(aaPingSSOAuthApiRef);
  const { tracker } = useTrackedRequest({
    bucket_id: BucketId.USE_METRIC,
    client_id: ClientId.RUNWAY_FRONTEND,
    pluginId: PluginId.CATALOG_DEPLOY_ARGO_APP.id,
  });

  const [hideResult, setHideResult] = useState(true);

  const appName = entity.metadata.name;

  const handleClose = () => {
    setHideResult(true);
    close();
  };

  const buildArgoErrorString = (
    detail: undefined | { message: string; group_names: string[]; group_ids: string[] },
  ) => {
    const defaultMessage = 'You need proper AAD Group access to proceed.';
    if (!detail) return defaultMessage;
    if (detail.group_names && detail.group_names.length)
      return `You need membership in one of the following AAD Groups: ${detail.group_names.join(', ')}`;
    if (detail.group_ids && detail.group_ids.length)
      return `You need membership in one of the following AAD Groups: ${detail.group_ids.join(', ')}`;
    return defaultMessage;
  };

  const [submitState, doSubmit] = useAsyncFn(async (values: RegisterAppFormValues) => {
    try {
      setHideResult(false);

      if (!entity?.metadata?.annotations?.['argocd/app-selector']) {
        throw new Error(
          "Missing 'metadata.annotations.argocd/app-selector' in 'catalog-info.yaml'. Please add this field and use the format 'backstage-name=<app-name>' for the value.",
        );
      }

      const req = {
        clusterName: values.hostCluster,
        namespace: values.namespace,
        projectName: values.appName,
        appName: values.appName,
        sourceRepo: `https://github.com/AAInternal/${values.sourceRepo}.git`,
        sourcePath: values.sourcePath,
        labelValue: entity?.metadata?.annotations?.['argocd/app-selector'].replace('backstage-name=', ''),
      };
      await tracker(
        async () => {
          await argoCatalog.createArgoApp(req);
        },
        {
          userEmployeeId: (await identity.getAAProfile())?.aaId ?? '-1',
          clusterName: values.hostCluster,
          namespace: values.namespace,
          appName: values.appName,
          sourceRepo: values.sourceRepo,
          sourcePath: values.sourcePath,
        },
      );
      setRegisterAppComponentOpen(false);
      setSnackbarOpen(true);
      return 'Successfully Created Application - Deployment may still be pending';
    } catch (e: any) {
      if (axios.isAxiosError(e)) {
        if (e.response?.status === 403) {
          throw new Error(
            `Error Creating ArgoCD Application - Insufficient Permissions. ${buildArgoErrorString(
              e.response.data.detail,
            )}`,
          );
        }
        if (e.response?.data?.message) {
          throw new Error(e.response?.data?.message);
        }
      }
      throw new Error(e.message);
    }
  });

  return (
    <Dialog
      open={isOpen}
      fullWidth
      keepMounted
      onClose={handleClose}
      aria-labelledby="alert-dialog-slide-title"
      aria-describedby="alert-dialog-slide-description"
    >
      <DialogTitle>Deploy ArgoCD App</DialogTitle>

      <DialogContent>
        <RegisterAppForm
          appName={appName}
          hideResult={hideResult}
          handleClose={handleClose}
          submitState={submitState}
          doSubmit={doSubmit}
        />
      </DialogContent>
    </Dialog>
  );
};
