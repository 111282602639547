import { Progress } from '@backstage/core-components';
import { useApi } from '@backstage/core-plugin-api';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  InputAdornment,
  TextField,
} from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import React, { useState } from 'react';
import { useAsync } from 'react-use';
import { ArgoCDServiceApiRef } from '../../services/argocd.catalog.service';
import { aaPingSSOAuthApiRef, useTrackedRequest } from '@runway/devkit';
import { BucketId, ClientId, PluginId } from '@runway/devkit-common';

enum SubmitState {
  NOT_SUBMITTED,
  SUBMITTING,
  SUBMITTED,
}

type ArgoDeployment = {
  clusterName: string;
  appName: string;
  appSelector: string;
};

const decodeRepoUrl = (repoURL: string): string => {
  const repoUrlRegex = /https:\/\/github\.com\/AAInternal\/([A-Za-z0-9_.-]+)\.git/m;
  const match = repoURL.match(repoUrlRegex);
  if (match && match.length > 1) {
    return match[1];
  }
  return '';
};

const encodeRepoUrl = (repo: string) => `https://github.com/AAInternal/${repo}.git`;

const ArgoDeploymentUpdate = ({
  repoURL,
  setRepoURL,
  sourcePath,
  setSourcePath,
  deployment,
}: {
  repoURL: string;
  setRepoURL: React.Dispatch<React.SetStateAction<string>>;
  sourcePath: string;
  setSourcePath: React.Dispatch<React.SetStateAction<string>>;
  deployment: ArgoDeployment;
}) => {
  return (
    <Grid container alignItems="flex-start">
      <Grid item xs={10}>
        <DialogContentText>
          App Name: <b>{deployment.appName}</b>
        </DialogContentText>
      </Grid>
      <Grid item xs={10}>
        <DialogContentText>
          Cluster Name: <b>{deployment.clusterName}</b>
        </DialogContentText>
      </Grid>
      <Grid item xs={10}>
        <TextField
          label="Repo"
          variant="outlined"
          id="repo"
          value={decodeRepoUrl(repoURL)}
          onChange={event => setRepoURL(encodeRepoUrl(event.target.value))}
          InputProps={{
            startAdornment: <InputAdornment position="start">AAInternal /</InputAdornment>,
          }}
          style={{ width: '100%' }}
        />
      </Grid>
      <Grid item xs={10}>
        <TextField
          label="Source Path"
          variant="outlined"
          id="sourcePath"
          value={sourcePath}
          onChange={event => setSourcePath(event.target.value)}
          style={{ marginTop: '5px', width: '100%' }}
        />
      </Grid>
    </Grid>
  );
};

export const UpdateArgoAppForm = ({
  isOpen,
  close,
  deployment,
}: {
  isOpen: boolean;
  close(): void;
  deployment: ArgoDeployment;
}) => {
  const { clusterName, appSelector, appName } = deployment;
  const argoApi = useApi(ArgoCDServiceApiRef);
  const currentIdentity = useApi(aaPingSSOAuthApiRef);

  const { tracker } = useTrackedRequest({
    bucket_id: BucketId.USE_METRIC,
    client_id: ClientId.RUNWAY_FRONTEND,
    pluginId: PluginId.ARGO_UPDATE.id,
  });

  const [repoURL, setRepoURL] = useState('');
  const [sourcePath, setSourcePath] = useState('');
  const [submit, setSubmit] = useState(SubmitState.NOT_SUBMITTED);

  const argoDeploymentInfo = useAsync(async () => {
    if (isOpen) {
      const result = await argoApi.getArgoInstanceData(clusterName, appSelector, appName);
      setRepoURL(result.spec.source.repoURL);
      setSourcePath(result.spec.source.path);
      return result;
    }
    return undefined;
  }, [isOpen]);

  const updateArgoApp = useAsync(async () => {
    if (submit === SubmitState.SUBMITTING && argoDeploymentInfo.value && repoURL && sourcePath) {
      const data = {
        argoAppName: deployment.appName,
        clusterName,
        projectName: deployment.appName,
        namespace: argoDeploymentInfo.value.spec.destination.namespace,
        sourceRepo: repoURL,
        sourcePath,
      };

      await tracker(
        async () => {
          await argoApi.updateArgoApp({
            clusterName,
            labelValue: appSelector.replace('backstage-name=', ''),
            appName: deployment.appName,
            projectName: deployment.appName,
            namespace: argoDeploymentInfo.value?.spec.destination.namespace || '',
            sourceRepo: repoURL,
            sourcePath,
          });
        },
        { userEmployeeId: (await currentIdentity.getAAProfile())?.aaId ?? '-1', data },
      );
      setSubmit(SubmitState.SUBMITTED);
    }
  }, [submit]);

  const doUpdate = () => setSubmit(SubmitState.SUBMITTING);
  return (
    <Dialog open={isOpen} onClose={close} fullWidth>
      <DialogTitle>Update Argo App Deployment</DialogTitle>
      <DialogContent style={{ alignContent: 'center' }}>
        {argoDeploymentInfo.value && (
          <ArgoDeploymentUpdate
            repoURL={repoURL}
            setRepoURL={setRepoURL}
            sourcePath={sourcePath}
            setSourcePath={setSourcePath}
            deployment={deployment}
          />
        )}
        {(argoDeploymentInfo.loading || updateArgoApp.loading) && <Progress />}
        {argoDeploymentInfo.error && (
          <Alert
            severity="error"
            style={{
              boxShadow: '0 0 5px 1px #000',
              WebkitBoxShadow: '0 0 5px 1px #000',
              borderColor: '#004F0C',
            }}
          >
            Error getting Argo Instance Data: {argoDeploymentInfo.error.message} - Please try again and reach out to the
            Runway team via Slack on #runway if the problem persists
          </Alert>
        )}
        {updateArgoApp.error && (
          <Alert
            severity="error"
            style={{
              boxShadow: '0 0 5px 1px #000',
              WebkitBoxShadow: '0 0 5px 1px #000',
              borderColor: '#004F0C',
            }}
          >
            Failed to Update Argo App and Project: {updateArgoApp.error.message} - Please try again and reach out to the
            Runway team via Slack on #runway if the problem persists
          </Alert>
        )}
        {submit === SubmitState.SUBMITTED && (
          <Alert
            severity="success"
            style={{
              boxShadow: '0 0 5px 1px #000',
              WebkitBoxShadow: '0 0 5px 1px #000',
              borderColor: '#004F0C',
            }}
          >
            Successfully Updated Argo App
          </Alert>
        )}
      </DialogContent>
      <DialogActions>
        <Button type="button" className="outline" onClick={doUpdate} disabled={updateArgoApp.loading}>
          Submit Changes
        </Button>
        <Button type="button" className="outline" onClick={close} disabled={updateArgoApp.loading}>
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};
