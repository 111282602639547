import { useApi } from '@backstage/core-plugin-api';
import { notificationServiceApiRef, useEmployeeProfile, useTrackedRequest } from '@runway/devkit';
import { BucketId, ClientId, PluginId } from '@runway/devkit-common';
import React, { FC, useState } from 'react';
import { orionServiceApiRef } from '../../service/orion.service';
import { OrionForm } from './OrionForm';
import { orionAppInitializeValues } from './orionFormInitialValue';
import { OrionAppRequestValues } from './orionFormValuesTypes';

const subjectLine = 'Runway - Orion Automation Provisioning';
const surveyLink = 'https://surveys.aa.com/assets/run.html?archer_id=189986';
const emailBody = (href: string) =>
  '<h4>Application provisioning has started.</h4><br>' +
  '<br> Below is a link to your Azure DevOps Pipeline where you can see provisioning status. <br>' +
  `<br> <a href="${href}">DevOps Pipeline</a>` +
  '<br> <br>' +
  '<br> Please take the time to leave any feedback through the survey linked below. <br>' +
  `<br> <a href="${surveyLink}">Survey Link</a>`;

interface OrionComponentProps {
  archerShortname: string;
  costCenter: string;
  squadName: string;
  appName: string;
}

export const OrionFormContainer: FC<OrionComponentProps> = ({ archerShortname, costCenter, squadName, appName }) => {
  const orionService = useApi(orionServiceApiRef);
  const notificationService = useApi(notificationServiceApiRef);

  const { aaId, email: employeeEmail } = useEmployeeProfile({ withPrefix: false });
  const { createRecord } = useTrackedRequest({
    bucket_id: BucketId.USE_METRIC,
    client_id: ClientId.RUNWAY_FRONTEND,
    pluginId: PluginId.ORION_DATA_LAKE_PROVISIONING.id,
  });

  const [loading, setLoading] = React.useState(false);
  const [error, setError] = useState<string>();
  const [success, setSuccess] = useState(false);
  const [currentStep] = useState<number>(0);

  const onSubmit = async (values: OrionAppRequestValues) => {
    setSuccess(false);
    setLoading(true);
    setError(undefined);

    const metadata = {
      ...values,
      userEmployeeId: aaId,
      success: true,
    };

    try {
      const output = await orionService.updateDataFromForm({
        ...values,
        aaEmail: employeeEmail,
        aaId,
      });
      await notificationService.sendEmail([employeeEmail], emailBody(output.response), subjectLine);
      await createRecord(metadata, 'success');
      setSuccess(true);
      setError(undefined);
    } catch (e: any) {
      await createRecord({ ...metadata, success: false }, 'failed', e.stack);
      setSuccess(false);
      setError(e.message || 'Unknown Error Occurred');
    } finally {
      setLoading(false);
    }
  };

  const initialValues = orionAppInitializeValues({
    archerShortname,
    costCenter,
    squadName,
    appName,
  });

  return (
    <OrionForm
      employeeEmail={employeeEmail}
      initialValues={initialValues}
      onSubmit={onSubmit}
      loading={loading}
      success={success}
      error={error}
      currentStep={currentStep}
    />
  );
};
