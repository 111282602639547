import React from 'react';
import { STATUS } from '../Status';
import { getStatusDetails, Layer } from './Layer';

export interface ArgoData {
  items: {
    metadata: {
      instance: {
        name: string;
      };
    };
    status: {
      conditions?: {
        message: string;
      }[];
      health?: { status: string };
      operationState?: { message: string };
      sync?: { status: string };
    };
  }[];
}
export interface Cluster {
  name: string;
  url: string;
  appName: string[];
}

export type ArgoStatus = Record<string, ArgoHealthStatus>;
interface ArgoHealthStatus {
  status: STATUS;
  message: string;
}

const getArgoClusterDetails = (cluster: ArgoHealthStatus) => {
  return cluster.message;
};

export const ArgoCDLayer = ({ title, status, argoData }: { title: string; status: STATUS; argoData: any }) => (
  <Layer
    title={title}
    status={status}
    subheader={getStatusDetails<ArgoHealthStatus>(status, argoData, getArgoClusterDetails, 'argoCD')}
  />
);
