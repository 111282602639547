import React, { useState } from 'react';
import { InfoCard, InfoCardVariants, StructuredMetadataTable } from '@backstage/core-components';
import { useProjectName } from '../useProjectName';
import { useIssuesStatistics } from '../useIssueStatistics';
import { Box, CircularProgress, FormControl, FormHelperText, MenuItem, Select, makeStyles } from '@material-ui/core';
import { Entity } from '@backstage/catalog-model';
import { useEntity } from '@backstage/plugin-catalog-react';

const useStyles = makeStyles(theme => ({
  infoCard: {
    marginBottom: theme.spacing(3),
    '& + .MuiAlert-root': {
      marginTop: theme.spacing(3),
    },
    '& .MuiCardContent-root': {
      padding: theme.spacing(2, 1, 2, 2),
    },
    '& td': {
      whiteSpace: 'normal',
    },
  },
}));

type Props = {
  /** @deprecated The entity is now grabbed from context instead */
  entity?: Entity;
  variant?: InfoCardVariants;
};

const IssueStatsCard = (props: Props) => {
  const { entity } = useEntity();
  const classes = useStyles();
  const [pageSize, setPageSize] = useState<number>(20);
  const projectName = useProjectName(entity);
  const [owner, repo] = (projectName ?? '/').split('/');
  const [{ statsData, loading: loadingStatistics }] = useIssuesStatistics({
    owner,
    repo,
    pageSize,
    state: 'CLOSED',
  });

  const metadata = {
    'average time until issue closed': statsData?.avgTimeUntilClose,
  };

  return (
    <InfoCard title="Issues statistics" className={classes.infoCard} variant={props.variant}>
      {loadingStatistics ? (
        <CircularProgress />
      ) : (
        <Box position="relative">
          <StructuredMetadataTable metadata={metadata} />
          <Box display="flex" justifyContent="flex-end">
            <FormControl>
              <Select value={pageSize} onChange={event => setPageSize(Number(event.target.value))}>
                <MenuItem value={10}>10</MenuItem>
                <MenuItem value={20}>20</MenuItem>
                <MenuItem value={50}>50</MenuItem>
                <MenuItem value={100}>100</MenuItem>
              </Select>
              <FormHelperText>Number of Issues</FormHelperText>
            </FormControl>
          </Box>
        </Box>
      )}
    </InfoCard>
  );
};

export default IssueStatsCard;
