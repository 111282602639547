import React, { useState } from 'react';
import { identityApiRef, useApi } from '@backstage/core-plugin-api';
import { InfoCard } from '@backstage/core-components';
import { Button, makeStyles, Typography } from '@material-ui/core';
import { useAsync, useCopyToClipboard } from 'react-use';

const useStyles = makeStyles(theme => ({
  button: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
}));

export const BackstageToken = () => {
  const classes = useStyles();
  const [showCopied, setShowCopy] = useState(false);
  const [, setCopyToClipboard] = useCopyToClipboard();
  const identityApi = useApi(identityApiRef);

  const getCredentials = useAsync(async () => {
    const { token } = await identityApi.getCredentials();
    return token;
  }, []);

  const handleCopy = () => {
    setCopyToClipboard(getCredentials.value ?? '');
    setShowCopy(true);
    setTimeout(() => {
      setShowCopy(false);
    }, 5000);
  };

  return (
    <InfoCard>
      <Typography>Backstage Token for Runway API Authorization</Typography>
      <Typography variant="caption" display="block" gutterBottom>
        Use this if you want to call runway backend api directly for testing, or use it as the authorizationSso config
        yaml value.
      </Typography>
      {getCredentials.value && (
        <>
          <Button className={classes.button} variant="contained" color="primary" onClick={handleCopy}>
            {showCopied ? 'Copied!' : 'Copy'}
          </Button>
        </>
      )}
      {getCredentials.error && <div>Something went wrong with getting your token...</div>}
    </InfoCard>
  );
};
