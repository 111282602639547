import { Card, CardContent, CardHeader, Divider, makeStyles } from '@material-ui/core';
import React from 'react';
import { AaAboutContent } from './ApiAboutContent';
import { useEntity } from '@backstage/plugin-catalog-react';

const useStyles = makeStyles({
  gridItemCard: {
    display: 'flex',
    flexDirection: 'column',
    height: 'calc(100% - 10px)', // for pages without content header
    marginBottom: '10px',
  },
  gridItemCardContent: {
    flex: 1,
  },
  cardHeader: {
    marginBottom: '20px',
  },
});

type AboutCardProps = {
  variant?: string;
};

export function AaApiAboutCard({ variant }: AboutCardProps) {
  const classes = useStyles();
  const { entity } = useEntity();

  return (
    <Card className={variant === 'gridItem' ? classes.gridItemCard : ''}>
      <CardHeader title="Runway Information" className={classes.cardHeader} />
      <Divider />
      <CardContent className={variant === 'gridItem' ? classes.gridItemCardContent : ''}>
        <AaAboutContent entity={entity} />
      </CardContent>
    </Card>
  );
}
