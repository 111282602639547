import axios from 'axios';

// Does the API call to ADO to get project list
// Returns a sorted list of project names
export const getProjectList = async (backendUrl: string, token: string): Promise<any> => {
  const result = await axios.get(`${backendUrl}/api/ado-access`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  const response = result.data.value;
  response.sort((a: any, b: any) => a.name.localeCompare(b.name)); // used for sorting
  return response;
};

// Does the API call to ADO to get the list of members
// Returns a list of members that are admins
export const findProjectMembers = async (backendUrl: string, projectId: string, token: string): Promise<any> => {
  const result = await axios.get(`${backendUrl}/api/ado-access/${projectId}/projectAdmins`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return result.data;
};

// CALLER: ADOLicenseContent.tsx::handleSubmit (Browser).
// DESCR: Called by Browser ADOLicenseContent.tsx::handleSubmit to post to backend router.ts::updateLicense to update
//  license and log change.
// RETURN: A response of the user's license details.
export const updateUserLicense = async (
  backendUrl: string,
  userId: string,
  requesterID: string,
  licenseType: string,
  token: string,
): Promise<any> => {
  const result = await axios.post(
    `${backendUrl}/api/ado-access/updateUserLicense`,
    {
      userId: `${userId}@corpaa.aa.com`,
      licenseType: licenseType,
      requesterID: requesterID,
    },
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    },
  );
  return result.data;
};
