import axios from 'axios';
import { createApiRef } from '@backstage/core-plugin-api';
import { OAuth2AAPingSSOApi } from '@runway/devkit';

export type ResetPasswordServiceResponse = {
  msg: string;
  email: string;
  job: string;
  product: string;
};

export interface ResetPasswordServiceApi {
  resetPassword(target: string, userid_on_target: string): Promise<void>;
}

export const resetPasswordServiceApiRef = createApiRef<ResetPasswordServiceApi>({
  id: 'plugin.infrastructure.reset-password.service',
});

export default class ResetPasswordService implements ResetPasswordServiceApi {
  constructor(
    private oAuthApi: OAuth2AAPingSSOApi,
    private backendUrl: string,
  ) {}

  resetPassword = async (target: string, userid_on_target: string) => {
    const token = await this.oAuthApi.getAccessToken();

    await axios.patch(
      `${this.backendUrl}/manage/vm/password`,
      {
        targetMachineName: target,
        targetMachineUsername: userid_on_target,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      },
    );
  };
}
