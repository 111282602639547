import React, { ChangeEvent } from 'react';
import { Dialog, DialogContent, DialogTitle, DialogActions, Button, LinearProgress, Box, Tab } from '@material-ui/core';
import { TabContext, TabList, TabPanel } from '@material-ui/lab';
import { RequestForm } from '../ActiveDirectoryGroups/RequestForm';
import { SimpleRequestForm } from '../ActiveDirectoryGroups/SimpleRequestForm';

interface ActiveDirectoryGroupsDialogProps {
  isOpen: boolean;
  close(): void;
  onSuccess: (req: { activeDirectoryGroupName: string }) => void;
}

export const ActiveDirectoryGroupsDialog = ({ isOpen, close, onSuccess }: ActiveDirectoryGroupsDialogProps) => {
  const [value, setValue] = React.useState('1');
  const [loading, setLoading] = React.useState(false);
  const handleChange = (_: ChangeEvent<Record<string, never>>, newValue: string) => {
    setValue(newValue);
  };

  React.useEffect(() => {
    if (!isOpen) {
      setLoading(false);
    }
  }, [isOpen]);

  const handleClose =
    (cancelled = true) =>
    async () => {
      if (cancelled) {
        close();
        return;
      }
      setLoading(false);
    };

  return (
    <Dialog
      open={isOpen}
      fullWidth
      keepMounted
      onClose={handleClose()}
      aria-labelledby="alert-dialog-slide-title"
      aria-describedby="alert-dialog-slide-description"
    >
      <DialogTitle>Create Azure Active Directory Group</DialogTitle>
      <DialogContent>
        <Box sx={{ width: '100%' }}>
          <TabContext value={value}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
              <TabList onChange={handleChange}>
                <Tab label="Guided" value="1" />
                <Tab label="Simple" value="2" />
              </TabList>
            </Box>
            <TabPanel value="1">
              <RequestForm onSuccess={onSuccess} />
            </TabPanel>
            <TabPanel value="2">
              <SimpleRequestForm onSuccess={onSuccess} />
            </TabPanel>
          </TabContext>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button color="primary" onClick={handleClose()}>
          Close
        </Button>
      </DialogActions>
      <LinearProgress hidden={!loading} />
    </Dialog>
  );
};
