import React, { useState } from 'react';
import { Grid, Button, Popover, IconButton } from '@material-ui/core';
import { Entity } from '@backstage/catalog-model';
import { DeleteRepoAndCatalogDialog } from '../DeleteRepoAndCatalogDialog/DeleteRepoAndCatalogDialog';
import { Alert } from '@material-ui/lab';
import InfoIcon from '@material-ui/icons/Info';
import { Link } from 'react-router-dom';

type DeleteRepoAndCatalogProps = {
  entity: Entity;
  isGithubRepoAdmin: boolean;
};

export const DeleteRepoAndCatalog = ({ entity, isGithubRepoAdmin }: DeleteRepoAndCatalogProps) => {
  const [deleteAppDialogOpen, setDeleteAppDialogOpen] = useState(false);
  const onClick = () => {
    setDeleteAppDialogOpen(true);
  };
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const handleClose = () => setAnchorEl(null);
  const handlePopoverClick = (event: React.MouseEvent<HTMLButtonElement>) => setAnchorEl(event.currentTarget);

  return (
    <Grid container>
      <Grid item>
        <Button
          disabled={!isGithubRepoAdmin}
          variant="contained"
          color="secondary"
          onClick={onClick}
          aria-label="delete-repo-and-catalog-button"
        >
          Delete Repo and Catalog Entity
        </Button>
        {!isGithubRepoAdmin && (
          <>
            <Popover
              anchorEl={anchorEl}
              onClose={handleClose}
              open={!!anchorEl}
              anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
              PaperProps={{ style: { width: '100%' } }}
              style={{ width: '25%' }}
            >
              <Alert aria-label="admin-only" severity="info">
                You must be an admin of the GitHub repository <strong>{entity.metadata.name}</strong> to manage
                deployments or delete repo and catalog entity. Follow{' '}
                <Link to={`/access/ghec-admin/${entity.metadata.name}`} style={{ textDecoration: 'underline' }}>
                  <strong>this</strong>
                </Link>{' '}
                link to view the admins in the repo.
              </Alert>
            </Popover>
            <IconButton onClick={handlePopoverClick} color="primary" size="small" aria-label="info-button">
              <InfoIcon />
            </IconButton>
          </>
        )}

        <DeleteRepoAndCatalogDialog
          isOpen={deleteAppDialogOpen}
          close={() => setDeleteAppDialogOpen(false)}
          entity={entity}
        />
      </Grid>
    </Grid>
  );
};
