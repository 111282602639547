import axios from 'axios';
import { createApiRef, OAuthApi } from '@backstage/core-plugin-api';
import { ClientModel, ClientFormModel, ClientCreateRespModel, PFUser } from '../components/PFAdmin/utils/TypesUtil';
import { PluginId } from '@runway/devkit-common';

export interface PFAdminServiceApi {
  createClient: (profile: any, clientFormData: ClientFormModel) => Promise<ClientCreateRespModel>;
  getClients: (appShortName: string, archerID: string, env: string) => Promise<ClientModel[]>;
  getLoggedUser: () => Promise<PFUser>;
}

export const pfAdminServiceApiRef = createApiRef<PFAdminServiceApi>({ id: PluginId.PFADMIN_REQUEST.id });

export default class PFAdminService implements PFAdminServiceApi {
  constructor(
    public appConfig: any,
    private oAuthApi: OAuthApi,
  ) {}

  async createClient(profile: any, clientFormData: ClientFormModel): Promise<ClientCreateRespModel> {
    const token = await this.oAuthApi.getAccessToken();
    const formData = Object.assign({}, clientFormData);
    const URL_REGEX =
      '^((((https?|ftps?|gopher|telnet|nntp)://)|(mailto:|news:))' +
      "(%[0-9A-Fa-f]{2}|[-()_.!~*';/?:@&=+$,A-Za-z0-9])+)" +
      "([).!';/?:,][[:blank:]])?$";

    formData.requesterId = profile!.aaId!;
    formData.requesterName = profile!.displayName!;
    formData.redirectUris = clientFormData.redirectUrisStr?.trim().split('\n');
    formData.redirectUris?.forEach((uri, index) => {
      if (!uri.match(URL_REGEX)) throw new Error(`Invalid URL at line: ${index + 1}`);
    });

    try {
      const res = await axios.post(`${this.appConfig[formData.env].baseUrl}/v1/oauth/clients`, formData, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      return res;
    } catch (err: any) {
      throw err;
    }
  }

  async getClients(appShortName: string, archerID: string, env: string): Promise<ClientModel[]> {
    const token = await this.oAuthApi.getAccessToken();
    const filter = `${appShortName}-${archerID}`;
    try {
      const res = await axios.get(`${this.appConfig[env].baseUrl}/v1/oauth/clients?filter=${filter}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      return res.data.items;
    } catch (err: any) {
      throw err;
    }
  }

  async getLoggedUser(): Promise<PFUser> {
    const token = await this.oAuthApi.getAccessToken();

    try {
      const res = await axios.get(`${this.appConfig.prod.baseUrl}/v1/oauth/loggeduser`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      return res.data;
    } catch (err: any) {
      throw err;
    }
  }
}
