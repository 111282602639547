import React from 'react';
import { MenuCard } from '@runway/devkit';
import { useNavigate } from 'react-router-dom';

export const AMLComputeCluster: React.FC = () => {
  const navigate = useNavigate();

  return (
    <MenuCard
      title="Azure Machine Learning Compute Cluster"
      subtitle="Create a compute cluster in Azure Machine Learning"
      onButtonClick={() => navigate('/dataanalytics/aml-cc')}
      buttonText="Create"
    />
  );
};
