import React, { useEffect, useState } from 'react';
import { pick } from 'lodash';
import { FieldExtensionComponentProps } from '@backstage/plugin-scaffolder-react';
import { OnChangeValueServerNaming, ServerNamingDropdown } from '../../components';

export const ServerNamingField = ({
  onChange,
  required,
  formData,
}: FieldExtensionComponentProps<OnChangeValueServerNaming>) => {
  const [returnedData, setReturnedData] = useState<OnChangeValueServerNaming>();
  const handleChange = (value: OnChangeValueServerNaming) => {
    const out = pick(value, [
      'departmentName',
      'locationName',
      'functionName',
      'environmentName',
      'secuenceNumber',
      'serverName',
    ]);

    setReturnedData(out);
  };

  useEffect(() => {
    onChange(returnedData);
  }, [returnedData, onChange]);

  return (
    <ServerNamingDropdown
      onChange={(value: OnChangeValueServerNaming) => {
        handleChange(value);
      }}
      required={required}
      initialValue={formData ?? (formData as unknown as OnChangeValueServerNaming)}
    />
  );
};
