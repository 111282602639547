import axios from 'axios';
import { createApiRef } from '@backstage/core-plugin-api';
import { OAuth2AAPingSSOApi } from '@runway/devkit';

export class GhecAdminError extends Error {
  constructor(
    message: string,
    public details?: string,
  ) {
    super(message);
    Object.setPrototypeOf(this, GhecAdminError.prototype);
  }
}

export interface GhecRepoAdminsFormApi {
  fetchRepoAdmins: (repoName: string) => Promise<{ administrators: AdminList[]; isListHaveAllAdmins: boolean }>;
}

export const ghecRepoAdminsFormApiRef = createApiRef<GhecRepoAdminsFormApi>({
  id: 'plugin.access-control.ghecadmins.service',
});

export interface AdminList {
  full_name: string;
  github_id: string;
  email_id: string;
  slack_uid: string;
}

export default class GhecRepoAdmins implements GhecRepoAdminsFormApi {
  constructor(
    private ghecRepoAdminsServiceUrl: string,
    private aaSso: OAuth2AAPingSSOApi,
  ) {}

  fetchRepoAdmins = async (repoName: string) => {
    try {
      const token = await this.aaSso.getAccessToken();
      const result = await axios.get(`${this.ghecRepoAdminsServiceUrl}/getrepoadmins/${repoName}`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      return result.data;
    } catch (error: any) {
      throw new GhecAdminError(error.response?.data?.message || error.message, error.response?.data?.detail);
    }
  };
}
