import React, { useState } from 'react';
import { TextField } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import { FieldExtensionComponentProps } from '@backstage/plugin-scaffolder-react';
import vulnerabilitiesData from './vulnerabilities.json';

export const CoverityIssueTypePicker = ({ onChange, required, formData }: FieldExtensionComponentProps<string>) => {
  const [selectedVulnerability, setSelectedVulnerability] = useState<string | null>(formData ?? null);

  const handleOnChange = (_: any, newValue: string | null) => {
    setSelectedVulnerability(newValue);
    if (newValue !== null) {
      onChange(newValue);
    } else {
      onChange(undefined);
    }
  };

  return (
    <div style={{ display: 'flex', alignItems: 'center', width: '100%' }}>
      <Autocomplete
        style={{ width: '100%' }}
        options={vulnerabilitiesData.sort()}
        getOptionLabel={option => option}
        value={selectedVulnerability}
        onChange={handleOnChange}
        renderInput={params => (
          <TextField
            {...params}
            label="Vulnerability Type"
            variant="outlined"
            required={required}
            fullWidth
            style={{ width: '100%' }}
          />
        )}
      />
    </div>
  );
};
