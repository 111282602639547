import React from 'react';
import { useAsync } from 'react-use';
import { CircularProgress, FormHelperText, TextField } from '@material-ui/core';
import ErrorIcon from '@material-ui/icons/Error';
import { Autocomplete } from '@material-ui/lab';
import { AzureSubscriptionRespItem, Squad360ServiceApiRef } from '../../services/squad360.service';
import { useApi } from '@backstage/core-plugin-api';
import { AzureADGroupMembership, adServiceApiRef } from '../../services/ad.service';

type OnChangeValue = {
  name: string;
  subscriptionId: string;
};

interface AzureSubscriptionProps {
  onChange(value: OnChangeValue): void;
  onBlur?(): void;
  shortName?: string;
  employeeId?: string;
  regexFilter?: string | RegExp;
  disabled?: boolean;
  disableUnavailableOptions?: boolean;
  width?: number;
}

export const AzureSubscription = ({
  onChange,
  onBlur,
  shortName = '',
  employeeId,
  regexFilter = '.*',
  disabled = false,
  disableUnavailableOptions = true,
  width = 300,
}: AzureSubscriptionProps) => {
  const squad360 = useApi(Squad360ServiceApiRef);
  const adService = useApi(adServiceApiRef);

  const badge_number = employeeId ? employeeId : '';
  const sName = shortName ? shortName : '';
  // the default regex should accept any string (no filtering)
  const regex = regexFilter ? regexFilter : '.*';
  const azureOptions = useAsync(async () => {
    // Starting with the the complete list of subscriptions
    const options: AzureSubscriptionRespItem[] = await squad360.getAzureSubscriptions(sName, badge_number);
    let filteredOptions;
    try {
      // try to filter based on the passed in regex
      filteredOptions = options.filter(sub => sub.name.match(regex));
    } catch {
      // if an error is encountered (a malformed regex is used),
      //  it returns all subs (no filtering happens)
      filteredOptions = options;
    }
    if (!disableUnavailableOptions) return { options: filteredOptions, userSubscriptions: [] };

    const aadGroupMembership: AzureADGroupMembership = await adService.getUsersGroupMembershipAzureAD();
    const userSubscriptions: AzureSubscriptionRespItem[] = [];
    const otherSubscriptions: AzureSubscriptionRespItem[] = [];
    filteredOptions.forEach(sub => {
      if (aadGroupMembership.outputGroups.some(group => group.groupName === sub.name)) {
        userSubscriptions.push(sub);
      } else {
        otherSubscriptions.push(sub);
      }
    });
    const orderedOptions = [...userSubscriptions, ...otherSubscriptions];
    return { options: orderedOptions, userSubscriptions: userSubscriptions };
  }, [squad360, adService]);

  return (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <Autocomplete
        disabled={azureOptions.error !== undefined || disabled}
        id="AzureSubscription"
        data-testid="AzureSubscription"
        disableClearable
        getOptionLabel={option => `${option.name} (${option.subscriptionId})`}
        options={azureOptions.value?.options ?? []}
        getOptionDisabled={option =>
          disableUnavailableOptions && !azureOptions.value?.userSubscriptions.includes(option)
        }
        onChange={(_: any, application: any) => {
          onChange({
            name: application.name,
            subscriptionId: application.subscriptionId,
          });
        }}
        onBlur={onBlur}
        style={{ width, display: 'inline-flex' }}
        renderInput={params => <TextField required {...params} label="Azure Subscription" variant="outlined" />}
      />

      {!!azureOptions.loading && <CircularProgress style={{ marginLeft: 10 }} />}
      {!!azureOptions.error && (
        <>
          <ErrorIcon style={{ marginLeft: 10, marginRight: 10, color: 'red' }} />
          <FormHelperText>There was an issue getting the Subscriptions </FormHelperText>
        </>
      )}
    </div>
  );
};
