import React from 'react';
import { makeStyles, Typography, Grid, Link } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  value: {
    fontWeight: 'bold',
    overflow: 'hidden',
    lineHeight: '24px',
    wordBreak: 'break-word',
  },
  link: {
    display: 'flex',
    gridAutoFlow: 'column',
    gridAutoColumns: 'min-content',
    gridGap: theme.spacing(3),
  },
  label: {
    color: theme.palette.text.secondary,
    textTransform: 'uppercase',
    fontSize: '10px',
    fontWeight: 'bold',
    letterSpacing: 0,
    overflow: 'hidden',
    whiteSpace: 'nowrap',
  },
}));

interface URLs {
  label: string;
  url: string;
}

type Props = {
  label: string;
  value?: string;
  gridSizes?: Record<string, number>;
  children?: React.ReactNode;
  url?: string;
  urls?: URLs[];
};

export const AboutField = ({ label, value, gridSizes, children, url, urls }: Props) => {
  const classes = useStyles();

  // Content is either children or a string prop `value`
  const content = React.Children.count(children) ? (
    children
  ) : (
    <Typography variant="body2" className={classes.value} color={value ? 'initial' : 'secondary'}>
      {value || `${label} Missing!`}
    </Typography>
  );

  if (url !== undefined)
    return (
      <Grid item {...gridSizes}>
        <Typography variant="subtitle2" className={classes.label}>
          {label}
        </Typography>
        <Link className={classes.link} href={url}>
          {content}
        </Link>
      </Grid>
    );

  if (urls !== undefined) {
    const urlsLength = urls.length;
    switch (urlsLength) {
      case 0: {
        return (
          <Grid item {...gridSizes}>
            <Typography variant="subtitle2" className={classes.label}>
              {label}
            </Typography>
            <Typography variant="body2" className={classes.value} color="secondary">
              {label} Missing!
            </Typography>
          </Grid>
        );
      }
      default: {
        return (
          <Grid item {...gridSizes}>
            <Typography variant="subtitle2" className={classes.label}>
              {label}
            </Typography>
            {urls.map((data, index) => (
              <>
                {data.url !== '' ? (
                  <Link className={classes.link} href={data.url}>
                    {data.label + (index < urlsLength - 1 ? ', ' : '')}
                  </Link>
                ) : (
                  data.label + (index < urlsLength - 1 ? ', ' : '')
                )}
              </>
            ))}
          </Grid>
        );
      }
    }
  }

  return (
    <Grid item {...gridSizes}>
      <Typography variant="subtitle2" className={classes.label}>
        {label}
      </Typography>
      {content}
    </Grid>
  );
};
