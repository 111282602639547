import { createApiRef, IdentityApi } from '@backstage/core-plugin-api';
import { CatalogCustomizationsRecordStoreServiceApi } from '@runway/devkit';
import axios from 'axios';
import {
  ArgoCDServiceApi,
  createArgoAppReq,
  ArgoNameAndCluster,
  ArgoInstanceData,
  UpdateArgoReq,
  DeleteApplicationAndProjectResponse,
} from './argocd.catalog.types';

export const ArgoCDServiceApiRef = createApiRef<ArgoCDServiceApi>({
  id: 'plugin.plugin-argocd-backend.service',
});

export class ArgoCDService implements ArgoCDServiceApi {
  constructor(
    private backendUrl: string,
    private identityApi: IdentityApi,
    private catalogCustomizationRecordStoreServiceApi: CatalogCustomizationsRecordStoreServiceApi,
  ) {}

  async createArgoApp(req: createArgoAppReq) {
    const { token } = await this.identityApi.getCredentials();
    await axios.post(`${this.backendUrl}/api/argocd/createArgo`, req, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  }

  async syncArgoApp(appSelector: string) {
    const { token } = await this.identityApi.getCredentials();
    const ArgoAppResp = await axios.post(
      `${this.backendUrl}/api/argocd/sync`,
      {
        appSelector: appSelector,
        terminateOperation: true,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    );
    return ArgoAppResp.data;
  }

  private buildErrorString = (detail: undefined | { message: string; group_names: string[]; group_ids: string[] }) => {
    const defaultMessage = 'You need proper AAD Group access to proceed.';
    if (!detail) return defaultMessage;
    if (detail.group_names?.length)
      return `You need membership in one of the following AAD Groups: ${detail.group_names.join(', ')}`;
    if (detail.group_ids?.length)
      return `You need membership in one of the following AAD Groups: ${detail.group_ids.join(', ')}`;
    return defaultMessage;
  };

  deleteArgoCd = async (argoItemsToDelete: { clusterName: string; appName: string }) => {
    try {
      const { token } = await this.identityApi.getCredentials();
      const argoInstanceName = argoItemsToDelete.clusterName;
      const argoAppName = argoItemsToDelete.appName;
      const response = await axios.delete<DeleteApplicationAndProjectResponse>(
        `${this.backendUrl}/api/argocd/argoInstance/${argoInstanceName}/applications/${argoAppName}?terminateOperation=true`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      );
      const { deleteAppDetails, deleteProjectDetails, terminateOperationDetails } = response.data;

      if (deleteAppDetails?.status !== 'failed' && deleteProjectDetails?.status === 'failed') {
        await this.catalogCustomizationRecordStoreServiceApi.createArgoCDDeleteRequest({
          project_name: argoAppName,
          cluster_name: argoInstanceName,
          attempts: 1,
          delete_response: JSON.stringify(response.data),
        });
      }
      return { terminateOperationDetails, deleteAppDetails, deleteProjectDetails, argoAppName, argoInstanceName };
    } catch (e: any) {
      let errorStr = 'Error Deleting ArgoCD Application';
      if (axios.isAxiosError(e) && e.response?.status === 403) {
        errorStr += ': Insufficient Permissions. ';
        errorStr += this.buildErrorString(e.response?.data?.detail);
      } else if (e?.response?.data?.error?.message || e?.response?.data?.status)
        errorStr += `: ${e?.response?.data?.error?.message || e?.response?.data?.status}`;
      throw new Error(errorStr);
    }
  };

  getArgoNameAndCluster = async (argoSelector: string): Promise<ArgoNameAndCluster[]> => {
    if (!argoSelector) {
      throw new Error('Annotation missing (argocd/app-name)');
    }
    try {
      const { token } = await this.identityApi.getCredentials();
      const res = await axios.get(`${this.backendUrl}/api/argocd/find/selector/${argoSelector}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      return res?.data;
    } catch (e) {
      throw new Error('Error Getting ArgoCD Name and Cluster');
    }
  };

  getArgoInstanceData = async (
    clusterName: string,
    argoSelector: string,
    appName: string,
  ): Promise<ArgoInstanceData> => {
    const { token } = await this.identityApi.getCredentials();
    const res = await axios.get(
      `${this.backendUrl}/api/argocd/argoInstance/${clusterName}/applications/selector/${argoSelector}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    );
    if (!res.data.items?.length) {
      throw new Error('Unexpected Response Body Getting Argo Instance Data');
    }
    const instanceData = res.data.items.find(
      (appInstance: { metadata: { name: string } }) => appInstance.metadata.name === appName,
    );
    if (!instanceData)
      throw new Error(
        `Argo app ${appName} and corresponding deployment information not found. Please try again. If the problem persists, reach out to #Runway on Slack`,
      );
    return instanceData;
  };

  updateArgoApp = async (req: UpdateArgoReq) => {
    const { token } = await this.identityApi.getCredentials();

    await axios.put(`${this.backendUrl}/api/argocd/updateArgo/${req.appName}`, req, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  };
}
