import { FieldExtensionComponentProps } from '@backstage/plugin-scaffolder-react';
import { TextField } from '@material-ui/core';
import { FieldValidation } from '@rjsf/utils';
import React from 'react';

export const DescriptionField = ({ onChange, rawErrors, required, formData }: FieldExtensionComponentProps<string>) => {
  return (
    <TextField
      id="description"
      label="Description"
      helperText="Description"
      style={{ width: '100%' }}
      required={required}
      value={formData ?? ''}
      onChange={({ target: { value } }) => {
        onChange(value);
      }}
      onBlur={({ target: { value } }) => {
        if (value) onChange(value);
      }}
      error={rawErrors?.length > 0 && !formData}
    />
  );
};

export const descriptionFieldValidation = async (value: string, validation: FieldValidation) => {
  if (value.includes('"') || value.includes("'")) {
    validation.addError('Apostrophe and Single/Double quotes are not allowed');
  }
};
