import { Grid, FormControl } from '@material-ui/core';
import React, { useContext } from 'react';
import { Controller } from 'react-hook-form';
import FormContext from '../FormContext';
import { ObjectLike, FieldName } from '../types';
import { GithubTeamSelect } from '../../../components/GithubTeamSelect';
import { componentGridStyle } from '../styles';

/**
 * Use this component to capture Github Team Selection Dropdown
 * @param name your schema field name
 * @param size optional size of the component
 */
export function FormGithubTeamSelect<T extends ObjectLike>({ name, size = 4 }: FieldName<T>) {
  const { ctx } = useContext(FormContext);
  return (
    <Grid item xs={12} sm={12} md={size} style={componentGridStyle}>
      <FormControl aria-label="form-github-team-select" variant="outlined" fullWidth required>
        <Controller
          name={name}
          control={ctx.control}
          render={({ field: { onChange } }) => <GithubTeamSelect onChange={value => onChange(value)} formData="" />}
        />
      </FormControl>
    </Grid>
  );
}
