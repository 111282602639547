import { GithubIssueApi } from './GithubIssueApi';
import { graphql } from '@octokit/graphql';
import { IssuesListRespData } from '../types';

export const GET_REPO_ISSUES_QUERY = `
query repoissues($owner: String!, $name: String!, $first: Int, $last: Int, $after: String, $before: String, $state: [IssueState!]){
  repository(owner: $owner, name: $name) {
    issues(states: $state, first: $first, last: $last, after: $after, before: $before) {
      pageInfo {
        endCursor
        startCursor
      }
      nodes {
        number
        state
        title
        updatedAt
        createdAt
        closedAt
        url
        id
        author {
          login
          url
        }
      }
      totalCount
    }
  }
}
`;

export class GithubIssueClient implements GithubIssueApi {
  async listIssues({
    token,
    owner,
    repo,
    first,
    last,
    after,
    before,
    state,
    baseUrl,
  }: {
    token: string;
    owner: string;
    repo: string;
    first: number | null;
    last: number | null;
    after: string | null;
    before: string | null;
    state?: any;
    baseUrl: string | undefined;
  }): Promise<{
    maxTotalItems: number;
    startCursor: string;
    endCursor: string;
    issuesData: IssuesListRespData;
  }> {
    const issueResponse: any = await graphql(GET_REPO_ISSUES_QUERY, {
      owner,
      name: repo,
      first,
      last,
      after,
      before,
      state,
      baseUrl,
      headers: {
        authorization: `token ${token}`,
      },
    });

    const maxTotalItems = issueResponse.repository.issues.totalCount;
    const startCursor = issueResponse.repository.issues.pageInfo.startCursor;
    const endCursor = issueResponse.repository.issues.pageInfo.endCursor;

    return {
      maxTotalItems,
      startCursor,
      endCursor,
      issuesData: issueResponse.repository.issues.nodes as IssuesListRespData,
    };
  }
}
