import * as React from 'react';
import { Grid } from '@material-ui/core';
import { Header, Page, Content, HeaderLabel } from '@backstage/core-components';
import { RunwaySupportInformation } from '@runway/devkit';
import { SwaggerEditorComponent } from '../SwaggerEditorComponent';
import { SwaggerValidateComponent } from '../SwaggerValidateComponent';
import Survey from '../Survey';

export const SwaggerComponent = () => (
  <Page themeId="tool">
    <Header title="Welcome to Swagger Editor!" subtitle="Design, Describe, Document, and Validate your API">
      <HeaderLabel label="Owner" value="Data Movement" />
      <HeaderLabel label="Lifecycle" value="Alpha" />
    </Header>
    <Content>
      <RunwaySupportInformation />
      <Survey />
      <Grid item>
        <SwaggerValidateComponent />
      </Grid>
      <Grid container spacing={3} direction="column">
        <Grid item xs={12}>
          <SwaggerEditorComponent />
        </Grid>
      </Grid>
    </Content>
  </Page>
);
