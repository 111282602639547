import axios from 'axios';
import { createApiRef, IdentityApi } from '@backstage/core-plugin-api';

export type ProxySetting = {
  proxy: string;
};

export interface SwaggerServiceApi {
  validateSpec(content: any): Promise<any>;
  downloadSpec(url: string): Promise<any>;
}

export const SwaggerServiceApiRef = createApiRef<SwaggerServiceApi>({
  id: 'plugin.swagger.service',
});

export class SwaggerService implements SwaggerServiceApi {
  constructor(
    private backendUrl: string,
    private identityApi: IdentityApi,
  ) {}

  validateSpec = async (content: any) => {
    const { token } = await this.identityApi.getCredentials();
    const res = await axios.post(`${this.backendUrl}/api/swagger/validate`, content, {
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/yaml',
      },
    });

    if (res.status !== 200) {
      throw new Error();
    }

    return res.data;
  };

  downloadSpec = async (url: string) => {
    const { token } = await this.identityApi.getCredentials();
    const res = await axios.get(`${this.backendUrl}/api/swagger/import`, {
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/yaml',
      },
      params: {
        url: `${url}`,
      },
    });

    if (res.status !== 200) {
      throw new Error();
    }

    return res.data;
  };
}
