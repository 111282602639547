import React, { FC, useState } from 'react';
import { Typography, Box, ButtonGroup, Button } from '@material-ui/core';
import GitHubIcon from '@material-ui/icons/GitHub';
import { Table, TableColumn } from '@backstage/core-components';
import { useProjectName } from '../useProjectName';
import { useIssues, Issue } from '../useIssue';
import { Entity } from '@backstage/catalog-model';
import { getStatusIconType } from '../Icons';

const generatedColumns: TableColumn<Issue>[] = [
  {
    title: 'ID',
    field: 'number',
    highlight: true,
    width: '150px',
    render: (row: Issue) => (
      <Box fontWeight="fontWeightBold">
        <a target="_blank" rel="noopener noreferrer" href={row.url!}>
          #{row.number}
        </a>
      </Box>
    ),
  },
  {
    title: 'Title',
    field: 'title',
    highlight: true,
    render: (row: Issue) => (
      <Typography variant="body2" noWrap>
        {getStatusIconType(row as Issue)}{' '}
        <Box ml={1} component="span">
          {row.title}
        </Box>
      </Typography>
    ),
  },
  {
    title: 'Creator',
    field: 'creatorNickname',
    highlight: true,
    width: '250px',
    render: (row: Issue) => (
      <Box fontWeight="fontWeightBold">
        <a target="_blank" rel="noopener noreferrer" href={row.creatorProfileLink!}>
          {row.creatorNickname}
        </a>
      </Box>
    ),
  },
  {
    title: 'Created',
    field: 'createdTime',
    highlight: true,
    render: (row: Issue) => (
      <Typography variant="body2" noWrap>
        {row.createdTime}
      </Typography>
    ),
  },
  {
    title: 'Last updated',
    field: 'updatedTime',
    highlight: true,
    render: (row: Issue) => (
      <Typography variant="body2" noWrap>
        {row.updatedTime}
      </Typography>
    ),
  },
];

type Props = {
  loading: boolean;
  retry: () => void;
  projectName: string;
  page: number;
  issueData?: Issue[];
  onPageChange: (page: number) => void;
  total: number;
  pageSize: number;
  onRowsPerPageChange: (pageSize: number) => void;
  StateFilterComponent: FC;
};

export const IssuesTableView: FC<Props> = ({
  projectName,
  loading,
  pageSize,
  page,
  issueData,
  onPageChange,
  onRowsPerPageChange,
  total,
  StateFilterComponent,
}) => {
  return (
    <Table
      isLoading={loading}
      options={{ paging: true, pageSize, padding: 'dense' }}
      totalCount={total}
      page={page}
      actions={[]}
      data={issueData ?? []}
      onPageChange={onPageChange}
      onRowsPerPageChange={onRowsPerPageChange}
      title={
        <>
          <Box display="flex" alignItems="center">
            <GitHubIcon />
            <Box mr={1} />
            <Typography variant="h6">{projectName}</Typography>
          </Box>
          <StateFilterComponent />
        </>
      }
      columns={generatedColumns}
    />
  );
};

export const IssuesTable = ({ entity }: { entity: Entity; branch?: string }) => {
  const [IssueStatusFilter, setIssueStatusFilter] = useState<any>('OPEN');
  const projectName = useProjectName(entity);
  const [owner, repo] = projectName.split('/');

  const [tableProps, { retry, setPage, setPageSize }] = useIssues({
    state: IssueStatusFilter,
    owner,
    repo,
  });

  const StateFilterComponent = () => (
    <Box position="absolute" right={300} top={20}>
      <ButtonGroup color="primary" aria-label="text primary button group">
        <Button
          color={IssueStatusFilter === 'OPEN' ? 'primary' : 'default'}
          onClick={() => {
            setIssueStatusFilter('OPEN');
          }}
        >
          OPEN
        </Button>
        <Button
          color={IssueStatusFilter === 'CLOSED' ? 'primary' : 'default'}
          onClick={() => {
            setIssueStatusFilter('CLOSED');
          }}
        >
          CLOSED
        </Button>
        <Button
          color={IssueStatusFilter === null ? 'primary' : 'default'}
          onClick={() => {
            setIssueStatusFilter(null);
          }}
        >
          ALL
        </Button>
      </ButtonGroup>
    </Box>
  );

  return (
    <IssuesTableView
      {...tableProps}
      StateFilterComponent={StateFilterComponent}
      loading={tableProps.loading}
      retry={retry}
      onRowsPerPageChange={setPageSize}
      onPageChange={setPage}
    />
  );
};
