import { STATUS } from '../../Status';

type DeploymentConditions = {
  reason: string;
  type: string;
  status: string;
};
interface DeploymentResource {
  metadata: { name: string };
  status: { conditions: DeploymentConditions[]; replicas: number; readyReplicas: number };
}

export interface DeploymentsMetadata {
  name?: string | undefined;
  status: STATUS;
  replicas?: number | undefined;
  readyReplicas?: number | undefined;
  error?: string | undefined;
}
export type Deployments = Record<string, DeploymentsMetadata[]>;

const combineDeploymentStatus = (deployment: any) => {
  if (!deployment.status.readyReplicas) return STATUS.unhealthy;
  if (deployment.status.readyReplicas === 0) return STATUS.unhealthy;
  if (deployment.status.readyReplicas === deployment.status.replicas) return STATUS.healthy;
  return STATUS.partiallyHealthy;
};

export const getDeploymentStatus = (item: any): DeploymentsMetadata[] => {
  const depObject = item.resources?.filter((resource: { type: string }) => {
    return resource.type === 'deployments';
  })[0];

  if (!depObject)
    return [{ name: undefined, status: STATUS.unretrievable, replicas: undefined, readyReplicas: undefined }];

  const deployments = depObject.resources as DeploymentResource[];
  if (!deployments || deployments.length === 0)
    return [{ name: undefined, status: STATUS.unretrievable, replicas: undefined, readyReplicas: undefined }];

  return deployments.map((deployment: DeploymentResource) => {
    const ResourceQuotaExceeded = deployment.status.conditions?.some(
      (condition: DeploymentConditions) => condition.reason === 'FailedCreate' && condition.type === 'ReplicaFailure',
    );
    if (deployment.status.readyReplicas === undefined) {
      if (ResourceQuotaExceeded) {
        return {
          name: deployment.metadata.name,
          status: combineDeploymentStatus(deployment),
          error: 'exceeded resource quota',
        };
      }
      return {
        name: deployment.metadata.name,
        status: combineDeploymentStatus(deployment),
        error: deployment.status.conditions?.filter(status => status.status === 'False')[0]?.reason,
      };
    }
    return {
      name: deployment.metadata.name,
      status: combineDeploymentStatus(deployment),
      replicas: deployment.status.replicas,
      readyReplicas: deployment.status.readyReplicas,
    };
  });
};
