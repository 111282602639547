import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Header, Page, Content, HeaderLabel } from '@backstage/core-components';
import { configApiRef, githubAuthApiRef, useApi } from '@backstage/core-plugin-api';
import { MockGithubAuthApi } from '../../services';
import { Button, Link, TextField } from '@material-ui/core';
import { Config } from '@backstage/config';
import { Octokit } from '@octokit/rest';
import { RunwaySupportInformation } from '../SupportInformation/SupportInformation';

const getGitHubLink = () => {
  return 'https://github.com/settings/tokens/new';
};

const getScopes = () => {
  return ['repo', 'workflow', 'user', 'delete_repo'];
};

const getGhApiUrl = (config: Config) => {
  return config.getString('github.ghec.apiUrl');
};

export const DevGitHubLogin = () => {
  const githubAuth = useApi(githubAuthApiRef);
  const config = useApi(configApiRef);
  const [token, setToken] = useState('');
  const navigate = useNavigate();

  const handleSubmit = async () => {
    const authApi = githubAuth;
    const octokit = new Octokit({
      auth: token,
      baseUrl: getGhApiUrl(config),
    });
    const user = await octokit.users.getAuthenticated();
    (authApi as MockGithubAuthApi).saveSession({
      providerInfo: {
        accessToken: token,
        scopes: new Set(getScopes()),
        expiresAt: new Date(),
      },
      profile: {},
      backstageIdentity: {
        identity: {
          ownershipEntityRefs: [`user:default/${user.data.login}`],
          type: 'user',
          userEntityRef: `user:default/${user.data.login}`,
        },
        token: 'test',
      },
    });
    // go back
    navigate(-1);
  };
  const handleChange: React.ChangeEventHandler<HTMLInputElement> = event => {
    setToken(event.target.value);
  };

  return (
    <Page themeId="home">
      <Header title="Set GitHub Token" subtitle="">
        <HeaderLabel label="Owner" value="Runway Metrics Team" />
        <HeaderLabel label="Lifecycle" value="Alpha" />
      </Header>
      <Content>
        <RunwaySupportInformation />
        Create a personal access token on
        <Link target="_blank" href={getGitHubLink()}>
          {' '}
          GitHub{' '}
        </Link>
        with the following scopes:
        <ul>
          {getScopes().map(scope => (
            <li key={scope}>{scope}</li>
          ))}
        </ul>
        <form
          onSubmit={e => {
            handleSubmit();
            e.preventDefault();
          }}
        >
          <TextField
            label="Personal Access Token"
            variant="outlined"
            type="password"
            value={token}
            onChange={handleChange}
          />
          <br />
          <br />
          <Button type="submit" variant="contained" color="primary">
            Set Token
          </Button>
        </form>
      </Content>
    </Page>
  );
};
