import { IdentityApi, createApiRef } from '@backstage/core-plugin-api';
import { RestClientApi, RestClientRequestConfig } from '@runway/devkit-common';

export type changeNumberRecord = {
  changeType: string;
  createdDate: string;
  ownedByTeam: string;
  riskLevel: string;
  ownedByTeamRecID: string;
  changeID: string;
  status: string;
  title: string;
  affectedServices: string;
  createdBy: string;
  proposedStartDate: string;
  proposedEndDate: string;
  actualEndDate: string;
  actualStartDate: string;
  finalDisposition: string;
  reasonForChange: string;
  impactAssesment: string;
  phase: string;
  description: string;
  proposedStartDateGMT: string;
  proposedEndDateGMT: string;
};

export type incidentNumberRecord = {
  incidentNumber: string;
  title: string;
  ticketSource: string;
  status: string;
  lastModified: string;
  createdDate: string;
  highestSev: string;
  category: string;
  ownedByTeam: string;
  ownedByTeamRecID: string;
  createdBy: string;
  outageduration: string;
  gscNotified: string;
};

export interface ItsmRecordFieldsServiceApi {
  validateChangeNum(changeId: number): Promise<validateChangeNumResp>;
  validateIncidentNum(incidentId: number): Promise<validateIncidentNumResp>;
}

export interface validateChangeNumResp {
  approved: boolean;
  message: string;
  change: changeNumberRecord[];
}
export interface validateIncidentNumResp {
  approved: boolean;
  message: string;
  incident: incidentNumberRecord[];
}

export const itsmRecordFieldsServiceApiRef = createApiRef<ItsmRecordFieldsServiceApi>({
  id: 'plugin.devkit.itsm-record-fields.service',
});

export default class ItsmRecordFieldsService implements ItsmRecordFieldsServiceApi {
  constructor(
    private backendUrl: string,
    private identityApi: IdentityApi,
    private restClient: RestClientApi,
  ) {}

  validateChangeNum = async (changeId: number): Promise<validateChangeNumResp> => {
    const { token } = await this.identityApi.getCredentials();
    const headers = {
      Authorization: `Bearer ${token}`,
    };

    const options: RestClientRequestConfig = {
      method: 'GET',
      url: `${this.backendUrl}/api/cloudElevatedAccess/validateChangeNum/${changeId}`,
      headers,
    };

    const res = await this.restClient.request<validateChangeNumResp>(options);

    if (res.status !== 200) {
      throw new Error('unable to validate change number');
    }

    return res.data;
  };

  validateIncidentNum = async (incidentId: number): Promise<validateIncidentNumResp> => {
    const { token } = await this.identityApi.getCredentials();
    const headers = {
      Authorization: `Bearer ${token}`,
    };

    const options: RestClientRequestConfig = {
      method: 'GET',
      url: `${this.backendUrl}/api/cloudElevatedAccess/validateIncidentNum/${incidentId}`,
      headers,
    };

    const res = await this.restClient.request<validateIncidentNumResp>(options);

    if (res.status !== 200) {
      throw new Error('unable to validate incident number');
    }

    return res.data;
  };
}
