import axios from 'axios';
import { IdentityApi, ProfileInfo, createApiRef } from '@backstage/core-plugin-api';

type ADOServiceResponse = {
  status: string;
  successEntries: any | undefined;
  errorEntries: any | undefined;
};

export interface ADOServiceApi {
  triggerPipelineRelease(
    jobJson: string,
    metastoreJson: string,
    jobName: string,
    application: string,
    servicePrincipalId: string,
    servicePrincipalSecret: string,
    defintionId: number,
    workspace: string,
  ): Promise<ADOServiceResponse>;
}

export const adoServiceApiRef = createApiRef<ADOServiceApi>({
  id: 'plugin.access-control.ado.service',
});

export default class ADOService implements ADOServiceApi {
  constructor(
    private backendUrl: string,
    private identityApi: IdentityApi,
  ) {}

  triggerPipelineRelease = async (
    jobJson: string,
    metastoreJson: string,
    jobName: string,
    application: string,
    servicePrincipalId: string,
    servicePrincipalSecret: string,
    definitionId: number,
    workspace: string,
  ) => {
    const { token } = await this.identityApi.getCredentials();
    const profile = (await this.identityApi.getProfileInfo()) as unknown as ProfileInfo & { aaId: string };

    if (profile === undefined) {
      throw new Error('You are not authorized to perform this request, please try signing into SSO again.');
    }

    let id = '0';
    if (!!profile.aaId) {
      id = profile.aaId;
    }

    const res = await axios.post(
      `${this.backendUrl}/api/adoService/createJob`,
      {
        jobJson: jobJson,
        metastoreJson: metastoreJson,
        jobName: jobName,
        application: application,
        user: `${parseInt(id, 10)}@corpaa.aa.com`,
        userEmail: profile.email,
        servicePrincipalId: servicePrincipalId,
        servicePrincipalSecret: servicePrincipalSecret,
        definitionId: definitionId,
        workspace: workspace,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    );

    return res.data;
  };
}
