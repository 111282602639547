import { SvgIconProps, SvgIcon } from '@material-ui/core';
import React from 'react';

export const RunwayIcon = (props: SvgIconProps) => (
  <SvgIcon
    {...props}
    viewBox="0 0 700 700"
    width="700.000000pt"
    height="700.000000pt"
    preserveAspectRatio="xMidYMid meet"
  >
    <g transform="translate(0.000000,700.000000) scale(0.100000,-0.100000)" stroke="none">
      <path
        d="M1526 6973 c-3 -16 -22 -129 -41 -253 -39 -253 -42 -271 -50 -320 -2
  -19 -21 -136 -40 -260 -20 -124 -37 -236 -40 -250 -18 -121 -27 -177 -28 -189
  -2 -12 110 -14 738 -15 407 -1 746 0 753 2 13 5 11 36 -8 147 -5 28 -12 68
  -15 90 -3 22 -12 83 -21 135 -18 118 -18 116 -44 280 -21 138 -24 155 -35 225
  -3 22 -17 110 -31 195 -13 85 -27 174 -31 198 l-6 42 -548 0 -547 0 -6 -27z"
      />
      <path
        d="M3064 6978 c5 -23 14 -76 21 -133 3 -16 7 -46 10 -65 3 -19 8 -53 11
  -75 3 -22 7 -47 9 -55 3 -8 14 -76 25 -150 11 -74 40 -261 64 -415 24 -154 47
  -306 51 -337 4 -32 10 -58 14 -59 3 -1 200 -2 436 -3 237 -1 448 -6 470 -10
  163 -31 310 -110 419 -224 96 -100 160 -216 195 -352 24 -94 23 -272 -3 -370
  -74 -280 -299 -494 -586 -556 -48 -10 -147 -14 -382 -14 -201 0 -318 -4 -318
  -10 0 -5 9 -67 21 -137 11 -71 22 -139 24 -153 2 -14 13 -81 24 -150 26 -161
  28 -176 46 -295 9 -55 18 -111 20 -125 2 -14 7 -41 10 -60 3 -19 27 -179 54
  -355 28 -176 52 -338 56 -360 3 -23 7 -52 10 -65 6 -29 14 -83 20 -135 3 -21
  7 -50 10 -64 4 -23 13 -82 30 -201 3 -19 10 -62 15 -95 17 -104 39 -248 45
  -295 3 -25 9 -70 15 -100 5 -30 12 -73 15 -95 3 -22 12 -80 20 -130 7 -49 16
  -106 19 -125 10 -67 47 -305 51 -330 2 -14 7 -43 10 -65 3 -22 10 -65 15 -95
  5 -30 12 -73 15 -95 3 -23 7 -50 9 -62 3 -12 8 -43 12 -70 3 -26 8 -57 10 -68
  4 -19 27 -165 38 -245 3 -19 7 -44 10 -55 2 -11 7 -44 11 -72 l7 -53 1209 0
  c665 0 1209 2 1209 5 0 3 -347 698 -771 1545 -425 847 -770 1542 -768 1544 2
  2 38 23 79 46 274 159 516 387 691 652 135 205 259 501 295 703 3 17 9 48 14
  70 5 22 12 66 15 98 4 32 8 68 11 80 6 33 6 292 -1 367 -64 710 -478 1326
  -1116 1662 -168 89 -368 156 -589 199 -110 22 -142 23 -738 27 l-623 3 5 -23z"
      />
      <path
        d="M1075 4107 c-4 -29 -16 -108 -27 -177 -11 -69 -21 -136 -23 -150 -3
  -28 -43 -291 -50 -330 -2 -14 -18 -117 -35 -230 -17 -113 -33 -216 -35 -230
  -3 -14 -8 -45 -11 -70 -3 -25 -8 -52 -9 -61 -3 -15 93 -17 1191 -17 1116 0
  1194 1 1194 17 0 9 -34 237 -75 507 -41 269 -78 505 -80 524 -5 31 -9 54 -21
  122 -1 9 -5 38 -9 65 -4 26 -9 56 -11 65 -5 16 -66 18 -999 17 l-993 0 -7 -52z"
      />
      <path
        d="M639 1292 c-5 -13 -9 -31 -9 -40 0 -9 -9 -71 -19 -137 -11 -66 -22
  -136 -25 -155 -3 -19 -14 -91 -25 -160 -11 -69 -22 -141 -25 -160 -3 -19 -25
  -165 -50 -323 -26 -158 -46 -295 -46 -302 0 -13 214 -15 1641 -15 l1640 0 -5
  32 c-3 18 -15 91 -26 163 -11 72 -23 146 -26 165 -3 19 -14 89 -24 155 -11 66
  -38 239 -60 385 -23 146 -43 276 -46 290 -2 14 -7 46 -10 73 -4 26 -9 47 -13
  48 -3 1 -649 2 -1435 3 l-1428 1 -9 -23z"
      />
    </g>
  </SvgIcon>
);
