import React, { useState } from 'react';
import {
  Grid,
  Radio,
  RadioGroup,
  FormControlLabel,
  Typography,
  IconButton,
  makeStyles,
  createStyles,
  withStyles,
  Theme,
  Tooltip,
} from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { useTheme as useMuiTheme } from '@material-ui/core/styles';
import InfoIcon from '@material-ui/icons/Info';

const NoPaddingTooltip = withStyles({
  tooltip: {
    padding: 0,
    margin: 5,
  },
})(Tooltip);

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    radioGroup: {
      flexDirection: 'row',
    },
    typography: {
      color: theme.palette.text.secondary,
      padding: '8px 16px',
      backgroundColor: theme.palette.background.paper,
      borderRadius: '4px',
      boxShadow: theme.shadows[1],
    },
    alert: {
      marginTop: '30px',
      marginLeft: '10px',
      marginRight: '10px',
      width: '100%',
    },
    alertLink: {
      color: theme.palette.primary.main,
      textDecoration: 'none',
    },
  }),
);

export enum rwSelectionOptions {
  DEFAULT = 'default',
  SELFMANAGED = 'selfManaged',
}
export const ReuseableWorkflow = (props: { value: string; onChange: (value: string) => void }) => {
  const { onChange, ...other } = props;
  const theme = useMuiTheme();
  const classes = useStyles(theme);
  const [rwOption, setRWOption] = useState<string>(rwSelectionOptions.DEFAULT);

  const handleSetRWOptionChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRWOption(event.target.value);
    onChange(event.target.value);
  };

  return (
    <Grid container spacing={2} direction="row" justifyContent="flex-start" alignItems="center">
      <Typography aria-label="rw-info" className={classes.typography}>
        Select CI/CD Workflow type
      </Typography>
      <RadioGroup
        className={classes.radioGroup}
        {...other}
        onChange={handleSetRWOptionChange}
        aria-label="rw-options"
        name="options"
        value={rwOption}
      >
        <FormControlLabel
          value={rwSelectionOptions.DEFAULT}
          aria-label="radio-btn-default"
          control={<Radio />}
          label="Default"
        />
        <FormControlLabel
          value={rwSelectionOptions.SELFMANAGED}
          aria-label="radio-btn-selfManaged"
          control={<Radio />}
          label="Customizable"
        />
      </RadioGroup>
      <NoPaddingTooltip title={<Alert severity="info">Click for More Information on these options</Alert>}>
        <IconButton
          color="primary"
          size="small"
          target="_blank"
          aria-label="CICD-Info Button"
          href="https://developer.aa.com/docs/default/component/taxiway-docs/configuration_options/"
        >
          <InfoIcon />
        </IconButton>
      </NoPaddingTooltip>
    </Grid>
  );
};
