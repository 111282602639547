import React from 'react';
import { Button, Card, CardActions, CardContent, Chip, Typography, Link } from '@material-ui/core';

interface MenuCardProps {
  title: string;
  subtitle?: string;
  linkText?: string;
  linkHref?: string;
  tags?: string[];
  buttonText?: string;
  loading?: boolean;
  onButtonClick?(): void;
}

const chipStyle: React.CSSProperties = {
  fontSize: 11,
  fontWeight: 'bolder',
};

export const MenuCard: React.FC<MenuCardProps> = ({
  title,
  subtitle,
  linkText,
  linkHref,
  tags,
  buttonText,
  loading,
  onButtonClick,
}) => (
  <Card style={{ display: 'flex', flexDirection: 'row', marginBottom: '.5rem' }}>
    <CardContent style={{ flex: 1 }}>
      <Typography variant="h6" component="h1">
        {title}
      </Typography>
      <Typography aria-label={subtitle} variant="caption" component="p">
        {subtitle}
      </Typography>
      {linkText && linkHref && (
        <Link href={linkHref} target="_blank">
          {linkText}
        </Link>
      )}
      <div style={{ marginTop: 10 }}>
        {tags?.map((tag, i) => <Chip key={`tag-${i}`} label={tag} size="small" style={chipStyle} />)}
      </div>
    </CardContent>
    <CardActions style={{ justifyContent: 'flex-end' }}>
      <Button color="primary" onClick={onButtonClick} disabled={loading} data-testid={title.replace(/\s/g, '')}>
        {loading ? 'Loading' : buttonText}
      </Button>
    </CardActions>
  </Card>
);
