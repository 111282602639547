import { IconButton, InputAdornment, TextField, Tooltip, withStyles } from '@material-ui/core';
import { FieldValidation } from '@rjsf/utils';
import React from 'react';
import InfoIcon from '@material-ui/icons/Info';
import { Alert } from '@material-ui/lab';

const containerNameRegex = new RegExp('^[a-z0-9]+(?:[\\/._-]{1}[a-z0-9]+)*$');

const NoPaddingTooltip = withStyles({
  tooltip: {
    padding: 0,
    margin: 5,
  },
})(Tooltip);

export const ContainerNameField = (props: { value: string; onChange: (value: string) => void }) => {
  const { value, onChange } = props;
  const [containerName, setContainerName] = React.useState(value);
  const [isTouched, setIsTouched] = React.useState(false);
  const INVALID_INPUT_MESSAGE =
    'Container name must match regex: ^[a-z0-9]+(?:[\\/._-]{1}[a-z0-9]+)*$ - Lowercase alphanumeric characters, -, _ are allowed';

  const validateInput = () => (!containerNameRegex.test(containerName) && isTouched ? INVALID_INPUT_MESSAGE : '');

  return (
    <>
      <TextField
        id="container-name"
        label="Container Name"
        name="containerName"
        aria-label="container-name"
        variant="outlined"
        style={{ width: '50%' }}
        onChange={event => {
          setContainerName(event.target.value);
          onChange(event.target.value);
        }}
        helperText={validateInput()}
        error={!!validateInput()}
        onFocus={() => setIsTouched(true)}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <NoPaddingTooltip title={<Alert severity="info">Name of the container saved to Artifactory</Alert>}>
                <IconButton color="primary" size="small" aria-label="info-button">
                  <InfoIcon />
                </IconButton>
              </NoPaddingTooltip>
            </InputAdornment>
          ),
        }}
        fullWidth
        required
      />
    </>
  );
};

export const containerNameValidation = (value: string, validation: FieldValidation) => {
  if (!value) {
    validation.addError('Please enter a container name');
  }
  if (!containerNameRegex.test(value)) {
    validation.addError('Please enter a valid container name');
  }
};

export const ContainerPortField = (props: { value: number; onChange: (value: number) => void }) => {
  const { value, onChange } = props;

  return (
    <>
      <TextField
        id="container-port"
        label="Container Port"
        name="containerPort"
        aria-label="container-port"
        value={value}
        variant="outlined"
        style={{ width: '100%' }}
        onChange={event => {
          onChange(+event.target.value);
        }}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <NoPaddingTooltip title={<Alert severity="info">Port of the container saved to Artifactory</Alert>}>
                <IconButton color="primary" size="small" aria-label="info-button">
                  <InfoIcon />
                </IconButton>
              </NoPaddingTooltip>
            </InputAdornment>
          ),
        }}
        fullWidth
        required
      />
    </>
  );
};

export const containerPortValidation = (value: number, validation: FieldValidation) => {
  if (!value) {
    validation.addError('Please enter a container port number');
  }
  if (value < 0 || value > 65535) {
    validation.addError('Please enter a valid port number');
  }
};
