import { TextField, Tooltip, Typography } from '@material-ui/core';
import { useFormikContext } from 'formik';
import React from 'react';
import { useStyles } from './orionFormStyles';
import { OrionAppRequestValues } from './orionFormValuesTypes';

export const OrionFormSectionAEH = () => {
  const classes = useStyles();
  const formik = useFormikContext<OrionAppRequestValues>();

  return (
    <>
      <Typography variant="h5" className={classes.centeredText} style={{ paddingBottom: 16 }}>
        Azure Event Hubs Information
      </Typography>
      <div className={classes.flexRow} style={{ flexWrap: 'wrap', minWidth: 600, marginBottom: 8 }}>
        <Tooltip
          disableFocusListener
          arrow
          title={
            <h3>
              This is what your Event Hub Namespace will be, derived from the application name and application's
              environment.
            </h3>
          }
        >
          <TextField
            id="evhName"
            key="evhName"
            data-testid="evhName"
            label="Namespace Name"
            placeholder="evhName"
            variant="outlined"
            disabled
            value={`ba-${formik.getFieldProps('environment').value.toLowerCase()}-${
              formik.getFieldProps('appName').value
            }-ext-${formik.getFieldProps('location').value === 'eastus' ? 'prmry' : 'scdry'}-evnhs`}
            inputProps={{ 'aria-label': 'naked' }}
            style={{ width: 300 }}
          />
        </Tooltip>
      </div>
    </>
  );
};
