import {
  createApiFactory,
  createComponentExtension,
  createPlugin,
  createRoutableExtension,
  createRouteRef,
} from '@backstage/core-plugin-api';
import { githubIssueApiRef, GithubIssueClient } from './api';

export const entityContentRouteRef = createRouteRef({
  id: 'github-issue-plugin',
});

export const githubIssuePlugin = createPlugin({
  id: 'github-issue-plugin',
  apis: [createApiFactory(githubIssueApiRef, new GithubIssueClient())],
  routes: {
    entityContent: entityContentRouteRef,
  },
});

export const EntityGithubIssueContent = githubIssuePlugin.provide(
  createRoutableExtension({
    name: 'Github Issues',
    component: () => import('./components/Router').then(m => m.Router),
    mountPoint: entityContentRouteRef,
  }),
);

export const EntityGithubIssueOverviewCard = githubIssuePlugin.provide(
  createComponentExtension({
    name: 'Issue stats card',
    component: {
      lazy: () => import('./components/IssueStatsCard').then(m => m.IssueStatsCard),
    },
  }),
);
