import React from 'react';
import { Progress, ResponseErrorPanel } from '@backstage/core-components';
import useAsync from 'react-use/lib/useAsync';
import { Data, DiffServiceApiRef } from '../../services/diff.service';
import { useApi } from '@backstage/core-plugin-api';
import ReactDiffViewer from 'react-diff-viewer-continued';
import { Typography } from '@material-ui/core';

type Props = {
  repo: string;
  taxiwayVersion: string;
};

export const DiffComponent = (props: Props) => {
  const { repo, taxiwayVersion } = props;
  const DiffServiceApi = useApi(DiffServiceApiRef);

  const { value, loading, error } = useAsync(async (): Promise<[Data, Data]> => {
    const resp1formatted = DiffServiceApi.formatData(await DiffServiceApi.getCustomerTaxiwayFiles(repo, 'main'));
    const resp2formatted = DiffServiceApi.formatData(await DiffServiceApi.getDTETaxiwayFiles(taxiwayVersion));
    return [resp1formatted, resp2formatted];
  }, [repo, taxiwayVersion]);

  if (loading) {
    return <Progress />;
  } else if (error) {
    return <ResponseErrorPanel error={error} />;
  }

  return (
    <div>
      {value &&
        Object.keys(value[0]).map(key => {
          const sidea = value[0][key]?.content;
          const sideb = value[1][key]?.content;
          if (sidea !== sideb) {
            return (
              <div key={key}>
                <Typography variant="h4" component="h4">
                  Left Side is your own repo and right side is taxiway repo
                </Typography>
                <Typography variant="h5" component="h5">
                  {key}
                </Typography>
                <ReactDiffViewer
                  oldValue={atob(value[0][key]?.content)}
                  newValue={atob(value[1][key]?.content)}
                  splitView
                />
              </div>
            );
          }
          return 'Couldnt complete diffing the files. Please check the files again.';
        })}
    </div>
  );
};
