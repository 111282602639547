import React from 'react';
import { useNavigate } from 'react-router-dom';
import { MenuCard } from '@runway/devkit';

export const OrionAccessCard: React.FC = () => {
  const navigate = useNavigate();

  return (
    <MenuCard
      title="Provision Orion Resources for Applications"
      subtitle="Orion Application Setup Request"
      onButtonClick={() => navigate('/orion/orion-auto')}
      buttonText="Request"
    />
  );
};
