import { FieldExtensionComponentProps } from '@backstage/plugin-scaffolder-react';
import React from 'react';
import { HashicorpVaultNamespaces } from '../../components';

export type ReturnedData = {
  namespace: string | undefined;
  cmdbAppShortName?: {
    shortName?: string;
  };
};

export const SecretVaultNamespaceForm = ({ onChange, formContext }: FieldExtensionComponentProps<ReturnedData>) => {
  const shortNameValue = formContext?.formData?.cmdbAppShortName?.shortName ?? undefined;
  const namespace = formContext?.formData?.vault_namespace?.namespace;
  return (
    <HashicorpVaultNamespaces
      onChange={onChange}
      shortName={shortNameValue}
      restrictByAdmin
      defaultNamespaceValue={namespace}
    />
  );
};
