import { useEffect, useState } from 'react';
import { ProfileInfo, identityApiRef, useApi } from '@backstage/core-plugin-api';

type Options = {
  withPrefix?: boolean;
};

type Profile = {
  aaId: string;
  displayName: string;
  email: string;
};
/**
 * Hook used to get employee profile information
 * @param withPrefix whether to include '00' prefix in aaId
 */
function useEmployeeProfile({ withPrefix = true }: Options = {}): Profile {
  const identityApi = useApi(identityApiRef);
  const [aaId, setAAId] = useState<string>('-1');
  const [displayName, setDisplayName] = useState<string>('');
  const [email, setEmail] = useState<string>('');

  useEffect(() => {
    const getId = async () => {
      try {
        const profile = (await identityApi.getProfileInfo()) as unknown as ProfileInfo & { aaId: string };
        setDisplayName(profile?.displayName || '');
        setEmail(profile?.email || '');
        if (!profile?.aaId) {
          setAAId('-1');
          return;
        }

        if (withPrefix) {
          setAAId(profile.aaId.length === 8 ? profile.aaId : `00${profile.aaId}`);
          return;
        }

        setAAId(profile.aaId.length === 8 ? profile.aaId.slice(2) : profile.aaId);
      } catch (e) {
        setAAId('-1');
      }
    };
    getId();
  });

  return { aaId, displayName, email };
}

export default useEmployeeProfile;
