import React, { useMemo, useState } from 'react';
import { Button, makeStyles, TextField } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import { v4 as uuid } from 'uuid';

export type Secret = {
  secretName: string;
  secretValue: string;
  isValidSecret: boolean;
  id: string;
};

export const repeatableSecretComponentStyles = makeStyles(theme => ({
  box: {
    marginBottom: theme.spacing(2),
  },
  ml10: {
    marginLeft: theme.spacing(2),
  },
  mr10: {
    marginRight: theme.spacing(2),
  },
  mrg: {
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
  },
}));

export default function RepeatableSecretComponent({
  onChange,
  formData,
  predeterminedSecretsInput,
}: {
  onChange: (secretList: Secret[]) => void;
  formData: any;
  predeterminedSecretsInput: string[] | undefined;
}) {
  const [secretList, setSecretList] = useState<Secret[]>(
    formData ? JSON.parse(formData) : [{ secretName: '', secretValue: '', isValidSecret: true, id: uuid() }],
  );
  useMemo(() => {
    if (predeterminedSecretsInput && !formData) {
      for (const item of predeterminedSecretsInput) {
        setSecretList([
          {
            secretName: item,
            secretValue: '',
            isValidSecret: true,
            id: uuid(),
          },
        ]);
      }
    }
  }, [predeterminedSecretsInput, formData]);

  // handle input change
  const handleInputChange = (secret: Secret) => {
    const list = [...secretList];
    const index = secretList.findIndex(s => s.id === secret.id);
    list[index] = secret;
    setSecretList(list);
    onChange(list);
  };

  // handle click event of the Remove button
  const handleRemoveClick = (secret: Secret) => {
    const list = secretList.filter(s => s.id !== secret.id);
    setSecretList(list);
    onChange(list);
  };

  // handle click event of the Add button
  const handleAddClick = () => {
    setSecretList([...secretList, { secretName: '', secretValue: '', isValidSecret: true, id: uuid() }]);
  };

  return (
    <div className="App">
      <h3>Secrets to be added for the repo</h3>
      {secretList.map((secret, i) => {
        return (
          <SecretRow
            key={secret.id}
            showRemoveBtn={secretList.length > 1}
            showAddBtn={secretList.length - 1 === i}
            handleAddClick={handleAddClick}
            handleRemoveClick={handleRemoveClick}
            updateSecret={handleInputChange}
            secret={secret}
          />
        );
      })}
      {/* <pre><code>{JSON.stringify(secretList, null, 2)}</code></pre> */}
    </div>
  );
}

function SecretRow({
  showAddBtn,
  showRemoveBtn,
  handleAddClick,
  handleRemoveClick,
  secret,
  updateSecret,
}: {
  showAddBtn: boolean;
  showRemoveBtn: boolean;
  handleAddClick: () => void;
  handleRemoveClick: (s: Secret) => void;
  updateSecret: (s: Secret) => void;
  secret: Secret;
}) {
  const [secretName, setSecretName] = useState(secret.secretName);
  const [secretValue, setSecretValue] = useState(secret.secretValue);
  // const [isValidSecret, setIsValidSecret] = useState(true);
  const classes = repeatableSecretComponentStyles();

  const githubSecretsFieldValidation = (s: Secret) => {
    const regexp = new RegExp('^[A-Za-z0-9_]+$');
    const { secretName: name, secretValue: value } = s;
    if (name !== '' && !regexp.test(name)) return false;
    if (value !== '' && !regexp.test(value)) return false;
    return true;
  };

  const validateAndUpdate = () => {
    const newSecret = {
      ...secret,
      secretName,
      secretValue,
      isValidSecret: false,
    };
    if (githubSecretsFieldValidation(newSecret)) {
      newSecret.isValidSecret = true;
    } else {
      newSecret.isValidSecret = false;
    }

    updateSecret(newSecret);
  };

  const msg = 'Please use only Alphanumeric and underscores...';

  return (
    <div className={classes.box}>
      <TextField
        name="secretName"
        placeholder="Enter Secret Key"
        data-testid={secretName}
        value={secretName}
        onChange={e => setSecretName(e.target.value)}
        onBlur={validateAndUpdate}
      />
      <TextField
        className={classes.ml10}
        name="secretValue"
        placeholder="Enter Secret Value"
        type="password"
        value={secretValue}
        onChange={e => setSecretValue(e.target.value)}
        onBlur={validateAndUpdate}
      />
      {showRemoveBtn && (
        <Button className={classes.mrg} onClick={() => handleRemoveClick(secret)}>
          Remove
        </Button>
      )}
      {showAddBtn && (
        <Button className={classes.mrg} onClick={handleAddClick}>
          Add
        </Button>
      )}
      {!secret.isValidSecret && <Alert severity="error">{msg}</Alert>}
    </div>
  );
}
