import React from 'react';
import { IconButton, Tooltip } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import { DeleteStatus } from './utils';

export type DeleteToolTipProp = {
  handleClick: () => void;
  title: string;
  appNameDeleteStatus: DeleteStatus;
  clusterNameDeleteStatus: DeleteStatus;
  appNameIsVisible: boolean;
  clusterNameIsVisible: boolean;
};

export const DeleteToolTip = (props: DeleteToolTipProp) => {
  const { handleClick, title, appNameDeleteStatus, clusterNameDeleteStatus, appNameIsVisible, clusterNameIsVisible } =
    props;
  if (!appNameIsVisible && !clusterNameIsVisible) {
    return null;
  }
  return (
    <>
      <Tooltip title={title}>
        <span>
          <IconButton
            role="button"
            aria-label={`${title}-trash-can`}
            onClick={() => handleClick()}
            disabled={appNameDeleteStatus === DeleteStatus.PENDING && clusterNameDeleteStatus === DeleteStatus.PENDING}
          >
            <DeleteIcon color="error" />
          </IconButton>
        </span>
      </Tooltip>
    </>
  );
};
