import React, { useEffect, useRef, useState } from 'react';
import { Formik, Form, Field, useFormikContext, FieldInputProps } from 'formik';
import {
  TextField,
  Button,
  MenuItem,
  Select,
  InputLabel,
  FormControl,
  CircularProgress,
  LinearProgress,
  FormHelperText,
  Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useApi } from '@backstage/core-plugin-api';
import { aaPingSSOAuthApiRef } from '@runway/devkit';
import { orionServiceApiRef } from '../../service/orion.service';
import * as Yup from 'yup';
import { Alert } from '@material-ui/lab';
import { workspaceMenuItems } from './ADBWorkspaceList';
import { checkPermission } from './ADBCheckPermissions';

interface FormValues {
  aaId: string;
  workspace: string;
  cluster: string;
  justification: string;
  cherwell: string;
}
interface ADBFormProps {
  loading: boolean;
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
  manageGroup: string;
  setManageGroup: React.Dispatch<React.SetStateAction<string>>;
  rwxGroup: string;
  setRwxGroup: React.Dispatch<React.SetStateAction<string>>;
}

export enum AlertState {
  Checking = 'checking',
  NoGroup = 'noGroup',
  InvalidGroup = 'invalidGroup',
  ValidGroup = 'validGroup',
  MissingGroup = 'missingGroup',
}

const useStyles = makeStyles(theme => ({
  form: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(2),
    maxWidth: '400px',
    margin: '0 auto',
  },
  linearProgress: {
    width: 'calc(100% - 8px)',
    bottom: 0,
    height: '1.5px',
    marginLeft: '4px',
  },
  aaIdTypography: {
    textAlign: 'center',
    marginBottom: theme.spacing(2),
  },
}));

const adbValidationSchema = Yup.object().shape({
  workspace: Yup.string().required(' Workspace required'),
  cluster: Yup.string().required(''),
  justification: Yup.string().required('Justification required'),
  cherwell: Yup.string().matches(/^[0-9]*$/, 'Cherwell Ticket Number must contain only numbers'),
});

export const ADBForm = ({ loading, manageGroup, setManageGroup, rwxGroup, setRwxGroup }: ADBFormProps) => {
  const classes = useStyles();
  const sso = useApi(aaPingSSOAuthApiRef);
  const orion = useApi(orionServiceApiRef);
  const formikContext = useFormikContext<FormValues>();
  const [workspaceClusters, setWorkspaceClusters] = useState<string[]>([]);
  const [isLoadingClusters, setLoadingClusters] = useState<boolean>(false);
  const [isLoadingAlert, setLoadingAlert] = useState<boolean>(false);
  const [alert, setAlert] = useState<AlertState | false>(false);

  const setFieldValueRef = useRef(formikContext.setFieldValue);
  const aaIdRef = useRef<string | undefined>();
  const workspaceRef = useRef<string | undefined>();
  const orionRef = useRef<any>();
  const manageGroupRef = useRef<string>('');
  const rwxGroupRef = useRef<string>('');
  const setManageGroupRef = useRef<React.Dispatch<React.SetStateAction<string>>>();
  const setRwxGroupRef = useRef<React.Dispatch<React.SetStateAction<string>>>();

  useEffect(() => {
    const fetchEmployeeId = async () => {
      const aaProfile = await sso.getAAProfile();
      let aaId = aaProfile?.aaId ?? '-1';
      aaId = aaId.length === 8 ? aaId.substring(2) : aaId;
      setFieldValueRef.current('aaId', aaId);
    };
    fetchEmployeeId();
  }, [formikContext.values.aaId, sso]);

  useEffect(() => {
    aaIdRef.current = formikContext.values.aaId;
    workspaceRef.current = formikContext.values.workspace;
    orionRef.current = orion;
    manageGroupRef.current = manageGroup;
    rwxGroupRef.current = rwxGroup;
    setManageGroupRef.current = setManageGroup;
    setRwxGroupRef.current = setRwxGroup;
  }, [
    formikContext.values.aaId,
    formikContext.values.workspace,
    orion,
    manageGroup,
    rwxGroup,
    setManageGroup,
    setRwxGroup,
  ]);

  useEffect(() => {
    const fetchClusters = async () => {
      if (formikContext.values.workspace) {
        setLoadingClusters(true);
        const clusters = await orion.getDatabricksClusters(formikContext.values.workspace);
        if (clusters.data.clusters) {
          const filteredClusters = clusters.data.clusters.filter(
            (cluster: string | string[]) => !cluster.includes('-adbah-'),
          );
          setWorkspaceClusters(filteredClusters);
        } else {
          setWorkspaceClusters([]);
        }
        setLoadingClusters(false);
      }
    };

    fetchClusters();
  }, [formikContext.values.workspace, orion]);

  useEffect(() => {
    checkPermission(
      orionRef.current,
      formikContext.values.cluster,
      formikContext.values.workspace,
      setManageGroupRef,
      setRwxGroupRef,
      aaIdRef,
      setLoadingAlert,
      setAlert,
    );
  }, [formikContext.values.cluster, formikContext.values.workspace]);

  return (
    <Form className={classes.form}>
      <Typography variant="h6" className={classes.aaIdTypography}>
        AA ID: {formikContext.values.aaId || 'Loading...'}
      </Typography>
      <Field name="workspace">
        {({ field, form }: { field: FieldInputProps<string>; form: any }) => (
          <FormControl variant="outlined" error={form.touched.workspace && Boolean(form.errors.workspace)}>
            <InputLabel htmlFor="workspace" required>
              Workspace
            </InputLabel>
            <Select
              {...field}
              label="Workspace"
              variant="outlined"
              inputProps={{
                id: 'workspace',
                name: 'workspace',
                'aria-label': 'workspace',
              }}
              onChange={event => {
                formikContext.setFieldValue('workspace', event.target.value);
                formikContext.setFieldValue('cluster', '');
              }}
            >
              {workspaceMenuItems.map(item => (
                <MenuItem key={item.value} value={item.value}>
                  {item.label}
                </MenuItem>
              ))}
            </Select>
            <FormHelperText>{form.touched.workspace && form.errors.workspace}</FormHelperText>
          </FormControl>
        )}
      </Field>

      <Field name="cluster">
        {({ field, form }: { field: FieldInputProps<string>; form: any }) => (
          <FormControl
            variant="outlined"
            fullWidth
            error={
              alert === AlertState.InvalidGroup ||
              alert === AlertState.Checking ||
              alert === AlertState.NoGroup ||
              alert === AlertState.MissingGroup ||
              (form.touched.cluster && Boolean(form.errors.cluster))
            }
          >
            <InputLabel htmlFor="cluster" required>
              Cluster
            </InputLabel>
            <Select
              {...field}
              label="Cluster"
              variant="outlined"
              inputProps={{
                id: 'cluster',
                name: 'cluster',
                'aria-label': 'cluster',
              }}
              disabled={isLoadingClusters || workspaceClusters.length === 0}
              IconComponent={
                isLoadingClusters
                  ? () => <CircularProgress style={{ position: 'absolute', right: '10px', marginTop: '4px' }} />
                  : undefined
              }
            >
              {isLoadingClusters ? (
                <MenuItem value="">
                  <em>Loading...</em>
                </MenuItem>
              ) : (
                workspaceClusters.map(cluster => (
                  <MenuItem key={cluster} value={cluster}>
                    {cluster}
                  </MenuItem>
                ))
              )}
            </Select>

            {isLoadingAlert && <LinearProgress className={classes.linearProgress} />}

            {alert === AlertState.Checking && <FormHelperText>Checking cluster permissions...</FormHelperText>}
            {alert === AlertState.NoGroup && (
              <FormHelperText>No group with access to cluster. Please select another.</FormHelperText>
            )}
            {alert === AlertState.InvalidGroup && (
              <FormHelperText>
                You must be a member of {manageGroup} or {rwxGroup}
              </FormHelperText>
            )}
            {alert === AlertState.MissingGroup && (
              <FormHelperText>M or RWX group do not have permission! Check with platform team.</FormHelperText>
            )}
          </FormControl>
        )}
      </Field>

      <Field name="justification">
        {({ field, form }: { field: FieldInputProps<string>; form: any }) => (
          <TextField
            {...field}
            id="justification"
            label="Justification"
            variant="outlined"
            placeholder="Why is this request necessary?"
            error={form.touched.justification && Boolean(form.errors.justification)}
            helperText={form.touched.justification && form.errors.justification}
            fullWidth
            required
          />
        )}
      </Field>

      <Field name="cherwell">
        {({ field, form }: { field: FieldInputProps<string>; form: any }) => (
          <TextField
            {...field}
            label="Cherwell Ticket Number (optional)"
            variant="outlined"
            placeholder="ex: 12345"
            error={form.touched.cherwell && Boolean(form.errors.cherwell)}
            helperText={form.touched.cherwell && form.errors.cherwell}
            fullWidth
            onChange={e => {
              const value = e.target.value;
              if (!value || /^[0-9]*$/.test(value)) {
                form.setFieldValue('cherwell', value);
              }
            }}
          />
        )}
      </Field>

      <Button
        type="submit"
        variant="contained"
        color="primary"
        onClick={formikContext.submitForm}
        disabled={
          !formikContext.isValid || isLoadingClusters || isLoadingAlert || alert !== AlertState.ValidGroup || loading
        }
        startIcon={loading ? <CircularProgress size={24} /> : null}
        style={{ height: '50px' }}
      >
        {loading ? 'Submitting...' : 'Request'}
      </Button>
    </Form>
  );
};

const App = () => {
  const [loading, setLoading] = useState(false);
  const [showSuccessAlert, setShowSuccessAlert] = useState(false);
  const [showFailAlert, setShowFailAlert] = useState(false);
  const [appManageGroup, setAppManageGroup] = useState<string>('');
  const [appRwxGroup, setAppRwxGroup] = useState<string>('');
  const [failureReason, setFailureReason] = useState<string>('');

  const orion = useApi(orionServiceApiRef);

  const handleSubmit = async (values: any) => {
    setShowSuccessAlert(false);
    setShowFailAlert(false);
    setLoading(true);
    const submitData = {
      cluster_name: values.cluster,
      workspace: values.workspace,
      ad_group_name: {
        rwx_group: appRwxGroup,
        m_group: appManageGroup,
      },
      requestor: values.aaId.toString(),
      justification: values.justification,
      cherwell_ticket: values.cherwell || null,
    };

    try {
      const response = await orion.adbGrantManagePermission(submitData);
      if (response.data.failure_reason) {
        setFailureReason(response.data.failure_reason);
        throw new Error(response.data.failure_reason);
      } else {
        setShowSuccessAlert(true);
      }
    } catch (error) {
      const failDetails =
        `The ADB Elevated access request submission has failed! ` +
        `**_Failure Reason_**: ${failureReason} | ` +
        `**_Cluster_**: ${values.cluster} | ` +
        `**_Workspace_**: ${values.workspace} | ` +
        `**_RWX AD Group_**: ${appRwxGroup} | ` +
        `**_Manage AD Group_**: ${appManageGroup} | ` +
        `**_Requestor_**: ${values.aaId} | ` +
        `**_Justification_**: ${values.justification} | ` +
        `**_Cherwell_ticket_**: ${values.cherwell || 'null'} | ` +
        `**_Timestamp_**: ${new Date(Date.now()).toUTCString()} `;
      const params = {
        requestor: values.aaId.toString(),
        summary: `"${values.cluster} - ADB Elevated Access Request Failure"`,
        details: `"${failDetails}"`,
      };
      await orion.createDeaEdpIssue(params);
      setShowFailAlert(true);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <Formik
        initialValues={{ aaId: '', workspace: '', cluster: '', justification: '', cherwell: '' }}
        onSubmit={handleSubmit}
        validationSchema={adbValidationSchema}
      >
        {() => (
          <ADBForm
            loading={loading}
            setLoading={setLoading}
            manageGroup={appManageGroup}
            setManageGroup={setAppManageGroup}
            rwxGroup={appRwxGroup}
            setRwxGroup={setAppRwxGroup}
          />
        )}
      </Formik>

      {showSuccessAlert && (
        <Alert severity="success" style={{ marginBottom: '20px', margin: '20px auto', maxWidth: '400px' }}>
          Access has been granted! You will have temporary 'Manage' access on the cluster for ~4 hours.
        </Alert>
      )}
      {showFailAlert && (
        <Alert severity="error" style={{ marginBottom: '20px', margin: '20px auto', maxWidth: '400px' }}>
          Access grant has failed! An issue will be created for the platform team to follow up. You will receive an
          email with a link for more information.{' '}
        </Alert>
      )}
    </>
  );
};

export default App;
