import React, { useEffect } from 'react';
import { pick } from 'lodash';
import { FieldExtensionComponentProps } from '@backstage/plugin-scaffolder-react';
import { CostCenter, CmdbAppShortName, OnChangeValue } from '../../components';

export const AppShortnamePicker = ({
  onChange,
  required,
  formData,
  uiSchema,
}: FieldExtensionComponentProps<OnChangeValue>) => {
  const [returnedData, setReturnedData] = React.useState<OnChangeValue>();
  const getOwnApps = uiSchema['ui:options']?.getOwnApps || false;
  const handleChange = (value: OnChangeValue) => {
    const out = pick(value, ['shortName', 'archerId', 'cmdbAppFullName', 'productName']);
    setReturnedData({ ...out, costCenter: value.costCenter });
  };

  useEffect(() => {
    if (returnedData?.costCenter) {
      onChange({ ...returnedData });
    } else if (returnedData && !returnedData?.costCenter) {
      onChange(undefined);
    }
  }, [returnedData, onChange]);

  return (
    <>
      <CmdbAppShortName
        onChange={(value: OnChangeValue) => {
          handleChange(value);
        }}
        getOwnApps={Boolean(getOwnApps)}
        required={required}
        initialValue={formData ?? (formData as unknown as OnChangeValue)}
      />
      {returnedData && !returnedData?.costCenter && (
        <div style={{ paddingTop: 8 }}>
          <CostCenter
            onChange={async (values: any) => {
              onChange({ ...returnedData, costCenter: values.costCenter });
            }}
          />
        </div>
      )}
    </>
  );
};
