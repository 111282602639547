import { useEffect, useState } from 'react';
import { useAsyncRetry } from 'react-use';
import { githubIssueApiRef } from '../api/GithubIssueApi';
import moment from 'moment';
import { useBaseUrl } from './useBaseUrl';
import { githubAuthApiRef, useApi } from '@backstage/core-plugin-api';

export type Issue = {
  id: string;
  number: number;
  url: string;
  title: string;
  updatedTime: string;
  createdTime: string;
  closedTime: string;
  state: string;
  creatorNickname: string;
  creatorProfileLink: string;
};

export function useIssues({
  owner,
  repo,
  branch,
  state,
}: {
  owner: string;
  repo: string;
  branch?: string;
  state?: any;
}) {
  const api = useApi(githubIssueApiRef);
  const auth = useApi(githubAuthApiRef);
  const baseUrl = useBaseUrl();
  const [total, setTotal] = useState(0);
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(5);

  const [first, setFirst] = useState<number | null>(pageSize);
  const [last, setLast] = useState<number | null>(null);
  const [after, setAfter] = useState<string | null>(null);
  const [before, setBefore] = useState<string | null>(null);

  const [startCursor, setStartCursor] = useState<string | null>(null);
  const [endCursor, setEndCursor] = useState<string | null>(null);

  const setPageWrapper = (requestedPage: number) => {
    const pageDelta = requestedPage - page;
    if (pageDelta < -1) {
      // jummp to start
      setFirst(pageSize);
      setLast(null);
      setAfter(null);
      setBefore(null);
    }
    if (pageDelta === -1) {
      // previous
      setFirst(null);
      setLast(pageSize);
      setAfter(null);
      setBefore(startCursor);
    }
    if (pageDelta === 1) {
      // next
      setFirst(pageSize);
      setLast(null);
      setAfter(endCursor);
      setBefore(null);
    }
    if (pageDelta > 1) {
      // jump to end
      setFirst(null);
      setLast(total % pageSize);
      setAfter(null);
      setBefore(null);
    }

    setPage(requestedPage);
  };

  const setPageSizeWrapper = (requestedPageSize: number) => {
    setFirst(requestedPageSize);
    setLast(null);
    setAfter(null);
    setBefore(null);
    setPageSize(requestedPageSize);
  };
  const getElapsedTime = (start: string) => {
    return moment(start).fromNow();
  };

  const {
    loading,
    value: issueData,
    retry,
    error,
  } = useAsyncRetry<Issue[]>(async () => {
    const token = await auth.getAccessToken(['repo']);
    if (!repo) {
      return [];
    }

    return api
      .listIssues({
        token,
        owner,
        repo,
        first,
        last,
        after,
        before,
        branch,
        state,
        baseUrl,
      })
      .then(issuesResponse => {
        if (issuesResponse.maxTotalItems) {
          setTotal(issuesResponse.maxTotalItems);
        }
        setStartCursor(issuesResponse.startCursor);
        setEndCursor(issuesResponse.endCursor);
        return issuesResponse.issuesData.map(({ id, url, title, number, createdAt, updatedAt, closedAt, author }) => ({
          url,
          id,
          number,
          title,
          state,
          creatorNickname: author.login,
          creatorProfileLink: author.url,
          createdTime: getElapsedTime(createdAt),
          updatedTime: getElapsedTime(updatedAt),
          closedTime: getElapsedTime(closedAt),
        }));
      });
  }, [page, pageSize, repo, owner]);
  useEffect(() => {
    setPage(0);
    setFirst(pageSize);
    setLast(null);
    setAfter(null);
    setBefore(null);
    retry();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state]);
  return [
    {
      page,
      pageSize,
      loading,
      issueData,
      projectName: `${owner}/${repo}`,
      total,
      error,
    },
    {
      issueData,
      setPage: setPageWrapper,
      setPageSize: setPageSizeWrapper,
      retry,
    },
  ] as const;
}
