import { GithubAuth, OAuth2 } from '@backstage/core-app-api';
import {
  AnyApiFactory,
  configApiRef,
  createApiFactory,
  discoveryApiRef,
  githubAuthApiRef,
  oauthRequestApiRef,
  identityApiRef,
} from '@backstage/core-plugin-api';
import SignInIcon from '@material-ui/icons/AcUnit';
import {
  adServiceApiRef,
  AdService,
  AzureManagementService,
  AzureManagementServiceApiRef,
  ObserveServiceApiRef,
  aaPingSSOAuthApiRef,
  ObserveService,
  OAuth2AAPingSSO,
  GithubEnterpriseClientApiGraphRef,
  GithubEnterpriseClientGraph,
  Squad360ServiceApiRef,
  Squad360Service,
  CatalogCustomizationsRecordStoreServiceRef,
  CatalogCustomizationsRecordStoreService,
  projectsDetailsQueryApiRef,
  ProjectsDetails,
  MockGithubAuthApi,
  notificationServiceApiRef,
  NotificationService,
  SecretVaultServiceApiRef,
  SecretVaultService,
  itsmRecordFieldsServiceApiRef,
} from './index';
import { AdminToggleService, RestClient } from '@runway/devkit-common';
import { restClientRef, adminToggleServiceApiRef } from '../refs';
import ItsmRecordFieldsService from './itsmRecordFields.service';

export const apis: AnyApiFactory[] = [
  createApiFactory({
    api: adminToggleServiceApiRef,
    deps: {
      configApi: configApiRef,
    },
    factory: ({ configApi }) => new AdminToggleService(configApi),
  }),
  createApiFactory({
    api: restClientRef,
    deps: {},
    factory({}) {
      return new RestClient(console);
    },
  }),
  createApiFactory({
    api: ObserveServiceApiRef,
    deps: {
      configApi: configApiRef,
      identityApi: identityApiRef,
    },
    factory: ({ configApi, identityApi }) => new ObserveService(configApi.getString('backend.baseUrl'), identityApi),
  }),
  createApiFactory({
    api: aaPingSSOAuthApiRef,
    deps: {
      configApi: configApiRef,
      discoveryApi: discoveryApiRef,
      oauthRequestApi: oauthRequestApiRef,
    },
    factory: ({ discoveryApi, oauthRequestApi, configApi }) => {
      return new OAuth2AAPingSSO(
        OAuth2.create({
          discoveryApi,
          oauthRequestApi,
          configApi,
          provider: {
            id: 'oauth2',
            title: 'Ping SSO Auth',
            icon: SignInIcon,
          },
          defaultScopes: ['openid', 'profile', 'email'],
        }),
        configApi,
      );
    },
  }),
  createApiFactory({
    api: adServiceApiRef,
    deps: {
      configApi: configApiRef,
      identityApi: identityApiRef,
    },
    factory: ({ configApi, identityApi }) => new AdService(configApi.getString('backend.baseUrl'), identityApi),
  }),
  createApiFactory({
    api: AzureManagementServiceApiRef,
    deps: {
      configApi: configApiRef,
      oAuthApi: aaPingSSOAuthApiRef,
    },
    factory: ({ configApi, oAuthApi }) => new AzureManagementService(configApi.getString('amService.url'), oAuthApi),
  }),
  createApiFactory({
    api: GithubEnterpriseClientApiGraphRef,
    deps: {
      configApi: configApiRef,
      githubAuthApi: githubAuthApiRef,
    },
    factory: ({ configApi, githubAuthApi }) =>
      new GithubEnterpriseClientGraph(configApi.getString(`github.ghec.graphUrl`), githubAuthApi),
  }),
  createApiFactory({
    api: Squad360ServiceApiRef,
    deps: {
      configApi: configApiRef,
      identityApi: identityApiRef,
    },
    factory: ({ configApi, identityApi }) => new Squad360Service(configApi.getString('backend.baseUrl'), identityApi),
  }),
  createApiFactory({
    api: projectsDetailsQueryApiRef,
    deps: {
      configApi: configApiRef,
      githubAuthApi: githubAuthApiRef,
    },
    factory: ({ configApi, githubAuthApi }) =>
      new ProjectsDetails(configApi.getString(`github.ghec.graphUrl`), githubAuthApi),
  }),
  createApiFactory({
    api: githubAuthApiRef,
    deps: {
      configApi: configApiRef,
      discoveryApi: discoveryApiRef,
      oauthRequestApi: oauthRequestApiRef,
    },
    factory: ({ configApi, discoveryApi, oauthRequestApi }) => {
      const overrideToken = configApi.getOptionalString('frontendOverrides.githubAuthToken');
      const isMockEnabled = configApi.getOptionalString('frontendOverrides.enableGithubUserPATAuth');
      const githubUrl = configApi.getString('github.ghec.apiUrl');

      if (isMockEnabled === 'yes') {
        const mockAuth = new MockGithubAuthApi('githubSession');

        if (overrideToken !== undefined) {
          mockAuth.setAccessTokenInSession(overrideToken, githubUrl);
        }

        return mockAuth;
      }

      // remove cached token from previous GitHub OAuth app login
      localStorage.removeItem('githubSession');

      return GithubAuth.create({
        discoveryApi,
        oauthRequestApi,
        provider: {
          id: 'github',
          title: 'Click here to authorize github.com access',
          icon: SignInIcon,
        },
        defaultScopes: ['repo', 'user:email', 'delete_repo', 'workflow'],
      });
    },
  }),
  createApiFactory({
    api: notificationServiceApiRef,
    deps: {
      configApi: configApiRef,
      identityApi: identityApiRef,
    },
    factory: ({ configApi, identityApi }) =>
      new NotificationService(configApi.getString('backend.baseUrl'), identityApi),
  }),
  createApiFactory({
    api: CatalogCustomizationsRecordStoreServiceRef,
    deps: {
      configApi: configApiRef,
      identityApi: identityApiRef,
    },
    factory: ({ configApi, identityApi }) =>
      new CatalogCustomizationsRecordStoreService(configApi.getString('backend.baseUrl'), identityApi),
  }),
  createApiFactory({
    api: SecretVaultServiceApiRef,
    deps: {
      configApi: configApiRef,
      identityApi: identityApiRef,
    },
    factory: ({ configApi, identityApi }) =>
      new SecretVaultService(configApi.getString('backend.baseUrl'), identityApi),
  }),
  createApiFactory({
    api: itsmRecordFieldsServiceApiRef,
    deps: {
      configApi: configApiRef,
      identityApi: identityApiRef,
      restClientApi: restClientRef,
    },
    factory: ({ configApi, identityApi, restClientApi }) =>
      new ItsmRecordFieldsService(configApi.get('backend.baseUrl'), identityApi, restClientApi),
  }),
];
