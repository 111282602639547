import { githubAuthApiRef, useApi } from '@backstage/core-plugin-api';
import { FormControl, TextField } from '@material-ui/core';
import React, { useCallback } from 'react';
import { useAsync } from 'react-use';
import Autocomplete, { AutocompleteChangeReason } from '@material-ui/lab/Autocomplete';
import { catalogApiRef, humanizeEntityRef } from '@backstage/plugin-catalog-react';
import { Entity, parseEntityRef, stringifyEntityRef } from '@backstage/catalog-model';
import { FieldExtensionComponentProps } from '@backstage/plugin-scaffolder-react';
import { GithubEnterpriseClientApiGraphRef } from '../../services';

const INVALID_GROUP_OPTIONS = ['all'];

export const GithubTeamField = ({
  onChange,
  rawErrors,
  required,
  formData,
  schema,
}: FieldExtensionComponentProps<string>) => {
  const githubEnterpriseClientGraph = useApi(GithubEnterpriseClientApiGraphRef);
  const catalogApi = useApi(catalogApiRef);
  const ghecAuthApi = useApi(githubAuthApiRef);

  const {
    value: entities,
    loading,
    error,
  } = useAsync(async () => {
    const backstageUserIdentity = await ghecAuthApi.getBackstageIdentity();

    if (!backstageUserIdentity?.identity?.userEntityRef) {
      throw new Error('No GitHub username found for this account.');
    }

    const { name: githubUsername } = parseEntityRef(backstageUserIdentity?.identity.userEntityRef as string);
    const [{ items }, githubTeams] = await Promise.all([
      catalogApi.getEntities({ filter: { kind: ['Group'] } }),
      githubEnterpriseClientGraph.getUsersGithubTeams(githubUsername),
    ]);

    if (githubTeams.length === 0) {
      throw new Error('No GitHub Teams found.');
    }

    return items.filter(e => {
      return githubTeams.some(
        team =>
          team.name === (e?.spec?.profile as { displayName: string })?.displayName &&
          !INVALID_GROUP_OPTIONS.find(value => team.name === value),
      );
    });
  });

  const onSelect = useCallback(
    (_: any, ref: string | Entity | null, reason: AutocompleteChangeReason) => {
      // ref can either be a string from free solo entry or
      if (typeof ref !== 'string') {
        // if ref does not exist: pass 'undefined' to trigger validation for required value
        onChange(ref ? stringifyEntityRef(ref as Entity) : undefined);
      } else if (reason === 'blur' || reason === 'create-option') {
        // Add in default namespace, etc.
        let entityRef = ref;
        try {
          // Attempt to parse the entity ref into it's full form.
          entityRef = stringifyEntityRef(
            parseEntityRef(ref as string, {
              defaultKind: 'Group',
              defaultNamespace: 'Group',
            }),
          );
        } catch (err) {
          // If the passed in value isn't an entity ref, do nothing.
        }
        // We need to check against formData here as that's the previous value for this field.
        if (formData !== ref) {
          onChange(entityRef);
        }
      }
    },
    [onChange, formData],
  );

  return (
    <FormControl margin="normal" required={required} error={rawErrors?.length > 0 && !formData}>
      <Autocomplete
        id={schema.$id}
        value={entities?.find(e => stringifyEntityRef(e) === formData)}
        loading={loading}
        onChange={onSelect}
        options={entities || []}
        autoSelect
        freeSolo={false}
        getOptionLabel={option =>
          typeof option === 'string' ? option : humanizeEntityRef(option, { defaultKind: 'Group' })!
        }
        renderInput={params => (
          <TextField
            {...params}
            label="GitHub Team"
            margin="dense"
            error={error !== undefined}
            helperText={error ? error.message : 'Select a GitHub Team'}
            FormHelperTextProps={{ margin: 'dense', style: { marginLeft: 0 } }}
            variant="outlined"
            required={required}
            InputProps={params.InputProps}
          />
        )}
      />
    </FormControl>
  );
};
