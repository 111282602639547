export type AzureSignInLogEntry = {
  createdDateTime: string;
  resourceDisplayName: string;
  clientCredentialType: string;
};

export enum RGRoles {
  OWNER = '8e3af657-a8ff-443c-a75c-2fe8c4bcb635',
  CONTRIBUTOR = 'b24988ac-6180-42a0-ab88-20f7382dd24c',
  READER_PLUS = 'c8433d08-6e31-470c-8b3a-e1f560f91590',
}

export type CreateAzureActiveDirectoryGroupReq = {
  activeDirectoryGroupName: string;
  tenant: string;
  users: AADUser[];
};

export type CreateRgReq = {
  appName: string;
  appVertical: string;
  costCenter?: string;
  subscriptionId: string;
  archerId?: string;
  archerShortname: string;
  tenant: string;
  envName?: string;
  region: string;
  dataClassification: string;
  tags?: Record<string, string>[];
};

export type AddOwnerToApplicationRequest = {
  tenant: string;
  appId: string;
  owners: string[];
};

export type AddOwnerToApplicationResponse = {
  badge_number: string;
  successfully_added: boolean;
  error: string;
};

export type AssociateAADWithAzRGReq = {
  resource_group_name: string;
  tenant: string;
  subscription_id: string;
  aad_groups: { aad_name: string; role_id: RGRoles }[];
};

export type AssociateAADWithAzRGPostRes = {
  _links: {
    self: {
      href: string;
    };
    deployment: {
      href: string;
    };
  };
  deployment_id: string;
};

export type AssociateAADWithAzRGRes = {
  id: string;
  status: string;
}[];

export type CreateRGAndAADGroupsAndAssociateRes = {
  resource_group_name: string;
  aad_group_owner: string;
  aad_group_contributor: string;
  aad_group_reader_plus: string;
};

export type AADUser = {
  employee_id: string;
  user_type: string;
};

export type GetUserReq = {
  tenant: string;
  employee_id: string;
};

export type Link = {
  href: string;
};
export type Links = {
  self: Link;
};

export type RoleAssignmentLink = {
  self: Link;
  deployment: Link;
};

export type ApplicationLinks = {
  delete: Link;
  password: Link;
};

export type Application = {
  _links: ApplicationLinks;
  id: string;
  client_id: string;
  displayName: string;
  tenant: string;
  created_at: string;
  owners: string[];
};

export type AppData = {
  _links: Links;
  _embedded: {
    applications: Application[];
  };
};

export type CreateSpReq = {
  displayName: string;
  env: string;
  owners?: string;
};

export type ApplicationRes = {
  azure_app_client_id: string;
  azure_app_object_id: string;
  deployment_id: string;
  outcome: string;
  pswd: string;
};
export type PswdRes = {
  azure_secret: string;
  azure_sp_name: string;
};

export type LinkRgAndSP = {
  rgName: string;
  spName: string;
  azSubscriptionId: string;
};

export type StatusPolling = {
  status: LinkRgAndSpStatusResponse;
  _links: RoleAssignmentLink;
};

export type LinkRgAndSpStatusResponse = {
  current_status: string;
  last_successful_step: string;
  error_msg?: string;
  created_at: string;
  updated_at: string;
};

export type create_SP_Return_Data = {
  deployment_data: ApplicationRes;
  pswd_data: PswdRes;
};

export type UserSearchValue = {
  displayName: string;
  mail: string;
  userPrincipalName: string;
};

export type UserSearchResult = {
  value: UserSearchValue[];
};
