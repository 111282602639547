import { useApi, configApiRef } from '@backstage/core-plugin-api';
import { IconButton, Tooltip, Snackbar } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import React, { FC, useState } from 'react';
import { useCopyToClipboard } from 'react-use';
import ShareIcon from '@material-ui/icons/Share';

type ShareTemplateButtonProps = {
  href: string;
};

export const ShareTemplateButton: FC<ShareTemplateButtonProps> = ({ href }) => {
  const config = useApi(configApiRef);

  const [, setCopyToClipboard] = useCopyToClipboard();
  const [open, setOpen] = useState(false);
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <IconButton
        onClick={() => {
          setCopyToClipboard(`${config.getString('app.baseUrl')}${href}`);
          setOpen(true);
        }}
        data-testid="shareTemplateButton"
      >
        <Tooltip title="Copy URL" aria-label="copyUrl" placement="top">
          <ShareIcon />
        </Tooltip>
      </IconButton>
      <Snackbar
        open={open}
        autoHideDuration={6000}
        onClose={handleClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      >
        <Alert onClose={handleClose} severity="success">
          Copied Software Template URL!
        </Alert>
      </Snackbar>
    </>
  );
};
