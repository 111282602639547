import React from 'react';
import { Box, makeStyles } from '@material-ui/core';
import {
  configApiRef,
  SignInPageProps,
  useApi,
  githubAuthApiRef,
  IdentityApi,
  BackstageUserIdentity,
  ProfileInfo,
  SessionApi,
} from '@backstage/core-plugin-api';
import { SignInPage } from '@backstage/core-components';
import { aaPingSSOAuthApiRef, HeroHeader } from '@runway/devkit';
import { Config } from '@backstage/config';

const useStyles = makeStyles({
  wrapper: {
    '& header': {
      display: 'none',
    },
  },
});

class IdentityApiWrapper implements IdentityApi {
  constructor(
    private base: IdentityApi,
    private config: Config,
    private ghecAuth: SessionApi,
  ) {}

  getProfileInfo(): Promise<ProfileInfo> {
    return this.base.getProfileInfo();
  }
  getBackstageIdentity(): Promise<BackstageUserIdentity> {
    return this.base.getBackstageIdentity();
  }
  getCredentials(): Promise<{ token?: string | undefined }> {
    return this.base.getCredentials();
  }
  async signOut() {
    // call the originally intended signout logic first (will be whatever user picks on sign in page)
    // probably sso
    await this.base.signOut();
    // sign out the other runway auth providers
    await this.ghecAuth.signOut();

    // AA sso sign out requires visiting a url
    if (process.env.NODE_ENV === 'production') {
      location.assign(this.config.getString('auth.providers.oauth2.development.logout'));
      await new Promise(resolve => setTimeout(resolve, 15 * 1000));
      // The busy wait timer above is so the location.assign before it gets enough time to take effect.
      // After this function is called  a location.reload() is preformed by AppIdentity#signOut()
      // the reload will invalidate the location.assign without the busy wait timer.
    }
  }
}

const authProvider = {
  id: 'oauth2-auth-provider',
  title: 'Ping SSO',
  message: 'Sign In using SSO',
  apiRef: aaPingSSOAuthApiRef,
};

export const RunwaySignInPage = (props: SignInPageProps) => {
  const classes = useStyles();
  const config = useApi(configApiRef);
  const ghecAuth = useApi(githubAuthApiRef);

  return (
    <Box className={classes.wrapper}>
      <HeroHeader title="Welcome to Runway" subtitle="Please sign in below" />
      <SignInPage
        {...props}
        onSignInSuccess={async identityApi => {
          props.onSignInSuccess(new IdentityApiWrapper(identityApi, config, ghecAuth));
        }}
        provider={authProvider}
        title="Available Providers"
        align="center"
        auto
      />
    </Box>
  );
};
