import React from 'react';
import { Page, Header, HeaderLabel, Content, RoutedTabs } from '@backstage/core-components';
import { ADOAccessContent } from './components/ADOAccessContent';
import { ADOLicenseContent } from './components/ADOLicenseContent';
import { SupportInformation } from '@runway/devkit';

const SupportButton = () => {
  return (
    <SupportInformation slackChannel="#topic-azuredevops" githubRepo="devops-tech-enablement">
      <span style={{ flexDirection: 'row' }}>A page to request access to manage surveys for your application</span>
    </SupportInformation>
  );
};

export const AdoPage = () => {
  const routes = [
    {
      title: 'ADOC Project Access',
      path: '/access',
      children: (
        <>
          <SupportButton />
          <ADOAccessContent />
        </>
      ),
    },
    {
      title: 'ADOC License',
      path: '/license',
      children: (
        <>
          <SupportButton />
          <ADOLicenseContent />
        </>
      ),
    },
  ];
  return (
    <Page themeId="home">
      <Header
        title="Azure DevOps Access"
        subtitle="Find the 'Project Administrators' for your ADOC project and upgrade your ADOC license"
      >
        <HeaderLabel label="Owner" value="DevOps Tech Enablement" />
        <HeaderLabel label="Lifecycle" value="Alpha" />
      </Header>
      <Content>
        <RoutedTabs routes={routes} />
      </Content>
    </Page>
  );
};
