import { Button, Typography, Grid } from '@material-ui/core';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import React from 'react';
import { useNavigate } from 'react-router-dom';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      backgroundColor: theme.palette.background.default,
      borderRadius: 4,
      padding: theme.spacing(3),
      border: `1px solid ${theme.palette.primary.light}`,
      minHeight: '150px',
      alignItems: 'center',
    },
    item: {
      minHeight: '100px',
    },
    center: {
      display: 'flex',
      justifyContent: 'center',
    },
  }),
);

export const CreateHashicorpVault = () => {
  const classes = useStyles();

  const navigate = useNavigate();

  const navigateToVaultCreationPage = () => {
    // navigate to the vault creation page
    navigate('/secret-vault');
  };

  return (
    <div className={classes.container}>
      <Grid
        data-testid="create-hashicorp-vault"
        container
        spacing={2}
        direction="row"
        justifyContent="space-around"
        alignItems="center"
        className={classes.item}
      >
        <Grid md={10} item>
          <Typography variant="h6" aria-label="title">
            Create Hashicorp Vault
          </Typography>
          <Typography variant="subtitle2" aria-label="description">
            Create a Hashicorp Vault to store secrets for your application.
          </Typography>
        </Grid>
        <Grid md={2} item className={classes.center}>
          <Button variant="contained" onClick={navigateToVaultCreationPage} aria-label="btn-open-form">
            CREATE
          </Button>
        </Grid>
      </Grid>
    </div>
  );
};
