import React from 'react';
import { MenuCard } from '@runway/devkit';
import { useNavigate } from 'react-router-dom';

export const AMLComputeInstance: React.FC = () => {
  const navigate = useNavigate();

  return (
    <MenuCard
      title="Azure Machine Learning Compute Instance"
      subtitle="Create a compute instance for a user in Azure Machine Learning"
      onButtonClick={() => navigate('/orion/aml-ci')}
      buttonText="Create"
    />
  );
};
