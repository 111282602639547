export enum DeleteStatus {
  PENDING,
  DELETED,
  NOT_DELETED,
}

export type ArgoData = {
  appName: string;
  clusterName: string;
};
