import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { TextField, Grid, Button, makeStyles, Typography } from '@material-ui/core';
import { Alert, Autocomplete } from '@material-ui/lab';
import { configApiRef, useApi } from '@backstage/core-plugin-api';
import { useAsync } from 'react-use';
import axios from 'axios';
import { CreateRancherNamespaceDialog } from './CreateRancherNamespaceDialog';
import { Squad360ServiceApiRef, aaPingSSOAuthApiRef } from '../../services';

type NamespaceSelectorProps = {
  selectedCluster: string | null;
  setSelectedCluster?: Dispatch<SetStateAction<string | null>>;
  isCreateButtonVisible: boolean;
  onChange(namespace: string): void;
  selectedNamespaceName?: string;
};
type ProjectAndNamepsace = {
  name: string;
  namespace: string;
};

const useStyles = makeStyles(theme => ({
  formGroup: {
    margin: theme.spacing(2),
    minWidth: 195,
  },
}));

export const NamespaceSelector: React.FC<NamespaceSelectorProps> = ({
  selectedCluster,
  setSelectedCluster,
  selectedNamespaceName = '',
  isCreateButtonVisible,
  onChange,
}) => {
  const sso = useApi(aaPingSSOAuthApiRef);
  const squad360 = useApi(Squad360ServiceApiRef);
  const config = useApi(configApiRef);
  const [availableNamespaces, setAvailableNamespaces] = useState<string[]>([]);
  const classes = useStyles();
  const [createRancherNamespaceDialogOpen, setCreateRancherNamespaceDialogOpen] = useState(false);
  const [isSquadMember, setIsSquadMember] = useState(false);
  const [selectedNamespace, setSelectedNamespace] = useState<string>(selectedNamespaceName);

  const filterNamespaces = async (projectsAndNamespaces: ProjectAndNamepsace[]) => {
    const ownSupportedApps = await squad360.getOwnSupportedApps();
    if (ownSupportedApps.length > 0) {
      setIsSquadMember(true);
    }
    const appShortNames: string[] = ownSupportedApps.map((archerApp: any) => archerApp.shortName).filter(n => n);
    const returnedNamespaces: string[] = [];
    for (const object of projectsAndNamespaces) {
      for (const appShortName of appShortNames) {
        if (object.namespace.includes(appShortName.toLowerCase()) || object.name.includes(appShortName.toLowerCase())) {
          returnedNamespaces.push(object.namespace);
        }
      }
    }
    return returnedNamespaces;
  };

  const getNamespaces = useAsync(async () => {
    if (!selectedCluster) {
      return;
    }
    try {
      const token = await sso.getAccessToken();
      const resp: any = await axios.get(
        `${config.getString('clusterService.baseUrl')}/projects?clusterNames=${selectedCluster}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      );
      const projectsAndNamespaces: ProjectAndNamepsace[] = [];
      resp.data.forEach((item: any) => {
        projectsAndNamespaces.push(item);
      });
      if (projectsAndNamespaces.length > 0) {
        const filteredNamespacesResp = await filterNamespaces(projectsAndNamespaces);
        setAvailableNamespaces(filteredNamespacesResp);
      }
    } catch (error: any) {
      throw new Error('Cannot retrieve namespaces');
    }
  }, [selectedCluster, createRancherNamespaceDialogOpen]);

  useEffect(() => {
    if (selectedNamespace && !availableNamespaces.includes(selectedNamespace)) {
      setAvailableNamespaces([selectedNamespace, ...availableNamespaces]);
      onChange(selectedNamespace);
    }
    // will cause infite renders if all deps are in the array
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedNamespace]);

  return (
    <Grid>
      <Grid item xs={12} md={12}>
        {!selectedCluster ? (
          <Alert severity="warning">Select a cluster to get available namespaces</Alert>
        ) : (
          <>
            {!getNamespaces.loading && !getNamespaces.error && !isSquadMember && (
              <Alert style={{ marginTop: 10 }} severity="error">
                <strong>
                  There was an issue getting the Squad Info.{' '}
                  <a href="https://squad360-ui.cloud.aa.com/" target="_blank">
                    Click <u>Squad360 to assign a squad to your AAID</u>
                  </a>
                </strong>
              </Alert>
            )}
          </>
        )}
        {availableNamespaces.length === 0 &&
          isSquadMember &&
          selectedCluster !== null &&
          selectedCluster !== '' &&
          !getNamespaces.loading &&
          !getNamespaces.error && (
            <Alert severity="warning">
              No available namespaces to select from. Select another cluster or create a namespace through our
              Infrastructure plugin.
            </Alert>
          )}
        {isSquadMember &&
          !getNamespaces.loading &&
          selectedCluster !== null &&
          selectedCluster !== '' &&
          (availableNamespaces.length > 0 || selectedNamespace) && (
            <Autocomplete
              id="namespace"
              data-testid="namespace_selector"
              options={availableNamespaces}
              disabled={getNamespaces.loading || availableNamespaces.length === 0}
              value={selectedNamespace}
              onChange={(_: any, namespace: any) => {
                setSelectedNamespace(namespace);
                onChange(namespace);
              }}
              onInputChange={(_: any, namespace: string) => {
                onChange(namespace);
              }}
              getOptionLabel={option => (availableNamespaces.length === 0 ? '' : option)}
              renderInput={params => <TextField required {...params} label="Namespace" variant="outlined" />}
            />
          )}
        {getNamespaces.loading && (
          <>
            <Alert severity="info">Loading Namespaces</Alert>
          </>
        )}
        {getNamespaces.error && <Alert severity="error">Cannot get namespaces for cluster {selectedCluster}</Alert>}
      </Grid>
      {isCreateButtonVisible && (
        <Grid item xs={6} md={6}>
          <div className={classes.formGroup}>
            <Button
              data-testid="Rancher-Namespace-Creation"
              variant="contained"
              color="primary"
              onClick={() => {
                setCreateRancherNamespaceDialogOpen(true);
              }}
            >
              Create Rancher Namespace
            </Button>
            <Typography variant="body2" style={{ width: 300, fontSize: '0.8em', display: 'block' }}>
              <strong>Create Rancher Project/Namespace if don't have one already.</strong>
            </Typography>
            <CreateRancherNamespaceDialog
              isOpen={createRancherNamespaceDialogOpen}
              close={() => setCreateRancherNamespaceDialogOpen(false)}
              setNamespace={setSelectedNamespace}
              setSelectedCluster={setSelectedCluster}
              onChange={onChange}
              setAvailableNamespaces={setAvailableNamespaces}
              availableNamespaces={availableNamespaces}
            />
          </div>
        </Grid>
      )}
    </Grid>
  );
};
