import React, { useState } from 'react';
import { InputLabel, Select, MenuItem } from '@material-ui/core';
import { verticalDropdownItems } from '@runway/devkit-common';

type OnChangeValue = {
  vertical: unknown;
};

interface VerticalDropdownProps {
  onChange(value: OnChangeValue): void;
  error?: boolean | undefined;
  initialValue?: string | undefined;
  label?: string;
}

export const VerticalDropdown: React.FC<VerticalDropdownProps> = ({
  onChange,
  error,
  initialValue = undefined,
  label = 'Vertical',
}) => {
  const [verticalSelected, setVerticalSelected] = useState(initialValue ?? '');

  return (
    <>
      <InputLabel id="vert-label" required>
        {label}
      </InputLabel>
      <Select
        labelId="vert-label"
        value={verticalSelected || ''}
        inputProps={{ 'data-testid': 'vertical' }}
        error={error}
        labelWidth={65}
        style={{ width: 300, display: 'inline-flex' }}
        onChange={e => {
          onChange({
            vertical: e.target.value,
          });
          setVerticalSelected(e.target.value as string);
        }}
      >
        {verticalDropdownItems.map(item => (
          <MenuItem value={item.value} key={item.key}>
            {item.name}
          </MenuItem>
        ))}
      </Select>
    </>
  );
};
