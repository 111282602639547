import { createPlugin, createRoutableExtension } from '@backstage/core-plugin-api';

import { rootRouteRef } from './routes';

export const metricsPlugin = createPlugin({
  id: 'metrics',
  routes: {
    root: rootRouteRef,
  },
});

export const MetricsPage = metricsPlugin.provide(
  createRoutableExtension({
    name: 'MetricsPage',
    component: () => import('./components/App').then(m => m.App),
    mountPoint: rootRouteRef,
  }),
);
