import React, { useState } from 'react';
import TextField from '@material-ui/core/TextField';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import CircularProgress from '@material-ui/core/CircularProgress';
import InfoIcon from '@material-ui/icons/Info';
import { SecretVaultServiceApiRef } from '../../services';
import { useApi } from '@backstage/core-plugin-api';
import { useAsync } from 'react-use';
import Autocomplete from '@material-ui/lab/Autocomplete';

export type NamespacesProps = {
  onChange: (values: { namespace: string }) => void;
  shortName?: string;
  restrictByAdmin?: boolean;
  namespace?: string;
  defaultNamespaceValue?: string;
};

export const Namespaces = ({ onChange, shortName, restrictByAdmin = true, defaultNamespaceValue }: NamespacesProps) => {
  const secretVaultApi = useApi(SecretVaultServiceApiRef);
  const [selectedNamespace, setSelectedNamespace] = useState<{ value: string; disabled: boolean } | null>(null);
  const {
    loading,
    error,
    value: namespaces,
  } = useAsync(async () => {
    setSelectedNamespace(null);
    if (!shortName) return [];

    const vaultNamespace = await secretVaultApi.getNameSpacesFromShortName(shortName);
    const trimmedNamespaces = vaultNamespace.reduce(
      (acc, { namespace_name, admin }) => {
        const disabled = admin || !restrictByAdmin ? false : true;
        const oneNamespace = { value: namespace_name.replace(/\//g, ''), disabled };
        if (defaultNamespaceValue === oneNamespace.value) setSelectedNamespace(oneNamespace);
        return [...acc, oneNamespace];
      },
      [] as { value: string; disabled: boolean }[],
    );
    return trimmedNamespaces;
  }, [shortName, secretVaultApi, defaultNamespaceValue]);

  const handleNamespaceChange = (_event: any, newValue: { value: string; disabled: boolean } | null) => {
    setSelectedNamespace(newValue);
    const newNamespace = newValue?.value ?? '';
    onChange({ namespace: newNamespace });
  };

  return (
    <div style={{ display: 'flex' }}>
      <Autocomplete
        onChange={handleNamespaceChange}
        style={{ width: '100%' }}
        options={namespaces || []}
        value={selectedNamespace}
        getOptionDisabled={option => option.disabled}
        getOptionLabel={option => option.value}
        disabled={namespaces?.length === 0 || loading}
        renderInput={params => <TextField required {...params} label="Vault Namespace" variant="outlined" />}
      />
      {loading && <CircularProgress role="progressbar" style={{ marginLeft: 10 }} />}
      {restrictByAdmin && !loading && (
        <Tooltip title="Only users with admin access to that particular namespace can manage the namespace.">
          <IconButton aria-label="info" role="button" name="info-button">
            <InfoIcon />
          </IconButton>
        </Tooltip>
      )}
      {error && <p style={{ color: 'rgb(244, 67, 54)' }}>Failed to fetch vault namespaces</p>}
    </div>
  );
};
