import React from 'react';
import {
  ContainersLayer,
  DeploymentsLayer,
  HpasLayer,
  Layer,
  PodsLayer,
  ReplicaSetsLayer,
  ServicesLayer,
} from './Layers';
import { AkamaiGTMLayer } from './Layers/AkamaiGTMLayer';
import { ArgoCDLayer } from './Layers/ArgoCD';
import { getStatusArgo } from './services';
import { STATUS } from './Status';
import { useLayerInfo } from './useLayerInfo';

export const EntityDataFlow = () => {
  const { allStatus, layerInfo, argoData } = useLayerInfo();
  return (
    <ul aria-label="Data Flow">
      <Layer title="Akamai CDN" status={STATUS.unretrievable} subheader="Coming Soon.." />
      <AkamaiGTMLayer title="Akamai GTM" status={allStatus.akamaiStatus} akamaiInfo={layerInfo?.akamaiInfo} />{' '}
      <Layer title="Azure NSG" status={STATUS.unretrievable} subheader="Coming Soon.." />
      <ArgoCDLayer title="ArgoCD" status={allStatus.argoStatus} argoData={getStatusArgo(argoData)} />
      <Layer title="Ingress Controller" status={STATUS.unretrievable} subheader="Coming Soon.." />
      <Layer title="Ingress" status={STATUS.unretrievable} subheader="Coming Soon.." />
      <Layer title="Service Mesh" status={STATUS.unretrievable} subheader="Coming Soon.." />
      <ServicesLayer
        title="External Name Services"
        status={allStatus.externalServicesStatus}
        clusters={layerInfo?.status?.externalServices}
      />
      <ServicesLayer title="Services" status={allStatus.servicesStatus} clusters={layerInfo?.status?.services} />
      <DeploymentsLayer status={allStatus.deploymentsStatus} clusters={layerInfo?.status?.deployments} />
      <HpasLayer status={allStatus.hpasStatus} clusters={layerInfo?.status?.hpas} />
      <ReplicaSetsLayer status={allStatus.replicaSetsStatus} clusters={layerInfo?.status?.replicaSets} />
      <PodsLayer status={allStatus.podsStatus} clusters={layerInfo?.status?.pods} />
      <ContainersLayer
        title="Kuma Sidecars"
        status={allStatus.kumaSideCarsStatus}
        clusters={layerInfo?.status?.kumaSideCars}
      />
      <ContainersLayer
        title="App Containers"
        status={allStatus.containersStatus}
        clusters={layerInfo?.status?.containers}
      />
    </ul>
  );
};
