import axios from 'axios';
import { createApiRef, IdentityApi } from '@backstage/core-plugin-api';
import { Entry } from '@runway/devkit';

export interface DeaServiceApi {
  getMetadata(): Promise<Entry[]>;
}

export const deaServiceApiRef = createApiRef<DeaServiceApi>({
  id: 'plugin.access-control.dea.service',
});

export default class DeaService implements DeaServiceApi {
  constructor(
    private backendUrl: string,
    private identityApi: IdentityApi,
  ) {}

  getMetadata = async () => {
    const { token } = await this.identityApi.getCredentials();
    const res = await axios.get(`${this.backendUrl}/api/deaService/`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    if (res.status !== 200) {
      throw new Error();
    }

    return res.data;
  };
}
